import numeral from 'numeral'
import { useParams } from 'react-router-dom'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { useResource } from './useResource'

export interface Resource {
  date: string | null
  count: string | null
}

export function ResourceUpdatesByHour() {
  const { id: resourceId } = useParams()
  const { data: { resource } = {} } = useResource({ id: resourceId || '' })
  const stats = JSON.parse(resource?.updatesByHour || '[]')

  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-xl">{resource?.name || ''} Updates by Hour</h2>
      <div className="rounded-lg bg-white pt-6 pb-4 pr-4 shadow">
        <ResponsiveContainer height={275}>
          <AreaChart
            width={500}
            height={400}
            data={stats}
            margin={{
              top: 0,
              right: 15,
              left: 15,
              bottom: 0
            }}>
            <>
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip
                formatter={(value: String) => numeral(value).format('0.0a')}
              />
              <Area
                key={resource?.id}
                type="monotone"
                dataKey="count"
                stackId={resource?.id}
                stroke="#ea580c"
                fill="#fb923c"
              />
            </>
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
