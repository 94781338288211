import { useReorderTransformsMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation ReorderTransforms($ids: [ID]!) {
    reorderTransforms(ids: $ids)
  }
`

export function useReorderTransforms() {
  const queryClient = useQueryClient()
  return useReorderTransformsMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['Field'])
    }
  })
}
