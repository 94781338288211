import { useUpdateFieldTemplateMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { fieldTemplateFragment } from './useFieldTemplates'

gql`
  mutation UpdateFieldTemplate(
    $id: ID!
    $recordType: FieldTemplateRecordType
    $displayName: String
    $name: String!
    $fieldType: String!
    $aliases: [String]
    $alternateNames: [String]
    $fieldCategoryName: FieldTemplateFieldCategoryName
  ) {
    updateFieldTemplate(
      id: $id
      display_name: $displayName
      name: $name
      field_type: $fieldType
      aliases: $aliases
      alternate_names: $alternateNames
      field_category_name: $fieldCategoryName
      record_type: $recordType
    ) {
      ...FieldTemplateFields
    }
    ${fieldTemplateFragment}
  }
`

export function useUpdateFieldTemplate() {
  const queryClient = useQueryClient()
  return useUpdateFieldTemplateMutation({
    async onSuccess({ updateFieldTemplate }) {
      await queryClient.invalidateQueries(['FieldTemplates'])
      queryClient.invalidateQueries([
        'FieldTemplate',
        { id: updateFieldTemplate?.id }
      ])
    }
  })
}
