import { useDeleteResourceGroupMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation DeleteResourceGroup($id: ID!) {
    deleteResourceGroup(id: $id)
  }
`

export function useDeleteResourceGroup() {
  const queryClient = useQueryClient()
  return useDeleteResourceGroupMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['AdapterResourceGroups'])
    }
  })
}
