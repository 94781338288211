import {
  AdminUserQueryVariables,
  useAdminUserQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query AdminUser($id: ID) {
    admin(id: $id) {
      id
      name
      email
      phone
      role
      alerts
      timezone
    }
  }
`

export function useAdminUser(vars: AdminUserQueryVariables) {
  return useAdminUserQuery(vars, { enabled: !!vars.id })
}
