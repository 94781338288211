import { Button, FormField, Input, Modal } from '@forge/common'
import { LookupPartsFragment } from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCreateLookup } from './useCreateLookup'
import { useUpdateLookup } from './useUpdateLookup'

const schema = yup.object({
  sourceId: yup.string().required('Lookup source id is required'),
  value: yup.string().required('Lookup data is required'),
  mappedValue: yup.string()
})

interface CreateUpdateLookupProps {
  fieldId: string
  lookup?: LookupPartsFragment
  isOpen: boolean
  onClose: () => void
}

export function CreateUpdateLookup({
  fieldId,
  lookup,
  isOpen,
  onClose
}: CreateUpdateLookupProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      sourceId: lookup?.sourceId || undefined,
      value: lookup?.value || undefined,
      mappedValue: lookup?.mappedValue || undefined
    }
  })
  const isEditing = !!lookup
  const { mutate: createLookup, isLoading: isCreating } = useCreateLookup()
  const { mutate: updateLookup, isLoading: isUpdating } = useUpdateLookup()

  return (
    <Modal isOpen={isOpen} onClose={onClose} onAfterClose={() => reset()}>
      <Modal.Title> {isEditing ? 'Updating' : 'Add'} Lookup</Modal.Title>
      <form
        className="mt-4 space-y-4"
        onSubmit={handleSubmit((data) => {
          if (isEditing) {
            updateLookup(
              { id: lookup.id, ...data },
              {
                onSuccess() {
                  onClose()
                }
              }
            )
          } else {
            createLookup(
              { fieldId: Number(fieldId), ...data },
              {
                onSuccess() {
                  onClose()
                }
              }
            )
          }
        })}>
        <FormField label="Source ID" error={errors.sourceId?.message} required>
          <Input {...register('sourceId')} />
        </FormField>
        <FormField label="Value" error={errors.value?.message} required>
          <Input {...register('value')} />
        </FormField>
        <FormField label="Mapped Value" error={errors.mappedValue?.message}>
          <Input {...register('mappedValue')} />
        </FormField>
        <div className="flex justify-end">
          <Button loading={isCreating || isUpdating}>
            {isEditing ? 'Update' : 'Add'} Lookup
          </Button>
        </div>
      </form>
    </Modal>
  )
}
