import { Alert } from '@forge/common'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useDeleteField } from './useDeleteField'

interface DeleteFieldProps {
  id: string | number
  name: string
  isOpen: boolean
  onClose: () => void
  onDelete?: () => void
}

export function DeleteField({
  id,
  name,
  isOpen,
  onClose,
  onDelete
}: DeleteFieldProps) {
  const {
    mutate: deleteField,
    isLoading: isDeletingField,
    error
  } = useDeleteField()

  return (
    <Alert variant="danger" isOpen={isOpen}>
      <Alert.Title>Delete Field</Alert.Title>
      <Alert.Content>
        <div className="space-y-2">
          <div>
            Are you sure you want to delete {name}? This action cannot be
            undone.
          </div>
          {error && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {error.message}
            </div>
          )}
        </div>
      </Alert.Content>
      <Alert.Cancel onClick={onClose}>Cancel</Alert.Cancel>
      <Alert.Confirm
        leftIcon={<ExclamationCircleIcon />}
        loading={isDeletingField}
        onClick={() => {
          deleteField({ id: String(id) })
          onDelete?.()
        }}>
        Delete Forever
      </Alert.Confirm>
    </Alert>
  )
}
