import {
  Alert,
  Breadcrumbs,
  Button,
  Menu,
  toast,
  Tooltip,
  toTitleCase,
  Wrapper
} from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import { useCurrentUser } from '@forge/features/auth'
import {
  ResourceGroupReindex,
  ResourceGroupTabs,
  ResourceGroupUpdatesByHour,
  useDeleteResourceGroup,
  useResourceGroup
} from '@forge/features/resourcegroups'
import {
  DotsHorizontalIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/outline'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'

export function ResourceGroup() {
  const navigate = useNavigate()

  const { data: currentUser } = useCurrentUser()
  const [
    isShowingResourceGroupReindexModal,
    setIsShowingResourceGroupReindexModal
  ] = useState(false)

  const { adapterId, resourceGroupId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  const {
    mutate: deleteResourceGroup,
    isLoading: isDeletingResourceGroup,
    error: errorDeletingResourceGroup
  } = useDeleteResourceGroup()

  return (
    <>
      <Helmet>
        <title>{toTitleCase(resourceGroup?.role || '')} Resource Group</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}`}>
            {resourceGroup?.role || ''} Resource Group
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="flex items-center justify-between space-x-2">
          <h1 className="flex text-4xl font-medium capitalize">
            {resourceGroup?.role || ''} Resource Group
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="A Resource Group is a grouping of common resources.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>

          <div className="relative flex flex-shrink-0 justify-end">
            <Menu
              button={
                <Button as="span" aria-label="Resource Group Options">
                  <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                </Button>
              }>
              <Menu.Item
                title="Settings"
                onClick={() => {
                  navigate(
                    `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/settings`
                  )
                }}>
                {resourceGroup?.role || ''} Resource Group Settings
              </Menu.Item>
              {resourceGroup?.role !== 'photo' && (
                <Menu.Item
                  title="Setup"
                  onClick={() => {
                    navigate(
                      `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/setup`
                    )
                  }}>
                  {resourceGroup?.role || ''} Resource Group Setup
                </Menu.Item>
              )}
              <Menu.Item
                title="Fields"
                onClick={() => {
                  navigate(
                    `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/fields`
                  )
                }}>
                {resourceGroup?.role || ''} Resource Group Fields
              </Menu.Item>
              {resourceGroup?.role !== 'photo' && (
                <Menu.Item
                  title="Search"
                  onClick={() => {
                    navigate(
                      `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/search`
                    )
                  }}>
                  Search {resourceGroup?.role || ''} Records
                </Menu.Item>
              )}
              <Menu.Item
                disabled={
                  resourceGroup?.role === 'photo' ||
                  resourceGroup?.status !== 'stable'
                }
                title="Reindex"
                onClick={() => setIsShowingResourceGroupReindexModal(true)}>
                Reindex {resourceGroup?.role || ''} Records
              </Menu.Item>
              {currentUser?.role === 'super' && (
                <Menu.Item
                  variant="danger"
                  onClick={() => setShowDeleteAlert(true)}>
                  Delete {toTitleCase(resourceGroup?.role ?? '')} Resource Group
                </Menu.Item>
              )}
            </Menu>
            {resourceGroup?.role !== 'photo' && (
              <ResourceGroupReindex
                isOpen={isShowingResourceGroupReindexModal}
                onClose={() => setIsShowingResourceGroupReindexModal(false)}
              />
            )}
          </div>
        </div>

        <div>
          {resourceGroup?.role !== 'photo' && (
            <>
              <ResourceGroupUpdatesByHour />

              <div className="xs:col-span-1 md:col-span-2 md:flex">
                <div className="h-fit w-full">
                  <ResourceGroupTabs />
                </div>
              </div>
            </>
          )}
        </div>

        <Alert variant="danger" isOpen={showDeleteAlert}>
          <Alert.Title>Delete Resource Group</Alert.Title>
          <Alert.Content>
            <div className="space-y-2">
              <div>
                <h3>
                  Are you sure you want to delete{' '}
                  <span className="font-bold">
                    {toTitleCase(resourceGroup?.role ?? '')} Resource Group
                  </span>
                  ?
                </h3>
                <span className="font-bold text-red-600">
                  THIS CANNOT BE UNDONE!
                </span>
              </div>
              {errorDeletingResourceGroup && (
                <div className="text-red-500" role="alert" aria-live="polite">
                  {errorDeletingResourceGroup.message}
                </div>
              )}
            </div>
          </Alert.Content>
          <Alert.Cancel onClick={() => setShowDeleteAlert(false)}>
            Cancel
          </Alert.Cancel>
          <Alert.Confirm
            leftIcon={<ExclamationCircleIcon />}
            loading={isDeletingResourceGroup}
            onClick={() => {
              deleteResourceGroup(
                { id: resourceGroupId || '' },
                {
                  onSuccess() {
                    navigate(`/adapters/${adapterId}/details`)
                    toast(
                      `${toTitleCase(
                        resourceGroup?.role || ''
                      )} Resource Group deleted successfully.`,
                      { variant: 'success' }
                    )
                    setShowDeleteAlert(false)
                  }
                }
              )
            }}>
            Delete
          </Alert.Confirm>
        </Alert>
      </Wrapper>
    </>
  )
}
