import { Button, Checkbox, FormField, Input, Select } from '@forge/common'
import {
  ApiUserEnvironment,
  ApiUserFieldsFragment,
  ApiUserPercolatorNotification
} from '@forge/graphql/generated'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useUpdateApiUser } from './useUpdateApiUser'

const schema = yup.object({
  name: yup.string(),
  forgeApi: yup.boolean(),
  replication: yup.boolean(),
  search: yup.boolean(),
  fullAccess: yup.boolean(),
  environment: yup
    .mixed<ApiUserEnvironment>()
    .oneOf(Object.values(ApiUserEnvironment)),
  percolatorEndpoint: yup.string().nullable(),
  percolatorNotifications: yup
    .mixed<ApiUserPercolatorNotification>()
    .oneOf(Object.values(ApiUserPercolatorNotification))
    .nullable()
})

interface ApiUserFormProps {
  apiUser: ApiUserFieldsFragment | undefined | null
  onSubmit: () => void
}

export function ApiUserEditForm({ apiUser, onSubmit }: ApiUserFormProps) {
  const {
    mutate: updateApiUser,
    error,
    isLoading,
    isSuccess
  } = useUpdateApiUser()

  const {
    formState: { errors },
    register,
    handleSubmit
  } = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      environment: apiUser?.environment || undefined,
      forgeApi: apiUser?.forgeApi || false,
      name: apiUser?.name || '',
      percolatorNotifications: apiUser?.percolatorNotifications || null,
      percolatorEndpoint: apiUser?.percolatorEndpoint || null,
      replication: apiUser?.replication || false,
      search: apiUser?.search || false,
      fullAccess: apiUser?.fullAccess || false
    }
  })

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          if (apiUser) {
            updateApiUser(
              {
                ...data,
                id: apiUser.id,
                percolatorNotifications: data.percolatorNotifications || null
              },
              {
                onSuccess() {
                  onSubmit()
                }
              }
            )
          }
        })}
        className="space-y-3">
        <div className="w-full">
          <FormField label="Name" error={errors.name?.message}>
            <Input {...register('name')} placeholder="Enter Name" />
          </FormField>
        </div>
        <div className="flex justify-between space-x-3">
          <div className="w-full">
            <FormField label="Environment" error={errors.environment?.message}>
              <Select {...register('environment')}>
                <option hidden value="">
                  Select an environment
                </option>
                {Object.values(ApiUserEnvironment).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormField>
          </div>
          <div className="w-full">
            <FormField
              label="Percolator Notifications"
              error={errors.percolatorNotifications?.message}>
              <Select {...register('percolatorNotifications')}>
                <option value="">Select a notification</option>
                {Object.values(ApiUserPercolatorNotification).map((option) => (
                  <option key={option} value="job_queue">
                    {option}
                  </option>
                ))}
              </Select>
            </FormField>
          </div>
        </div>
        <div className="w-full pb-2">
          <FormField label="Percolator Endpoint" error={errors.name?.message}>
            <Input
              {...register('percolatorEndpoint')}
              placeholder="Enter Endpoint"
            />
          </FormField>
        </div>
        <div>
          <div className="pl-2 mt-5 mb-2 space-y-3">
            <FormField>
              <Checkbox
                defaultChecked={false}
                {...register('fullAccess')}
                variant="primary">
                Full Access
                <br />
                (Grant access to ANY dataset)
              </Checkbox>
            </FormField>
            <FormField>
              <Checkbox
                defaultChecked={false}
                {...register('replication')}
                variant="primary">
                Replication API
              </Checkbox>
            </FormField>
            <FormField>
              <Checkbox
                defaultChecked={false}
                {...register('search')}
                variant="primary">
                Search API
                <br />
                (Use only for Cloud Agent Suite applications)
              </Checkbox>
            </FormField>
            <FormField>
              <Checkbox
                defaultChecked={false}
                {...register('forgeApi')}
                variant="primary">
                Forge API
                <br />
                (Use only for external applications)
              </Checkbox>
            </FormField>
          </div>
          <Button
            fullWidth
            loading={isLoading}
            type="submit"
            variant="primary"
            leftIcon={
              error ? (
                <ExclamationCircleIcon />
              ) : isSuccess ? (
                <BeakerIcon />
              ) : undefined
            }>
            {!!error && <>An Error Occured. Try again?</>}
            {!error && (
              <>
                {isLoading
                  ? 'Updating Adapter'
                  : isSuccess
                  ? 'Success'
                  : `Update API User`}
              </>
            )}
          </Button>
        </div>
        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
