import {
  DeleteCriteriumMutationVariables,
  useDeleteCriteriumMutation
} from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation DeleteCriterium($id: ID!) {
    deleteDefaultCriterium(id: $id)
  }
`

export function useDeleteCriteria({ id }: DeleteCriteriumMutationVariables) {
  const queryClient = useQueryClient()

  return useDeleteCriteriumMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['ApiUser', { id: String(id) }])
      await queryClient.invalidateQueries(['Resource', { id: String(id) }])
    }
  })
}
