import { Button, FormField, Input, Logo } from '@forge/common'
import { useResetPassword } from '@forge/features/auth'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  resetToken: yup.string().required(),
  password: yup.string().required('Password is required'),
  passwordConfirmation: yup
    .string()
    .required('Password confirmation is required')
})

export function PasswordReset() {
  const params = useParams<{ resetToken: string }>()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      resetToken: params.resetToken
    },
    resolver: yupResolver(schema)
  })

  const {
    mutate: resetPassword,
    isLoading: isUpdating,
    isSuccess,
    error
  } = useResetPassword()

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div className="m-auto flex min-h-full min-w-fit flex-col justify-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex flex-shrink-0 items-center px-4">
            <div className="m-auto h-8">
              <Logo />
            </div>
          </div>
          <h1 className="flex text-4xl font-medium">Reset Your Password</h1>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <form
              onSubmit={handleSubmit((data) => {
                resetPassword(data, {
                  onSuccess() {
                    navigate('/', { replace: true })
                  }
                })
              })}
              className="space-y-3">
              <FormField label="Password" error={errors.password?.message}>
                <Input placeholder="New Password" {...register('password')} />
              </FormField>
              <FormField
                label="Confirm Password"
                error={errors.passwordConfirmation?.message}>
                <Input
                  placeholder="Confirm New Password"
                  {...register('passwordConfirmation')}
                />
              </FormField>
              <Button
                fullWidth
                type="submit"
                loading={isUpdating}
                variant={!error ? 'primary' : 'danger'}
                leftIcon={
                  error ? (
                    <ExclamationCircleIcon />
                  ) : isSuccess ? (
                    <BeakerIcon />
                  ) : undefined
                }>
                {!!error && <>An Error Occured. Try again?</>}
                {!error && (
                  <>
                    {isUpdating
                      ? 'Updating Password'
                      : isSuccess
                      ? 'Success'
                      : 'Reset Password'}
                  </>
                )}
              </Button>
              {error && (
                <p
                  role="alert"
                  aria-live="polite"
                  className="text-sm text-red-600">
                  {error.message}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
