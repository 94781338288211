import { Attribute } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingShowingsProps {
  adapterId: string
  agentListEmail?: string | null
  agentListName?: string | null
  agentListPhone?: string | null
  fields: Record<string, FieldPartsFragment>
  officeListName?: string | null
  resourceGroupId: string
  showingInst?: string | null
}

export function ListingShowings({
  adapterId,
  agentListEmail,
  agentListName,
  agentListPhone,
  fields,
  officeListName,
  resourceGroupId,
  showingInst
}: ListingShowingsProps) {
  return (
    <div>
      <div className="pb-2 hover:cursor-pointer">
        <Attribute
          adapterId={adapterId}
          fieldId={fields?.['showing_inst']?.id}
          label="showing_inst"
          resourceGroupId={resourceGroupId}
          value={showingInst}
        />
      </div>
      <div className="flex flex-col justify-between gap-3 sm:flex-row sm:items-end">
        <div className="flex items-center gap-3">
          <div className="text-sm">
            <div>
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['agent_list_name']?.id}
                  label="agent_list_name"
                  resourceGroupId={resourceGroupId}
                  value={agentListName}
                />
              </div>
            </div>
            <div>
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['office_list_name']?.id}
                  label="office_list_name"
                  resourceGroupId={resourceGroupId}
                  value={officeListName}
                />
              </div>
            </div>
            <div>
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['agent_list_email']?.id}
                  label="agent_list_email"
                  resourceGroupId={resourceGroupId}
                  value={agentListEmail}
                />
              </div>
              <Attribute
                adapterId={adapterId}
                fieldId={fields?.['agent_list_phone']?.id}
                label="agent_list_phone"
                resourceGroupId={resourceGroupId}
                value={agentListPhone}
              />
            </div>
          </div>
        </div>
        <em className="ml-auto text-xs text-gray-700">Agents Only</em>
      </div>
    </div>
  )
}
