import {
  Checkbox,
  Dropdown,
  SearchFilterInput,
  Table,
  useDebounce
} from '@forge/common'
import { ListingServicesListingServiceFragment } from '@forge/graphql/generated'
import { ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { ListingServicesMap } from './ListingServicesMap'
import { useListingServices } from './useListingServices'
import { hasApp } from './utils'

const filterListingServices = (
  listingServices: ListingServicesListingServiceFragment[] | undefined | null,
  activations: string[],
  searchTerm: string
) => {
  const result: ListingServicesListingServiceFragment[] = []

  if (!listingServices) return

  if (activations?.length === 0)
    return listingServices?.filter((listingService) =>
      JSON.stringify(listingService)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  else
    activations.forEach((activation) => {
      listingServices?.forEach((listingService) => {
        if (
          hasApp(listingService.activations, activation + ' Production') &&
          JSON.stringify(listingService)
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
          if (result.indexOf(listingService) === -1) result.push(listingService)
      })
    })
  return result
}

export function ListingServicesTable() {
  const { data } = useListingServices()
  const [searchTerm, setSearchTerm] = useState('')
  const activations = ['CMA', 'Streams', 'MLX']
  const [selectedActivations, setSelectedActivations] = useState<string[]>([])
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const filteredListingServices = filterListingServices(
    data?.listingServices,
    selectedActivations,
    debouncedSearchTerm
  )

  return (
    <>
      <div className="relative mb-8 min-h-[30rem] overflow-hidden rounded-lg bg-white px-0 shadow hover:shadow-2xl">
        <ListingServicesMap listingServices={filteredListingServices} />
      </div>
      <div className="mb-4 flex flex-col space-y-4">
        <div className="flex flex-row justify-end space-x-2 ">
          <div className="w-full md:max-w-[20%]">
            <Dropdown
              multiple
              value={selectedActivations}
              onChange={setSelectedActivations}>
              <Dropdown.Button>
                {selectedActivations.length === 0
                  ? 'No Activations Selected'
                  : selectedActivations.length === 3
                  ? 'All Activations'
                  : selectedActivations
                      .filter((selectedActivation) =>
                        selectedActivations.includes(selectedActivation)
                      )
                      .map((selectedActivation) => selectedActivation)
                      .join(', ')}
              </Dropdown.Button>
              {activations.map((v) => (
                <Dropdown.Option key={v} value={v}>
                  {v}
                </Dropdown.Option>
              ))}
            </Dropdown>
          </div>
          <div className=" w-full md:w-[30%]">
            <SearchFilterInput
              value={searchTerm}
              placeholder="Search by Key, Name, or State"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(e.target.value)
              }
            />
          </div>
        </div>
        <div className="max-h-[calc(100vh-13rem)] overflow-y-auto rounded-lg border bg-white shadow">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header>Listing Service Key</Table.Header>
                <Table.Header>Name</Table.Header>
                <Table.Header>State / Province</Table.Header>
                <Table.Header align="center">CMA</Table.Header>
                <Table.Header align="center">Streams</Table.Header>
                <Table.Header align="center">MLX</Table.Header>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {filteredListingServices?.length === 0 ? (
                <Table.Row>
                  <Table.Data colSpan={6}>
                    <p className="p-4 text-center text-lg">
                      No listing services found{' '}
                      {debouncedSearchTerm && 'for the search term'}
                      {debouncedSearchTerm && (
                        <span className="font-bold text-orange-400">
                          {' '}
                          "{debouncedSearchTerm}"{' '}
                        </span>
                      )}
                      {'in selected activations'}.
                    </p>
                  </Table.Data>
                </Table.Row>
              ) : (
                filteredListingServices?.map((listingService) => (
                  <Table.Row key={listingService.id}>
                    <Table.Data>
                      <Link
                        to={`/listing_services/${listingService.id}/details`}
                        className="cursor-pointer hover:text-orange-400">
                        {listingService.key?.toUpperCase()}
                      </Link>
                    </Table.Data>
                    <Table.Data>
                      <Link
                        to={`/listing_services/${listingService.id}/details`}
                        className="cursor-pointer hover:text-orange-400">
                        {listingService?.name}
                      </Link>
                    </Table.Data>
                    <Table.Data>
                      <Link
                        to={`/listing_services/${listingService.id}/details`}
                        className="cursor-pointer hover:text-orange-400">
                        {listingService?.state &&
                          [listingService?.state].slice().join(', ')}
                      </Link>
                    </Table.Data>
                    <Table.Data>
                      <span className="flex justify-center">
                        <Link
                          to={`/listing_services/${listingService.id}/details`}>
                          <Checkbox
                            id={`${listingService.id}_search_data`}
                            checked={hasApp(
                              listingService.activations,
                              'CMA Production'
                            )}
                            size="lg"
                            readOnly
                          />
                        </Link>
                      </span>
                    </Table.Data>
                    <Table.Data>
                      <span className="flex justify-center">
                        <Link
                          to={`/listing_services/${listingService.id}/details`}>
                          <Checkbox
                            id={`${listingService.id}_search_data`}
                            checked={hasApp(
                              listingService.activations,
                              'Streams Production'
                            )}
                            size="lg"
                            readOnly
                          />
                        </Link>
                      </span>
                    </Table.Data>
                    <Table.Data>
                      <span className="flex justify-center">
                        <Link
                          to={`/listing_services/${listingService.id}/details`}>
                          <Checkbox
                            id={`${listingService.id}_search_data`}
                            checked={hasApp(
                              listingService.activations,
                              'MLX Production'
                            )}
                            size="lg"
                            readOnly
                          />
                        </Link>
                      </span>
                    </Table.Data>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    </>
  )
}
