import { useApiUsersQuery } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query ApiUsers {
    apiUsers: api_users(limit: 100) {
      id
      name
      apiKey: api_key
      search
      replication
      forgeApi: forge_api
      jwt
      environment
      percolatorNotification: percolator_notifications
      percolatorEndpoint: percolator_endpoint
      activations {
        id
        createdAtIso: created_at_iso
        listingService: listing_service {
          id
          key
          name
        }
      }
    }
  }
`
export function useApiUsers() {
  return useApiUsersQuery()
}
