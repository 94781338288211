import { Alert, Table } from '@forge/common'
import { FieldQuery, TransformPartsFragment } from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { CheckIcon, DotsVerticalIcon, TrashIcon } from '@heroicons/react/solid'
import { useQueryClient } from '@tanstack/react-query'
import { forwardRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { UpdateTransform } from './UpdateTransform'
import { useDestroyTransform } from './useDestroyTransform'
import { useReorderTransforms } from './useReorderTransforms'

interface TransformsProps {
  transforms: TransformPartsFragment[]
}

export function Transforms({ transforms }: TransformsProps) {
  const [_transforms, setTransforms] = useState(transforms)
  const { mutate: reorderTransforms } = useReorderTransforms()
  const canDrag = _transforms.length > 1

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.destination) return

        const newTransforms = Array.from(_transforms)
        const [removed] = newTransforms.splice(result.source.index, 1)
        newTransforms.splice(result.destination.index, 0, removed)

        const newIds = newTransforms.map((transform) => transform.id)

        setTransforms(newTransforms)
        reorderTransforms({ ids: newIds })
      }}>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>Operation</Table.Header>
            <Table.Header align="right">Data</Table.Header>
            <Table.Header align="center">Serialize Only</Table.Header>
            <Table.Header align="center">Delete</Table.Header>
          </Table.Row>
        </Table.Head>
        <Droppable droppableId="droppable" isDropDisabled={!canDrag}>
          {(provided) => (
            <Table.Body ref={provided.innerRef} {...provided.droppableProps}>
              {!_transforms.length && (
                <Table.Row>
                  <Table.Data colSpan={4}>No transforms found.</Table.Data>
                </Table.Row>
              )}
              {_transforms.map((transform, index) => (
                <Draggable
                  key={transform.id}
                  draggableId={transform.id}
                  index={index}
                  isDragDisabled={!canDrag}>
                  {(provided) => (
                    <Transform
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      transform={transform}
                      canDrag={canDrag}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Table.Body>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  )
}

interface TransformProps {
  transform: TransformPartsFragment
  canDrag: boolean
}

const Transform = forwardRef<HTMLTableRowElement, TransformProps>(
  function Transform({ transform, canDrag, ...rest }, ref) {
    const [showDestroy, setShowDestroy] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const queryClient = useQueryClient()
    const {
      mutate: destroyTransform,
      isLoading: isDestroying,
      error
    } = useDestroyTransform()

    return (
      // Spreading here so we can get the drag and drop props
      <Table.Row ref={ref} {...rest}>
        <Table.Data width="30%">
          <div className="flex space-x-2">
            {canDrag && (
              <DotsVerticalIcon className="w-5 h-5 -ml-6 text-gray-400 cursor-move" />
            )}
            <button onClick={() => setShowEdit(true)}>
              {transform.operation}
            </button>
            <UpdateTransform
              transform={transform}
              isOpen={showEdit}
              onClose={() => {
                setShowEdit(false)
              }}
            />
          </div>
        </Table.Data>
        <Table.Data width="30%" align="right">
          {transform.data}
        </Table.Data>
        <Table.Data width="30%" align="center">
          <div className="flex justify-center">
            {!!transform.serializeOnly && (
              <CheckIcon className="w-5 h-5 text-green-500" />
            )}
          </div>
        </Table.Data>
        <Table.Data width="10%" align="center">
          <button onClick={() => setShowDestroy(true)}>
            <TrashIcon className="w-5 h-5 text-gray-400 hover:cursor-pointer hover:text-red-500" />
          </button>
          <Alert variant="danger" isOpen={showDestroy}>
            <Alert.Title>Delete Transform</Alert.Title>
            <Alert.Content>
              <div className="space-y-2">
                <div>
                  Are you sure you want to delete this transform? This action
                  cannot be undone.
                </div>
                {error && (
                  <div className="text-red-500" role="alert" aria-live="polite">
                    {error.message}
                  </div>
                )}
              </div>
            </Alert.Content>
            <Alert.Cancel onClick={() => setShowDestroy(false)}>
              Cancel
            </Alert.Cancel>
            <Alert.Confirm
              leftIcon={<ExclamationCircleIcon />}
              loading={isDestroying}
              onClick={() => {
                destroyTransform(
                  { id: transform.id },
                  {
                    onSuccess() {
                      setShowDestroy(false)

                      const field = queryClient.getQueryData<FieldQuery>([
                        'Field',
                        { id: String(transform.fieldId) }
                      ])

                      if (field) {
                        queryClient.setQueryData(
                          ['Field', { id: String(transform.fieldId) }],
                          {
                            ...field,
                            field: {
                              ...field.field,
                              transforms: field.field?.transforms?.filter(
                                (t) => t.id !== transform.id
                              )
                            }
                          }
                        )
                      }

                      queryClient.invalidateQueries([
                        'Field',
                        { id: String(transform.fieldId) }
                      ])
                    }
                  }
                )
              }}>
              Delete Forever
            </Alert.Confirm>
          </Alert>
        </Table.Data>
      </Table.Row>
    )
  }
)
