import { airbrake } from '@forge/common'
import { getPersistedCurrentUser } from '@forge/features/auth'
import { GraphQLClient } from 'graphql-request'

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  initialHeaders?: RequestInit['headers']
): (() => Promise<TData>) => {
  return async () => {
    try {
      const currentUser = getPersistedCurrentUser()
      const headers = new Headers(initialHeaders)

      if (currentUser?.token) {
        headers.append('Authorization', `Bearer ${currentUser.token}`)
      }

      return new GraphQLClient(process.env.REACT_APP_DATA_DUTY_URL!, {
        headers
      }).request(query, variables)
    } catch (error: any) {
      // https://github.com/prisma-labs/graphql-request#error-handling

      const [firstError] = error?.response?.errors || []
      const message =
        firstError?.message || error.message || 'An error occurred'

      const status: number = error?.response?.status || -1
      if (status >= 500) {
        airbrake?.notify({ error, params: { info: error } })
        throw new Error(
          "There seems to be a problem on our end. We've been notified and are looking into it."
        )
      }
      throw new Error(message)
    }
  }
}
