import { Pagination, Table } from '@forge/common'

export function ResourceGroupSkeletonTable() {
  return (
    <>
      <div className="mb-4">
        <Pagination disablePrev={true} label="Page" />
      </div>
      <div className="overflow-auto rounded-lg bg-white py-1 shadow">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header align="left">Status</Table.Header>
              <Table.Header align="center">Total</Table.Header>
              <Table.Header align="center">Took</Table.Header>
              <Table.Header align="center">Created</Table.Header>
              <Table.Header align="right">Stop</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {Array.from({ length: 12 }).map((_, index) => (
              <Table.Row key={index}>
                <Table.Data>
                  <div className="absolute top-0 left-0 h-full w-[0.3125rem] animate-pulse bg-gray-200" />
                  <div className="mt-1 h-4 w-14 animate-pulse rounded bg-gray-200" />
                </Table.Data>
                <Table.Data>
                  <div className="flex justify-center">
                    <div className="mt-1 h-4 w-9 animate-pulse rounded bg-gray-200" />
                  </div>
                </Table.Data>
                <Table.Data>
                  <div className="flex justify-center">
                    <div className="mt-1 h-4 w-24 animate-pulse rounded bg-gray-200" />
                  </div>
                </Table.Data>
                <Table.Data>
                  <div className="flex justify-center">
                    <div className="mt-1 h-4 w-40 animate-pulse rounded bg-gray-200" />
                  </div>
                </Table.Data>

                <Table.Data>
                  <div className="flex justify-end">
                    <div className="mt-1 h-4 w-9 animate-pulse rounded bg-gray-200" />
                  </div>
                </Table.Data>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}
