import {
  Breadcrumbs,
  Menu,
  NotFound,
  Spinner,
  Tabs,
  Wrapper
} from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import {
  DeleteField,
  EditField,
  FieldDetails,
  useField
} from '@forge/features/field'
import { Lookups, LookupsMenu } from '@forge/features/lookup'
import { SampleDataTable } from '@forge/features/resource'
import { useResourceGroup } from '@forge/features/resourcegroups'
import {
  TransformPipeline,
  Transforms,
  TransformsMenu,
  useTransformPipeline
} from '@forge/features/transform'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { TrashIcon } from '@heroicons/react/outline'
import { includes, omitBy, without } from 'lodash'
import { Suspense, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

enum TabEnum {
  info,
  transforms,
  lookups,
  sampleData
}

export function ResourceGroupField() {
  const { adapterId, resourceGroupId, fieldId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId || '' })
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })
  const { data: { field } = {}, dataUpdatedAt: fieldUpdatedAt } = useField({
    id: fieldId || ''
  })
  const { data: { transformPipeline } = {} } = useTransformPipeline({
    fieldId: fieldId || '',
    timestamp: fieldUpdatedAt
  })
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const type = (searchParams.get('tab') || 'info') as keyof typeof TabEnum
  const selectedTabIndex = TabEnum[type]
  const [showDestroy, setShowDestroy] = useState(false)

  const [state, setState] = useState<{
    changed: boolean
    fieldData: FieldPartsFragment
  }>({
    changed: false,
    fieldData: {
      // We have to explicitly state id here because field could be undefined
      id: fieldId || '',
      ...field,
      ...omitBy(field?.pendingChanges, (pc) => pc === null)
    }
  })

  if (!field || !resourceGroup) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{field.displayName}</title>
      </Helmet>
      <Breadcrumbs>
        <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
        <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
          {adapter?.key?.toUpperCase()}
        </Breadcrumbs.Crumb>
        <Breadcrumbs.Crumb
          to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}`}>
          {resourceGroup.role || ''} Resource Group
        </Breadcrumbs.Crumb>
        <Breadcrumbs.Crumb
          to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/fields`}>
          {resourceGroup.role || ''} Fields
        </Breadcrumbs.Crumb>
        <Breadcrumbs.Crumb
          to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/fields/${fieldId}`}>
          Field: {field.displayName}
        </Breadcrumbs.Crumb>
      </Breadcrumbs>

      <div className="space-y-4">
        <Tabs selectedIndex={selectedTabIndex}>
          <div className="grid items-end grid-cols-2 gap-4">
            <h1 className="text-4xl font-medium">
              <span className="capitalize">{resourceGroup.role} field</span>:{' '}
              <em>{field.displayName}</em>
            </h1>

            <div className="-mb-4">
              <Tabs.List>
                <Tabs.Tab onClick={() => setSearchParams({ tab: 'info' })}>
                  Field Info
                </Tabs.Tab>
                <Tabs.Tab
                  onClick={() => setSearchParams({ tab: 'transforms' })}>
                  Transforms
                </Tabs.Tab>
                <Tabs.Tab onClick={() => setSearchParams({ tab: 'lookups' })}>
                  Lookups
                </Tabs.Tab>
                <Tabs.Tab
                  onClick={() => setSearchParams({ tab: 'sampleData' })}>
                  Sample Data
                </Tabs.Tab>
              </Tabs.List>
              <div className="flex justify-end pb-2 -mt-8">
                <Menu
                  button={
                    <span className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
                      <TrashIcon
                        className="w-5 h-5 text-gray-500 hover:text-red-500"
                        aria-hidden="true"
                      />
                    </span>
                  }>
                  <Menu.Item
                    variant="danger"
                    onClick={() => setShowDestroy(true)}>
                    Delete Field
                  </Menu.Item>
                </Menu>
                <DeleteField
                  id={field?.id}
                  name={field?.colName || ''}
                  isOpen={showDestroy}
                  onClose={() => setShowDestroy(false)}
                  onDelete={() =>
                    navigate(
                      `/adapters/${adapterId}/resource_groups/${resourceGroupId}/fields`
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-white rounded-lg shadow">
              {resourceGroup.role && (
                <EditField
                  key={JSON.stringify(state.fieldData)}
                  role={resourceGroup.role}
                  field={state.fieldData}
                  fieldCategories={resourceGroup.fieldCategories || []}
                />
              )}
            </div>
            <div className="space-y-6">
              <div className="space-y-4">
                <Tabs.Panels>
                  <Tabs.Panel>
                    <div className="p-4 overflow-auto bg-white rounded-lg shadow">
                      <FieldDetails field={field} />
                    </div>
                  </Tabs.Panel>
                  <Tabs.Panel>
                    <div className="space-y-8">
                      <div className="p-4 space-y-4 bg-white rounded-lg shadow">
                        <div className="flex justify-end">
                          <TransformsMenu field={field} />
                        </div>
                        <Transforms
                          key={fieldUpdatedAt}
                          transforms={field.transforms || []}
                        />
                      </div>
                      <div className="max-w-full p-4 overflow-auto bg-white rounded-lg shadow">
                        <TransformPipeline pipeline={transformPipeline} />
                      </div>
                    </div>
                  </Tabs.Panel>
                  <Tabs.Panel>
                    <div className="p-4 space-y-4 bg-white rounded-lg shadow">
                      <div className="flex justify-end">
                        <LookupsMenu field={field} />
                      </div>
                      <div className="overflow-auto max-w-fit">
                        <Lookups
                          key={fieldUpdatedAt}
                          lookups={field.lookups || []}
                        />
                      </div>
                    </div>
                  </Tabs.Panel>
                  <Tabs.Panel>
                    <div className="p-4 bg-white rounded-lg shadow">
                      <Suspense
                        fallback={
                          <div className="flex items-center space-x-2">
                            <div className="w-5 h-5 text-orange-400">
                              <Spinner />
                            </div>
                            <div className="text-gray-500">
                              Loading Sample Data
                            </div>
                          </div>
                        }>
                        <SampleDataTable
                          onFieldNameClick={(name) => {
                            const { fieldData } = state
                            const sourceNames = fieldData?.sourceNames || []

                            setState({
                              ...state,
                              changed: true,
                              fieldData: {
                                ...fieldData,
                                sourceNames: includes(sourceNames, name)
                                  ? without(sourceNames, name)
                                  : [...sourceNames, name]
                              }
                            })
                          }}
                        />
                      </Suspense>
                    </div>
                  </Tabs.Panel>
                </Tabs.Panels>
              </div>
            </div>
          </div>
        </Tabs>
      </div>
    </Wrapper>
  )
}
