import {
  FullScreenHeader,
  Pagination,
  PlaceholderMap,
  Spinner
} from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import { AgentCard } from '@forge/features/agent'
import { ListingCard, ListingMap } from '@forge/features/listing'
import { OfficeCard } from '@forge/features/office'
import {
  useResourceGroupSearch,
  useResourceGroupSearchFields,
  useResourceGroupSearchResults
} from '@forge/features/resourcegroups'
import {
  AgentResultType,
  AgentSearchFieldPartsFragment,
  FilterQueryType,
  ListingResultType,
  ListingSearchFieldPartsFragment,
  OfficeResultType,
  OfficeSearchFieldPartsFragment,
  ResourceGroupRole,
  SortDirectionEnum
} from '@forge/graphql/generated'
import { keyBy } from 'lodash'
import { parse, stringify } from 'qs'
import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  Suspense,
  useContext,
  useState
} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  QuickAgentCriteria,
  QuickListingCriteria,
  QuickOfficeCriteria
} from './QuickCriteria'
import { SearchResultDetails } from './SearchResultDetails'

const SearchContext = createContext<{
  resourceGroupId: string
  page: number
  setPage: Dispatch<SetStateAction<number>>
  total: number
  setTotal: Dispatch<SetStateAction<number>>
  limit: number
  role: ResourceGroupRole | undefined
  selectedRecordId?: string | null
  setSelectedRecordId: Dispatch<SetStateAction<string | null | undefined>>
  setHasLoaded: Dispatch<SetStateAction<boolean>>
  fields: Record<
    string,
    | ListingSearchFieldPartsFragment
    | AgentSearchFieldPartsFragment
    | OfficeSearchFieldPartsFragment
  >
  setFields: Dispatch<
    SetStateAction<
      Record<
        string,
        | ListingSearchFieldPartsFragment
        | AgentSearchFieldPartsFragment
        | OfficeSearchFieldPartsFragment
      >
    >
  >
}>({
  resourceGroupId: '',
  page: 1,
  setPage: () => {},
  total: 0,
  setTotal: () => {},
  limit: 12,
  role: undefined,
  selectedRecordId: undefined,
  setSelectedRecordId: () => {},
  setHasLoaded: () => {},
  fields: {},
  setFields: () => {}
})
const useSearch = () => useContext(SearchContext)

interface SearchProps {
  adapterId: string
  resourceGroupId: string
}

export function Search({ adapterId, resourceGroupId }: SearchProps) {
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { data: { resourceGroup } = {} } = useResourceGroupSearch({
    id: resourceGroupId
  })

  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit] = useState(12)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [fields, setFields] = useState<
    Record<
      string,
      | ListingSearchFieldPartsFragment
      | AgentSearchFieldPartsFragment
      | OfficeSearchFieldPartsFragment
    >
  >({})

  const role = resourceGroup?.role || undefined
  const [selectedRecordId, setSelectedRecordId] = useState<string | null>()

  return (
    <SearchContext.Provider
      value={{
        resourceGroupId,
        page,
        setPage,
        total,
        setTotal,
        limit,
        role,
        selectedRecordId,
        setSelectedRecordId,
        setHasLoaded,
        fields,
        setFields
      }}>
      <div className="relative flex min-h-screen flex-col">
        <FullScreenHeader
          title={`Search ${role} Resource Group`}
          onBackClick={() =>
            navigate(
              `/adapters/${adapter?.id}/resource_groups/${resourceGroup?.id}`
            )
          }
        />
        <div className="flex grow">
          <div className="sm:shadow-raised z-[9] flex h-full w-full shrink-0 flex-col bg-gray-50 lg:max-w-[50%]">
            <div className="space-y-4">
              <div className="sticky top-[3.75rem] z-10 bg-gray-50 p-4">
                <Suspense
                  fallback={
                    <div className="rounded bg-white p-4 shadow sm:gap-4">
                      <div className="flex items-center gap-2 text-gray-500">
                        <div className="h-5 w-5">
                          <Spinner />
                        </div>
                        <div className="text-sm">Loading Filters</div>
                      </div>
                    </div>
                  }>
                  <div className="rounded bg-white p-4 shadow sm:gap-4">
                    <Filters />
                  </div>
                </Suspense>
              </div>
              <div>
                <div className="px-4">
                  <Pagination
                    label={hasLoaded ? undefined : 'Loading...'}
                    limit={limit}
                    total={total}
                    disablePrev={page === 1}
                    disableNext={page === Math.ceil(total / limit)}
                    onPrevClick={() => {
                      setPage(page - 1)
                      setSelectedRecordId(undefined)
                    }}
                    onNextClick={() => {
                      setPage(page + 1)
                      setSelectedRecordId(undefined)
                    }}
                  />
                </div>
                <Suspense fallback={<Results.Skeleton />}>
                  <Results />
                </Suspense>
              </div>
            </div>
          </div>
          <div className="max-w-1/2 sticky top-[3.75rem] hidden h-[calc(100vh-3.75rem)] grow bg-gray-100 lg:block">
            <Suspense fallback={<PlaceholderMap />}>
              <Map />
            </Suspense>
            {role && (
              <div className="hidden lg:block">
                <SearchResultDetails
                  adapterId={adapterId}
                  isOpen={!!selectedRecordId}
                  onClose={() => setSelectedRecordId(undefined)}
                  recordId={selectedRecordId}
                  resourceGroupId={resourceGroupId}
                  role={role}
                />
              </div>
            )}
          </div>
          {role && (
            <div className="block lg:hidden">
              <SearchResultDetails
                adapterId={adapterId}
                isFullscreen
                isOpen={!!selectedRecordId}
                onClose={() => setSelectedRecordId(undefined)}
                recordId={selectedRecordId}
                resourceGroupId={resourceGroupId}
                role={role}
              />
            </div>
          )}
        </div>
      </div>
    </SearchContext.Provider>
  )
}

function Filters() {
  const { resourceGroupId, role } = useSearch()

  const { data: { resourceGroup } = {} } = useResourceGroupSearchFields({
    resourceGroupId
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const parsedParams = parse(searchParams.toString()) as Record<
    string,
    FilterQueryType
  >
  const handleFilter = (field: FilterQueryType) => {
    const newFilter = { ...parsedParams, [field.field]: field }
    if (!field.eq?.length) {
      delete newFilter[field.field]
    }
    setSearchParams(new URLSearchParams(stringify(newFilter)))
  }

  const fields = keyBy(
    resourceGroup?.fields,
    (field) => field.aliases?.[0] || field.colName || ''
  )

  if (role === 'listing') {
    return (
      <QuickListingCriteria
        bathsFieldId={fields['baths']?.id || fields['baths_search']?.id || ''}
        bedsFieldId={fields['beds']?.id || ''}
        cityFieldId={fields['city']?.id || ''}
        handleFilter={handleFilter}
        propSubTypeFieldId={fields['prop_sub_type']?.id || ''}
        propTypeFieldId={fields['prop_type']?.id || ''}
        statusFieldId={fields['status']?.id || ''}
        yearBuiltFieldId={fields['year_built']?.id || ''}
      />
    )
  }

  if (role === 'agent') {
    return <QuickAgentCriteria handleFilter={handleFilter} />
  }

  if (role === 'office') {
    return <QuickOfficeCriteria handleFilter={handleFilter} />
  }

  return null
}

function Results() {
  const { role, selectedRecordId, setSelectedRecordId } = useSearch()
  const { data: records } = useResults()

  return (
    <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 2xl:grid-cols-3">
      {records?.map((record) => {
        const listing = record as ListingResultType
        const agent = record as AgentResultType
        const office = record as OfficeResultType

        return (
          <Fragment key={record.id}>
            {role === 'listing' && (
              <ListingCard
                address={listing.address}
                city={listing.city}
                isOpen={selectedRecordId === record.id}
                mappedStatus={listing.mapped_status}
                onClick={() =>
                  setSelectedRecordId(
                    selectedRecordId !== record.id ? record.id : undefined
                  )
                }
                photos={listing.photos}
                price={listing.price}
                beds={listing.beds}
                baths={listing.baths}
                dom={listing.dom}
                yearBuilt={listing.year_built}
                state={listing.state}
                status={listing.status}
                sqft={listing.sqft}
                zip={listing.zipcode || listing.zip}
              />
            )}
            {role === 'agent' && (
              <AgentCard
                active={agent.active}
                agentId={agent.agent_id}
                email={agent.email}
                fullName={agent.full_name}
                isOpen={selectedRecordId === record.id}
                officeName={agent.office_name}
                onClick={() =>
                  setSelectedRecordId(
                    selectedRecordId !== record.id ? record.id : undefined
                  )
                }
                phone={agent.phone}
              />
            )}
            {role === 'office' && (
              <OfficeCard
                active={office.active}
                email={office.email}
                isOpen={selectedRecordId === record.id}
                name={office.name}
                officeId={office.office_id}
                onClick={() =>
                  setSelectedRecordId(
                    selectedRecordId !== record.id ? record.id : undefined
                  )
                }
                phone={office.phone}
              />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

Results.Skeleton = function ResultsSkeleton() {
  const { limit, role } = useSearch()

  return (
    <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 2xl:grid-cols-3">
      {Array.from({ length: limit }, (_, index) => (
        <Fragment key={index}>
          {role === 'listing' && <ListingCard.Skeleton />}
          {role === 'agent' && <AgentCard.Skeleton />}
          {role === 'office' && <OfficeCard.Skeleton />}
        </Fragment>
      ))}
    </div>
  )
}

function Map() {
  const { role, setSelectedRecordId } = useSearch()
  const { data: results, isFetching } = useResults({ keepPreviousData: true })

  if (role === 'listing') {
    return (
      <div className="relative h-full w-full">
        <ListingMap
          listings={results as ListingResultType[]}
          onPinClick={(listing) => setSelectedRecordId(listing.id)}
          onDoneClick={() => setSelectedRecordId(undefined)}
        />
        {isFetching && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-white/50 backdrop-blur-sm">
            <div className="h-12 w-12 text-orange-500">
              <Spinner />
            </div>
          </div>
        )}
      </div>
    )
  }

  if (role === 'agent' || role === 'office') {
    return <PlaceholderMap />
  }

  return null
}

interface UseResultsArgs {
  keepPreviousData?: boolean
}
function useResults(args?: UseResultsArgs) {
  const { resourceGroupId, page, limit, role, setTotal, setHasLoaded } =
    useSearch()

  const [searchParams] = useSearchParams()
  const parsedParams = parse(searchParams.toString()) as Record<
    string,
    FilterQueryType
  >

  // Remove tab from the parsedParams before passing it to the backend as filter
  delete parsedParams['tab']

  const { data: resourceGroupResults = {}, isFetching } =
    useResourceGroupSearchResults(
      {
        resourceGroupId,
        limit,
        page,
        sort: { field: 'updated_at', dir: SortDirectionEnum.Desc },
        filter: Object.values(parsedParams)
      },
      {
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: args?.keepPreviousData,
        onSuccess(data) {
          setTotal(
            data?.resourceGroup?.listings?.count ||
              data?.resourceGroup?.agents?.count ||
              data?.resourceGroup?.offices?.count ||
              0
          )
          setHasLoaded(true)
        },
        refetchOnWindowFocus: false
      }
    )

  return {
    data:
      role === 'listing'
        ? resourceGroupResults?.resourceGroup?.listings?.results
        : role === 'agent'
        ? resourceGroupResults?.resourceGroup?.agents?.results
        : role === 'office'
        ? resourceGroupResults?.resourceGroup?.offices?.results
        : [],
    isFetching:
      role === 'listing'
        ? isFetching
        : role === 'agent'
        ? isFetching
        : role === 'office'
        ? isFetching
        : false
  }
}
