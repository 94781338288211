import { Button, FormField, Tabs, toast } from '@forge/common'
import { BeakerIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import Editor from '@monaco-editor/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useSavedSearch } from './useSavedSearch'
import { useUpdateSavedSearch } from './useUpdateSavedSearch'

const schema = yup.object({
  rawCriteria: yup.string()
})

export function ListingServiceSavedSearchEditForm() {
  const { id, savedSearchId } = useParams()
  const { data: { savedSearch } = {} } = useSavedSearch({
    listingServiceId: Number(id || ''),
    id: savedSearchId
  })
  const [hasSyntaxError, setHasSyntaxError] = useState(false)
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const { mutate: updateSavedSearch, isLoading, error } = useUpdateSavedSearch()
  const navigate = useNavigate()

  return (
    <>
      <Tabs>
        <Tabs.List>
          <Tabs.Tab>Edit Saved Search</Tabs.Tab>
          <Tabs.Tab>Generated Query</Tabs.Tab>
        </Tabs.List>

        <div className="pt-4">
          <Tabs.Panels>
            <Tabs.Panel>
              <form
                className="pt-4 bg-white rounded-lg shadow"
                onSubmit={handleSubmit((data) => {
                  updateSavedSearch(
                    {
                      id: String(savedSearchId),
                      listingServiceId: Number(id),
                      rawCriteria: data.rawCriteria
                    },
                    {
                      onSuccess() {
                        navigate(`/listing_services/${id}/details?tab=savedSearches`)
                        toast('Saved search successfully updated!', {
                          variant: 'success'
                        })
                      },
                      onError() {
                        toast('Failed to update saved search!', {
                          variant: 'error'
                        })
                      }
                    }
                  )
                })}>
                <FormField error={errors.rawCriteria?.message}>
                  <Editor
                    height="30vh"
                    language="json"
                    defaultValue={savedSearch?.rawCriteria ?? ''}
                    onChange={(val) => setValue('rawCriteria', val)}
                    onMount={(_, monaco) => {
                      monaco.editor.defineTheme('forge', {
                        base: 'vs',
                        inherit: true,
                        rules: [],
                        colors: {
                          'scrollbar.shadow': '#ffffff',
                          'widget.shadow': '#ffffff',
                          'editor.background': '#ffffff',
                          'editorCursor.foreground': '#fb923c'
                        }
                      })
                      monaco.editor.setTheme('forge')
                    }}
                    onValidate={(markers) =>
                      setHasSyntaxError(!!markers.length)
                    }
                    options={{
                      scrollBeyondLastLine: false,
                      minimap: {
                        enabled: false
                      },
                      fontSize: 14,
                      suggestFontSize: 14,
                      tabSize: 2,
                      multiCursorModifier: 'ctrlCmd'
                    }}
                  />
                </FormField>
                <div className="p-4 space-y-4">
                  <Button
                    variant={!error ? 'primary' : 'danger'}
                    loading={isLoading}
                    disabled={hasSyntaxError || !watch('rawCriteria')}
                    leftIcon={<BeakerIcon />}>
                    Update Saved Search
                  </Button>
                </div>
              </form>
            </Tabs.Panel>
            <Tabs.Panel>
              <pre className="h-[100vh] overflow-scroll rounded-lg bg-white p-8 shadow-lg">
                {JSON.stringify(
                  JSON.parse(savedSearch?.queries ?? ''),
                  null,
                  2
                )}
              </pre>
            </Tabs.Panel>
          </Tabs.Panels>
        </div>
      </Tabs>
    </>
  )
}
