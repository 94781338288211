import { useDeleteRecordMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation DeleteRecord($id: ID!, $record_id: ID!) {
    deleteRecord(resource_group_id: $id, record_id: $record_id) {
      success
    }
  }
`

export function useDeleteRecord() {
  const queryClient = useQueryClient()

  return useDeleteRecordMutation({
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries(['ListingSearch']),
        queryClient.invalidateQueries(['AgentSearch']),
        queryClient.invalidateQueries(['OfficeSearch'])
      ])
    }
  })
}
