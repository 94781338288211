import { Modal, Table, TimeAgo, Tooltip } from '@forge/common'
import { useDashboardAlarms } from '@forge/features/dashboard'
import { BellIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export function HeaderAlarms() {
  const [showModal, setShowModal] = useState(false)
  const { data: { alarms } = {} } = useDashboardAlarms()
  const alarmCount = alarms?.length || 0

  return (
    <div>
      <Tooltip delay={700} placement="bottom" content="Adapter Alarms">
        <button className="relative flex" onClick={() => setShowModal(true)}>
          <span className="sr-only">View Adapter Alarms</span>
          {alarmCount > 0 && (
            <span className="absolute -top-1.5 -right-1.5 rounded-full bg-red-500 px-1.5 py-0.5 text-[0.5rem] text-white ring-2 ring-white">
              {alarmCount > 99 ? '99+' : alarmCount}
            </span>
          )}
          <BellIcon className="h-6 w-6 text-slate-400" aria-hidden="true" />
        </button>
      </Tooltip>
      <Modal
        size={alarmCount > 0 ? '4xl' : '2xl'}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onAfterClose={() => {}}>
        <Modal.Title>
          Adapter Alarms
          {alarmCount > 0 && (
            <span className="float-right pr-2 text-sm">
              {' '}
              Count: {alarmCount}
            </span>
          )}
        </Modal.Title>
        <div className="max-h-[calc(100vh-18.75rem)] overflow-auto">
          {alarmCount <= 0 && <h3>Nothing's on fire. 🥳</h3>}
          {alarmCount > 0 && (
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header align="left">Alarm</Table.Header>
                  <Table.Header align="right">Created</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {alarms?.map(({ id, urlPath, description, createdAtIso }) => (
                  <Table.Row key={id}>
                    <Table.Data>
                      <Link
                        to={urlPath || 'NO_PATH'}
                        onClick={() => setShowModal(false)}
                        className="text-sm text-red-500 hover:text-orange-400">
                        {description}
                      </Link>
                    </Table.Data>
                    <Table.Data align="right">
                      <i>
                        {createdAtIso ? (
                          <TimeAgo date={new Date(createdAtIso)} />
                        ) : (
                          '-'
                        )}
                      </i>
                    </Table.Data>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </div>
      </Modal>
    </div>
  )
}
