import { classNames, Spinner } from '@forge/common'
import { AgentResult } from '@forge/features/agent'
import { ListingResult } from '@forge/features/listing'
import { OfficeResult } from '@forge/features/office'
import {
  AgentResultType,
  ListingResultType,
  OfficeResultType,
  ResourceGroupRole
} from '@forge/graphql/generated'
import { AnimatePresence, motion } from 'framer-motion'
import { Suspense, useEffect } from 'react'
import { useResourceGroupSearchDetails } from '../resourcegroups/useResourceGroupSearch'

interface SearchResultDetailsProps {
  adapterId: string
  isFullscreen?: boolean
  isOpen: boolean
  onClose: () => void
  recordId?: string | null
  resourceGroupId: string
  role: ResourceGroupRole
}

export function SearchResultDetails({
  adapterId,
  isFullscreen,
  isOpen,
  onClose,
  recordId,
  resourceGroupId,
  role
}: SearchResultDetailsProps) {
  const { data, isLoading, isFetching } = useResourceGroupSearchDetails(
    {
      id: resourceGroupId,
      filter: {
        field: 'id',
        eq: recordId ? [recordId] : []
      }
    },
    {
      suspense: false,
      enabled: !!recordId
    }
  )

  const recordDetails =
    role === ResourceGroupRole.Listing
      ? data?.resourceGroup?.listings?.results
      : role === ResourceGroupRole.Agent
      ? data?.resourceGroup?.agents?.results
      : role === ResourceGroupRole.Office
      ? data?.resourceGroup?.offices?.results
      : []

  const listing = recordDetails?.[0] as ListingResultType
  const agent = recordDetails?.[0] as AgentResultType
  const office = recordDetails?.[0] as OfficeResultType

  useEffect(() => {
    function onKeyPress(event: KeyboardEvent) {
      if (event.key === 'Escape' && isOpen) {
        onClose()
      }
    }
    document.body.addEventListener('keydown', onKeyPress)
    return () => {
      document.body.removeEventListener('keydown', onKeyPress)
    }
  }, [isOpen, onClose])

  return (
    <AnimatePresence>
      {recordId && isOpen && (
        <>
          <motion.div
            className="absolute inset-0 bg-white/40 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: isFullscreen ? 0 : undefined }}
          />
          <motion.div
            className={classNames(
              'z-10 mx-auto flex flex-col overflow-y-auto bg-white',
              {
                'absolute top-8 left-8 right-8 bottom-0 rounded-t-xl shadow-xl':
                  !isFullscreen,
                'fixed inset-0 top-0 left-0 z-50 h-full': isFullscreen
              }
            )}
            initial={{ scale: 0.97, translateY: 25, opacity: 0 }}
            animate={{ scale: 1, translateY: 0, opacity: 1 }}
            exit={{ scale: 0.97, translateY: 25, opacity: 0 }}
            transition={{ duration: isFullscreen ? 0 : 0.25 }}>
            <div className="p-4">
              {isLoading ? (
                <div className="h-5 w-5 text-orange-500">
                  <Spinner />
                </div>
              ) : (
                <>
                  <Suspense
                    fallback={
                      <div className="h-5 w-5 text-orange-400">
                        <Spinner />
                      </div>
                    }>
                    {role === 'listing' && (
                      <ListingResult
                        adapterId={adapterId}
                        onClose={onClose}
                        resourceGroupId={resourceGroupId}
                        record={listing}
                        role={role}
                      />
                    )}
                    {role === 'agent' && (
                      <AgentResult
                        adapterId={adapterId}
                        onClose={onClose}
                        resourceGroupId={resourceGroupId}
                        record={agent}
                        role={role}
                      />
                    )}
                    {role === 'office' && (
                      <OfficeResult
                        adapterId={adapterId}
                        onClose={onClose}
                        resourceGroupId={resourceGroupId}
                        record={office}
                        role={role}
                      />
                    )}
                  </Suspense>
                  {isFetching && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-white/50 backdrop-blur-sm">
                      <div className="h-12 w-12 text-orange-500">
                        <Spinner />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}
