import { Attribute, Tooltip } from '@forge/common'
import { AgentResultType, FieldPartsFragment } from '@forge/graphql/generated'
import { useResourceGroupSearchFields } from '@forge/features/resourcegroups'
import { AgentHeader } from './AgentHeader'

interface AgentDetailProps {
  adapterId: string
  resourceGroupId: string
  agent: AgentResultType
  onClose: () => void
}

export function AgentDetail({
  agent,
  adapterId,
  resourceGroupId,
  onClose
}: AgentDetailProps) {
  const { data: resourceGroupSearchFields = {} } = useResourceGroupSearchFields(
    {
      resourceGroupId
    }
  )

  const searchFields = resourceGroupSearchFields?.resourceGroup?.fields?.sort(
    (a, b) => (a?.displayName || '').localeCompare(b?.displayName || '')
  )

  const fields =
    searchFields?.reduce<Record<string, FieldPartsFragment>>(
      (fields, field) => {
        const key = field.aliases?.[0] || field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}

  return (
    <>
      <AgentHeader onClose={onClose}></AgentHeader>
      <div className="flex">
        <div className="w-9/12 mx-auto mt-4">
          <h3>Root</h3>
          <div className="mt-4 columns-2">
            <ul>
              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="A unique identifier for this record from the immediate source.">
                <li className="p-2">
                  Unique ID:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['id']?.id || fields['agent_id']?.id}
                    label="id"
                    resourceGroupId={resourceGroupId}
                    value={agent.id}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The local, well-known identifier for the member. This value may not be unique, specifically in the case of aggregation systems, this value should be the identifier from the original system.">
                <li className="p-2">
                  Agent ID:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['agent_id']?.id || fields['id']?.id}
                    label="agent_id"
                    resourceGroupId={resourceGroupId}
                    value={agent.agent_id}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The full name of the Member. (First Middle Last) or an alternate full name.">
                <li className="p-2">
                  Full Name:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['full_name']?.id}
                    label="full_name"
                    resourceGroupId={resourceGroupId}
                    value={agent.full_name}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The first name of the Member.">
                <li className="p-2">
                  First Name:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['firstname']?.id}
                    label="firstname"
                    resourceGroupId={resourceGroupId}
                    value={agent.firstname}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The last name of the Member">
                <li className="p-2">
                  Last Name:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['lastname']?.id}
                    label="lastname"
                    resourceGroupId={resourceGroupId}
                    value={agent.lastname}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The email address of the Member.">
                <li className="p-2">
                  Email:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['email']?.id}
                    label="email"
                    resourceGroupId={resourceGroupId}
                    value={agent.email}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol.">
                <li className="p-2">
                  Phone:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['phone']?.id}
                    label="phone"
                    resourceGroupId={resourceGroupId}
                    value={agent.phone}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The legal name of the brokerage.">
                <li className="p-2">
                  Office Name:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['office_name']?.id}
                    label="office_name"
                    resourceGroupId={resourceGroupId}
                    value={agent.office_name}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="Date/time the roster (member) record was last modified.">
                <li className="p-2">
                  Updated At:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['updated_at']?.id}
                    label="updated_at"
                    resourceGroupId={resourceGroupId}
                    value={agent.updated_at}
                  />
                </li>
              </Tooltip>
              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The current status of an agent within the MLS system.">
                <li className="p-2">
                  Active:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['active']?.id}
                    label="active"
                    resourceGroupId={resourceGroupId}
                    value={agent.active}
                  />
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
