import {
  ResourceGroupRole,
  useResourceGroupsQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query ResourceGroups($adapterId: Int, $role: ResourceGroupRole) {
    resourceGroups: resource_groups(adapter_id: $adapterId, role: $role) {
      id
      uid
      role
      version
      indexData: index_data
      searchData: search_data
      auditData: audit_data
      skipPhotos: skip_photos
      status
      needsSetup: needs_setup
      loadingProgress: loading_progress
      deletesStatus: deletes_status {
        total
        pending
        lastPurge: last_purge
      }
      resources {
        resourceGroupId: resource_group_id
        id
        name
        updateData: update_data
        populateStatus: populate_status
        health {
          sync
          audit
          auditResolution: audit_resolution
          weeklyAudit: weekly_audit
          monthlyAudit: monthly_audit
        }
      }
    }
  }
`

export function useResourceGroups() {
  return useResourceGroupsQuery({ role: ResourceGroupRole.Listing })
}
