import numeral from 'numeral'
import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { useResourceGroup } from './useResourceGroup'

export interface Resource {
  label: string | null
  name: number | null
}

const stroke_colors = [
  '#6d28d9',
  '#f97316',
  '#ef4444',
  '#eab308',
  '#1d4ed8',
  '#f59e0b',
  '#c026d3',
  '#0891b2',
  '#059669',
  '#9333ea',
  '#e11d48'
]

const fill_colors = [
  '#8b5cf6',
  '#fb923c',
  '#f87171',
  '#facc15',
  '#3b82f6',
  '#fcd34d',
  '#e879f9',
  '#22d3ee',
  '#6ee7b7',
  '#c084fc',
  '#fb7185'
]

export function ResourceGroupUpdatesByHour() {
  const { resourceGroupId } = useParams()
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })
  const stats = JSON.parse(resourceGroup?.updatesByHour || '[]')

  return (
    <div>
      <h3 className="pb-4 text-xl capitalize">
        {resourceGroup?.role || ''} Updates by Hour
      </h3>
      <div className="mb-4 rounded-lg bg-white pt-6 pb-4 pr-4 shadow">
        <ResponsiveContainer height={275}>
          <AreaChart
            width={500}
            height={400}
            data={stats}
            margin={{
              top: 0,
              right: 15,
              left: 15,
              bottom: 0
            }}>
            <Legend />
            <defs>
              {resourceGroup?.resources?.map((r, i) => (
                <linearGradient
                  key={i}
                  id={`gradient_${r.id}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1">
                  <stop
                    offset="5%"
                    stopColor={fill_colors[i]}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="100%"
                    stopColor={fill_colors[i]}
                    stopOpacity={0.3}
                  />
                </linearGradient>
              ))}
            </defs>
            <defs>
              {resourceGroup?.resources?.map((r, i) => (
                <linearGradient
                  key={i}
                  id={`stroke_${r.id}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1">
                  <stop
                    offset="100%"
                    stopColor={stroke_colors[i]}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="100%"
                    stopColor={stroke_colors[i]}
                    stopOpacity={0}
                  />
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis />
            <Tooltip
              labelClassName="text-gray-500"
              formatter={(value: String) => numeral(value).format('0.0a')}
            />
            {resourceGroup?.resources?.map(({ id, name }) => (
              <Fragment key={`class_${id}`}>
                <Area
                  key={id}
                  type="monotone"
                  dataKey={name || ''}
                  stackId={id}
                  stroke={`url(#stroke_${id})`}
                  fillOpacity={1}
                  fill={`url(#gradient_${id})`}
                />
              </Fragment>
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
