import {
  Menu,
  Pagination,
  SearchFilterInput,
  Table,
  useDebounce
} from '@forge/common'
import { FieldTemplateFieldsFragment } from '@forge/graphql/generated'
import { DotsHorizontalIcon, PencilIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteFieldTemplate } from './DeleteFieldTemplate'
import { useFieldTemplates } from './useFieldTemplates'

export function FieldTemplatesTable() {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const { data: { fieldTemplates } = {} } = useFieldTemplates({
    page,
    search: debouncedSearch
  })

  if (!fieldTemplates) return null

  return (
    <div className="flex flex-col">
      <div className="my-3 self-end md:w-1/4">
        <SearchFilterInput
          value={search}
          placeholder="Search Field Template"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="space-y-2 overflow-auto rounded-lg bg-white shadow print:border print:shadow-none">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Record Type</Table.Header>
              <Table.Header>Display Name</Table.Header>
              <Table.Header>Source Name</Table.Header>
              <Table.Header>Aliases</Table.Header>
              <Table.Header>Category</Table.Header>
              <Table.Header>Type</Table.Header>
              <Table.Header>Edit</Table.Header>
              <Table.Header align="center">Action</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {!fieldTemplates.length ? (
              <Table.Row>
                <Table.Data colSpan={7} align="center">
                  No Field Templates found
                  {debouncedSearch && (
                    <>
                      {' '}
                      for the search term
                      <span className="font-bold text-orange-400">
                        {' '}
                        "{debouncedSearch}"
                      </span>
                    </>
                  )}
                  .
                </Table.Data>
              </Table.Row>
            ) : (
              fieldTemplates.map((field) => (
                <FieldTemplateRow key={field.id} field={field} />
              ))
            )}
          </Table.Body>
        </Table>
      </div>
      <div className="mt-4">
        <Pagination
          label={`Page ${page}`}
          disablePrev={page === 1}
          onPrevClick={() => setPage(page - 1)}
          onNextClick={() => setPage(page + 1)}
        />
      </div>
    </div>
  )
}

interface FieldTemplateRowProps {
  field: FieldTemplateFieldsFragment
}

function FieldTemplateRow({ field }: FieldTemplateRowProps) {
  const [isDeleteTemplateOpen, setIsDeleteTemplateOpen] = useState(false)

  return (
    <Table.Row highlightOnHover>
      <Table.Data>{field.recordType}</Table.Data>
      <Table.Data>{field.displayName}</Table.Data>
      <Table.Data>{field.name}</Table.Data>
      <Table.Data>{field.aliases}</Table.Data>
      <Table.Data>{field.fieldCategoryName}</Table.Data>
      <Table.Data>{field.fieldType}</Table.Data>
      <Table.Data>
        <Link
          to={`/field_templates/${field.id}/edit`}
          className="cursor-pointer text-center hover:text-orange-400">
          <PencilIcon className="mx-auto h-5 w-5" />
        </Link>
      </Table.Data>
      <Table.Data align="center">
        <Menu
          button={
            <DotsHorizontalIcon
              className="h-5 w-5 text-gray-600 hover:text-orange-400"
              aria-hidden="true"
            />
          }>
          <Menu.Item>
            <Link to={`/field_templates/${field.id}/edit`}>
              Edit Field Template
            </Link>
          </Menu.Item>
          <Menu.Item
            variant="danger"
            onClick={() => setIsDeleteTemplateOpen(true)}>
            Delete Field Template
          </Menu.Item>
        </Menu>
        <DeleteFieldTemplate
          field={field}
          isOpen={isDeleteTemplateOpen}
          onClose={() => setIsDeleteTemplateOpen(false)}
        />
      </Table.Data>
    </Table.Row>
  )
}
