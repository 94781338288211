import {
  Button,
  Checkbox,
  Combobox,
  FormField,
  Input,
  Modal
} from '@forge/common'
import { OperationEnum } from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { PropsWithChildren } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCreateTransform } from './useCreateTransform'

const schema = yup.object({
  operation: yup
    .mixed<OperationEnum>()
    .oneOf(Object.values(OperationEnum))
    .required('Transform type is required'),
  data: yup.string(),
  serializeOnly: yup.bool()
})

interface CreateTransformProps {
  fieldId: string
  isOpen: boolean
  onClose: () => void
}

export function CreateTransform({
  fieldId,
  isOpen,
  onClose
}: CreateTransformProps) {
  const { mutate: createTransform, isLoading } = useCreateTransform()
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const operationOptions = Object.values(OperationEnum).map((value) => ({
    label: value,
    value: value
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} onAfterClose={() => reset()}>
      <Modal.Title>Add Transform</Modal.Title>
      <form
        className="mt-4 space-y-4"
        onSubmit={handleSubmit((data) => {
          createTransform(
            { fieldId: Number(fieldId), ...data },
            {
              onSuccess() {
                onClose()
              }
            }
          )
        })}>
        <FormField
          label="Transform Type"
          error={errors.operation?.message}
          required>
          {(props) => (
            <Combobox
              {...props}
              selected={operationOptions.find(
                (option) => watch('operation') === option.value
              )}
              options={operationOptions}
              onSelect={(option) => {
                setValue('operation', option.value as OperationEnum)
              }}
            />
          )}
        </FormField>
        <FormField label="Transform Data" error={errors.data?.message}>
          <Input {...register('data')} />
        </FormField>
        <FormField error={errors.serializeOnly?.message}>
          <Checkbox size="lg" {...register('serializeOnly')}>
            Serialize Only
          </Checkbox>
        </FormField>
        <HelpText operation={watch('operation')} />
        <div className="flex justify-end">
          <Button loading={isLoading}>Add Transform</Button>
        </div>
      </form>
    </Modal>
  )
}

function Card({ children }: PropsWithChildren<unknown>) {
  return (
    <div className="[&_p]:mx-4 space-y-2 rounded-xl bg-gray-50 p-4 text-sm">
      {children}
    </div>
  )
}

interface HelpTextProps {
  operation: OperationEnum
}

export function HelpText({ operation }: HelpTextProps) {
  if (operation === OperationEnum.Join) {
    return (
      <Card>
        <strong>
          Create a new value by joining all the source name values together.
        </strong>
        <p>Use Case: Building address field</p>
        <p>
          For example, StreetNumber, StreetDirPrefix, StreetName, StreetSuffix,
          StreetDirSuffix, StreetSuffixModifier, and UnitNumber could become:
        </p>
        <pre>11625 N State Highway 95</pre>
        <p>Null values are omitted.</p>
      </Card>
    )
  }

  if (operation === OperationEnum.Sum) {
    return (
      <Card>
        <strong>
          Convert all source name values to integers and add them up.
        </strong>
        <p>Use Case: Building baths total field</p>
        <p>
          For example, BathroomsFull (1), BathroomsHalf (1) would become: 2.
        </p>
      </Card>
    )
  }

  if (operation === OperationEnum.Static) {
    return (
      <Card>
        <strong>Provide a static value to be stored in this field.</strong>
        <p>Use Case: Building MLS Source Field</p>
        <p>Example Transform Data:</p>
        <pre>CRMLS</pre>
      </Card>
    )
  }

  if (operation === OperationEnum.SplitZipcode) {
    return (
      <Card>
        <strong>
          If only a 9 digit zipcode field is provided, this transform will
          extract just the first 5 digits.
        </strong>
      </Card>
    )
  }

  if (operation === OperationEnum.Titleize) {
    return (
      <Card>
        <strong>Converts the value to "title case".</strong>
        <p>Use Case: Formatting address.</p>
        <p>For example, "1600 COUNTY ROAD" would become:</p>
        <pre>1600 County Road </pre>
      </Card>
    )
  }

  if (operation === OperationEnum.Strip) {
    return (
      <Card>
        <strong>
          Remove either the first part or last part of a value after splitting
          on a given pattern.
        </strong>
        <p>Use Case: Stripping erroneous data from field values.</p>
        <p>
          Source field provides the value "Portland, OR", but you just want
          "Portland":
        </p>
        <p>Example Transform Data</p>
        <pre>, | last </pre>
        <p>
          Source field provides the value "Portland, OR", but you just want
          "OR":
        </p>
        <p>Example Transform Data</p>
        <pre>, | first </pre>
      </Card>
    )
  }

  if (operation === OperationEnum.Condition) {
    return (
      <Card>
        <strong>
          Control this field's value based on the provided list of comma
          separated values for the transform to .
        </strong>
        <pre>source_name | value </pre>
        <pre>StandardStatus | Active </pre>
        <p>
          You can also supply another source name to fall back to if the
          condition fails.
        </p>
        <pre>source_name | value, value | source_name </pre>
        <pre>StandardStatus | Active, Pending | MlsStatus </pre>
      </Card>
    )
  }

  if (operation === OperationEnum.CheckValue) {
    return (
      <Card>
        <strong>
          Provide a value to check the source name value against, returns the
          result.
        </strong>
        <p>Use Case: Building active field for agents</p>
        <p>For example, MemberStatus should check for "Active" value.</p>
        <pre>"MemberStatus": "Active" == true </pre>
        <p>Column type should be boolean.</p>
      </Card>
    )
  }

  if (operation === OperationEnum.CalculateDom) {
    return (
      <Card>
        <strong>Calculates days on market from two date source fields.</strong>
        <p>Use case: Creating days on market field</p>
        <p>Provide the list date and off market date fields.</p>
        <p>Example Transform Data</p>
        <pre>DateList | DateOffmarket </pre>
        <p>If the list date field is blank, nothing will be returned.</p>
        <p>
          If the off market date field is blank the listing will be considered
          on market and will use the current date instead.
        </p>
      </Card>
    )
  }

  if (operation === OperationEnum.MapValue) {
    return (
      <Card>
        <strong>
          Provide a value to match the source name value and what to transform
          it to.
        </strong>
        <p>
          Use case: Creating property type field values from property class
          values
        </p>
        <p>Example Transform Data</p>
        <pre>123 | Residential </pre>
      </Card>
    )
  }

  if (operation === OperationEnum.RemoteField) {
    return (
      <Card>
        <strong>
          Match source name field value with field value from remote resource
          record, then map desired remote field value.
        </strong>
        <p>
          Use case: Map agent or office values to listing record from agent
          resource
        </p>
        <p>
          So, to pull in the "MemberFullName" field from an agent resource might
          look like:
        </p>
        <p>Example Transform Data</p>
        <pre>45 | MemberKeyNumeric | MemberFullName </pre>
        <p>Abstract Transform Data</p>
        <pre>
          <p>remote resource_group id |</p>
          <p>remote reference field column name |</p>
          <p>remote field column name</p>
        </pre>
      </Card>
    )
  }

  if (operation === OperationEnum.RetsObject) {
    return (
      <Card>
        <strong>
          Pulls in value from RETS object, and can combine with value from
          additional field.
        </strong>
        <p>Use case: Source particular value from field object</p>
        <p>
          So, to pull in the "Supplement" remarks object and add it to the
          initial remarks field might look like:
        </p>
        <p>Example Transform Data</p>
        <pre>Property | Supplement | Remarks </pre>
        <p>Abstract Transform Data</p>
        <pre>
          <p>Resource Name |</p>
          <p>Object Name |</p>
          <p>Initial field column name (optional)</p>
        </pre>
        <p>Source field will need to be the primary key field.</p>
      </Card>
    )
  }

  return null
}
