import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export interface Address {
  value: {
    address: string
    lat: number
    lon: number
  }
  label: string
}

const MIN_CHAR_LIMIT = 5

interface AddressSearchArgs {
  address: string
}

export function useAddressSearch(
  { address }: AddressSearchArgs,
  options?: Omit<
    UseQueryOptions<Address[], Error>,
    | 'queryKey'
    | 'queryFn'
    | 'suspense'
    | 'refetchOnWindowFocus'
    | 'keepPreviousData'
  >
) {
  return useQuery<Address[], Error>(
    ['addressSearch', address],
    async () => {
      const encodedQuery = encodeURIComponent(address)
      const res = await fetch(
        `${process.env.REACT_APP_MAPBOX_GEOCODING_BASE_URL}${encodedQuery}.json?country=US,CA&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
      )
      const data = await res.json()
      return data.features.map((r: any) => {
        let [lon, lat] = r.center
        return {
          value: { address: r.place_name, lat, lon },
          label: r.place_name
        }
      })
    },
    {
      ...options,
      suspense: false,
      refetchOnWindowFocus: false,
      enabled: options?.enabled ?? address.length > MIN_CHAR_LIMIT,
      keepPreviousData: address.length > MIN_CHAR_LIMIT
    }
  )
}
