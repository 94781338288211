import { useUpdateAdapterMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation updateAdapter(
    $id: ID!
    $key: String
    $url: String
    $username: String
    $password: String
    $retsVersion: String
    $status: AdapterStatus
    $adapterType: AdapterAdapterType
    $userAgent: String
    $userAgentPassword: String
    $requestTimeZone: String
    $responseTimeZone: String
    $authEndpoint: String
    $scope: String
    $updateDom: Boolean
    $esStatus: AdapterEsStatus
  ) {
    updateAdapter(
      id: $id
      key: $key
      url: $url
      username: $username
      password: $password
      rets_version: $retsVersion
      status: $status
      adapter_type: $adapterType
      user_agent: $userAgent
      user_agent_password: $userAgentPassword
      request_time_zone: $requestTimeZone
      response_time_zone: $responseTimeZone
      auth_endpoint: $authEndpoint
      scope: $scope
      update_dom: $updateDom
      es_status: $esStatus
    ) {
      id
      adapterType: adapter_type
      key
      url
      username
      password
      retsversion: rets_version
      status
      userAgent: user_agent
      userAgentPassword: user_agent_password
      requestTimeZone: request_time_zone
      responseTimeZone: response_time_zone
      authEndPoint: auth_endpoint
      scope
      updateDom: update_dom
      esStatus: es_status
      recordCounts: record_counts {
        today
        listings
        agents
        offices
      }
    }
  }
`

export function useUpdateAdapter() {
  const queryClient = useQueryClient()
  return useUpdateAdapterMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['Adapter'])
    }
  })
}
