import { Tabs } from '@forge/common'
import { StatJob } from '@forge/graphql/generated'
import { useParams, useSearchParams } from 'react-router-dom'
import { StatsCondensed } from './StatsCondensed'

enum TabEnum {
  updateStats,
  auditStats,
  weeklyAudits,
  monthlyAudits,
  populates
}

export function ResourceTabs() {
  const { id: resourceId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const type = (searchParams.get('tab') ||
    'updateStats') as keyof typeof TabEnum
  const selectedTabIndex = TabEnum[type]

  return (
    <div className="w-full">
      <Tabs selectedIndex={selectedTabIndex}>
        <Tabs.List>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'updateStats' })}>
            Update Stats
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'auditStats' })}>
            Audit Stats
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'weeklyAudits' })}>
            Weekly Audits
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'monthlyAudits' })}>
            Monthly Audits
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'populates' })}>
            Populates
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panels>
          <Tabs.Panel>
            <StatsCondensed
              showTotal
              job={StatJob.Updater}
              resourceId={resourceId}
            />
          </Tabs.Panel>
          <Tabs.Panel>
            <StatsCondensed
              showTotal
              job={StatJob.Auditor}
              resourceId={resourceId}
            />
          </Tabs.Panel>
          <Tabs.Panel>
            <StatsCondensed
              showTotal
              job={StatJob.WeeklyAuditor}
              resourceId={resourceId}
            />
          </Tabs.Panel>
          <Tabs.Panel>
            <StatsCondensed
              showTotal
              job={StatJob.MonthlyAuditor}
              resourceId={resourceId}
            />
          </Tabs.Panel>
          <Tabs.Panel>
            <StatsCondensed
              showTotal
              job={StatJob.Populator}
              resourceId={resourceId}
            />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </div>
  )
}
