import { gql } from 'graphql-request'
import { useReorderLookupsMutation } from '@forge/graphql/generated'

gql`
  mutation ReorderLookups($ids: [ID]!) {
    reorderLookups(ids: $ids)
  }
`

export function useReorderLookups() {
  return useReorderLookupsMutation()
}
