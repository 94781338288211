import {
  AdapterMetadataQueryVariables,
  useAdapterMetadataQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'
import { adapterFragment } from './useAdapter'

gql`
  query AdapterMetadata($id: ID) {
    adapter(id: $id) {
      metadata {
        ...MetadataFields
      }
    }
  }
  ${adapterFragment}
`

export function useAdapterMetadata(vars: AdapterMetadataQueryVariables) {
  return useAdapterMetadataQuery(vars, { enabled: !!vars.id })
}
