import {
  FieldTemplateQueryVariables,
  useFieldTemplateQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'
import { fieldTemplateFragment } from './useFieldTemplates'

gql`
  query FieldTemplate($id: ID) {
    fieldTemplate: field_template(id: $id) {
      ...FieldTemplateFields
    }
  }
  ${fieldTemplateFragment}
`

export function useFieldTemplate({ id }: FieldTemplateQueryVariables) {
  return useFieldTemplateQuery({ id }, { enabled: !!id })
}
