import { Avatar, Logo, Menu } from '@forge/common'
import { useCurrentUser, useLogout } from '@forge/features/auth'
import { Jumper } from '@forge/features/jumper'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  HomeIcon,
  MenuIcon,
  UserGroupIcon,
  XIcon
} from '@heroicons/react/outline'
import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { HeaderAlarms } from './HeaderAlarms'
import { HeaderJumper } from './HeaderJumper'
import { HeaderResourceGroups } from './HeaderResourceGroups'

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: false },
  {
    name: 'Data Adapters',
    href: '/adapters',
    icon: CalendarIcon,
    current: false
  },
  {
    name: 'Listing Services',
    href: '/listing_services',
    icon: UserGroupIcon,
    current: false
  }
]

export function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { data: currentUser } = useCurrentUser()
  const logout = useLogout()
  const location = useLocation()

  // Any time the url changes, close the menu
  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location.pathname])

  return (
    <header className="sticky top-0 z-30 grid items-center h-16 grid-cols-12 gap-4 px-4 bg-white border-b border-b-1 border-slate-200/70 backdrop-blur-sm lg:px-8">
      <div className="col-span-2">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <ul className="items-center justify-center hidden col-span-8 space-x-3 lg:flex">
        {navigation.map((item) => (
          <li key={item.name}>
            <NavLink
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  'flex rounded-md py-2 px-3 text-sm font-medium tracking-wide hover:bg-slate-100',
                  {
                    'text-slate-500 hover:text-slate-700': !isActive,
                    'bg-slate-100 text-slate-700': isActive
                  }
                )
              }>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="justify-end hidden col-span-2 lg:flex">
        <div className="flex items-center space-x-4">
          <HeaderJumper />
          <HeaderResourceGroups />
          <HeaderAlarms />
          <Menu
            button={
              <>
                <span className="sr-only">Open user menu</span>
                <Avatar
                  src={currentUser?.avatar || undefined}
                  name={currentUser?.name || undefined}
                  size="sm"
                />
              </>
            }>
            {currentUser?.role === 'super' && (
              <>
                <Menu.Item title="Admin Users" as={Link} to="/admins">
                  Admin Users
                </Menu.Item>
                <Menu.Item title="API Users" as={Link} to="/api_users">
                  API Users
                </Menu.Item>
                <Menu.Item
                  title="Field Templates"
                  as={Link}
                  to="/field_templates">
                  Field Templates
                </Menu.Item>
                <div className="my-1.5 border-t border-slate-200"></div>
              </>
            )}
            <Menu.Item title="Sign Out" onClick={logout}>
              Sign Out
            </Menu.Item>
          </Menu>
        </div>
      </div>

      <div className="flex justify-end col-span-10 lg:hidden">
        <button
          type="button"
          className="inline-flex items-center justify-center p-2 rounded-md text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset"
          onClick={() => setMobileMenuOpen(true)}>
          <span className="sr-only">Open main menu</span>
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>

      {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
      <Transition.Root appear show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 lg:hidden"
          onClose={() => setMobileMenuOpen(false)}>
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="hidden sm:fixed sm:inset-0 sm:block sm:bg-slate-600 sm:bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
            enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
            enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
            leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
            afterLeave={() => setMobileMenuOpen(false)}>
            <nav
              className="fixed inset-0 z-40 w-full h-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
              aria-label="Global">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6">
                <Link to="/">
                  <div className="flex items-center flex-shrink-0 px-1">
                    <div className="w-auto h-8">
                      <Logo />
                    </div>
                  </div>
                </Link>
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset"
                  onClick={() => setMobileMenuOpen(false)}>
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="block w-5 h-5" aria-hidden="true" />
                </button>
              </div>
              <div className="max-w-full px-4 mx-auto mt-2 sm:px-6">
                <Jumper />
              </div>
              <div className="px-2 py-3 mx-auto max-w-8xl sm:px-4">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="block px-3 py-2 text-sm font-medium rounded-md text-slate-900 hover:bg-orange-400 hover:text-white">
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-slate-200">
                <div className="flex items-center px-4 mx-auto max-w-8xl sm:px-6">
                  <Avatar
                    src={currentUser?.avatar || undefined}
                    name={currentUser?.name || undefined}
                    size="md"
                  />
                  <div className="flex-1 min-w-0 ml-3">
                    <div className="font-medium truncate text-slate-800">
                      {currentUser?.name}
                    </div>
                    <div className="text-sm font-medium truncate text-slate-500">
                      {currentUser?.email}
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <HeaderResourceGroups />
                    <HeaderAlarms />
                  </div>
                </div>
                <div className="px-2 mx-auto mt-3 space-y-1 text-sm max-w-8xl sm:px-4">
                  {currentUser?.role === 'super' && (
                    <>
                      <Link
                        to="/admins"
                        className="block px-3 py-2 font-medium rounded-md text-slate-900 hover:bg-orange-400 hover:text-white">
                        Admin Users
                      </Link>
                      <Link
                        to="/api_users"
                        className="block px-3 py-2 font-medium rounded-md text-slate-900 hover:bg-orange-400 hover:text-white">
                        API Users
                      </Link>
                      <Link
                        to="/field_templates"
                        className="block px-3 py-2 font-medium rounded-md text-slate-900 hover:bg-orange-400 hover:text-white">
                        Field Templates
                      </Link>
                    </>
                  )}
                  <button
                    className="block w-full px-3 py-2 font-medium text-left rounded-md text-slate-900 hover:bg-orange-400 hover:text-white"
                    onClick={logout}>
                    Sign out
                  </button>
                </div>
              </div>
            </nav>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </header>
  )
}
