import { useDashboardStatsByHourQuery } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

// TODO: Modify forge so we can query these fields from auto generated data

export interface Job {
  label: string | null
  updater: number | null
  percolator: number | null
  recordsConsumer: number | null
}

gql`
  query DashboardStatsByHour($jobs: [String]) {
    statsByHour: stats_by_hour(jobs: $jobs)
  }
`

export function useDashboardStatsByHour() {
  const res = useDashboardStatsByHourQuery()
  return { ...res, data: JSON.parse(res.data?.statsByHour || '[]') as Job[] }
}
