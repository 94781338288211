import { Button, FormField, Input, Select, toast } from '@forge/common'
import {
  AlarmProfileField,
  AlarmProfileJob,
  AlarmProfileJobStatus,
  AlarmProfileOperation,
  AlarmProfileRole,
  AlarmProfileStatus
} from '@forge/graphql/generated'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useCreateAlarmProfile } from './useCreateAlarmProfile'

export const schema = yup.object({
  status: yup
    .mixed<AlarmProfileStatus>()
    .oneOf(Object.values(AlarmProfileStatus))
    .required('Status is required'),
  name: yup.string(),
  role: yup
    .mixed<AlarmProfileRole>()
    .oneOf(Object.values(AlarmProfileRole))
    .required('Resource Group Role is required'),
  job: yup
    .mixed<AlarmProfileJob>()
    .oneOf(Object.values(AlarmProfileJob))
    .required('Job is required'),
  jobStatus: yup
    .mixed<AlarmProfileJobStatus>()
    .oneOf(Object.values(AlarmProfileJobStatus))
    .required('Job status is required'),
  operation: yup
    .mixed<AlarmProfileOperation>()
    .oneOf(Object.values(AlarmProfileOperation))
    .required('Operation is required'),
  field: yup
    .mixed<AlarmProfileField>()
    .oneOf(Object.values(AlarmProfileField))
    .required('Stat field is required'),
  minThreshold: yup.number(),
  maxThreshold: yup.number(),
  duration: yup.number()
})

export function AlarmProfileNewForm() {
  const { adapterId } = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })

  const {
    mutate: createAlarmProfile,
    isLoading,
    isSuccess,
    error
  } = useCreateAlarmProfile()

  const navigate = useNavigate()
  return (
    <div className="flex w-full max-w-lg justify-center">
      <form
        onSubmit={handleSubmit((data) => {
          createAlarmProfile(
            {
              name: data.name,
              adapterId: Number(adapterId || ''),
              status: data.status,
              role: data.role,
              job: data.job,
              jobStatus: data.jobStatus,
              operation: data.operation,
              field: data.field,
              minThreshold: data.minThreshold,
              maxThreshold: data.maxThreshold,
              duration: data.duration
            },
            {
              onSuccess() {
                toast(
                  <div className="space-y-0.5">
                    <strong>Alarm trigger created successfully</strong>
                  </div>,
                  { variant: 'success' }
                )
                navigate(`/adapters/${adapterId}/alarms`)
              }
            }
          )
        })}
        className="space-y-3">
        <div className="w-full">
          <FormField label="Status" error={errors.status?.message}>
            <Select {...register('status')}>
              <option value="">Select a Status</option>
              {Object.entries(AlarmProfileStatus)?.map(([status, value]) => (
                <option value={value} key={value}>
                  {status}
                </option>
              ))}
            </Select>
          </FormField>
        </div>
        <div className="flex justify-between space-x-3">
          <div className="w-full pb-2">
            <FormField label="Name" error={errors.name?.message}>
              <Input
                {...register('name')}
                placeholder="Enter Alarm Trigger Name"
              />
            </FormField>
          </div>
        </div>
        <div className="flex justify-between space-x-3">
          <div className="w-full pb-2">
            <FormField label="Resource Group Role" error={errors.role?.message}>
              <Select {...register('role')}>
                <option value="">Select a Resource Group Role</option>
                {Object.entries(AlarmProfileRole)?.map(([role, value]) => (
                  <option value={value} key={value}>
                    {role}
                  </option>
                ))}
              </Select>
            </FormField>
          </div>
          <div className="w-full pb-2">
            <FormField label="Job" error={errors.job?.message}>
              <Select {...register('job')}>
                <option value="">Select a Job</option>
                {Object.entries(AlarmProfileJob)?.map(([job, value]) => (
                  <option value={value} key={value}>
                    {job}
                  </option>
                ))}
              </Select>
            </FormField>
          </div>
        </div>

        <div className="flex justify-between space-x-3">
          <div className="w-full pb-2">
            <FormField label="Job Status" error={errors.jobStatus?.message}>
              <Select {...register('jobStatus')}>
                <option value="">Select a Job Status</option>
                {Object.entries(AlarmProfileJobStatus)?.map(
                  ([jobStatus, value]) => (
                    <option value={value} key={value}>
                      {jobStatus}
                    </option>
                  )
                )}
              </Select>
            </FormField>
          </div>
          <div className="w-full pb-2">
            <FormField label="Stat Field" error={errors.field?.message}>
              <Select {...register('field')}>
                <option value="">Select a Stat Field</option>
                {(
                  Object.keys(AlarmProfileField) as Array<
                    keyof typeof AlarmProfileField
                  >
                ).map((field) => (
                  <option value={AlarmProfileField[field]} key={field}>
                    {field}
                  </option>
                ))}

                {Object.entries(AlarmProfileField)?.map(([field, value]) => (
                  <option value={value} key={value}>
                    {field}
                  </option>
                ))}
              </Select>
            </FormField>
          </div>
        </div>

        <div className="flex justify-between space-x-3">
          <div className="w-full pb-2">
            <FormField label="Operation" error={errors.operation?.message}>
              <Select {...register('operation')}>
                <option value="">Select an Operation</option>

                {Object.entries(AlarmProfileOperation)?.map(
                  ([operation, value]) => (
                    <option value={value} key={value}>
                      {operation}
                    </option>
                  )
                )}
              </Select>
            </FormField>
          </div>
          <div className="w-full pb-2">
            <FormField
              label="Min Threshold"
              error={errors.minThreshold?.message}>
              <Input
                {...register('minThreshold')}
                defaultValue={0}
                placeholder="Enter min threshold"
              />
            </FormField>
          </div>
        </div>

        <div className="flex justify-between space-x-3">
          <div className="w-full pb-2">
            <FormField
              label="Max Threshold"
              error={errors.maxThreshold?.message}>
              <Input
                defaultValue={0}
                {...register('maxThreshold')}
                placeholder="Enter max threshold"
              />
            </FormField>
          </div>

          <div className="w-full pb-2">
            <FormField label="Duration" error={errors.duration?.message}>
              <Select {...register('duration')}>
                <option value={0}>Select an Operation</option>
                <option value={1}>1 Hour</option>
                <option value={2}>2 Hours</option>
              </Select>
            </FormField>
          </div>
        </div>
        <div>
          <div className="mt-5 mb-2 ml-2"></div>
          <Button
            fullWidth
            variant={!error ? 'primary' : 'danger'}
            type="submit"
            loading={isLoading}
            leftIcon={
              error ? (
                <ExclamationCircleIcon />
              ) : isSuccess ? (
                <BeakerIcon />
              ) : undefined
            }>
            {!!error && <>An Error Occured. Try again?</>}
            {!error && (
              <>
                {isLoading
                  ? 'Creating Alarm Trigger'
                  : isSuccess
                  ? 'Success'
                  : `Create Alarm Trigger`}
              </>
            )}
          </Button>
        </div>
        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
