import { toTitleCase } from '@forge/common'
import { useResourceGroup } from '@forge/features/resourcegroups'
import { Setup } from '@forge/features/setup'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function ResourceGroupSetup() {
  const { adapterId, resourceGroupId } = useParams()
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  return (
    <>
      <Helmet>
        <title>{toTitleCase(resourceGroup?.role || '')} Setup</title>
      </Helmet>

      <>
        <Setup
          adapterId={adapterId || ''}
          resourceGroupId={resourceGroupId || ''}
        />
      </>
    </>
  )
}
