import { Button, FormField, Input, Modal, Tooltip } from '@forge/common'
import { AdjustmentsIcon } from '@heroicons/react/outline'
import { PlusIcon, TrashIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { usePopulate } from './usePopulate'
import { useResource } from './useResource'

const schema = yup.object({
  startDate: yup.string().required('The start date is required'),
  filters: yup.array(
    yup.object({
      field: yup.string(),
      value: yup.string()
    })
  )
})

interface ResourcePopulateProps {
  isOpen: boolean
  onClose: () => void
}

export function ResourcePopulate({ isOpen, onClose }: ResourcePopulateProps) {
  const { id: resourceId, adapterId } = useParams()
  const [showFilters, setShowFilters] = useState(false)
  const { data: { resource } = {} } = useResource({ id: resourceId || '' })
  const { mutate: populate, isLoading: isPopulating, error } = usePopulate()
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: '3 years ago'
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters'
  })

  const handleAddFilter = () => append({ field: '', value: '' })

  const handleRemoveFilter = (index: number) => remove(index)

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={() => reset()}>
      <Modal.Title>
        Populate Resource: {resource?.name?.toUpperCase() || ''}
      </Modal.Title>
      <div className="-mb-6 -ml-2 flex w-full">
        {!showFilters && (
          <Tooltip
            delay={700}
            placement="left"
            maxWidth={150}
            content="Show Filters">
            <button className="ml-auto" onClick={() => setShowFilters(true)}>
              <AdjustmentsIcon className="h-5 w-5 hover:cursor-pointer" />
            </button>
          </Tooltip>
        )}
        {showFilters && (
          <Tooltip
            delay={700}
            placement="left"
            maxWidth={150}
            content="Hide Filters">
            <button className="ml-auto" onClick={() => setShowFilters(false)}>
              <AdjustmentsIcon className="h-5 w-5 hover:cursor-pointer" />
            </button>
          </Tooltip>
        )}
      </div>
      <form
        className="mt-4 space-y-4"
        onSubmit={handleSubmit((data) => {
          if (resource) {
            populate(
              {
                resourceId: Number(resource.id),
                startDate: data.startDate,
                filters: data.filters
              },
              {
                onSuccess() {
                  reset()
                  onClose()
                  navigate(`/adapters/${adapterId}/details`)
                }
              }
            )
          }
        })}>
        <FormField
          required
          label="How far back in time should Forge request data?"
          error={errors.startDate?.message || error?.message}>
          <Input type="text" {...register('startDate')} />
        </FormField>

        {showFilters && (
          <>
            {fields.map((field, index) => (
              <div key={field.id}>
                <div className="flex w-full justify-center">
                  <div className="mr-3 flex w-full flex-col">
                    <FormField
                      label="Field"
                      error={errors.filters?.message || error?.message}>
                      <Input
                        key={`${field.id}_field`}
                        type="text"
                        {...register(`filters.${index}.field`)}
                      />
                    </FormField>
                  </div>
                  <div className="flex w-full flex-col">
                    <FormField
                      key={`${field.id}_value`}
                      label="Value"
                      error={errors.filters?.message || error?.message}>
                      <Input
                        type="text"
                        {...register(`filters.${index}.value`)}
                      />
                    </FormField>
                  </div>
                  <div className="align-center mt-7 flex h-fit space-x-2">
                    <Tooltip
                      delay={700}
                      placement="top"
                      maxWidth={150}
                      content="Delete Filter">
                      <div
                        className="my-auto -mr-1 flex cursor-pointer items-center justify-center pl-2 align-middle hover:text-red-500"
                        onClick={() => handleRemoveFilter(index)}>
                        <TrashIcon className="h-5 w-5 hover:cursor-pointer" />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-4 flex h-fit w-full">
              {fields.length < 4 && (
                <Tooltip
                  delay={700}
                  placement="right"
                  maxWidth={150}
                  content="Add Filter">
                  <div
                    className="mr-auto flex cursor-pointer items-center justify-center hover:text-green-500"
                    onClick={handleAddFilter}>
                    <PlusIcon className="h-5 w-5 hover:cursor-pointer" />
                  </div>
                </Tooltip>
              )}
            </div>
          </>
        )}
        <div>
          <Button loading={isPopulating} fullWidth>
            Start Populate
          </Button>
        </div>
      </form>
    </Modal>
  )
}
