import md5 from 'md5'
import { CurrentUser } from './useCurrentUser'

const LOCAL_STORAGE_KEY = 'xs-currentLogin'

export function getPersistedCurrentUser() {
  try {
    return JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEY) || ''
    ) as CurrentUser
  } catch (e) {
    return null
  }
}

export function setPersistedCurrentUser(user: CurrentUser) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user))
}

export function removePersistedCurrentUser() {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export async function getAvatar(email?: string | null) {
  if (!email) return null

  try {
    const hash = md5(email.trim().toLowerCase())
    const res = await fetch(`https://en.gravatar.com/${hash}.json`)

    if (res.status === 404) {
      throw new Error('Not Found')
    }

    return `https://www.gravatar.com/avatar/${hash}`
  } catch (error) {
    return null
  }
}
