import {
  Button,
  formatDate,
  formatNumber,
  Select,
  Spinner,
  Table,
  Tabs
} from '@forge/common'
import { AdapterResourceGroupFragment } from '@forge/graphql/generated'
import { Suspense, useState } from 'react'
import { useAdapterResourceGroups } from '../resourcegroups'
import { useAdapterTimestamps } from './useAdapterTimestamps'
import { useUpdateAdapter } from './useUpdateAdapter'

interface AdapterTimezoneDebugProps {
  adapterId: string
}

export function AdapterTimezoneDebug({ adapterId }: AdapterTimezoneDebugProps) {
  const { data: { adapter } = {} } = useAdapterResourceGroups({
    id: String(adapterId)
  })
  const resourceGroups =
    adapter?.resourceGroups as AdapterResourceGroupFragment[]

  return (
    <Tabs>
      <Tabs.List>
        {resourceGroups?.map((resourceGroup) => (
          <>
            {resourceGroup.role !== 'photo' && (
              <Tabs.Tab key={`tab-${resourceGroup.role}`}>
                <span className="capitalize">{resourceGroup.role}</span>
              </Tabs.Tab>
            )}
          </>
        ))}
      </Tabs.List>
      <Tabs.Panels>
        {resourceGroups?.map((resourceGroup) => (
          <>
            {resourceGroup.role !== 'photo' && (
              <TimezonePanel
                key={`panel-${resourceGroup.role}`}
                adapterId={adapterId}
                resourceGroup={resourceGroup}
              />
            )}
          </>
        ))}
      </Tabs.Panels>
    </Tabs>
  )
}

interface TimezonePanelProps {
  adapterId: string
  resourceGroup: AdapterResourceGroupFragment
}

function TimezonePanel({ adapterId, resourceGroup }: TimezonePanelProps) {
  const [selectedResourceId, setSelectedResourceId] = useState<string>('')

  return (
    <Tabs.Panel>
      <div className="my-4">
        <Select
          value={selectedResourceId}
          onChange={(e) => setSelectedResourceId(e.target.value)}>
          <option key={'no_option'} value={''}>
            Choose Resource
          </option>
          {resourceGroup?.resources?.map((resource: any) => (
            <option key={resource.id} value={resource.id}>
              {resource.name}
            </option>
          ))}
        </Select>
        <Suspense
          fallback={
            <div className="my-4 flex space-x-2 text-gray-500">
              <div className="h-5 w-5">
                <Spinner />
              </div>
              <div className="text-sm font-medium">
                Loading timezone details
              </div>
            </div>
          }>
          <>
            {selectedResourceId !== '' && (
              <TimezoneTable
                adapterId={adapterId}
                resourceId={selectedResourceId}
              />
            )}
          </>
        </Suspense>
      </div>
    </Tabs.Panel>
  )
}

interface TimezoneTableProps {
  resourceId: string
  adapterId: string
}

function TimezoneTable({ adapterId, resourceId }: TimezoneTableProps) {
  const {
    mutate: updateRequestTimezone,
    isLoading: isUpdatingRequestTimezone
  } = useUpdateAdapter()
  const {
    mutate: updateResponseTimezone,
    isLoading: isUpdatingResponseTimezone
  } = useUpdateAdapter()
  const { data: { adapterTimestamps } = {} } = useAdapterTimestamps({
    resourceId
  })

  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Data>Oldest Timestamp</Table.Data>
          <Table.Data
            align="right"
            title={adapterTimestamps?.oldestTimestamp || ''}>
            {adapterTimestamps?.oldestTimestamp ? (
              formatDate(
                adapterTimestamps.oldestTimestamp,
                'MM/dd - hh:mm:ss aaaa'
              )
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Newest Timestamp</Table.Data>
          <Table.Data
            align="right"
            title={adapterTimestamps?.newestTimestamp || ''}>
            {adapterTimestamps?.newestTimestamp ? (
              formatDate(
                adapterTimestamps.newestTimestamp,
                'MM/dd - hh:mm:ss aaaa'
              )
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Timestamp Variance</Table.Data>
          <Table.Data align="right">
            {adapterTimestamps?.timestampVariance ? (
              `${formatNumber(
                adapterTimestamps.timestampVariance / 60,
                '0,0[.]00'
              )}h`
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Total Records</Table.Data>
          <Table.Data align="right">
            {adapterTimestamps?.totalRecords ? (
              formatNumber(adapterTimestamps.totalRecords)
            ) : (
              <span className="text-gray-500">-</span>
            )}
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Recommended Request Timezone</Table.Data>
          <Table.Data>
            <div className="flex items-center justify-end gap-2">
              <span className="text-right">
                {adapterTimestamps?.recommendedRequestTimezone || (
                  <span className="text-gray-500">N/A</span>
                )}
              </span>
              <Button
                size="xs"
                loading={isUpdatingRequestTimezone}
                disabled={!adapterTimestamps?.recommendedRequestTimezone}
                onClick={() =>
                  updateRequestTimezone({
                    id: adapterId,
                    requestTimeZone:
                      adapterTimestamps?.recommendedRequestTimezone
                  })
                }>
                Update
              </Button>
            </div>
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Recommended Response Timezone</Table.Data>
          <Table.Data>
            <div className="flex items-center justify-end gap-2">
              <span className="text-right">
                {adapterTimestamps?.recommendedResponseTimezone || (
                  <span className="text-gray-500">N/A</span>
                )}
              </span>
              <Button
                size="xs"
                loading={isUpdatingResponseTimezone}
                disabled={!adapterTimestamps?.recommendedResponseTimezone}
                onClick={() =>
                  updateResponseTimezone({
                    id: adapterId,
                    responseTimeZone:
                      adapterTimestamps?.recommendedResponseTimezone
                  })
                }>
                Update
              </Button>
            </div>
          </Table.Data>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
