import { classNames } from '@forge/common'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

const variants = {
  enter: (direction: number) => ({
    translateX: `${direction * 100}%`,
    opacity: 0
  }),
  center: {
    translateX: '0%',
    opacity: 1
  },
  exit: (direction: number) => ({
    translateX: `${direction * -100}%`,
    opacity: 0
  })
}

interface ListingFullscreenPhotosProps {
  photos: string[]
  initialPage?: number
}

export function ListingFullscreenPhotos({
  photos,
  initialPage = 0
}: ListingFullscreenPhotosProps) {
  const photoCount = photos.length
  const [canSlide, setCanSlide] = useState(true)
  const [hasManuallyMoved, setHasManuallyMoved] = useState(false)
  const [page, setPage] = useState(initialPage)
  const [prev, setPrev] = useState([null, page]) // [prev, current]
  if (prev[1] !== page) {
    setPrev([prev[1], page])
  }
  const prevPage = prev[0]
  const direction = prevPage === null || page > prevPage ? 1 : -1
  const thumbnailOuterContainerRef = useRef<HTMLDivElement>(null)
  const thumbnailInnerContainerRef = useRef<HTMLDivElement>(null)
  const activeThumbnailRef = useRef<HTMLButtonElement>(null)
  const lastThumbnailRef = useRef<HTMLButtonElement>(null)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    setOffset((offset) => {
      const parentRect =
        thumbnailOuterContainerRef.current?.getBoundingClientRect() || {
          left: 0
        }
      const activeChildRect =
        activeThumbnailRef.current?.getBoundingClientRect() || {
          left: 0,
          width: 0
        }
      const containerRect =
        thumbnailInnerContainerRef.current?.getBoundingClientRect() || {
          width: 0
        }
      const slideCenter =
        Math.abs(parentRect.left - activeChildRect.left) +
        activeChildRect.width / 2
      const containerCenter = containerRect.width / 2
      const diff = slideCenter - containerCenter
      const nextDiff = offset - diff

      // If you are the last child you are the active child.
      const lastChildRect = (
        lastThumbnailRef.current || activeThumbnailRef.current
      )?.getBoundingClientRect()

      // Stop the scrolling if there are less than enough thumbnails to fill the screen
      if (
        lastChildRect &&
        Math.abs(parentRect.left - lastChildRect.right) < containerRect.width
      ) {
        return 0
      }

      // If we don't have any room to the left, scroll to zero
      if (nextDiff > 0 || page === 0) {
        return 0
      }
      // Calculates the end of the div
      else if (
        lastChildRect &&
        Math.abs(parentRect.left - lastChildRect.right) - diff <
          containerRect.width
      ) {
        return (
          offset +
          (containerRect.width -
            Math.abs(parentRect.left - lastChildRect.right))
        )
      }
      // Normal calculations
      else {
        return nextDiff
      }
    })
  }, [page])

  return (
    <div className="relative flex h-full flex-col overflow-hidden bg-[#262626]">
      <div className="group relative flex max-h-[calc(100%-8rem)] grow items-center justify-center">
        <AnimatePresence initial={false} mode="popLayout" custom={direction}>
          <motion.img
            layout
            key={page}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={direction}
            transition={{ translate: { ease: 'easeInOut' }, duration: 0.2 }}
            src={photos[page]}
            alt="Active Photo"
            className="object-contain max-w-full max-h-full text-white select-none"
            draggable={false}
            onAnimationStart={() => setCanSlide(false)}
            onAnimationComplete={() => setCanSlide(true)}
          />
        </AnimatePresence>
        <button
          disabled={!canSlide}
          className="absolute left-0 z-[1] hidden h-full items-center justify-center p-2.5 text-white group-hover:flex"
          onClick={() => {
            setHasManuallyMoved(true)
            if (page - 1 < 0) {
              setPrev([prev[1], photoCount])
              setPage(photoCount - 1)
              return
            }
            setPage(page - 1)
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 34"
            className="h-[2.8125rem] w-5 rotate-180">
            <path
              d="m1 1 12 16L1 33"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              opacity=".8"
              strokeLinecap="round"
            />
          </svg>
        </button>
        <button
          disabled={!canSlide}
          className="absolute right-0 z-[1] hidden h-full items-center justify-center p-2.5 text-white group-hover:flex"
          onClick={() => {
            setHasManuallyMoved(true)
            if (page + 1 > photoCount - 1) {
              setPrev([prev[1], null])
              setPage(0)
              return
            }
            setPage(page + 1)
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 34"
            className="h-[2.8125rem] w-5">
            <path
              d="m1 1 12 16L1 33"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              opacity=".8"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
      <div
        ref={thumbnailOuterContainerRef}
        className="h-32 overflow-hidden shrink-0">
        <motion.div
          ref={thumbnailInnerContainerRef}
          className="flex h-full gap-2 py-2"
          animate={{ translateX: offset }}
          transition={{
            translate: { ease: 'easeInOut' },
            duration: hasManuallyMoved ? 0.2 : 0
          }}>
          {photos.map((photo, index, photos) => (
            <button
              key={photo}
              ref={
                index === page
                  ? activeThumbnailRef
                  : index === photos.length - 1
                  ? lastThumbnailRef
                  : undefined
              }
              disabled={!canSlide}
              className={classNames('h-full w-36 shrink-0', {
                'opacity-50': index !== page
              })}
              onClick={() => {
                setHasManuallyMoved(true)
                setPage(index)
              }}>
              <img
                src={photo}
                alt="Listing"
                className="object-cover w-full h-full select-none"
                draggable={false}
              />
            </button>
          ))}
        </motion.div>
      </div>
    </div>
  )
}
