import {
  Breadcrumbs,
  ErrorBoundary,
  Spinner,
  Tooltip,
  Wrapper
} from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import { Metadata } from '@forge/features/metadata'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function AdapterMetadata() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })

  return (
    <>
      <Helmet>
        <title>Adapter: {adapter?.key?.toUpperCase()} - Metadata</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/metadata`}>
            Metadata
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            {adapter?.key?.toUpperCase()} {adapter?.adapterType?.toUpperCase()}{' '}
            Metadata
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Adapter Metadata fields can be imported and applied to various resource groups.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        {adapter?.id && (
          <ErrorBoundary
            fallback={
              <div
                className="text-sm text-red-600"
                role="alert"
                aria-live="polite">
                Couldn't load adapter metadata
              </div>
            }>
            <Suspense
              fallback={
                <div className="w-full p-5">
                  <div className="w-5 h-5 mx-auto text-orange-400">
                    <Spinner />
                  </div>
                </div>
              }>
              <Metadata adapterId={adapter.id} />
            </Suspense>
          </ErrorBoundary>
        )}
      </Wrapper>
    </>
  )
}
