import { fetcher } from '@forge/app'
import {
  SyncRecordsQuery,
  SyncRecordsQueryVariables
} from '@forge/graphql/generated'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

// ! We make this a fake mutation because it's really a
// ! query and we don't want to use the auto generated hooks.
export function useSyncRecords() {
  return useMutation<SyncRecordsQuery, Error, SyncRecordsQueryVariables>(
    async (variables) => {
      const query = gql`
        query SyncRecords($id: ID, $recordIds: [String!]!) {
          resource(id: $id) {
            reloadRecord: reload_record(record_ids: $recordIds) {
              success
              logs
              records {
                ... on ListingResultType {
                  acres
                  address
                  area
                  assocFee: assoc_fee
                  baths
                  bathsFull: baths_full
                  bathsHalf: baths_half
                  bathsSearch: baths_search
                  beds
                  city
                  county
                  createdAt: created_at
                  dateList: date_list
                  dateOffmarket: date_offmarket
                  datePending: date_pending
                  dateSold: date_sold
                  directions
                  dom
                  features
                  featuresStoriesTotal: features_stories_total
                  fullAddress: full_address
                  garages
                  gpsLoc: gps_loc
                  id
                  lat
                  location
                  lon
                  lotdim
                  lotsize
                  mappedPropType: mapped_prop_type
                  mappedStatus: mapped_status
                  mlsnum
                  parcel
                  photoCount: photo_count
                  photos
                  photosUpdatedAt: photos_updated_at
                  price
                  priceList: price_list
                  priceListOrig: price_list_orig
                  priceListPerSqft: price_list_per_sqft
                  priceSold: price_sold
                  propSubType: prop_sub_type
                  propType: prop_type
                  remarks
                  remarksPrivate: remarks_private
                  schoolDistrict: school_district
                  schoolElementary: school_elementary
                  schoolHigh: school_high
                  schoolMiddle: school_middle
                  showingInst: showing_inst
                  sqft
                  state
                  status
                  streetname
                  streetnum
                  style
                  subdivision
                  taxes
                  unit
                  updated_at
                  yearBuilt: year_built
                  zip
                  zipcode
                  wrResourceId: wr_resource_id
                  agentList: agent_list {
                    id
                    name
                    phone
                    email
                  }
                  officeList: office_list {
                    id
                    name
                    phone
                    email
                  }
                  agentSell: agent_sell {
                    id
                    name
                    phone
                    email
                  }
                  officeSell: office_sell {
                    id
                    name
                    phone
                    email
                  }
                  rawData: raw_data {
                    serialized
                    raw
                  }
                }
                ... on AgentResultType {
                  active
                  agentId: agent_id
                  email
                  firstname
                  fullName: full_name
                  id
                  lastname
                  login
                  officeName: office_name
                  phone
                  updatedAt: updated_at
                  wrResourceId: wr_resource_id
                  rawData: raw_data {
                    serialized
                    raw
                  }
                }
                ... on OfficeResultType {
                  email
                  id
                  name
                  officeId: office_id
                  phone
                  updatedAt: updated_at
                  active
                  wrResourceId: wr_resource_id
                  rawData: raw_data {
                    serialized
                    raw
                  }
                }
              }
            }
          }
        }
      `
      const res = await fetcher<SyncRecordsQuery, SyncRecordsQueryVariables>(
        query,
        variables
      )()
      return res
    }
  )
}
