import classNames from 'classnames'
import { ForwardedRef, forwardRef } from 'react'

interface CheckboxProps
  extends Omit<JSX.IntrinsicElements['input'], 'className' | 'type' | 'size'> {
  variant?: 'primary' | 'danger'
  size?: 'md' | 'lg'
}

export const Checkbox = forwardRef(function Checkbox(
  {
    variant = 'primary',
    size = 'md',
    disabled,
    checked,
    children,
    ...props
  }: CheckboxProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <label
      className={classNames('inline-flex space-x-2', {
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled
      })}>
      <input
        ref={ref}
        type="checkbox"
        {...props}
        disabled={disabled}
        checked={checked}
        className={classNames(
          'peer appearance-none rounded border-2 bg-white outline-none focus:ring-2 focus:ring-offset-2',
          {
            'border-gray-300 checked:border-orange-400 checked:bg-orange-400 focus:ring-orange-400':
              variant === 'primary',
            'border-red-300 checked:border-red-500 checked:bg-red-500 focus:ring-red-500':
              variant === 'danger',

            'h-4 w-4': size === 'md',
            'h-5 w-5': size === 'lg',

            'bg-gray-50': disabled
          }
        )}
      />
      {!!children && (
        <span
          className={classNames('font-medium text-gray-500', {
            'text-xs': size === 'md',
            'text-sm': size === 'lg'
          })}>
          {children}
        </span>
      )}
    </label>
  )
})
