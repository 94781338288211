import {
  Button,
  Checkbox,
  Combobox,
  FormField,
  Input,
  Modal
} from '@forge/common'
import { OperationEnum, TransformPartsFragment } from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { HelpText } from './CreateTransform'
import { useUpdateTransform } from './useUpdateTransform'

const schema = yup.object({
  operation: yup
    .mixed<OperationEnum>()
    .oneOf(Object.values(OperationEnum))
    .required('Transform type is required'),
  data: yup.string(),
  serializeOnly: yup.bool()
})

interface UpdateTransformProps {
  transform: TransformPartsFragment
  isOpen: boolean
  onClose: () => void
}

export function UpdateTransform({
  transform,
  isOpen,
  onClose
}: UpdateTransformProps) {
  const { mutate: UpdateTransform, isLoading } = useUpdateTransform()
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      operation: transform.operation || undefined,
      data: transform.data || undefined,
      serializeOnly: transform?.serializeOnly || undefined
    }
  })
  const operationOptions = Object.values(OperationEnum).map((value) => ({
    label: value,
    value: value
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} onAfterClose={() => reset()}>
      <Modal.Title>Edit {transform.operation} Transform</Modal.Title>
      <form
        className="mt-4 space-y-4"
        onSubmit={handleSubmit((data) => {
          UpdateTransform(
            { id: transform.id, fieldId: Number(transform.fieldId), ...data },
            {
              onSuccess() {
                onClose()
              }
            }
          )
        })}>
        <FormField
          label="Transform Type"
          error={errors.operation?.message}
          required>
          {(props) => (
            <Combobox
              {...props}
              selected={operationOptions.find(
                (option) => watch('operation') === option.value
              )}
              options={operationOptions}
              onSelect={(option) => {
                setValue('operation', option.value as OperationEnum)
              }}
            />
          )}
        </FormField>
        <FormField label="Transform Data" error={errors.data?.message}>
          <Input {...register('data')} />
        </FormField>
        <FormField error={errors.serializeOnly?.message}>
          <Checkbox size="lg" {...register('serializeOnly')}>
            Serialize Only
          </Checkbox>
        </FormField>
        <HelpText operation={watch('operation')} />
        <div className="flex justify-end">
          <Button loading={isLoading}>Update Transform</Button>
        </div>
      </form>
    </Modal>
  )
}
