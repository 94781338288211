import { useParams } from 'react-router-dom'
import { Table } from '@forge/common'
import { useAdapter } from './useAdapter'

export function AdapterDetails() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  return (
    <div className="w-full space-y-2 overflow-auto rounded-lg bg-white shadow">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Field</Table.Header>
            <Table.Header align="left">Value</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Data>Adapter Type</Table.Data>
            <Table.Data>{adapter?.adapterType}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>Elasticsearch Version</Table.Data>
            <Table.Data>{adapter?.esVersion}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>URL</Table.Data>
            <Table.Data>
              <div className="max-w-sm overflow-x-auto whitespace-normal">
                {adapter?.url}
              </div>
            </Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>Username</Table.Data>
            <Table.Data>{adapter?.username}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>Password</Table.Data>
            <Table.Data>{adapter?.password}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>Auth EndPoint (RESO)</Table.Data>
            <Table.Data>{adapter?.authEndpoint}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>Scope (RESO)</Table.Data>
            <Table.Data>{adapter?.scope}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>RETS Version</Table.Data>
            <Table.Data>{adapter?.retsVersion}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>User Agent</Table.Data>
            <Table.Data>{adapter?.userAgent}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>User Agent Password</Table.Data>
            <Table.Data>{adapter?.userAgentPassword}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>Request Timezone</Table.Data>
            <Table.Data>{adapter?.requestTimeZone}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>Response Timezone </Table.Data>
            <Table.Data>{adapter?.responseTimeZone}</Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}
