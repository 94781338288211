import {
  ListingServiceQuery,
  useDeleteServiceAdapterMutation
} from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useParams } from 'react-router-dom'

gql`
  mutation deleteServiceAdapter($id: ID!) {
    deleteServiceAdapter(id: $id)
  }
`

export function useDeleteServiceAdapter() {
  const queryClient = useQueryClient()
  const { id: listingServiceId } = useParams()

  return useDeleteServiceAdapterMutation({
    onMutate: async ({ id }) => {
      await queryClient.invalidateQueries(['Adapter'])

      await queryClient.cancelQueries([
        'ListingService',
        { id: String(listingServiceId) }
      ])

      const previousListingService =
        queryClient.getQueryData<ListingServiceQuery>([
          'ListingService',
          { id: String(listingServiceId) }
        ])

      if (previousListingService?.listingService) {
        queryClient.setQueryData(
          ['ListingService', { id: String(listingServiceId) }],
          {
            ...previousListingService,
            listingService: {
              ...previousListingService.listingService,
              serviceAdapters:
                previousListingService.listingService.serviceAdapters?.filter(
                  (service) => service.id !== id
                )
            }
          }
        )
      }

      return { previousListingService }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData<ListingServiceQuery>(
        ['ListingService', { id: String(listingServiceId) }],
        context?.previousListingService
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'ListingService',
        { id: String(listingServiceId) }
      ])
    }
  })
}
