import {
  ResourceGroupSearchDetailsQuery,
  ResourceGroupSearchDetailsQueryVariables,
  ResourceGroupSearchFieldsQueryVariables,
  ResourceGroupSearchQueryVariables,
  ResourceGroupSearchResultsQuery,
  ResourceGroupSearchResultsQueryVariables,
  useFieldSearchValuesQuery,
  useResourceGroupSearchDetailsQuery,
  useResourceGroupSearchFieldsQuery,
  useResourceGroupSearchQuery,
  useResourceGroupSearchResultsQuery
} from '@forge/graphql/generated'
import { UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  query ResourceGroupSearch($id: ID) {
    resourceGroup: resource_group(id: $id) {
      id
      role
      needsSetup: needs_setup
      adapter {
        key
      }
    }
  }
`

export function useResourceGroupSearch(
  variables: ResourceGroupSearchQueryVariables
) {
  return useResourceGroupSearchQuery(variables)
}

gql`
  query ResourceGroupSearchResults(
    $resourceGroupId: ID
    $page: Int
    $limit: Int
    $sort: QuerySortType
    $filter: [FilterQueryType]
  ) {
    resourceGroup: resource_group(id: $resourceGroupId) {
      role
      listings(page: $page, limit: $limit, sort: $sort, filter: $filter) {
        count
        results {
          address
          baths
          beds
          city
          dom
          id
          lat
          lon
          mapped_status
          photos
          price
          sqft
          state
          status
          year_built
          zipcode
        }
      }
      agents(page: $page, limit: $limit, sort: $sort, filter: $filter) {
        count
        results {
          active
          agent_id
          email
          firstname
          full_name
          id
          lastname
          office_name
          phone
        }
      }
      offices(page: $page, limit: $limit, sort: $sort, filter: $filter) {
        count
        results {
          id
          email
          name
          office_id
          phone
          active
        }
      }
    }
  }
`

export function useResourceGroupSearchResults(
  variables: ResourceGroupSearchResultsQueryVariables,
  options?: UseQueryOptions<ResourceGroupSearchResultsQuery, Error>
) {
  return useResourceGroupSearchResultsQuery(variables, options)
}

gql`
  query ResourceGroupSearchDetails(
    $id: ID
    $page: Int
    $limit: Int
    $filter: [FilterQueryType]
  ) {
    resourceGroup: resource_group(id: $id) {
      id
      role
      needsSetup: needs_setup
      listings(page: $page, limit: $limit, filter: $filter) {
        count
        results {
          acres
          address
          area
          assoc_fee
          baths
          baths_full
          baths_half
          baths_search
          beds
          city
          county
          created_at
          date_list
          date_offmarket
          date_pending
          date_sold
          directions
          dom
          features
          features_stories_total
          full_address
          garages
          gps_loc
          id
          lat
          location
          lon
          lotdim
          lotsize
          mapped_prop_type
          mapped_status
          mlsnum
          parcel
          photo_count
          photos
          photos_updated_at
          price
          price_list
          price_list_orig
          price_list_per_sqft
          price_sold
          prop_sub_type
          prop_type
          remarks
          remarks_private
          school_district
          school_elementary
          school_high
          school_middle
          showing_inst
          sqft
          state
          status
          streetname
          streetnum
          style
          subdivision
          taxes
          unit
          updated_at
          year_built
          zipcode
          wr_resource_id
          agent_list {
            id
            name
            phone
            email
          }
          office_list {
            id
            name
            phone
            email
          }
          agent_sell {
            id
            name
            phone
            email
          }
          office_sell {
            id
            name
            phone
            email
          }
          raw_data {
            serialized
            raw
          }
        }
      }
      agents(page: $page, limit: $limit, filter: $filter) {
        count
        results {
          active
          agent_id
          email
          firstname
          full_name
          id
          lastname
          login
          office_name
          phone
          updated_at
          wr_resource_id
          raw_data {
            serialized
            raw
          }
        }
      }
      offices(page: $page, limit: $limit, filter: $filter) {
        count
        results {
          email
          id
          name
          office_id
          phone
          updated_at
          active
          wr_resource_id
          raw_data {
            serialized
            raw
          }
        }
      }
    }
  }
`

export function useResourceGroupSearchDetails(
  variables?: ResourceGroupSearchDetailsQueryVariables,
  options?: UseQueryOptions<ResourceGroupSearchDetailsQuery, Error>
) {
  return useResourceGroupSearchDetailsQuery(variables, options)
}

gql`
  query ResourceGroupSearchFields($resourceGroupId: ID) {
    resourceGroup: resource_group(id: $resourceGroupId) {
      fields(limit: 9999) {
        id
        displayName: display_name
        colName: col_name
        colType: col_type
        aliases
        fieldCategory: field_category {
          id
        }
      }
    }
  }
`

export function useResourceGroupSearchFields(
  variables: ResourceGroupSearchFieldsQueryVariables
) {
  return useResourceGroupSearchFieldsQuery(variables)
}

gql`
  query FieldSearchValues($id: ID) {
    field(id: $id) {
      searchValues: search_values
    }
  }
`

export function useFieldSearchValues(fieldId?: string | null) {
  const { data: fieldSearchValues } = useFieldSearchValuesQuery(
    {
      id: fieldId
    },
    {
      enabled: !!fieldId
    }
  )
  return fieldSearchValues?.field?.searchValues
}
