import {
  JumperDataAdapterFragment,
  JumperDataListingServiceFragment,
  useJumperDataQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

export type JumperService =
  | JumperDataListingServiceFragment
  | JumperDataAdapterFragment

gql`
  fragment JumperDataListingService on ListingService {
    __typename
    id
    key
    name
  }

  fragment JumperDataAdapter on Adapter {
    __typename
    id
    key
    resourceGroups: resource_groups {
      id
    }
  }

  query JumperData {
    listingServices: listing_services(limit: 1000) {
      ...JumperDataListingService
    }
    adapters(limit: 1000) {
      ...JumperDataAdapter
    }
  }
`

export function useJumper() {
  return useJumperDataQuery()
}
