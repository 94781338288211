import { useCreateApiUserMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation createApiUser(
    $name: String
    $apiKey: String!
    $password: String
    $environment: ApiUserEnvironment
    $percolatorNotifications: ApiUserPercolatorNotification
    $percolatorEndpoint: String
  ) {
    createApiUser(
      name: $name
      api_key: $apiKey
      password: $password
      environment: $environment
      percolator_notifications: $percolatorNotifications
      percolator_endpoint: $percolatorEndpoint
    ) {
      id
      name
      apiKey: api_key
      updatedAt: updated_at
    }
  }
`

export function useCreateApiUser() {
  const queryClient = useQueryClient()
  return useCreateApiUserMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['ApiUsers'])
    }
  })
}
