import { Modal } from '@forge/common'
import { SearchIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { Jumper } from '../jumper'
import { useKeyShortcut } from './useKeyShortcut'

export function HeaderJumper() {
  const [showModal, setShowModal] = useState(false)

  useKeyShortcut(() => setShowModal(true))

  return (
    <div>
      <button className="flex" onClick={() => setShowModal(true)}>
        <span className="sr-only">Search</span>
        <SearchIcon className="w-6 h-6 text-slate-400" />
      </button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Title>Search</Modal.Title>
        <div className="mt-4">
          <Jumper onJump={() => setShowModal(false)} />
        </div>
      </Modal>
    </div>
  )
}
