import { Alert } from '@forge/common'
import { DefaultCriteriaFieldsFragment } from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useDeleteCriteria } from './useDeleteCriteria'

interface DeleteCriteriaProps {
  criteria: DefaultCriteriaFieldsFragment
  isOpen: boolean
  onClose: () => void
}

export function DeleteCriteria({
  criteria,
  isOpen,
  onClose
}: DeleteCriteriaProps) {
  const {
    mutate: deleteCriteria,
    isLoading: isDestroying,
    error
  } = useDeleteCriteria({ id: String(criteria.parentId) })

  return (
    <Alert variant="danger" isOpen={isOpen}>
      <Alert.Title>Delete Criteria</Alert.Title>
      <Alert.Content>
        <div className="space-y-2">
          <div>
            Are you sure you want to delete this criteria(
            <strong className="font-medium text-gray-700">
              {criteria.fieldName}
            </strong>
            )? This action cannot be undone.
          </div>
          {error && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {error.message}
            </div>
          )}
        </div>
      </Alert.Content>
      <Alert.Cancel onClick={onClose}>Cancel</Alert.Cancel>
      <Alert.Confirm
        leftIcon={<ExclamationCircleIcon />}
        loading={isDestroying}
        onClick={() => {
          deleteCriteria(
            { id: criteria.id },
            {
              onSuccess() {
                onClose()
              }
            }
          )
        }}>
        Delete Forever
      </Alert.Confirm>
    </Alert>
  )
}
