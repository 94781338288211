import { Label, Select, Spinner, Table } from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import { Suspense, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useResourceDebug } from './useResourceDebug'

export function AdapterDebuggerRoute() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const [selectedResourceId, setSelectedResourceId] = useState(
    adapter?.resources?.[0]?.id || ''
  )

  return (
    <div className="mb-4 space-y-4">
      <div className="space-y-2">
        <Label>Select a Resource for debugging</Label>
        <div className="w-1/4">
          <Select
            value={selectedResourceId}
            onChange={(e) => setSelectedResourceId(e.target.value)}>
            {adapter?.resources?.map((resource: any) => (
              <option key={resource.id} value={resource.id}>
                {resource.name}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <Suspense
        fallback={
          <div className="flex gap-2 text-gray-500">
            <div className="h-5 w-5">
              <Spinner />
            </div>
            <div className="text-sm font-medium">Debugging ...</div>
          </div>
        }>
        <ResourceDebug resourceId={selectedResourceId} />
      </Suspense>
    </div>
  )
}

interface ResourceDebugProps {
  resourceId: string
}

function ResourceDebug({ resourceId }: ResourceDebugProps) {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useResourceDebug({
    adapterId: adapterId,
    resourceId: resourceId
  })
  const debugResult = adapter?.resources?.[0]?.debug?.debugResult
  const debugError = adapter?.resources?.[0]?.debug?.error

  return (
    <div>
      {debugError && (
        <div className="space-y-2">
          <Label>
            <div className="text-red-600 ">
              An error occured during debugging
            </div>
          </Label>

          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Error Request</Table.Header>
                <Table.Header align="left">Error Response</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Data>{JSON.stringify(debugError?.request)}</Table.Data>
                <Table.Data>{JSON.stringify(debugError?.response)}</Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      )}

      {debugResult && (
        <div className="space-y-2">
          <Label>
            <div className="text-green-600 ">Debugging Result:</div>
          </Label>
          <div className="max-h-[calc(100vh-28rem)] overflow-auto">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header align="left">Key</Table.Header>
                  <Table.Header align="left">Value</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {Object.entries(debugResult)?.map((result) => (
                  <Table.Row key={result[0]}>
                    <Table.Data>{JSON.stringify(result[0])}</Table.Data>
                    <Table.Data>
                      <div className="max-w-lg overflow-x-auto whitespace-nowrap">
                        {JSON.stringify(result[1])}
                      </div>
                    </Table.Data>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      )}
    </div>
  )
}
