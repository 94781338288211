import { Breadcrumbs, Tooltip, Wrapper } from '@forge/common'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { EditFieldTemplateForm } from '@forge/features/fieldtemplates'

export function EditFieldTemplate() {
  return (
    <>
      <Helmet>
        <title>Edit Field Template</title>
      </Helmet>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/field_templates">
            Field Templates
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="flex w-full">
          <h1 className="flex whitespace-nowrap text-4xl font-medium">
            Edit Field Template
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Edit Field Template">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>
        <div className="mx-auto max-w-lg justify-center">
          <EditFieldTemplateForm />
        </div>
      </Wrapper>
    </>
  )
}
