import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'

// TODO: Brandon fix this - Dennis
// @ts-ignore
JavascriptTimeAgo.locale(en)
interface TimeAgoProps {
  date: string | Date
}

export function TimeAgo({ date }: TimeAgoProps) {
  return <ReactTimeAgo locale="en" date={new Date(date)} />
}
