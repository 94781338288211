import {
  ResourceDebugQueryVariables,
  useResourceDebugQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query ResourceDebug($adapterId: ID, $resourceId: ID) {
    adapter(id: $adapterId) {
      resources(id: $resourceId) {
        debug {
          error {
            request
            response
          }
          debugResult: debug_result {
            acres
            address
            agentList: agent_list {
              email
              id
              name
              phone
              agentId: agent_id
            }
            agentSell: agent_sell {
              id
              name
              phone
              agentId: agent_id
              email
            }
            area
            assocFee: assoc_fee
            baths
            bathsFull: baths_full
            bathsHalf: baths_half
            bathsQuarter: baths_quarter
            bathsSearch: baths_search
            bathsThreeQuarter: baths_three_quarter
            beds
            cdom
            changes
            city
            county
            createdAt: created_at
            dateExpired: date_expired
            dateList: date_list
            dateOffMarket: date_offmarket
            datePending: date_pending
            dateSold: date_sold
            directions
            dom
            featureCategories: feature_categories
            features
            featuresStoriesTotal: features_stories_total
            fullAddress: full_address
            garages
            gpsLoc: gps_loc
            lat
            location
            lockboxLocation: lockbox_location
            lockboxType: lockbox_type
            lon
            lotdim
            lotsize
            mappedPropType: mapped_prop_type
            mappedStatus: mapped_status
            mlsnum
            officeList: office_list {
              email
              id
              name
              phone
            }
            officeSell: office_sell {
              email
              id
              name
              phone
            }
            parcel
            photoCount: photo_count
            photos
            photosUpdatedAt: photos_updated_at
            price
            priceList: price_list
            priceListLow: price_list_low
            priceListOrig: price_list_orig
            priceListPerSqft: price_list_per_sqft
            priceSold: price_sold
            priceSoldPerSqft: price_sold_per_sqft
            propSubType: prop_sub_type
            propType: prop_type
            rawData: raw_data {
              raw
              serialized
            }
            remarks
            remarksPrivate: remarks_private
            saleRent: sale_rent
            schoolDistrict: school_district
            schoolElementary: school_elementary
            schoolHigh: school_high
            schoolMiddle: school_middle
            showingInst: showing_inst
            sqft
            sqftFields: sqft_fields
            sqftSource: sqft_source
            sqftTotal: sqft_total
            state
            status
            streetname
            streetnum
            style
            subdivision
            taxes
            unit
            updated_at
            wrResourceId: wr_resource_id
            yearBuilt: year_built
            zip
            zipcode
          }
        }
      }
    }
  }
`

export function useResourceDebug(vars: ResourceDebugQueryVariables) {
  return useResourceDebugQuery(vars, {
    enabled: !!vars.resourceId || !!vars.adapterId
  })
}
