import {
  Breadcrumbs,
  Button,
  Spinner,
  Tabs,
  Tooltip,
  Wrapper
} from '@forge/common'
import {
  ApiUsersDetailsTable,
  ApiUsersMlsTable,
  useApiUser
} from '@forge/features/apiusers'
import { DefaultCriteria } from '@forge/features/defaultCriteria'
import { InformationCircleIcon, PencilAltIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'

export function ApiUser() {
  const { apiUserId } = useParams()

  const { data: { apiUser } = {} } = useApiUser(apiUserId || '123')

  return (
    <>
      <Helmet>
        <title>API User: {apiUser?.name}</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/api_users">API Users</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/api_user/${apiUserId}/details`}>
            {apiUser?.name}
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex whitespace-nowrap text-4xl font-medium">
            {apiUser?.name}
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="API Users are better thought of as applications that query
                Forge's GQL endpoint for data.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
          <div className="w-full">
            <div className="float-right">
              <Button
                as={Link}
                rightIcon={<PencilAltIcon />}
                to={`/api_user/${apiUserId}/edit`}>
                Edit
              </Button>
            </div>
          </div>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center p-4">
              <div className="h-5 w-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <Tabs>
            <Tabs.List>
              <Tabs.Tab>Listing Services</Tabs.Tab>
              <Tabs.Tab>Details</Tabs.Tab>
              <Tabs.Tab>Replication Criteria</Tabs.Tab>
            </Tabs.List>
            {apiUser && (
              <Tabs.Panels>
                <Tabs.Panel>
                  <ApiUsersMlsTable activations={apiUser.activations} />
                </Tabs.Panel>
                <Tabs.Panel>
                  <ApiUsersDetailsTable apiUser={apiUser} />
                </Tabs.Panel>
                <Tabs.Panel>
                  <DefaultCriteria
                    parentId={Number(apiUser.id)}
                    parentType="ApiUser"
                    defaultCriteria={apiUser.defaultCriteria?.reverse()}
                  />
                </Tabs.Panel>
              </Tabs.Panels>
            )}
          </Tabs>
        </Suspense>
      </Wrapper>
    </>
  )
}
