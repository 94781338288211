// import { ConfirmationIcon, ErrorIcon, InfoIcon, WarningIcon } from '@cma/icons'
import { XIcon } from '@heroicons/react/outline'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/solid'
import classNames from 'classnames'
import {
  toast,
  ToastContainer as ReactToastifyContainer,
  ToastContent
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Variant = 'success' | 'error' | 'info' | 'warning' | 'default'

export function ToastContainer() {
  return (
    <ReactToastifyContainer
      draggable={false}
      className="space-y-3 sm:w-full sm:max-w-[26.4375rem]"
      toastClassName={(props) => {
        const variant = (props?.type || 'default') as Variant
        return classNames(
          'flex items-start p-4 text-sm rounded shadow-lg w-full',
          {
            'bg-blue-700 text-white': variant === 'info',
            'bg-gray-50 text-gray-900': variant === 'default',
            'bg-green-700 text-white': variant === 'success',
            'bg-red-700 text-white': variant === 'error',
            'bg-yellow-700 text-white': variant === 'warning'
          }
        )
      }}
      bodyClassName="p-0"
      position="bottom-right"
      hideProgressBar
      autoClose={3000}
      icon={false}
      closeButton={({ closeToast }) => (
        <button onClick={closeToast}>
          <XIcon className="h-5 w-5 flex-shrink-0" />
        </button>
      )}
    />
  )
}

interface ToastProps {
  variant?: Variant
  showIcon?: boolean
}

const _toast = (content: ToastContent, props?: ToastProps) => {
  const { variant = 'default', showIcon = true } = props || {}

  return toast(
    <div className="flex items-center space-x-3 pr-2">
      {showIcon && (
        <div className="h-5 w-5 flex-shrink-0" data-testid="toast-icon">
          {variant === 'error' && <div>Error</div>}
          {variant === 'info' && <InformationCircleIcon />}
          {variant === 'success' && <CheckCircleIcon />}
          {variant === 'warning' && <ExclamationCircleIcon />}
          {variant === 'default' && (
            <ExclamationCircleIcon className="text-gray-500" />
          )}
        </div>
      )}
      <>{content}</>
    </div>,
    {
      type: variant
    }
  )
}

export { _toast as toast }
