import {
  AgentSearchQuery,
  AgentSearchQueryVariables,
  useAgentSearchQuery
} from '@forge/graphql/generated'
import { UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

// ! Spoke w/ dennis about keeping AgentSearchResult keys snake_case
// ! We're doing this due to the JSON returned in instances like raw_data where we're unable to alias said key
// ! This keeps the listnig detail raw data tab from breaking
// ! We'll revisit after __old__ is removed

export const AgentSearchResult = gql`
  fragment AgentSearchResult on AgentResultType {
    active
    agent_id
    email
    firstname
    full_name
    id
    lastname
    login
    office_name
    phone
    updated_at
    wr_resource_id
    raw_data {
      serialized
      raw
    }
  }
`

gql`
  fragment AgentSearchFieldParts on Field {
    aliases
    colName: col_name
    id
  }

  query AgentSearch(
    $id: ID!
    $limit: Int
    $page: Int
    $filter: [FilterQueryType]
    $sort: QuerySortType
    $source: String
  ) {
    agentRg: resource_group(id: $id) {
      id
      agents(
        limit: $limit
        page: $page
        filter: $filter
        sort: $sort
        source: $source
      ) {
        count
        explain
        results {
          ...AgentSearchResult
        }
      }
    }
    resourceGroupFieldsSearchValues: resource_group(id: $id) {
      id
      fields(limit: 10000) {
        ...AgentSearchFieldParts
      }
    }
  }
  ${AgentSearchResult}
`

export function useAgentSearch(
  vars: AgentSearchQueryVariables,
  options?: Omit<
    UseQueryOptions<AgentSearchQuery, Error>,
    'queryKey' | 'queryFn'
  >
) {
  return useAgentSearchQuery(vars, options)
}
