import { FieldQueryVariables, useFieldQuery } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

export const FieldPartsFragment = gql`
  fragment TransformParts on Transform {
    id
    order
    operation
    data
    serializeOnly: serialize_only
    fieldId: field_id
  }

  fragment LookupParts on Lookup {
    id
    order
    value
    mappedValue: mapped_value
    sourceId: source_id
    fieldId: field_id
  }

  fragment FieldParts on Field {
    id
    aliases
    colName: col_name
    colType: col_type
    compose
    displayName: display_name
    fieldCategory: field_category {
      id
      name
      resourceGroupId: resource_group_id
      resourceGroup: resource_group {
        adapterId: adapter_id
      }
    }
    fieldCategoryId: field_category_id
    lookups(limit: 10000) {
      ...LookupParts
    }
    mapValues: map_values
    possibleCategories: possible_categories {
      id
      name
    }
    possileSources: possible_sources {
      label
      value
    }
    queryable
    autocomplete
    calculateDom: calculate_dom
    sourceNames: source_names
    searchValues: search_values
    isDraft: is_draft
    standardName: standard_name
    agentOnly: agent_only
    categoryName: category_name
    subcategoryName: subcategory_name
    sqft
    pendingChanges: pending_changes {
      aliases
      categoryName: category_name
      subcategoryName: subcategory_name
      colName: col_name
      colType: col_type
      compose
      displayName: display_name
      fieldCategory: field_category {
        id
        name
        resourceGroupId: resource_group_id
      }
      fieldCategoryId: field_category_id
      mapValues: map_values
      queryable
      agentOnly: agent_only
      autocomplete
      calculateDom: calculate_dom
      standardName: standard_name
      sourceNames: source_names
      sqft
    }
    transforms(limit: 1000, sort: { field: "order", dir: asc }) {
      ...TransformParts
    }
  }
`

gql`
  query Field($id: ID!) {
    field(id: $id) {
      ...FieldParts
    }
  }

  ${FieldPartsFragment}
`

export function useField(vars: FieldQueryVariables) {
  return useFieldQuery(vars)
}
