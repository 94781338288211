import { Diff, Table } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface FieldDetailsProps {
  field: FieldPartsFragment
}

export function FieldDetails({ field }: FieldDetailsProps) {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header align="left">Name</Table.Header>
          <Table.Header align="left">Value</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Data>Field Category</Table.Data>
          <Table.Data>
            <Diff
              value={field.fieldCategory?.name}
              newValue={field.pendingChanges?.fieldCategory?.name}
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Source Names</Table.Data>
          <Table.Data>
            <Diff
              value={field.sourceNames?.join(', ')}
              newValue={field.pendingChanges?.sourceNames?.join(', ')}
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Display Name</Table.Data>
          <Table.Data>
            <Diff
              value={field.displayName}
              newValue={field.pendingChanges?.displayName}
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Column Name</Table.Data>
          <Table.Data>
            <Diff
              value={field.colName}
              newValue={field.pendingChanges?.colName}
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">Column Type</Table.Data>
          <Table.Data>
            <Diff
              value={field.colType}
              newValue={field.pendingChanges?.colType}
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">Aliases</Table.Data>
          <Table.Data>
            <Diff
              value={field.aliases?.join(', ')}
              newValue={field.pendingChanges?.aliases?.join(', ')}
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Field Mapping Settings</Table.Data>
          <Table.Data>
            <Diff
              value={field.mapValues}
              newValue={field.pendingChanges?.mapValues}
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">Queryable</Table.Data>
          <Table.Data>
            <Diff
              value={!!field.queryable ? 'True' : 'False'}
              newValue={
                field.pendingChanges?.queryable == null
                  ? null
                  : field.pendingChanges?.queryable
                  ? 'True'
                  : 'False'
              }
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">Autocomplete</Table.Data>
          <Table.Data>
            <Diff
              value={!!field.autocomplete ? 'True' : 'False'}
              newValue={
                field.pendingChanges?.autocomplete == null
                  ? null
                  : field.pendingChanges?.autocomplete
                  ? 'True'
                  : 'False'
              }
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">Composite</Table.Data>
          <Table.Data>
            <Diff
              value={!!field.compose ? 'True' : 'False'}
              newValue={
                field.pendingChanges?.compose == null
                  ? null
                  : field.pendingChanges?.compose
                  ? 'True'
                  : 'False'
              }
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">SqFt Field</Table.Data>
          <Table.Data>
            <Diff
              value={!!field.sqft ? 'True' : 'False'}
              newValue={
                field.pendingChanges?.sqft == null
                  ? null
                  : field.pendingChanges?.sqft
                  ? 'True'
                  : 'False'
              }
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">Calculate Dom</Table.Data>
          <Table.Data>
            <Diff
              value={!!field.calculateDom ? 'True' : 'False'}
              newValue={
                field.pendingChanges?.calculateDom == null
                  ? null
                  : field.pendingChanges?.calculateDom
                  ? 'True'
                  : 'False'
              }
            />
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data width="200">Standard Name</Table.Data>
          <Table.Data>
            <Diff
              value={field.standardName}
              newValue={field.pendingChanges?.standardName}
            />
          </Table.Data>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
