import { Button, Modal } from '@forge/common'
import Mousetrap from 'mousetrap'
import { PropsWithChildren, useEffect } from 'react'

interface DialogProps {
  method: string
  confirmAction: () => void
  cancelAction: () => void
  confirmLabel: string
  cancelLabel: string
  title: string
}

export const Dialog = ({
  method,
  confirmAction,
  cancelAction,
  confirmLabel,
  cancelLabel,
  title,
  children
}: PropsWithChildren<DialogProps>) => {
  useEffect(() => {
    Mousetrap.bind('esc', cancelAction)
  }, [cancelAction])

  return (
    <Modal isOpen size="2xl" onClose={cancelAction}>
      <h3 className="text-xl">{title}</h3>
      <div className="text-sm">{children}</div>
      <div className="pt-4">
        {cancelAction && cancelLabel && (
          <Button size="xs" variant="secondary" onClick={cancelAction}>
            {cancelLabel}
          </Button>
        )}

        <span className="px-2"></span>
        {confirmAction &&
          confirmLabel &&
          (method === 'DELETE' ? (
            <Button size="xs" variant="danger" onClick={confirmAction}>
              {confirmLabel}
            </Button>
          ) : (
            <Button size="xs" variant="primary" onClick={confirmAction}>
              {confirmLabel}
            </Button>
          ))}
      </div>
    </Modal>
  )
}
