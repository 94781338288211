import {
  ListingSearchQuery,
  ListingSearchQueryVariables,
  useListingSearchQuery
} from '@forge/graphql/generated'
import { UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

// ! Spoke w/ dennis about keeping ListingSearchResult keys snake_case
// ! We're doing this due to the JSON returned in instances like raw_data where we're unable to alias said key
// ! This keeps the listnig detail raw data tab from breaking
// ! We'll revisit after __old__ is removed

export const ListingSearchResult = gql`
  fragment ListingSearchResult on ListingResultType {
    acres
    address
    area
    assoc_fee
    baths
    baths_full
    baths_half
    baths_search
    beds
    city
    county
    created_at
    date_list
    date_offmarket
    date_pending
    date_sold
    directions
    dom
    features
    features_stories_total
    full_address
    garages
    gps_loc
    id
    lat
    location
    lon
    lotdim
    lotsize
    mapped_prop_type
    mapped_status
    mlsnum
    parcel
    photo_count
    photos
    photos_updated_at
    price
    price_list
    price_list_orig
    price_list_per_sqft
    price_sold
    prop_sub_type
    prop_type
    remarks
    remarks_private
    school_district
    school_elementary
    school_high
    school_middle
    showing_inst
    sqft
    state
    status
    streetname
    streetnum
    style
    subdivision
    taxes
    unit
    updated_at
    year_built
    zipcode
    wr_resource_id
    agent_list {
      id
      name
      phone
      email
    }
    office_list {
      id
      name
      phone
      email
    }
    agent_sell {
      id
      name
      phone
      email
    }
    office_sell {
      id
      name
      phone
      email
    }
    raw_data {
      serialized
      raw
    }
  }
`

gql`
  fragment ListingSearchFieldParts on Field {
    aliases
    colName: col_name
    id
  }

  query ListingSearch(
    $id: ID!
    $limit: Int
    $page: Int
    $filter: [FilterQueryType]
    $sort: QuerySortType
    $source: String
  ) {
    listingRg: resource_group(id: $id) {
      id
      listings(
        limit: $limit
        page: $page
        filter: $filter
        sort: $sort
        source: $source
      ) {
        count
        explain
        results {
          ...ListingSearchResult
        }
      }
    }
    resourceGroupFieldsSearchValues: resource_group(id: $id) {
      id
      fields(limit: 10000) {
        ...ListingSearchFieldParts
      }
    }
  }
  ${ListingSearchResult}
`

export function useListingSearch(
  vars: ListingSearchQueryVariables,
  options?: Omit<
    UseQueryOptions<ListingSearchQuery, Error>,
    'queryKey' | 'queryFn'
  >
) {
  return useListingSearchQuery(vars, options)
}
