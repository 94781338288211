import { Breadcrumbs, Tooltip, Wrapper } from '@forge/common'
import { ListingServiceNewForm } from '@forge/features/listingservices'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'

export function ListingServiceNew() {
  return (
    <>
      <Helmet>
        <title>New Listing Service</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/listing_services">
            Listing Services
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/listing_services/new`}>
            New Listing Service
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            New Listing Service
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Add a listing service.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        <div className="mx-auto max-w-lg justify-center">
          <ListingServiceNewForm />
        </div>
      </Wrapper>
    </>
  )
}
