export const listingDataDictionaryOptions = [
  'UnparsedAddress',
  'StreetNumber',
  'StreetDirPrefix',
  'StreetName',
  'StreetSuffix',
  'StreetDirSuffix',
  'StreetSuffixModifier',
  'UnitNumber',
  'City',
  'StateOrProvince',
  'PostalCode',
  'MLSAreaMajor',
  'MlsAreaMajor',
  'BathroomsFull',
  'BathroomsHalf',
  'BathroomsOneQuarter',
  'BathroomsThreeQuarter',
  'BathroomsTotalInteger',
  'BedroomsTotal',
  'County',
  'CountyOrParish',
  'ExpirationDate',
  'ListDate',
  'ListingContractDate',
  'AvailabilityDate',
  'OffMarketDate',
  'CloseDate',
  'PurchaseContractDate',
  'Directions',
  'DaysOnMarket',
  'CumulativeDaysOnMarket',
  'Longitude',
  'Latitude',
  'ListingKey',
  'LotSizeDimensions',
  'LotSizeSquareFeet',
  'StandardStatus',
  'ListingId',
  'OriginalEntryTimestamp',
  'PhotosCount',
  'Photos',
  'PhotosChangeTimestamp',
  'Price',
  'ClosePrice',
  'ListPrice',
  'ListPriceLow',
  'OriginalListPrice',
  'PublicRemarks',
  'SyndicationRemarks',
  'PrivateRemarks',
  'ShowingInstructions',
  'LivingArea',
  'BuildingAreaTotal',
  'LivingAreaSource',
  'LivingAreaUnits',
  'StatusChangeTimestamp',
  'ModificationTimestamp',
  'ParcelNumber',
  'Possession',
  'CommonWalls',
  'YearBuiltSource',
  'LotSizeAcres',
  'AssociationFee',
  'ArchitecturalStyle',
  'GarageSpaces',
  'ParkingTotal',
  'SchoolDistrict',
  'HighSchoolDistrict',
  'ElementarySchool',
  'HighSchool',
  'MiddleOrJuniorSchool',
  'SubdivisionName',
  'StoriesTotal',
  'Stories',
  'TaxAnnualAmount',
  'YearBuilt',
  'AboveGradeFinishedArea',
  'Appliances',
  'AccessibilityFeatures',
  'AssociationAmenities',
  'AssociationFeeFrequency',
  'AssociationFeeIncludes',
  'AssociationName',
  'AssociationFee2',
  'AssociationFee2Frequency',
  'Basement',
  'BedroomsPossible',
  'BelowGradeFinishedArea',
  'BuilderModel',
  'BuilderName',
  'BuildingAreaUnits',
  'BuildingFeatures',
  'BuildingName',
  'BusinessName',
  'CableTvExpense',
  'CarportSpaces',
  'CommonInterest',
  'CommunityFeatures',
  'Concessions',
  'ConstructionMaterials',
  'Cooling',
  'CoveredSpaces',
  'CultivatedArea',
  'CurrentFinancing',
  'CurrentUse',
  'DevelopmentStatus',
  'DirectionFaces',
  'Disclosures',
  'DoorFeatures',
  'Electric',
  'ElectricExpense',
  'Elevation',
  'ElevationUnits',
  'EntryLevel',
  'EntryLocation',
  'Exclusions',
  'ExteriorFeatures',
  'Fencing',
  'FireplaceFeatures',
  'FireplacesTotal',
  'Flooring',
  'FoundationArea',
  'FoundationDetails',
  'FrontageLength',
  'FuelExpense',
  'Furnished',
  'GardenerExpense',
  'GreenEnergyEfficient',
  'GreenEnergyGeneration',
  'GreenIndoorAirQuality',
  'GreenLocation',
  'GreenSustainability',
  'GreenWaterConservation',
  'GrossIncome',
  'Heating',
  'HorseAmenities',
  'HoursDaysOfOperation',
  'Inclusions',
  'IncomeIncludes',
  'InsuranceExpense',
  'InteriorFeatures',
  'IrrigationSource',
  'LaborInformation',
  'LandLeaseAmount',
  'LandLeaseAmountFrequency',
  'LaundryFeatures',
  'LeaseTerm',
  'Levels',
  'LotFeatures',
  'MaintenanceExpense',
  'ManagerExpense',
  'NumberOfBuildings',
  'NumberOfFullTimeEmployees',
  'NumberOfPartTimeEmployees',
  'NumberOfLots',
  'NumberOfPads',
  'NumberOfUnitsTotal',
  'NumberOfUnitsVacant',
  'OtherEquipment',
  'OtherExpense',
  'OtherParking',
  'OtherStructures',
  'OwnerPays',
  'ParkingFeatures',
  'PastureArea',
  'PatioAndPorchFeatures',
  'PetsAllowed',
  'PoolExpense',
  'PoolFeatures',
  'PossibleUse',
  'PropertyCondition',
  'OperatingExpense',
  'OperatingExpenseIncludes',
  'ProfessionalManagementExpense',
  'RentIncludes',
  'RoadResponsibility',
  'Roof',
  'RoomsTotal',
  'SecurityFeatures',
  'SeniorCommunityYN',
  'Sewer',
  'Skirt',
  'SpaFeatures',
  'SpecialLicenses',
  'SuppliesExpense',
  'TenantPays',
  'TrashExpense',
  'UnitsFurnished',
  'Utilities',
  'Vegetation',
  'View',
  'VirtualTourURLUnbranded',
  'WaterBodyName',
  'WaterfrontFeatures',
  'WaterSewerExpense',
  'WaterSource',
  'WindowFeatures',
  'WoodedArea',
  'ZoningDescription',
  'ListAgentKey',
  'ListAgentKeyNumeric',
  'ListAgentMlsId',
  'ListAgentFullName',
  'ListAgentFirstName',
  'ListAgentLastName',
  'ListAgentDirectPhone',
  'ListAgentPreferredPhone',
  'ListAgentEmail',
  'ListAgentFax',
  'BuyerAgentKey',
  'BuyerAgentKeyNumeric',
  'BuyerAgentMlsId',
  'BuyerAgentFullName',
  'BuyerAgentFirstName',
  'BuyerAgentLastName',
  'BuyerAgentDirectPhone',
  'BuyerAgentPreferredPhone',
  'BuyerAgentEmail',
  'ListOfficeKey',
  'ListOfficeKeyNumeric',
  'ListOfficeMlsId',
  'ListOfficeName',
  'ListOfficePhone',
  'ListAgentOfficePhone',
  'ListOfficeEmail',
  'BuyerOfficeKey',
  'BuyerOfficeKeyNumeric',
  'BuyerOfficeMlsId',
  'BuyerOfficeName',
  'BuyerOfficePhone',
  'BuyerAgentOfficePhone',
  'BuyerOfficeEmail'
]

export const listingAliasOptions = [
  'acres',
  'address',
  'agent_list_email',
  'agent_list_id',
  'agent_list_name',
  'agent_list_phone',
  'agent_sell_email',
  'agent_sell_id',
  'agent_sell_name',
  'agent_sell_phone',
  'area',
  'assoc_fee',
  'baths_full',
  'baths_half',
  'baths_quarter',
  'baths_search',
  'baths_three_quarter',
  'baths',
  'beds',
  'cdom',
  'city',
  'county',
  'created_at',
  'date_cancelled',
  'date_expired',
  'date_list',
  'date_offmarket',
  'date_pending',
  'date_sold',
  'directions',
  'dom',
  'features_stories_total',
  'full_address',
  'garages',
  'gps_loc',
  'id',
  'lat',
  'location',
  'lon',
  'lotdim',
  'lotsize',
  'mapped_status',
  'mlsnum',
  'office_list_email',
  'office_list_id',
  'office_list_name',
  'office_list_phone',
  'office_sell_email',
  'office_sell_id',
  'office_sell_name',
  'office_sell_phone',
  'parcel',
  'photo_count',
  'photos_updated_at',
  'photos',
  'price_list_orig',
  'price_list_per_sqft',
  'price_list',
  'price_sold',
  'price',
  'prop_sub_type',
  'prop_type',
  'remarks_private',
  'remarks',
  'sale_rent',
  'school_district',
  'school_elementary',
  'school_high',
  'school_middle',
  'showing_inst',
  'source',
  'sqft',
  'state',
  'status',
  'streetname',
  'streetnum',
  'style',
  'subdivision',
  'taxes',
  'unit',
  'updated_at',
  'virtual_tour',
  'wr_resource_id',
  'year_built',
  'zip',
  'zipcode'
]

export const agentAliasOptions = [
  'active',
  'agent_id',
  'email',
  'firstname',
  'full_name',
  'id',
  'lastname',
  'office_name',
  'phone',
  'updated_at',
  'wr_resource_id'
]

export const agentDataDictionaryOptions = [
  'MemberKey',
  'MemberFirstName',
  'MemberLastName',
  'MemberFullName',
  'MemberMlsId',
  'MemberPreferredPhone',
  'MemberEmail',
  'MemberStatus',
  'ModificationTimestamp',
  'MemberAORkey',
  'OfficeName'
]

export const officeAliasOptions = [
  'active',
  'email',
  'id',
  'name',
  'office_id',
  'phone',
  'updated_at',
  'wr_resource_id'
]

export const officeDataDictionaryOptions = [
  'ModificationTimestamp',
  'OfficeEmail',
  'OfficeKey',
  'OfficeMlsId',
  'OfficeName',
  'OfficePhone',
  'OfficeStatus'
]
