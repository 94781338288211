import { useUpdateFieldMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation UpdateField(
    $id: ID!
    $colName: String
    $colType: ColTypeEnum
    $displayName: String
    $queryable: Boolean
    $autocomplete: Boolean
    $calculateDom: Boolean
    $fieldCategoryId: Int
    $aliases: [String]
    $sourceNames: [String]
    $mapValues: FieldMapValue
    $compose: Boolean
    $standardName: String
    $agentOnly: Boolean
    $categoryName: String
    $subcategoryName: String
    $sqft: Boolean
  ) {
    updateField(
      id: $id
      display_name: $displayName
      col_name: $colName
      col_type: $colType
      aliases: $aliases
      queryable: $queryable
      autocomplete: $autocomplete
      calculate_dom: $calculateDom
      source_names: $sourceNames
      map_values: $mapValues
      field_category_id: $fieldCategoryId
      compose: $compose
      standard_name: $standardName
      agent_only: $agentOnly
      category_name: $categoryName
      subcategory_name: $subcategoryName
      sqft: $sqft
    ) {
      id
    }
  }
`

export function useUpdateField() {
  const queryClient = useQueryClient()
  return useUpdateFieldMutation({
    async onSuccess({ updateField }) {
      await queryClient.invalidateQueries(['Field', { id: updateField?.id }])
      await queryClient.invalidateQueries(['ResourceGroupFields'])
    }
  })
}
