import { classNames } from '@forge/common'
import { ListingBadge } from './ListingBadge'
import { ListingPhotos } from './ListingPhotos'

interface ListingCardProps {
  address?: string | null
  baths?: number | null
  beds?: number | null
  city?: string | null
  dom?: string | number | null
  isOpen?: boolean
  mappedStatus?: string | null | undefined
  onClick?: () => void
  photos?: string[] | null | undefined
  price?: number | null
  sqft?: number | null
  state?: string | null
  status?: string | null
  variant?: 'primary' | 'preview'
  yearBuilt?: number | null
  zip?: string | null
}

export function ListingCard({
  address,
  baths,
  beds,
  city,
  dom,
  isOpen,
  mappedStatus,
  onClick,
  photos,
  price,
  sqft,
  state,
  status,
  yearBuilt,
  zip
}: ListingCardProps) {
  const cityStateZip = [city, [state, zip].filter(Boolean).join(' ')]
    .filter(Boolean)
    .join(', ')

  return (
    <div
      className={classNames('rounded-md bg-white hover:shadow-2xl', {
        'shadow-raised ring-2 ring-orange-500': isOpen,
        shadow: !isOpen
      })}>
      <div className="rounded">
        {!!photos ? (
          <ListingPhotos photos={photos} />
        ) : (
          <div className="h-[17.25rem]">
            <p className="p-4 text-red-500">No Photos Available</p>
          </div>
        )}
      </div>
      <div onClick={onClick} className="p-2 cursor-pointer">
        <div className="w-4/6 overflow-hidden text-sm truncate 2xl:text-md">
          {address}
        </div>
        <div className="float-right -mt-6 text-sm 2xl:text-md">
          ${price && price.toLocaleString()}{' '}
        </div>
        <div className="flex">
          <div className="w-full my-1 text-xs text-gray-500 truncate">
            {cityStateZip}
          </div>
          <div className="flex justify-end w-full my-1">
            <ListingBadge mappedStatus={mappedStatus} status={status} />
          </div>
        </div>
        <div className="flex text-xs text-gray-500">
          <div className="w-3/4">
            {beds} Beds • {baths} Baths • {''}
            {sqft?.toLocaleString()} Sqft • {yearBuilt}
          </div>
          <div className="w-1/4">
            <p className="float-right">{dom} DOM</p>
          </div>
        </div>
      </div>
    </div>
  )
}

ListingCard.Skeleton = function ListingCardSkeleton() {
  return (
    <div className="bg-white rounded-md shadow">
      <div className="h-[17.25rem] w-full animate-pulse bg-gray-200 object-cover" />
      <div className="flex w-full p-2">
        <div className="w-1/2 space-y-2 px-1 py-0.5">
          <div className="h-[1.0625rem] w-[12.0625rem] animate-pulse bg-gray-200" />
          <div className="h-[1.0625rem] w-[10.0625rem] animate-pulse bg-gray-200" />
          <div className="flex h-[1.0625rem] w-[8.0625rem] animate-pulse bg-gray-200" />
        </div>
        <div className="w-1/2 space-y-2 px-1 py-0.5">
          <div className="float-right h-[1.0625rem] w-[6.0625rem] animate-pulse bg-gray-200" />
          <div className="float-right flex h-[1.0625rem] w-[8.0625rem] animate-pulse bg-gray-200" />
          <div className="float-right h-[1.0625rem] w-[6.0625rem] animate-pulse bg-gray-200" />
        </div>
      </div>
    </div>
  )
}
