import { fetcher } from '@forge/app'
import {
  AdminUserFragment,
  CurrentUserQuery,
  CurrentUserQueryVariables
} from '@forge/graphql/generated'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { getAvatar, getPersistedCurrentUser } from './utils'

export interface CurrentUser extends AdminUserFragment {
  token: string
  avatar: string | null
}

//  ! Impersonate the adapters query as the current user
//  ! if adapters comes back as null we'll assume
//  ! the current user isn't logged in
const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    adapters(limit: 0) {
      id
    }
  }
`

// We would use a generated hook but we need to talk to gravatar to see if the user exists
// If the user doesn't exist, use null for avatar rather than the default avatar.
export function useCurrentUser() {
  return useQuery<CurrentUser | null>(['CurrentUser'], async () => {
    const res = await fetcher<CurrentUserQuery, CurrentUserQueryVariables>(
      CURRENT_USER_QUERY
    )()

    const currentUser =
      res?.adapters !== null ? getPersistedCurrentUser() : null

    if (currentUser) {
      const avatar = await getAvatar(currentUser?.email)
      return { ...currentUser, avatar }
    }

    return null
  })
}
