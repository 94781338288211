import { useAdminsQuery } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  fragment AdminFields on Admin {
    id
    email
    name
    phone
    timezone
    role
    alerts
    resetUrl: reset_url
    lastLogin: last_login
    updatedAt: updated_at
  }

  query Admins {
    admins(limit: 1000) {
      ...AdminFields
    }
  }
`
export function useAdminUsers() {
  return useAdminsQuery()
}
