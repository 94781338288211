import { useCreateTransformMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation CreateTransform(
    $fieldId: Int
    $operation: OperationEnum
    $order: Int
    $data: String
    $serializeOnly: Boolean
  ) {
    createTransform(
      field_id: $fieldId
      operation: $operation
      order: $order
      data: $data
      serialize_only: $serializeOnly
    ) {
      id
      field {
        id
        transforms {
          id
          order
          operation
          data
          serialize_only
          field_id
        }
      }
    }
  }
`

export function useCreateTransform() {
  const queryClient = useQueryClient()
  return useCreateTransformMutation({
    async onSuccess(_, { fieldId }) {
      await queryClient.invalidateQueries(['Field', { id: String(fieldId) }])
    }
  })
}
