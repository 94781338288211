import { useCreateFieldMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { FieldPartsFragment } from './useField'

gql`
  mutation createField(
    $colName: String!
    $colType: ColTypeEnum
    $displayName: String
    $queryable: Boolean
    $autocomplete: Boolean
    $calculateDom: Boolean
    $fieldCategoryId: Int
    $aliases: [String]
    $sourceNames: [String]
    $mapValues: FieldMapValue
    $compose: Boolean
    $standardName: String
    $agentOnly: Boolean
    $categoryName: String
    $subcategoryName: String
  ) {
    createField(
      display_name: $displayName
      col_name: $colName
      col_type: $colType
      aliases: $aliases
      queryable: $queryable
      autocomplete: $autocomplete
      calculate_dom: $calculateDom
      source_names: $sourceNames
      map_values: $mapValues
      field_category_id: $fieldCategoryId
      compose: $compose
      standard_name: $standardName
      agent_only: $agentOnly
      category_name: $categoryName
      subcategory_name: $subcategoryName
    ) {
      ...FieldParts
    }
  }

  ${FieldPartsFragment}
`

export function useCreateField() {
  const queryClient = useQueryClient()
  return useCreateFieldMutation({
    async onSuccess({ createField }) {
      await queryClient.invalidateQueries([
        'ResourceGroupFields',
        { resource_group_id: createField?.fieldCategory?.resourceGroupId }
      ])
    }
  })
}
