import { SearchFilterInput, Table, useDebounce } from '@forge/common'
import { ApiUserFieldsFragment } from '@forge/graphql/generated'
import { PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteApiUser } from './DeleteApiUser'
import { useApiUsers } from './useApiUsers'

const filterApiUsers = (
  apiUsers: ApiUserFieldsFragment[] | undefined | null,
  searchTerm: string
) => {
  const result: ApiUserFieldsFragment[] = []

  if (!apiUsers) return

  apiUsers?.forEach((apiUser) => {
    if (
      JSON.stringify(apiUser)?.toLowerCase().includes(searchTerm.toLowerCase())
    )
      if (result.indexOf(apiUser) === -1) result.push(apiUser)
  })
  return result
}

export function ApiUsersTable() {
  const { data: { apiUsers } = {} } = useApiUsers()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const filteredApiUsers = filterApiUsers(apiUsers, debouncedSearchTerm)

  return (
    <div>
      <div className="flex flex-row justify-end">
        <div className=" w-full pb-4 md:w-[30%]">
          <SearchFilterInput
            value={searchTerm}
            placeholder="Search API Users"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
        </div>
      </div>
      <div className="space-y-2 overflow-auto bg-white rounded-lg shadow print:border print:shadow-none">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>API Key</Table.Header>
              <Table.Header align="center">Edit</Table.Header>
              <Table.Header align="center">Delete</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {filteredApiUsers?.length === 0 ? (
              <Table.Row>
                <Table.Data colSpan={7}>
                  <p className="p-4 text-lg text-center">
                    No API users found{' '}
                    {debouncedSearchTerm && 'for the search term'}
                    {debouncedSearchTerm && (
                      <span className="font-bold text-orange-400">
                        {' '}
                        "{debouncedSearchTerm}"{' '}
                      </span>
                    )}
                    .
                  </p>
                </Table.Data>
              </Table.Row>
            ) : (
              filteredApiUsers?.map((apiUser) => (
                <ApiUserRow key={apiUser.id} apiUser={apiUser} />
              ))
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

interface ApiUserRowProps {
  apiUser: ApiUserFieldsFragment
}

function ApiUserRow({ apiUser }: ApiUserRowProps) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <Table.Row key={apiUser.id} highlightOnHover>
      <Table.Data>
        <Link
          className="hover:text-orange-400"
          to={`/api_user/${apiUser.id}/details`}>
          {apiUser.name}
        </Link>
      </Table.Data>
      <Table.Data>
        <Link
          className="hover:text-orange-400"
          to={`/api_user/${apiUser.id}/details`}>
          {apiUser.apiKey}
        </Link>
      </Table.Data>
      <Table.Data>
        <Link
          className="hover:text-orange-400"
          to={`/api_user/${apiUser.id}/edit`}>
          <PencilIcon className="w-5 h-5 mx-auto" />
        </Link>
        <div className="text-center cursor-pointer hover:text-orange-400"></div>
      </Table.Data>
      <Table.Data>
        <div className="text-center cursor-pointer hover:text-orange-400">
          <TrashIcon
            className="w-5 h-5 mx-auto"
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </div>
        <DeleteApiUser
          apiUser={apiUser}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Table.Data>
    </Table.Row>
  )
}
