import { Breadcrumbs, Tooltip, toTitleCase, Wrapper } from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import {
  ResourceGroupFieldsTable,
  useResourceGroup
} from '@forge/features/resourcegroups'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function ResourceGroupFields() {
  const { adapterId, resourceGroupId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  return (
    <>
      <Helmet>
        <title>{toTitleCase(resourceGroup?.role || '')} Fields</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapterId}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}`}>
            {resourceGroup?.role || ''} Resource Group
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/fields`}>
            {resourceGroup?.role || ''} Fields
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium capitalize">
            {resourceGroup?.role || ''} Resource Group Fields
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Fields pertaing to a particular group of resources.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>
        <ResourceGroupFieldsTable
          adapterId={String(adapterId)}
          resourceGroupId={String(resourceGroupId)}
        />
      </Wrapper>
    </>
  )
}
