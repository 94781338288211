import {
  AlarmProfileQueryVariables,
  useAlarmProfileQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query AlarmProfile($alarmProfileId: ID) {
    alarmProfile: alarm_profile(id: $alarmProfileId) {
      id
      name
      status
      role
      job
      jobStatus: job_status
      field
      operation
      maxThreshold: max_threshold
      minThreshold: min_threshold
      duration
    }
  }
`

export function useAlarmProfile(vars?: AlarmProfileQueryVariables) {
  return useAlarmProfileQuery(vars)
}
