import { Spinner, Table, Tooltip, useCopyToClipboard } from '@forge/common'
import { ClipboardIcon } from '@heroicons/react/solid'
import { Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { ListingServiceMap } from './ListingServiceMap'
import { useListingService } from './useListingService'

export function ListingServiceDetails() {
  return (
    <div>
      <div className="rounded-lg bg-white shadow">
        <Suspense
          fallback={
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header align="left">Field</Table.Header>
                  <Table.Header align="left">Value</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Data colSpan={2}>
                    <div className="flex items-center gap-2">
                      <div className="h-5 w-5 text-orange-400">
                        <Spinner />
                      </div>
                      <div>Loading</div>
                    </div>
                  </Table.Data>
                </Table.Row>
              </Table.Body>
            </Table>
          }>
          <ListingServiceDetailsTable />
        </Suspense>
      </div>
      <div className="pt-4 hover:shadow-2xl">
        <ListingServiceMap />
      </div>
    </div>
  )
}

function ListingServiceDetailsTable() {
  const { id } = useParams()
  const { data: { listingService } = {} } = useListingService({
    id
  })
  const [, copy] = useCopyToClipboard()
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header align="left">Field</Table.Header>
          <Table.Header align="left">Value</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Data>Name</Table.Data>
          <Table.Data>
            <span className="flex">
              {listingService?.name}{' '}
              <span className="flex px-2">
                <Tooltip
                  delay={700}
                  placement="right"
                  maxWidth={150}
                  content="Click to copy">
                  <button onClick={() => copy(`${listingService?.name}`)}>
                    <ClipboardIcon className="h-3 w-3 hover:text-orange-400" />
                  </button>
                </Tooltip>
              </span>
            </span>
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Key</Table.Data>
          <Table.Data>
            <span className="flex uppercase">
              {listingService?.key}
              <span className="flex px-2">
                <Tooltip
                  delay={700}
                  placement="right"
                  maxWidth={150}
                  content="Click to copy">
                  <button onClick={() => copy(`${listingService?.key}`)}>
                    <ClipboardIcon className="h-3 w-3 hover:text-orange-400" />
                  </button>
                </Tooltip>
              </span>
            </span>
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>State</Table.Data>
          <Table.Data>
            <span>{[listingService?.state].slice().join(', ')}</span>
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Timezone</Table.Data>
          <Table.Data>
            <span>{listingService?.timeZone}</span>
          </Table.Data>
        </Table.Row>
        <Table.Row>
          <Table.Data>Address</Table.Data>
          <Table.Data>
            <div className="overflow-x-auto">
              <span>{listingService?.address}</span>
            </div>
          </Table.Data>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
