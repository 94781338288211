import { Select, Spinner, Table } from '@forge/common'
import { useAdapters } from '@forge/features/adapters'
import { ServiceAdapterFieldsFragment } from '@forge/graphql/generated'
import { TrashIcon } from '@heroicons/react/solid'
import { Suspense, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ListingServiceDeleteDataAdapter } from './ListingServiceDeleteDataAdapter'
import { useCreateServiceAdapter } from './useCreateServiceAdapter'
import { useListingService } from './useListingService'

export function ListingServiceDataAdapters() {
  return (
    <div className="space-y-2 overflow-auto rounded-lg bg-white shadow print:border print:shadow-none">
      <Suspense
        fallback={
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Key</Table.Header>
                <Table.Header align="right">Updates Today</Table.Header>
                <Table.Header align="right">Listings</Table.Header>
                <Table.Header align="right">Agents</Table.Header>
                <Table.Header align="right">Unlink</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Data colSpan={5}>
                  <div className="flex items-center gap-2">
                    <div className="h-5 w-5 text-orange-400">
                      <Spinner />
                    </div>
                    <div>Loading Data Adapters</div>
                  </div>
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        }>
        <ListingServiceDataAdaptersTable />
      </Suspense>
    </div>
  )
}

function ListingServiceDataAdaptersTable() {
  const { id: listingServiceId } = useParams()
  const { data: { adapters } = {} } = useAdapters({ limit: 500 })
  const { data: { listingService } = {} } = useListingService({
    id: String(listingServiceId)
  })

  const { mutate: createServiceAdapter, isLoading: isCreatingAdapter } =
    useCreateServiceAdapter()

  const servicesMap: Record<string, ServiceAdapterFieldsFragment> = (
    listingService?.serviceAdapters || []
  ).reduce(
    (byId, serviceAdapter) => ({
      ...byId,
      [serviceAdapter.adapter?.id || '']: serviceAdapter
    }),
    {}
  )

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header align="left">Key</Table.Header>
          <Table.Header align="right">Updates Today</Table.Header>
          <Table.Header align="right">Listings</Table.Header>
          <Table.Header align="right">Agents</Table.Header>
          <Table.Header align="right">Unlink</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {listingService?.serviceAdapters?.map((dataAdapter) => (
          <ServiceDataAdapterRow
            key={dataAdapter?.id}
            dataAdapter={dataAdapter}
          />
        ))}
        <Table.Row>
          <Table.Data colSpan={5}>
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <Select
                  placeholder="Select"
                  onChange={({ target }) => {
                    createServiceAdapter({
                      listingServiceId: Number(listingServiceId),
                      adapterId: Number(target.value)
                    })
                  }}>
                  <option value="">Select an Adapter</option>
                  {adapters
                    ?.filter((adapter) => servicesMap[adapter.id] === undefined)
                    .map((adapter) => (
                      <option key={adapter.id} value={adapter.id}>
                        {adapter.key}
                      </option>
                    ))}
                </Select>
              </div>
              {isCreatingAdapter && (
                <div className="h-5 w-5 text-gray-400">
                  <Spinner />
                </div>
              )}
            </div>
          </Table.Data>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

interface ServiceDataAdapterRowProps {
  dataAdapter: ServiceAdapterFieldsFragment
}

function ServiceDataAdapterRow({ dataAdapter }: ServiceDataAdapterRowProps) {
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false)

  return (
    <Table.Row>
      <Table.Data>
        <Link
          className="uppercase hover:text-orange-400"
          to={`/adapters/${dataAdapter?.adapter?.id}/details`}>
          {dataAdapter?.adapter?.key}
        </Link>
      </Table.Data>
      <Table.Data align="right">
        <Link
          className="hover:text-orange-400"
          to={`/adapters/${dataAdapter?.adapter?.id}/details`}>
          {dataAdapter?.adapter?.recordCounts?.today}
        </Link>
      </Table.Data>
      <Table.Data align="right">
        <Link
          className="hover:text-orange-400"
          to={`/adapters/${dataAdapter?.adapter?.id}/details`}>
          {dataAdapter?.adapter?.recordCounts?.listings}
        </Link>
      </Table.Data>
      <Table.Data align="right">
        <Link
          className="hover:text-orange-400"
          to={`/adapters/${dataAdapter?.adapter?.id}/details`}>
          {dataAdapter?.adapter?.recordCounts?.agents}
        </Link>
      </Table.Data>
      <Table.Data align="right">
        <button className="ml-auto flex cursor-pointer pr-4 hover:text-orange-500">
          <TrashIcon
            className="h-5 w-5"
            onClick={() => setIsUnlinkModalOpen(true)}
          />
        </button>
        <ListingServiceDeleteDataAdapter
          dataAdapter={dataAdapter}
          isOpen={isUnlinkModalOpen}
          onClose={() => setIsUnlinkModalOpen(false)}
        />
      </Table.Data>
    </Table.Row>
  )
}
