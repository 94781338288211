import { Badge } from '@forge/common'

interface AgentBadgeProps {
  active: boolean | null | undefined
}

export function AgentBadge({ active }: AgentBadgeProps) {
  return (
    <Badge
      variant={
        active === true ? 'success' : active === false ? 'danger' : 'default'
      }>
      {String(active) || 'N/A'}
    </Badge>
  )
}
