import { Breadcrumbs, Wrapper } from '@forge/common'
import {
  DashboardAlarms,
  DashboardGlobalCounts,
  DashboardResourceGroups,
  DashboardStatsByHour,
  Welcome
} from '@forge/features/dashboard'
import { Helmet } from 'react-helmet-async'

export function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Forge Dashboard</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/">Home</Breadcrumbs.Crumb>
        </Breadcrumbs>
        <Welcome />
        <DashboardGlobalCounts />
        <DashboardStatsByHour />
        <div className="flex flex-col gap-8 md:flex-row">
          <DashboardResourceGroups />
          <DashboardAlarms />
        </div>
      </Wrapper>
    </>
  )
}
