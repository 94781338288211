import { useClearPurgeMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation ClearPurge($resourceGroupId: ID!) {
    clearDeletes(resource_group_id: $resourceGroupId)
  }
`

export function useResourceGroupPurgeClear() {
  const queryClient = useQueryClient()
  return useClearPurgeMutation({
    onSuccess() {
      queryClient.invalidateQueries(['clearDeletes'])
    },
    onError(error) {
      console.error('ERROR CLEARING PURGE', error)
    }
  })
}
