import { Breadcrumbs, Button, Tooltip, Wrapper, Spinner } from '@forge/common'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { FieldTemplatesTable } from '@forge/features/fieldtemplates'
import { Suspense } from 'react'

export function FieldTemplates() {
  return (
    <>
      <Helmet>
        <title>Field Templates</title>
      </Helmet>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/field_templates">
            Field Templates
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="flex w-full">
          <h1 className="flex whitespace-nowrap text-4xl font-medium">
            Field Templates
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Field Templates are utilized to infer field mapping on RETS
                metadata import.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
          <div className="w-full">
            <div className="float-right">
              <Button as={Link} to="/field_templates/new">
                New Field Template
              </Button>
            </div>
          </div>
        </div>
        <Suspense
          fallback={
            <div className="flex p-4">
              <div className="h-5 w-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <FieldTemplatesTable />
        </Suspense>
      </Wrapper>
    </>
  )
}
