import { useUpdateTransformMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation UpdateTransform(
    $id: ID!
    $fieldId: Int
    $order: Int
    $operation: OperationEnum
    $data: String
    $serializeOnly: Boolean
  ) {
    updateTransform(
      id: $id
      field_id: $fieldId
      order: $order
      operation: $operation
      data: $data
      serialize_only: $serializeOnly
    ) {
      id
      order
      operation
      data
      serialize_only
    }
  }
`

export function useUpdateTransform() {
  const queryClient = useQueryClient()
  return useUpdateTransformMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['Field'])
    }
  })
}
