import { gql } from 'graphql-request'
import { useQueryClient } from '@tanstack/react-query'
import {
  ResourceGroupQuery,
  useUpdateResourceMutation
} from '@forge/graphql/generated'

gql`
  mutation UpdateResource(
    $id: ID!
    $resourceGroupId: Int
    $updateData: Boolean
  ) {
    updateResource(
      id: $id
      resource_group_id: $resourceGroupId
      update_data: $updateData
    ) {
      id
    }
  }
`

export function useUpdateResource() {
  const queryClient = useQueryClient()
  return useUpdateResourceMutation({
    onMutate: async ({ resourceGroupId, ...newResourceData }) => {
      await queryClient.cancelQueries([
        'ResourceGroup',
        { id: String(resourceGroupId) }
      ])

      const previousResourceGroup: ResourceGroupQuery | undefined =
        queryClient.getQueryData<ResourceGroupQuery>([
          'ResourceGroup',
          { id: String(resourceGroupId) }
        ])

      if (previousResourceGroup?.resourceGroup) {
        queryClient.setQueryData<ResourceGroupQuery>(
          ['ResourceGroup', { id: String(resourceGroupId) }],
          {
            ...previousResourceGroup,
            resourceGroup: {
              ...previousResourceGroup.resourceGroup,
              resources: previousResourceGroup.resourceGroup.resources?.map(
                (resource) => {
                  if (resource.id === newResourceData.id) {
                    return { ...resource, ...newResourceData }
                  }
                  return resource
                }
              )
            }
          }
        )
      }
      return { previousResourceGroup }
    },
    onError: (_, { resourceGroupId }, context) => {
      queryClient.setQueryData(
        ['ResourceGroup', { id: String(resourceGroupId) }],
        context?.previousResourceGroup
      )
    },
    onSettled() {
      queryClient.invalidateQueries(['ResourceGroup'])
      queryClient.invalidateQueries(['AdapterResourceGroups'])
      queryClient.invalidateQueries(['ResourceHealth'])
    }
  })
}
