import { Button, Dialog, Pagination, StatInfo, Table } from '@forge/common'
import { StatJob } from '@forge/graphql/generated'
import classNames from 'classnames'
import { Suspense, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdapterStatDestroy } from '../adapters'
import { ResourceGroupSkeletonTable } from './ResourceGroupSkeletonTable'
import { useResourceGroupStats } from './useResourceGroupStats'

function RGRecordsConsumerTableProvider() {
  const [page, setPage] = useState(1)
  const limit = 12
  const { resourceGroupId } = useParams()

  const [stopJob, setStopJob] = useState<string | null>(null)
  const [showDetails, setShowDetails] = useState<any | null>(null)

  const { data: { resourceGroupStats } = {} } = useResourceGroupStats(
    resourceGroupId || '',
    StatJob['RecordsConsumer'],
    limit,
    page
  )
  const { mutate: stopStat, isLoading } = useAdapterStatDestroy()
  const hasNextPage = !!useResourceGroupStats(
    resourceGroupId || '',
    StatJob['RecordsConsumer'],
    limit,
    page + 1
  )?.data?.resourceGroupStats?.stats?.length

  if (!resourceGroupStats) return null

  return (
    <>
      <div className="mb-4">
        <Pagination
          limit={limit}
          disablePrev={page === 1}
          disableNext={!hasNextPage}
          onPrevClick={() => setPage(page - 1)}
          onNextClick={() => setPage(page + 1)}
          label={`Page ${page}`}
        />
      </div>
      <div className="overflow-auto rounded-lg bg-white py-1 shadow">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header align="left">Status</Table.Header>
              <Table.Header align="center">Total</Table.Header>
              <Table.Header align="center">Took</Table.Header>
              <Table.Header align="center">Created</Table.Header>
              <Table.Header align="right">Stop</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {resourceGroupStats?.stats?.map((stat) => {
              return (
                <Table.Row key={stat.id}>
                  <Table.Data>
                    <div
                      className={classNames(
                        'absolute top-0 left-0 h-full w-[0.3125rem]',
                        {
                          'bg-gray-200': stat.status === 'ready',
                          'bg-green-500': stat.status === 'success',
                          'bg-yellow-500': ['hold', 'in_progress'].includes(
                            stat.status || ''
                          ),
                          'bg-red-500': [
                            'error',
                            'finished_with_errors',
                            'stopped'
                          ].includes(stat.status || '')
                        }
                      )}
                    />
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => setShowDetails(stat)}>
                      {stat.status}
                    </span>
                  </Table.Data>

                  <Table.Data align="center">
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => setShowDetails(stat)}>
                      {stat.totalRecords}
                    </span>
                  </Table.Data>
                  <Table.Data align="center">
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => setShowDetails(stat)}>
                      {stat.tookHuman}
                    </span>
                  </Table.Data>
                  <Table.Data align="center">
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => setShowDetails(stat)}>
                      {stat.humanCreatedAgo} ago
                    </span>
                  </Table.Data>
                  <Table.Data align="right">
                    {stat?.status === 'in_progress' && (
                      <Button
                        size="xs"
                        type="submit"
                        loading={isLoading}
                        variant="danger"
                        onClick={() => {
                          setStopJob(stat.id)
                        }}>
                        Stop
                      </Button>
                    )}
                  </Table.Data>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>

      {stopJob && (
        <Dialog
          title={`Are you sure you want to stop job ${stopJob}?`}
          method="DELETE"
          cancelLabel="Cancel"
          confirmLabel="Stop"
          cancelAction={() => setStopJob(null)}
          confirmAction={() => {
            stopStat({ id: stopJob })
            setStopJob(null)
          }}
        />
      )}

      {showDetails && (
        <StatInfo
          stat={showDetails}
          isOpen
          hideParentNavigation
          onClose={() => setShowDetails(null)}
        />
      )}
    </>
  )
}

export function ResourceGroupRecordsConsumerTable() {
  return (
    <Suspense fallback={<ResourceGroupSkeletonTable />}>
      <RGRecordsConsumerTableProvider />
    </Suspense>
  )
}
