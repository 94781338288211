import {
  Alert,
  Button,
  Checkbox,
  classNames,
  Pagination,
  StatInfo,
  Table,
  TimeAgo
} from '@forge/common'
import { useAdapterStatDestroy } from '@forge/features/adapters'
import {
  AdapterResourceGroupFieldsFragment,
  StatFieldsFragment,
  StatJob
} from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { Suspense, useState } from 'react'
import { ResourceGroupSkeletonTable } from './ResourceGroupSkeletonTable'
import { useResourceGroupStats } from './useResourceGroupStats'
import { useUpdateResourceGroup } from './useUpdateResourceGroup'

interface RGReplicationApiTableProps {
  resourceGroup: AdapterResourceGroupFieldsFragment | null | undefined
}

function RGReplicationApiTableProvider({
  resourceGroup
}: RGReplicationApiTableProps) {
  const [openedStat, openStat] = useState<StatFieldsFragment | null>(null)
  const [page, setPage] = useState(1)
  const limit = 12

  const { data: { resourceGroupStats } = {} } = useResourceGroupStats(
    resourceGroup?.id,
    StatJob['ReplicationConsumer'],
    limit,
    page
  )

  const { mutate: updateResourceGroup } = useUpdateResourceGroup()

  const hasNextPage = !!useResourceGroupStats(
    resourceGroup?.id || '',
    StatJob['ReplicationConsumer'],
    limit,
    page + 1
  )?.data?.resourceGroupStats?.stats?.length

  if (!resourceGroupStats) return null

  return (
    <div className="mt-2 space-y-2">
      <Checkbox
        size="lg"
        checked={!!resourceGroup?.replication}
        onChange={(e) => {
          if (resourceGroup?.id) {
            updateResourceGroup({
              id: resourceGroup.id,
              replication: e.target.checked
            })
          }
        }}>
        Enable Replication API
      </Checkbox>
      {resourceGroup?.replication && (
        <div className="space-y-2">
          <div className="pl-2">
            <p className="text-sm font-semibold">
              Replication Lag: {resourceGroup.replicationLag}
            </p>
          </div>
          <div className="mb-4">
            <Pagination
              limit={limit}
              disablePrev={page === 1}
              disableNext={!hasNextPage}
              onPrevClick={() => setPage(page - 1)}
              onNextClick={() => setPage(page + 1)}
              label={`Page ${page}`}
            />
          </div>
          <div className="overflow-auto rounded-lg bg-white py-1 shadow">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header align="left">Status</Table.Header>
                  <Table.Header align="left">Total</Table.Header>
                  <Table.Header align="left">Took</Table.Header>
                  <Table.Header align="left">Created</Table.Header>
                  <Table.Header align="right">Stop</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {resourceGroupStats.stats?.map((stat) => (
                  <TableStatRow
                    key={stat.id}
                    stat={stat}
                    onClick={() => openStat(stat)}
                  />
                ))}
              </Table.Body>
            </Table>
          </div>
          {openedStat && (
            <StatInfo
              hideParentNavigation
              isOpen={openedStat !== null}
              onClose={() => openStat(null)}
              stat={openedStat}
            />
          )}
        </div>
      )}
    </div>
  )
}

interface TableStatRowProps {
  stat: StatFieldsFragment
  onClick: () => void
}

function TableStatRow({ stat, onClick }: TableStatRowProps) {
  const [openStopModal, setOpenStopModal] = useState(false)

  const {
    mutate: stopStat,
    isLoading: stoppingStat,
    error
  } = useAdapterStatDestroy()

  return (
    <Table.Row key={stat.id} onClick={onClick}>
      <Table.Data>
        <div
          className={classNames('absolute top-0 left-0 h-full w-[0.3125rem]', {
            'bg-gray-200': stat.status === 'ready',
            'bg-green-500': stat.status === 'success',
            'bg-yellow-500': ['hold', 'in_progress'].includes(
              stat.status || ''
            ),
            'bg-red-500': ['error', 'finished_with_errors', 'stopped'].includes(
              stat.status || ''
            )
          })}
        />
        {stat.status}
      </Table.Data>
      <Table.Data>{stat.totalRecords}</Table.Data>
      <Table.Data>{stat.tookHuman}</Table.Data>
      <Table.Data>
        <TimeAgo date={new Date(stat.createdAtIso || '')} />
      </Table.Data>
      <Table.Data align="right" onClick={(evt) => evt.stopPropagation()}>
        {stat?.status === 'in_progress' && (
          <Button
            size="xs"
            type="submit"
            variant="danger"
            loading={stoppingStat}
            onClick={() => setOpenStopModal(true)}>
            Stop
          </Button>
        )}
        <Alert variant="danger" isOpen={openStopModal}>
          <Alert.Title>Stop Job</Alert.Title>
          <Alert.Content>
            <div className="space-y-2">
              <div>
                Are you sure you want to stop this job(
                <strong className="font-medium text-gray-700">
                  {stat?.id}
                </strong>
                )? This action cannot be undone.
              </div>
              {error && (
                <div className="text-red-500" role="alert" aria-live="polite">
                  {error.message}
                </div>
              )}
            </div>
          </Alert.Content>
          <Alert.Cancel onClick={() => setOpenStopModal(false)}>
            Cancel
          </Alert.Cancel>
          <Alert.Confirm
            leftIcon={<ExclamationCircleIcon />}
            loading={stoppingStat}
            onClick={() => {
              if (stat?.id) {
                stopStat(
                  { id: stat.id },
                  { onSuccess: () => setOpenStopModal(false) }
                )
              }
            }}>
            Stop
          </Alert.Confirm>
        </Alert>
      </Table.Data>
    </Table.Row>
  )
}

export function ResourceGroupReplicationApiTable(
  props: RGReplicationApiTableProps
) {
  return (
    <Suspense
      fallback={
        <div className="mt-2 space-y-2">
          <Checkbox size="lg" checked={true}>
            Enable Replication API
          </Checkbox>
          <div className="pl-2">
            <p className="text-sm font-semibold">Replication Lag: Loading...</p>
          </div>
          <ResourceGroupSkeletonTable />
        </div>
      }>
      <RGReplicationApiTableProvider {...props} />
    </Suspense>
  )
}
