import { Spinner } from '@forge/common'
import { Suspense } from 'react'

function RGCasInvitesTableProvider() {
  return (
    <div className="w-full overflow-auto py-6">
      <div className="m-2 flex h-96 justify-center rounded-lg border-4 border-dashed border-gray-200">
        <p className="my-auto text-lg">
          TODO: Build Adapter Resource Group CAS Auto Invites Table
        </p>
      </div>
    </div>
  )
}

export function ResourceGroupCasInvitesTable() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center gap-2">
          <div className="h-5 w-5 text-orange-500">
            <Spinner />
          </div>
          <div>Loading CasInvites</div>
        </div>
      }>
      <RGCasInvitesTableProvider />
    </Suspense>
  )
}
