import { useReRegisterSavedSearchesMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation reRegisterSavedSearches($id: Int!) {
    reRegisterSavedSearches(id: $id)
  }
`
export function useReRegisterSavedSearches() {
  const queryClient = useQueryClient()
  return useReRegisterSavedSearchesMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['SavedSearches'])
    }
  })
}
