import {
  AlarmProfilesQueryVariables,
  useAlarmProfilesQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query AlarmProfiles($adapterId: Int) {
    alarmProfiles: alarm_profiles(adapter_id: $adapterId) {
      id
      name
      explain
      status
    }
  }
`

export function useAlarmProfiles(vars?: AlarmProfilesQueryVariables) {
  return useAlarmProfilesQuery(vars)
}
