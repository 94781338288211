import { StatJob, useResourceGroupStatsQuery } from '@forge/graphql/generated'
import { gql } from 'graphql-request'
import { statsFragment } from '../adapters/useAdapterStats'

gql`
  query ResourceGroupStats($id: ID!, $job: StatJob, $limit: Int, $page: Int) {
    resourceGroupStats: resource_group(id: $id) {
      id
      stats(page: $page, limit: $limit, job: $job) {
        ...statFields
      }
    }
  }
  ${statsFragment}
`

export function useResourceGroupStats(
  id?: string,
  job?: StatJob,
  limit?: number,
  page?: number
) {
  return useResourceGroupStatsQuery(
    { id: id!, limit, page, job },
    {
      enabled: !!id
    }
  )
}
