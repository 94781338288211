import { FormField, Combobox, Input, Checkbox, Button } from '@forge/common'
import {
  FieldCategoryPartsFragment,
  ResourceGroupRole,
  ColTypeEnum,
  FieldMapValue
} from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  agentAliasOptions,
  agentDataDictionaryOptions,
  listingAliasOptions,
  listingDataDictionaryOptions,
  officeAliasOptions,
  officeDataDictionaryOptions
} from './utils'
import { useCreateField } from './useCreateField'
import { useNavigate } from 'react-router-dom'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/solid'

const schema = yup.object({
  fieldCategoryId: yup.number(),
  sourceNames: yup.array(yup.string().required()),
  displayName: yup.string(),
  categoryName: yup.string(),
  subcategoryName: yup.string(),
  colName: yup.string().required('Column Name is required'),
  colType: yup.mixed<ColTypeEnum>().oneOf(Object.values(ColTypeEnum)),
  aliases: yup.array(yup.string().required()),
  mapValues: yup.mixed<FieldMapValue>().oneOf(Object.values(FieldMapValue)),
  standardName: yup.string(),
  queryable: yup.boolean(),
  agentOnly: yup.boolean(),
  autocomplete: yup.boolean(),
  compose: yup.boolean(),
  sqft: yup.boolean(),
  calculateDom: yup.boolean()
})

interface NewFieldProps {
  role: ResourceGroupRole
  fieldCategories: FieldCategoryPartsFragment[]
  possibleSources: {
    label?: string | null
    value?: string | null
  }[]
}

export function NewField({
  role,
  possibleSources,
  fieldCategories
}: NewFieldProps) {
  const navigate = useNavigate()

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })

  const {
    mutate: createField,
    isLoading: isCreating,
    isSuccess,
    error
  } = useCreateField()

  const isListing = role === ResourceGroupRole.Listing

  const categoryOptions = fieldCategories.map((category) => ({
    label: category.name || 'NO_LABEL',
    value: category.id || 'NO_VALUE'
  }))

  const possibleSourcesOptions = possibleSources.map((source) => ({
    label: source.label || 'NO_LABEL',
    value: source.value || 'NO_VALUE'
  }))

  const columnTypeOptions = Object.values(ColTypeEnum).map((option) => ({
    label: option,
    value: option
  }))

  const aliasOptions = (
    role === ResourceGroupRole.Listing
      ? listingAliasOptions
      : role === ResourceGroupRole.Office
      ? officeAliasOptions
      : role === ResourceGroupRole.Agent
      ? agentAliasOptions
      : []
  ).map((option) => ({
    label: option,
    value: option
  }))

  const mapValueOptions = Object.values(FieldMapValue).map((value) => ({
    label: value,
    value: value
  }))

  const standardNameOptions = (
    role === ResourceGroupRole.Listing
      ? listingDataDictionaryOptions
      : role === ResourceGroupRole.Office
      ? officeDataDictionaryOptions
      : role === ResourceGroupRole.Agent
      ? agentDataDictionaryOptions
      : []
  ).map((option) => ({
    label: option,
    value: option
  }))

  const [sourceNamesOptions, setSourceNameOptions] = useState(
    possibleSourcesOptions
  )

  return (
    <div className="max-w-lg m-auto">
      <form
        className="space-y-4"
        onSubmit={handleSubmit((data) => {
          createField(data, {
            onSuccess({ createField }) {
              navigate(
                `/adapters/${createField?.fieldCategory?.resourceGroup?.adapterId}/resource_groups/${createField?.fieldCategory?.resourceGroupId}/fields/${createField?.id}`
              )
            }
          })
        })}>
        <FormField
          label="Category Name"
          error={errors.fieldCategoryId?.message}>
          <Combobox
            options={categoryOptions}
            selected={categoryOptions.find(
              (option) => watch('fieldCategoryId') === Number(option.value)
            )}
            onSelect={(option) => {
              setValue('fieldCategoryId', Number(option.value))
            }}
          />
        </FormField>

        <FormField
          label="Source Names"
          error={errors.sourceNames?.map?.((error) => error?.message)}>
          <Combobox
            multiple
            options={sourceNamesOptions}
            selected={sourceNamesOptions?.filter((option) =>
              watch('sourceNames')?.includes(option.value ?? '')
            )}
            onSelect={(options) => {
              setValue(
                'sourceNames',
                options.map((option) => option.value)
              )
            }}
            onCreateOption={(option) => {
              setSourceNameOptions((options) => [...options, option])
            }}
          />
        </FormField>

        <FormField label="Display Name" error={errors.displayName?.message}>
          <Input {...register('displayName')} />
        </FormField>

        <FormField label="Category Name" error={errors.displayName?.message}>
          <Input {...register('categoryName')} />
        </FormField>

        <FormField label="Subcategory Name" error={errors.displayName?.message}>
          <Input {...register('categoryName')} />
        </FormField>

        <FormField
          required
          label="Column Name"
          error={errors.displayName?.message}>
          <Input {...register('colName')} />
        </FormField>

        <FormField label="Column Type" error={errors.displayName?.message}>
          <Combobox
            options={columnTypeOptions}
            selected={columnTypeOptions.find(
              (option) => watch('colType') === option.value
            )}
            onSelect={(option) => {
              setValue('colType', option.value as ColTypeEnum)
            }}
          />
        </FormField>

        <FormField
          label="Aliases"
          error={errors.aliases?.map?.((error) => error?.message)}>
          <Combobox
            multiple
            options={aliasOptions}
            selected={aliasOptions.filter((option) =>
              watch('aliases')?.includes(option.value)
            )}
            onSelect={(options) => {
              setValue(
                'aliases',
                options.map((option) => option.value)
              )
            }}
          />
        </FormField>

        <FormField
          label="Field Mapping Settings"
          error={errors.mapValues?.message}>
          <Combobox
            options={mapValueOptions}
            selected={mapValueOptions.find(
              (option) => watch('mapValues') === option.value
            )}
            onSelect={(option) => {
              setValue('mapValues', option.value as FieldMapValue)
            }}
          />
        </FormField>

        <FormField
          label="Data Dictionary Name"
          error={errors.standardName?.message}>
          <Combobox
            options={standardNameOptions}
            selected={standardNameOptions.find(
              (option) => watch('standardName') === option.value
            )}
            onSelect={(option) => {
              setValue('standardName', option.value)
            }}
          />
        </FormField>

        <div className="space-y-4">
          <h3 className="text-xl">Options</h3>
          <Checkbox
            size="lg"
            {...register('queryable')}
            onChange={(evt) => setValue('queryable', evt.target.checked)}>
            Queryable
            <small className="block">
              Check this box if this field needs to be searchable via the API
            </small>
          </Checkbox>

          {isListing && (
            <Checkbox
              size="lg"
              {...register('agentOnly')}
              onChange={(evt) => setValue('agentOnly', evt.target.checked)}>
              Agent Only
              <small className="block">
                Check this box if this field is for an agent-only feature field
                (i.e. Lockbox info)
              </small>
            </Checkbox>
          )}

          <Checkbox
            size="lg"
            {...register('autocomplete')}
            onChange={(evt) => setValue('autocomplete', evt.target.checked)}>
            Autocomplete
            <small className="block">
              Check this box if this field needs to support autocomplete
              functions
            </small>
          </Checkbox>

          <Checkbox
            size="lg"
            {...register('compose')}
            onChange={(evt) => setValue('compose', evt.target.checked)}>
            Composite
            <small className="block">
              Check this box if this field's values should have source_name
              appended to them
            </small>
          </Checkbox>

          {isListing && (
            <>
              <Checkbox
                size="lg"
                {...register('sqft')}
                onChange={(evt) => setValue('sqft', evt.target.checked)}>
                Squarefoot
                <small className="block">
                  Check this box if this field is associated with sqaure feet
                </small>
              </Checkbox>

              <Checkbox
                size="lg"
                {...register('calculateDom')}
                onChange={(evt) =>
                  setValue('calculateDom', evt.target.checked)
                }>
                Calculate DOM
                <small className="block">
                  Check this box if this field is to be calculated in
                  elasticsearch as the days on the market
                </small>
              </Checkbox>
            </>
          )}
        </div>
        <Button
          fullWidth
          variant={!error ? 'primary' : 'danger'}
          type="submit"
          loading={isCreating}
          leftIcon={
            error ? (
              <ExclamationCircleIcon />
            ) : isSuccess ? (
              <BeakerIcon />
            ) : undefined
          }>
          {!!error && <>An Error Occured. Try again?</>}
          {!error && (
            <>
              {isCreating
                ? 'Creating Field'
                : isSuccess
                ? 'Success'
                : `Create Field`}
            </>
          )}
        </Button>
        {error && (
          <p role="alert" aria-live="polite" className="text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
