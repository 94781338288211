import { Alert } from '@forge/common'
import { FieldTemplateFieldsFragment } from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useDeleteFieldTemplate } from './useDeleteFieldTemplate'

interface DeleteApiUserProps {
  field: FieldTemplateFieldsFragment
  isOpen: boolean
  onClose: () => void
}

export function DeleteFieldTemplate({
  field,
  isOpen,
  onClose
}: DeleteApiUserProps) {
  const {
    mutate: deleteTemplate,
    isLoading: isDestroying,
    error
  } = useDeleteFieldTemplate()

  return (
    <Alert variant="danger" isOpen={isOpen}>
      <Alert.Title>Delete Field Template</Alert.Title>
      <Alert.Content>
        <div className="space-y-2">
          <div>
            Are you sure you want to delete this template (
            <strong className="font-medium text-gray-700">{field.name}</strong>
            )? This action cannot be undone.
          </div>
          {error && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {error.message}
            </div>
          )}
        </div>
      </Alert.Content>
      <Alert.Cancel onClick={onClose}>Cancel</Alert.Cancel>
      <Alert.Confirm
        leftIcon={<ExclamationCircleIcon />}
        loading={isDestroying}
        onClick={() => {
          deleteTemplate(
            { id: field.id },
            {
              onSuccess() {
                onClose()
              }
            }
          )
        }}>
        Delete Forever
      </Alert.Confirm>
    </Alert>
  )
}
