import {
  AdapterHealthQuery,
  AdapterHealthQueryVariables,
  useAdapterHealthQuery
} from '@forge/graphql/generated'
import { UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  query AdapterHealth($adapterId: ID!) {
    adapter(id: $adapterId) {
      health {
        consumers
        sync
        audit
        auditResolution: audit_resolution
        weeklyAudit: weekly_audit
        monthlyAudit: monthly_audit
      }
    }
  }
`

export function useAdapterHealth(
  vars: AdapterHealthQueryVariables,
  options?: Omit<
    UseQueryOptions<AdapterHealthQuery>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined
  }
) {
  return useAdapterHealthQuery(vars, options)
}
