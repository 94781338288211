import { useCreateAdminMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation CreateAdmin(
    $name: String!
    $email: String!
    $phone: String
    $title: String
    $timezone: String
    $role: AdminRole!
    $alerts: Boolean
  ) {
    createAdmin(
      name: $name
      email: $email
      phone: $phone
      title: $title
      timezone: $timezone
      role: $role
      alerts: $alerts
    ) {
      id
    }
  }
`

export function useCreateAdmin() {
  const queryClient = useQueryClient()
  return useCreateAdminMutation({
    async onSuccess() {
      queryClient.invalidateQueries(['Admins'])
    }
  })
}
