import { Attribute, formatNumber } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingStatsProps {
  adapterId: string
  baths?: number | null
  beds?: string | number | null
  fields: Record<string, FieldPartsFragment>
  garages?: string | number | null
  lotsize?: number | null
  resourceGroupId: string
  sqft?: number | null
  yearBuilt?: number | null
}

export function ListingStats({
  adapterId,
  baths,
  beds,
  fields,
  garages,
  lotsize,
  resourceGroupId,
  sqft,
  yearBuilt
}: ListingStatsProps) {
  return (
    <div className="grid grid-cols-2 gap-6 2xl:grid-cols-3">
      <Stat
        label="Bedrooms"
        value={
          <div className="hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['beds']?.id}
              label="beds"
              resourceGroupId={resourceGroupId}
              value={formatNumber(beds || 0)}
            />
          </div>
        }
        icon={<BedIcon />}></Stat>
      <Stat
        label="Bathrooms"
        value={
          <div className="hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['baths']?.id}
              label="baths"
              resourceGroupId={resourceGroupId}
              value={formatNumber(baths || 0)}
            />
          </div>
        }
        icon={<BathIcon />}
      />
      <Stat
        label="Home Size"
        value={
          <div className="hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['sqft']?.id}
              label="sqft"
              resourceGroupId={resourceGroupId}
              value={formatNumber(sqft || 0)}
            />{' '}
            sq ft.
          </div>
        }
        icon={<HomeSizeIcon />}
      />
      <Stat
        label="Year Built"
        value={
          <div className="hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['year_built']?.id}
              label="year_built"
              resourceGroupId={resourceGroupId}
              value={yearBuilt}
            />
          </div>
        }
        icon={<YearBuiltIcon />}
      />
      <Stat
        label="Garages"
        value={
          <div className="hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['garages']?.id}
              label="garages"
              resourceGroupId={resourceGroupId}
              value={formatNumber(garages || 0)}
            />
          </div>
        }
        icon={<GarageIcon />}
      />
      <Stat
        label="Lot Size"
        value={
          <div className="hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['lotsize']?.id}
              label="lotsize"
              resourceGroupId={resourceGroupId}
              value={formatNumber(lotsize || 0)}
            />
          </div>
        }
        icon={<LotSizeIcon />}
      />
    </div>
  )
}

interface StatProps {
  label: string
  value: string | number | JSX.Element
  icon: JSX.Element
}

function Stat({ label, value, icon }: StatProps) {
  return (
    <div className="flex items-center gap-3">
      <div className="h-12 w-12 shrink-0">{icon}</div>
      <div className="min-w-0">
        <div className="truncate text-xs font-semibold uppercase text-gray-700">
          {label}
        </div>
        <div className="text-md font-semibold text-gray-900">
          <>{value}</>
        </div>
      </div>
    </div>
  )
}

function BedIcon() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        d="M40 8H8v16h32V8ZM40 42v-7M8 42v-7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8 24h32a4 4 0 0 1 4 4v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-6a4 4 0 0 1 4-4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M26.536 22.5a3.5 3.5 0 0 1 3.464-3h4a3.5 3.5 0 0 1 3.464 3H26.536ZM10.536 22.5a3.5 3.5 0 0 1 3.464-3h4a3.5 3.5 0 0 1 3.464 3H10.536Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}

function BathIcon() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        d="M34 42H18A12 12 0 0 1 6 30v-4h36v8a8 8 0 0 1-8 8ZM34 26V10a6 6 0 0 0-6-6 6 6 0 0 0-6 6v2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M22 10a6 6 0 0 0-6 6h12a6 6 0 0 0-6-6Z" fill="currentColor" />
    </svg>
  )
}

function HomeSizeIcon() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        d="M6 8v34h36M42 38v8M2 8h8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M42 8H14v26h28V8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function YearBuiltIcon() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        d="M44 6H4v38h40V6Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12 4v4M36 4v4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.32 21.06 14 34M22.08 14.8l7.06 7.08"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="m33.38 20.471-5.657-5.656a2 2 0 0 0-2.829 0l-1.414 1.414a2 2 0 0 0 0 2.828l5.657 5.657a2 2 0 0 0 2.828 0L33.38 23.3a2 2 0 0 0 0-2.829Z"
        fill="currentColor"
      />
    </svg>
  )
}

function GarageIcon() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        d="M12 40H8a1 1 0 0 1-1-1V26l6 8v5a1 1 0 0 1-1 1ZM36 40h4a1 1 0 0 0 1-1V26l-6 8v5a1 1 0 0 0 1 1ZM10 28v-4h5a3 3 0 0 1 3 3 1 1 0 0 1-1 1h-7ZM30 27a3 3 0 0 1 3-3h5v4h-7a1 1 0 0 1-1-1Z"
        fill="currentColor"
      />
      <path
        d="M30 8H18a9.44 9.44 0 0 0-9.32 8A74.3 74.3 0 0 0 8 26v2a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6v-2a74.303 74.303 0 0 0-.66-10A9.44 9.44 0 0 0 30 8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="m8 20 .78-.38A15.28 15.28 0 0 1 15.6 18h16.8a15.28 15.28 0 0 1 6.82 1.62L40 20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function LotSizeIcon() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        d="M42 4H6v40h36V4ZM42 14 12 44M8 4l22 22M24 32l12 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
