import { Alert } from '@forge/common'
import { ServiceAdapterFieldsFragment } from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useDeleteServiceAdapter } from './useDeleteServiceAdapter'

interface DeleteDataAdapterProps {
  dataAdapter: ServiceAdapterFieldsFragment
  isOpen: boolean
  onClose: () => void
}

export function ListingServiceDeleteDataAdapter({
  dataAdapter,
  isOpen,
  onClose
}: DeleteDataAdapterProps) {
  const {
    mutate: deleteServiceAdapter,
    isLoading: isDestroying,
    error
  } = useDeleteServiceAdapter()

  return (
    <Alert variant="danger" isOpen={isOpen}>
      <Alert.Title>Unlink Adapter</Alert.Title>
      <Alert.Content>
        <div className="space-y-2">
          <div>
            Are you sure you want to unlink this data adapter (
            <strong className="font-medium text-gray-700">
              {dataAdapter.adapter?.key}
            </strong>
            )?
          </div>
          {error && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {error.message}
            </div>
          )}
        </div>
      </Alert.Content>
      <Alert.Cancel onClick={onClose}>Cancel</Alert.Cancel>
      <Alert.Confirm
        leftIcon={<ExclamationCircleIcon />}
        loading={isDestroying}
        onClick={() => {
          deleteServiceAdapter(
            { id: dataAdapter.id },
            {
              onSuccess() {
                onClose()
              }
            }
          )
        }}>
        Unlink
      </Alert.Confirm>
    </Alert>
  )
}
