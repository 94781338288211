import { useParams } from 'react-router-dom'
import { useAdapter } from './useAdapter'

export function AdapterCounts() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const counts = adapter?.recordCounts

  return (
    <div className="py-4">
      <dl className="grid grid-cols-2 gap-5 text-center lg:grid-cols-3 xl:grid-cols-4">
        <Count name="Total Listing Records" count={counts?.listings || '0'} />
        <Count name="Total Agent Records" count={counts?.agents || '0'} />
        <Count name="Total Office Records" count={counts?.offices || '0'} />
        <Count name="Daily Updates" count={counts?.today || '0'} />
      </dl>
    </div>
  )
}

interface CountProps {
  name: string
  count: string
}

function Count({ name, count }: CountProps) {
  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500" title={name}>
        {name}
      </dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">{count}</dd>
    </div>
  )
}
