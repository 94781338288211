import { Menu } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteField } from './DeleteField'

interface FieldActionsMenuProps {
  field: FieldPartsFragment
  adapterId: string
  resourceGroupId: string
}

export function FieldActionsMenu({
  field,
  adapterId,
  resourceGroupId
}: FieldActionsMenuProps) {
  const [showDestroy, setShowDestroy] = useState(false)

  return (
    <div>
      <Menu
        button={
          <span className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <DotsHorizontalIcon
              className="w-5 h-5 text-gray-500"
              aria-hidden="true"
            />
          </span>
        }>
        <Menu.Item
          as={Link}
          to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/fields/${field?.id}`}>
          Edit Field
        </Menu.Item>

        <Menu.Item variant="danger" onClick={() => setShowDestroy(true)}>
          Delete Field
        </Menu.Item>
      </Menu>
      <DeleteField
        id={field?.id}
        name={field?.colName || ''}
        isOpen={showDestroy}
        onClose={() => setShowDestroy(false)}
      />
    </div>
  )
}
