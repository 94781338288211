import { classNames } from '@forge/common'
import { AgentBadge } from './AgentBadge'

interface AgentCardProps {
  active?: boolean | null
  agentId?: string | null
  email?: string | null
  fullName?: string | null
  isOpen?: boolean
  officeName?: string | null
  onClick?: () => void
  phone?: string | null
}

export function AgentCard({
  active,
  agentId,
  email,
  fullName,
  isOpen,
  officeName,
  onClick,
  phone
}: AgentCardProps) {
  return (
    <div
      className={classNames('rounded-md bg-white hover:shadow-2xl', {
        'shadow-raised ring-2 ring-orange-500': isOpen,
        shadow: !isOpen
      })}>
      <div
        role="button"
        tabIndex={0}
        className="flex justify-between p-2 space-x-2"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onClick?.()
          }
        }}
        onClick={onClick}>
        <div className="w-7/12 space-y-1 lg:w-6/12 xl:w-7/12 2xl:w-6/12">
          <div
            className="text-xs font-semibold text-gray-900 truncate md:text-sm"
            title={fullName || ''}>
            {fullName}
          </div>
          <div
            className="text-xs font-semibold text-gray-900 truncate md:text-sm"
            title={officeName || ''}>
            {officeName}
          </div>

          <div
            className="text-xs font-semibold text-gray-900 truncate md:text-sm"
            title={email || ''}>
            {email}
          </div>
        </div>
        <div className="space-y-1 text-right">
          <div
            className="text-xs font-semibold text-gray-900 md:text-sm"
            title={agentId || ''}>
            {agentId}
          </div>

          <div
            className="text-xs font-semibold text-gray-900 whitespace-nowrap md:text-sm"
            title={phone || ''}>
            {phone}
          </div>

          <div className="flex float-right">
            <AgentBadge active={active} />
          </div>
        </div>
      </div>
    </div>
  )
}

AgentCard.Skeleton = function AgentCardSkeleton() {
  return (
    <div className="flex p-2 bg-white rounded-md shadow">
      <div className="w-1/2 px-1 space-y-2">
        <div className="h-[1rem] w-[8.0625rem] animate-pulse bg-gray-200" />
        <div className="h-[1rem] w-[10.0625rem] animate-pulse bg-gray-200" />
        <div className="h-[1rem] w-[12.0625rem] animate-pulse bg-gray-200" />
      </div>
      <div className="w-1/2 px-1 space-y-2">
        <div className="float-right h-[1rem] w-[6.0625rem] animate-pulse bg-gray-200" />
        <div className="float-right h-[1rem] w-[8.0625rem] animate-pulse bg-gray-200" />
        <div className="float-right h-[1rem] w-[6.0625rem] animate-pulse bg-gray-200" />
      </div>
    </div>
  )
}
