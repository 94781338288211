import { useStopStatMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { statsFragment } from './useAdapterStats'

gql`
  mutation stopStat($id: ID!) {
    updateStat(id: $id, status: stopped) {
      ...statFields
    }
  }
  ${statsFragment}
`

export function useAdapterStatDestroy() {
  const queryClient = useQueryClient()
  return useStopStatMutation({
    onSuccess() {
      queryClient.invalidateQueries(['StopStat'])
      queryClient.invalidateQueries(['ResourceGroupStats'])
      queryClient.invalidateQueries(['Resource'])
    },
    onError(error) {
      console.error('ERROR DESTROYING STAT', error)
    }
  })
}
