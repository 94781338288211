import { Table, TimeAgo } from '@forge/common'
import { ApiUserFieldsFragment } from '@forge/graphql/generated'
import { Link } from 'react-router-dom'

interface MlsTableProps {
  activations: ApiUserFieldsFragment['activations']
}

export function ApiUsersMlsTable({ activations }: MlsTableProps) {
  return (
    <div className="rounded-lg bg-white p-1 shadow">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Key</Table.Header>
            <Table.Header align="left">Name</Table.Header>
            <Table.Header align="right">Activated</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {activations?.map((act) => (
            <Table.Row key={act.id}>
              <Table.Data>
                <Link
                  className="hover:text-orange-400"
                  to={`/listing_services/${act.listingService?.id}/details`}>
                  {act.listingService?.key?.toUpperCase()}
                </Link>
              </Table.Data>
              <Table.Data>{act.listingService?.name}</Table.Data>
              <Table.Data align="right">
                {act.createdAtIso && (
                  <TimeAgo date={new Date(act.createdAtIso)} />
                )}
              </Table.Data>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
