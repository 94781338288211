import { PhotoIcon, RotateIcon } from '@forge/icons'
import { forwardRef, useRef } from 'react'

interface ListingPhotosProps {
  photos: string[]
  hasTourField?: boolean
  hasTourUrl?: boolean
  onPhotoClick?: (index: number) => void
  onTourClick?: (index: number) => void
}

export function ListingPhotos({
  photos,
  hasTourField,
  hasTourUrl,
  onPhotoClick,
  onTourClick
}: ListingPhotosProps) {
  const sliderRef = useRef<HTMLDivElement>(null)
  const firstPhotoRef = useRef<HTMLImageElement>(null)

  return (
    <div className="relative">
      <div
        ref={sliderRef}
        className="flex h-[17.25rem] snap-x snap-mandatory gap-0.5 overflow-x-hidden">
        {photos.map((photo, index) => (
          <button
            key={`${photo}-${index}`}
            onClick={() => onPhotoClick?.(index)}>
            <Image
              ref={index === 0 ? firstPhotoRef : undefined}
              src={photo}
              alt="Listing Photo"
            />
          </button>
        ))}
      </div>
      <div className="absolute bottom-2 left-2 z-[1] flex gap-2">
        <button
          className="flex items-center gap-1 rounded-[0.1875rem] bg-black/50 px-2 py-1 text-sm font-medium text-white"
          onClick={() => onPhotoClick?.(0)}>
          <PhotoIcon className="h-5 w-5" />
          <span>{photos.length}</span>
        </button>
        {!!hasTourUrl && !!hasTourField && (
          <button
            className="flex items-center gap-1 rounded-[0.1875rem] bg-black/50 px-2 py-1 text-sm font-medium text-white"
            onClick={() => onTourClick?.(0)}>
            <RotateIcon className="h-5 w-5" />
            <span>360°</span>
          </button>
        )}
      </div>
      <button
        className="absolute top-0 left-0 flex h-full w-10 rotate-180 items-center justify-center bg-gradient-to-l from-black/20"
        onClick={() => {
          sliderRef.current?.scrollTo({
            behavior: 'smooth',
            top: 0,
            left:
              sliderRef.current.scrollLeft -
              (firstPhotoRef.current?.offsetWidth || 0)
          })
        }}>
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15 34"
          className="h-[2.125rem] w-[0.9375rem]">
          <path
            opacity="0.8"
            d="m1.617 1 12 16-12 16"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </button>
      <button
        className="absolute top-0 right-0 flex h-full w-10 items-center justify-center bg-gradient-to-l from-black/20"
        onClick={() => {
          sliderRef.current?.scrollTo({
            behavior: 'smooth',
            top: 0,
            left:
              sliderRef.current.scrollLeft +
              (firstPhotoRef.current?.offsetWidth || 0)
          })
        }}>
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15 34"
          className="h-[2.125rem] w-[0.9375rem]">
          <path
            opacity="0.8"
            d="m1.617 1 12 16-12 16"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </div>
  )
}

const Image = forwardRef<
  HTMLImageElement,
  Omit<JSX.IntrinsicElements['img'], 'className'>
>(function Image(props, ref) {
  return (
    <div className="aspect-video h-full min-w-[30rem] shrink-0 snap-start bg-gray-100">
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        ref={ref}
        {...props}
        className="h-full w-full object-cover"
        draggable="false"
      />
    </div>
  )
})
