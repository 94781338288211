import { useCreateCriteriaMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation CreateCriteria(
    $parentId: Int
    $parentType: String
    $fieldName: String
    $value: String
    $scope: String
    $actionType: String
    $casCriterium: Boolean
  ) {
    createDefaultCriterium(
      parent_id: $parentId
      parent_type: $parentType
      field_name: $fieldName
      value: $value
      scope: $scope
      action_type: $actionType
      cas_criterium: $casCriterium
    ) {
      parentId: parent_id
    }
  }
`

export function useCreateCriteria() {
  const queryClient = useQueryClient()

  return useCreateCriteriaMutation({
    onSuccess: async ({ createDefaultCriterium }) => {
      await queryClient.invalidateQueries([
        'ApiUser',
        { id: String(createDefaultCriterium?.parentId) }
      ])
      await queryClient.invalidateQueries([
        'Resource',
        { id: String(createDefaultCriterium?.parentId) }
      ])
    }
  })
}
