import { useCreateAlarmProfileMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation CreateAlarmProfile(
    $adapterId: Int
    $duration: Int
    $field: AlarmProfileField
    $job: AlarmProfileJob
    $jobStatus: AlarmProfileJobStatus
    $maxThreshold: Int
    $minThreshold: Int
    $name: String
    $operation: AlarmProfileOperation
    $role: AlarmProfileRole
    $status: AlarmProfileStatus
  ) {
    createAlarmProfile(
      adapter_id: $adapterId
      duration: $duration
      field: $field
      job: $job
      job_status: $jobStatus
      max_threshold: $maxThreshold
      min_threshold: $minThreshold
      name: $name
      operation: $operation
      role: $role
      status: $status
    ) {
      id
    }
  }
`

export function useCreateAlarmProfile() {
  const queryClient = useQueryClient()
  return useCreateAlarmProfileMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['AlarmProfiles'])
    }
  })
}
