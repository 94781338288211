import { useCreateLookupsFromMetadataMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation CreateLookupsFromMetadata($fieldId: ID!, $name: String!) {
    createLookupsFromMetadata(field_id: $fieldId, name: $name) {
      id
      lookups {
        id
        order
        value
        mappedValue: mapped_value
        sourceId: source_id
      }
    }
  }
`

export function useCreateLookupsFromMetadata() {
  const queryClient = useQueryClient()
  return useCreateLookupsFromMetadataMutation({
    async onSuccess(_, { fieldId }) {
      await queryClient.invalidateQueries(['Field', { id: String(fieldId) }])
    }
  })
}
