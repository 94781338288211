import { Spinner, Table, Tabs } from '@forge/common'
import { Suspense } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ListingServiceConfigEditor } from './ListingServiceConfigEditor'
import { ListingServiceDataAdapters } from './ListingServiceDataAdapters'
import { ListingServiceDetails } from './ListingServiceDetails'
import { ListingServiceSavedSearchManager } from './ListingServiceSavedSearchManager'

enum ListingServiceTabEnum {
  details,
  dataAdapters,
  config,
  savedSearches
}

export function ListingServiceTabs() {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'details' })
  const type = (searchParams.get('tab') ||
    'details') as keyof typeof ListingServiceTabEnum
  const selectedTabIndex = ListingServiceTabEnum[type]

  return (
    <Tabs selectedIndex={selectedTabIndex}>
      <Tabs.List>
        <Tabs.Tab onClick={() => setSearchParams({ tab: 'details' })}>
          Details
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSearchParams({ tab: 'dataAdapters' })}>
          Data Adapters
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSearchParams({ tab: 'config' })}>
          Config
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSearchParams({ tab: 'savedSearches' })}>
          Saved Searches
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panels>
        <Tabs.Panel>
          <ListingServiceDetails />
        </Tabs.Panel>
        <Tabs.Panel>
          <ListingServiceDataAdapters />
        </Tabs.Panel>
        <Tabs.Panel>
          <ListingServiceConfigEditor />
        </Tabs.Panel>
        <Tabs.Panel>
          <Suspense
            fallback={
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header align="left">Id</Table.Header>
                    <Table.Header align="left">Match Count</Table.Header>
                    <Table.Header align="left">Last Match</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  <Table.Row>
                    <Table.Data colSpan={5}>
                      <div className="flex items-center gap-2">
                        <div className="w-5 h-5 text-orange-400">
                          <Spinner />
                        </div>
                        <div>Loading Saved Searches</div>
                      </div>
                    </Table.Data>
                  </Table.Row>
                </Table.Body>
              </Table>
            }>
            <ListingServiceSavedSearchManager />
          </Suspense>
        </Tabs.Panel>
      </Tabs.Panels>
    </Tabs>
  )
}
