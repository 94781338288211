import {
  ListingServiceQuery,
  useDeleteListingServiceActivationMutation
} from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useParams } from 'react-router-dom'

gql`
  mutation DeleteListingServiceActivation($id: ID!) {
    deleteActivation(id: $id)
  }
`

export function useDeactivateListingServiceApiUser() {
  const queryClient = useQueryClient()
  const { id: listingServiceId } = useParams()

  return useDeleteListingServiceActivationMutation({
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries([
        'ListingService',
        { id: String(listingServiceId) }
      ])

      const previousListingService: ListingServiceQuery | undefined =
        queryClient.getQueryData<ListingServiceQuery>([
          'ListingService',
          { id: String(listingServiceId) }
        ])

      if (previousListingService?.listingService) {
        queryClient.setQueryData<ListingServiceQuery>(
          ['ListingService', { id: String(listingServiceId) }],
          {
            ...previousListingService,
            listingService: {
              ...previousListingService.listingService,
              activations: (
                previousListingService.listingService.activations || []
              ).filter((activation) => activation.id !== id)
            }
          }
        )
      }
      return { previousListingService }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        ['ListingService', { id: String(listingServiceId) }],
        context?.previousListingService
      )
    },
    onSettled() {
      queryClient.invalidateQueries(['ListingService'])
    }
  })
}
