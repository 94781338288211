import { Attribute, Tooltip } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingFieldsProps {
  acres?: string | number | null
  adapterId: string
  area?: string | null
  associationFee?: string | number | null
  baths?: string | number | null
  bathsFull?: string | number | null
  bathsHalf?: string | number | null
  bathsSearch?: string | number | null
  city?: string | null
  county?: string | null
  dateList?: string | null
  dateOffmarket?: string | null
  datePending?: string | null
  dateSold?: string | null
  dom?: string | null
  fields: Record<string, FieldPartsFragment>
  garages?: string | number | null
  latitude?: string | number | null
  longitude?: string | number | null
  lotDim?: string | null
  lotSize?: string | number | null
  mappedStatus?: string | null
  parcel?: string | null
  price?: string | number | null
  priceList?: string | number | null
  priceListOrig?: string | number | null
  priceSold?: string | number | null
  propSubType?: string | null
  propType?: string | null
  resourceGroupId: string
  schoolDistrict?: string | null
  schoolElementary?: string | null
  schoolHigh?: string | null
  schoolMiddle?: string | null
  state?: string | null
  status?: string | null
  stories?: string | number | null
  streetName?: string | null
  streetNum?: string | null
  style?: string | null
  subdivision?: string | null
  taxes?: string | number | null
  unit?: string | null
  virtualTour?: string | null
  zip?: string | null
  zipcode?: string | null
}

export function ListingFields({
  acres,
  adapterId,
  area,
  associationFee,
  baths,
  bathsFull,
  bathsHalf,
  bathsSearch,
  city,
  county,
  dateList,
  dateOffmarket,
  datePending,
  dateSold,
  dom,
  fields,
  garages,
  latitude,
  longitude,
  lotDim,
  lotSize,
  mappedStatus,
  parcel,
  price,
  priceList,
  priceListOrig,
  priceSold,
  propSubType,
  propType,
  resourceGroupId,
  schoolDistrict,
  schoolElementary,
  schoolHigh,
  schoolMiddle,
  state,
  status,
  stories,
  streetName,
  streetNum,
  style,
  subdivision,
  taxes,
  unit,
  virtualTour,
  zip,
  zipcode
}: ListingFieldsProps) {
  return (
    <div className=" 2xl:flex">
      <div>
        <h2 className="p-2 text-xl">Listing Fields</h2>
        <div>
          <h3 className="p-2 text-lg">Root</h3>
          <ul className="min-w-sm overflow-auto sm:max-w-full 2xl:max-w-[15rem]">
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="A list of types of properties such as Residential, Lease, Income, Land, Mobile, Commercial Sale, etc...">
              <li className="p-2">
                Property Type:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['prop_type']?.id}
                  label="prop_type"
                  resourceGroupId={resourceGroupId}
                  value={propType}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="A list of types of residential and residential lease properties, i.e. SFR, Condo, etc. Or a list of Sub Types for Mobile, such as Expando, Manufactured, Modular, etc.">
              <li className="p-2">
                Property Sub Type:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['prop_sub_type']?.id}
                  label="prop_sub_type"
                  resourceGroupId={resourceGroupId}
                  value={propSubType}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The status of the listing as it reflects the state of the contract between the listing agent and seller or an agreement with a buyer (Active, Active Under Contract, Canceled, Closed, Expired, Pending, Withdrawn)">
              <li className="p-2">
                Status:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['status']?.id}
                  label="status"
                  resourceGroupId={resourceGroupId}
                  value={status}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The status of the listing as it reflects the state of the contract between the listing agent and seller or an agreement with a buyer (active, backup, pending, closed, deleted)">
              <li className="p-2">
                Mapped Status:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['mapped_status']?.id || fields['status']?.id}
                  label="mapped_status"
                  resourceGroupId={resourceGroupId}
                  value={mappedStatus}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The number of days the listing is on market, as defined by the MLS business rules.">
              <li className="p-2">
                Days on Market:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['dom']?.id}
                  label="dom"
                  resourceGroupId={resourceGroupId}
                  value={dom}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The current price of the property as determined by the seller and the seller's broker.">
              <li className="p-2">
                Price Search: $
                <Attribute
                  adapterId={adapterId}
                  currency
                  fieldId={fields['price']?.id}
                  label="price"
                  resourceGroupId={resourceGroupId}
                  value={price}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The current list price of the property as determined by the seller and the seller's broker.">
              <li className="p-2">
                Price List: $
                <Attribute
                  adapterId={adapterId}
                  currency
                  fieldId={fields['price_list']?.id}
                  label="price_list"
                  resourceGroupId={resourceGroupId}
                  value={priceList}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The original price of the property on the initial agreement between the seller and the seller's broker.">
              <li className="p-2">
                Price List Original: $
                <Attribute
                  adapterId={adapterId}
                  currency
                  fieldId={fields['price_list_orig']?.id}
                  label="price_list_orig"
                  resourceGroupId={resourceGroupId}
                  value={priceListOrig}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The amount of money paid by the purchaser to the seller for the property under the agreement.">
              <li className="p-2">
                Price Sold: $
                <Attribute
                  adapterId={adapterId}
                  currency
                  fieldId={fields['price_sold']?.id}
                  label="price_sold"
                  resourceGroupId={resourceGroupId}
                  value={priceSold}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The effective date of the agreement between the seller and the seller's broker. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS.">
              <li className="p-2">
                Date Listed:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['date_list']?.id}
                  label="date_list"
                  resourceGroupId={resourceGroupId}
                  value={dateList}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="With for-sale listings, the date an offer was accepted and the listing was no longer on market. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. With lease listings this may represent a meeting of the minds to lease, but some contractual requirements are yet to be fulfilled, such as contract signing or receipt of the deposit.">
              <li className="p-2">
                Date Pending:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['date_pending']?.id}
                  label="date_pending"
                  resourceGroupId={resourceGroupId}
                  value={datePending}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="With for-sale listings, the date the purchase agreement was fulfilled. With lease listings, the date the requirements were fulfilled, such as contract and/or deposit. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS.">
              <li className="p-2">
                Date Sold:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['date_sold']?.id}
                  label="date_sold"
                  resourceGroupId={resourceGroupId}
                  value={dateSold}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The date the listing was taken off market. Where possible, this date is reflective of the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS.">
              <li className="p-2">
                Date Offmarket:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['date_offmarket']?.id}
                  label="date_offmarket"
                  resourceGroupId={resourceGroupId}
                  value={dateOffmarket}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content={`The street number portion of a listed property's street address. In some areas the street number may contain non-numeric characters. This field can also contain extensions and modifiers to the street number, such as "1/2" or "-B". This street number field should not include Prefixes, Direction or Suffixes.`}>
              <li className="p-2">
                Street Number:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['streetnum']?.id}
                  label="streetnum"
                  resourceGroupId={resourceGroupId}
                  value={streetNum}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The street name portion of a listed property's street address.">
              <li className="p-2">
                Street Name:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['streetname']?.id}
                  label="streetname"
                  resourceGroupId={resourceGroupId}
                  value={streetName}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content={
                'Text field containing the number or portion of a larger building or complex. Unit Number should appear following the street suffix or, if it exists, the street suffix direction, in the street address. Examples are: "APT G", "55", etc.'
              }>
              <li className="p-2">
                Unit Number:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['unit']?.id}
                  label="unit"
                  resourceGroupId={resourceGroupId}
                  value={unit}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content={'The city in listing address.'}>
              <li className="p-2">
                City:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['city']?.id}
                  label="city"
                  resourceGroupId={resourceGroupId}
                  value={city}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content={
                'Text field containing the accepted postal abbreviation for the state or province.'
              }>
              <li className="p-2">
                State:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['state']?.id}
                  label="state"
                  resourceGroupId={resourceGroupId}
                  value={state}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="Postal Code">
              <li className="p-2">
                Zipcode:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['zipcode']?.id || fields['zip']?.id}
                  label="zipcode"
                  resourceGroupId={resourceGroupId}
                  value={zipcode}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="Postal Code">
              <li className="p-2">
                Zip:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['zip']?.id || fields['zipcode']?.id}
                  label="zip"
                  resourceGroupId={resourceGroupId}
                  value={zip}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The County, Parish or other regional authority">
              <li className="p-2">
                County:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['county']?.id}
                  label="county"
                  resourceGroupId={resourceGroupId}
                  value={county}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The major marketing area name, as defined by the MLS or other non-governmental organization.">
              <li className="p-2">
                Area:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['area']?.id}
                  label="area"
                  resourceGroupId={resourceGroupId}
                  value={area}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="A number used to uniquely identify a parcel or lot. This number is typically issued by the county or county assessor. The AP number format varies from county to county. It is recommended that all Parcel Numbers be transmitted without dashes or hyphens.">
              <li className="p-2">
                Parcel Number:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['parcel']?.id}
                  label="parcel"
                  resourceGroupId={resourceGroupId}
                  value={parcel}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The total square footage of the lot. This field is related to the Lot Size Area and Lot Size Units and must be in sync with the values represented in those fields. Lot Size Source also applies to this field when used.">
              <li className="p-2">
                Lot Size Sqft:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['lotsize']?.id}
                  label="lotsize"
                  resourceGroupId={resourceGroupId}
                  value={lotSize}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The dimensions of the lot minimally represented as length and width (i.e. 250 x 180) or a measurement of all sides of the polygon representing the property lines of the property. i.e. 30 x 50 x 120 x 60 x 22.">
              <li className="p-2">
                Lot Size Dimensions:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['lotdim']?.id}
                  label="lotdim"
                  resourceGroupId={resourceGroupId}
                  value={lotDim}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The simple sum of the number of bathrooms. For example for a property with two Full Bathrooms and one Half Bathroom, the Bathrooms Total Integer will be 3.">
              <li className="p-2">
                Baths Search:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['baths_search']?.id || fields['baths']?.id}
                  label="baths_search"
                  resourceGroupId={resourceGroupId}
                  value={bathsSearch}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The simple sum of the number of bathrooms, intended for display. For example for a property with two Full Bathrooms and one Half Bathroom, the Bathrooms Total Integer will be 3. Expressing this example as 2.5, you will need to use a non-standard field name. Decimal based bathrooms are not recommended but possible via the Dictionary's extensibility.">
              <li className="p-2">
                Baths Total:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['baths']?.id || fields['baths_search']?.id}
                  label="baths"
                  resourceGroupId={resourceGroupId}
                  value={baths}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="A room containing all 4 of the 4 elements constituting a bath, which are; Toilet, Sink, Bathtub or Shower Head. A Full Bath will typically contain four elements; Sink, Toilet, Tub and Shower Head (in tub or stall). However, some may considered a Sink, Toilet and Tub (without a shower) a Full Bath, others consider this to be a Three Quarter Bath. In the event that BathroomsThreeQuarter is not in use, this field may represent the sum of all Full and Three Quarter bathrooms.">
              <li className="p-2">
                Baths Full:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['baths_full']?.id}
                  label="baths_full"
                  resourceGroupId={resourceGroupId}
                  value={bathsFull}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="A room containing 2 of the 4 elements constituting a bath, which are; Toilet, Sink, Bathtub or Shower Head. A Half Bath will typically contain a Sink and Toilet.">
              <li className="p-2">
                Baths Half:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['baths_half']?.id}
                  label="baths_half"
                  resourceGroupId={resourceGroupId}
                  value={bathsHalf}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="Longitude Coordinates">
              <li className="p-2">
                Longitude:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['lon']?.id}
                  label="lon"
                  resourceGroupId={resourceGroupId}
                  value={longitude}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="Latitude Coordinates">
              <li className="p-2">
                Latitude:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['lat']?.id}
                  label="lat"
                  resourceGroupId={resourceGroupId}
                  value={latitude}
                />
              </li>
            </Tooltip>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <h3 className="p-2 text-lg sm:mt-10">Standard</h3>

          <ul className="min-w-sm overflow-auto sm:max-w-full 2xl:max-w-[15rem]">
            <Tooltip
              delay={700}
              placement="top-start"
              content="A neighborhood, community, complex or builder tract.">
              <li className="p-2">
                Subdivision:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['subdivison']?.id}
                  label="subdivison"
                  resourceGroupId={resourceGroupId}
                  value={subdivision}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="A list describing the style of the structure. For example, Victorian, Ranch, Craftsman, etc.">
              <li className="p-2">
                Archetctual Style:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['style']?.id}
                  label="style"
                  resourceGroupId={resourceGroupId}
                  value={style}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="  A fee paid by the homeowner to the Home Owners Association which is used for the upkeep of the common area, neighborhood or other association related benefits.">
              <li className="p-2">
                Association Fee: $
                <Attribute
                  adapterId={adapterId}
                  currency
                  fieldId={fields['assoc_fee']?.id}
                  label="assoc_fee"
                  resourceGroupId={resourceGroupId}
                  value={associationFee}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The annual property tax amount as of the last assessment made by the taxing authority.">
              <li className="p-2">
                Taxes: $
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['taxes']?.id}
                  label="taxes"
                  resourceGroupId={resourceGroupId}
                  value={taxes}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The total Acreage of the lot.">
              <li className="p-2">
                Acres:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['acres']?.id}
                  label="acres"
                  resourceGroupId={resourceGroupId}
                  value={acres}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The total number of floors in the building. In the case of multi-dwelling structures, this is the entire structure and not the individual dwelling being sold.">
              <li className="p-2">
                Stories Total:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['features_stories_total']?.id}
                  label="features_stories_total"
                  resourceGroupId={resourceGroupId}
                  value={stories}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The number of spaces in the garage(s)">
              <li className="p-2">
                Garage Parking Total:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['garages']?.id}
                  label="garages"
                  resourceGroupId={resourceGroupId}
                  value={garages}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The name of the school district having a catchment area that includes the associated property. When only one school district is used, HighSchoolDistrict should be used over the Junior or Elementary Districts.">
              <li className="p-2">
                School District:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['school_district']?.id}
                  label="school_district"
                  resourceGroupId={resourceGroupId}
                  value={schoolDistrict}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The name of the primary school having a catchment area that includes the associated property.">
              <li className="p-2">
                Elementary School:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['school_elementary']?.id}
                  label="school_elementary"
                  resourceGroupId={resourceGroupId}
                  value={schoolElementary}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The name of the junior or middle school having a catchment area that includes the associated property.">
              <li className="p-2">
                Middle School:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['school_middle']?.id}
                  label="school_middle"
                  resourceGroupId={resourceGroupId}
                  value={schoolMiddle}
                />
              </li>
            </Tooltip>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="The name of the high school having a catchment area that includes the associated property.">
              <li className="p-2">
                High School:{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['school_high']?.id}
                  label="school_high"
                  resourceGroupId={resourceGroupId}
                  value={schoolHigh}
                />
              </li>
            </Tooltip>
          </ul>
          <h3 className="p-2 text-lg">Features</h3>
          <ul>
            <Tooltip
              delay={700}
              placement="top-start"
              maxWidth={400}
              content="A text field that holds the URL for a virtual tour of the property.">
              <li className="max-w-[15rem] overflow-auto p-2">
                Virtual Tour:<br></br>
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields['virtual_tour']?.id}
                  label="virtual_tour"
                  resourceGroupId={resourceGroupId}
                  value={virtualTour}
                />
              </li>
            </Tooltip>
          </ul>
        </div>
      </div>
    </div>
  )
}
