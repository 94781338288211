import {
  Button,
  Modal,
  ModalProps,
  Table,
  TimeAgo,
  Tooltip,
  useCopyToClipboard
} from '@forge/common'
import { StatFieldsFragment, StatStatus } from '@forge/graphql/generated'
import { CheckCircleIcon, ClipboardIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface StatInfoProps {
  stat: StatFieldsFragment
  isOpen: boolean
  size?: ModalProps['size']
  hideParentNavigation?: boolean
  onClose: () => void
}

export function StatInfo({
  stat,
  isOpen,
  size = '5xl',
  hideParentNavigation = false,
  onClose
}: StatInfoProps) {
  const {
    id,
    createdAt,
    job,
    totalRecords,
    status,
    jsonData,
    tookHuman,
    statable,
    stats
  } = stat
  const statData = JSON.parse(jsonData || '[]')

  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={() => {}}>
      <Modal.Title>Stat Info</Modal.Title>
      <div className="overflow-x-auto">
        {!hideParentNavigation && statable && (
          <div className="flex justify-end">
            <LinkToParent parent={statable} />
          </div>
        )}
        <div className="max-h-[calc(100vh-15rem)]">
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Data>ID:</Table.Data>
                <Table.Data>
                  <span className="flex">
                    {id}
                    <Tooltip
                      delay={700}
                      placement="right"
                      maxWidth={150}
                      content="Click to copy">
                      <span className="flex w-fit px-2">
                        <CopyButton value={`${id}`} />
                      </span>
                    </Tooltip>
                  </span>
                </Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data>Created At:</Table.Data>
                <Table.Data>
                  <span className="flex">
                    {createdAt}
                    <Tooltip
                      delay={700}
                      placement="right"
                      maxWidth={150}
                      content="Click to copy">
                      <span className="flex w-fit px-2">
                        <CopyButton value={`${createdAt}`} />
                      </span>
                    </Tooltip>
                  </span>
                </Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data>Job:</Table.Data>
                <Table.Data>
                  <span className="flex">
                    {job}
                    <Tooltip
                      delay={700}
                      placement="right"
                      maxWidth={150}
                      content="Click to copy">
                      <span className="flex w-fit px-2">
                        <CopyButton value={`${job}`} />
                      </span>
                    </Tooltip>
                  </span>
                </Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data>Total Records:</Table.Data>
                <Table.Data>{totalRecords}</Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data>Status:</Table.Data>
                <Table.Data>{status}</Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data>Took:</Table.Data>
                <Table.Data>{tookHuman}</Table.Data>
              </Table.Row>
              {Object.keys(statData).map((key) => (
                <Table.Row key={key}>
                  <Table.Data>{key}:</Table.Data>
                  <Table.Data>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: statData[key]
                      }}
                    />
                  </Table.Data>
                </Table.Row>
              ))}
              {stats?.map((stat) => (
                <tr
                  key={stat.id}
                  className={classNames(
                    'absolute top-0 left-0 h-full w-[0.3125rem]',
                    {
                      'bg-red-500': status === null,
                      'bg-green-500': status === StatStatus.Success,
                      'bg-gray-200': status === StatStatus.Ready,
                      'bg-yellow-500':
                        status === StatStatus.Hold ||
                        status === StatStatus.InProgress
                    }
                  )}>
                  <Table.Data>{stat.id}:</Table.Data>
                  <Table.Data>
                    <LogLine {...stat} />
                    {stat.updatedAtIso && (
                      <TimeAgo date={new Date(stat.updatedAtIso)} />
                    )}
                  </Table.Data>
                </tr>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </Modal>
  )
}

interface CopyButtonProps {
  value: string
}

function CopyButton({ value }: CopyButtonProps) {
  const [copied, setCopied] = useState(false)
  const [, copy] = useCopyToClipboard()

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1200)
    }
  }, [copied])

  return (
    <button
      onClick={() => {
        copy(`${value}`)
        setCopied(true)
      }}>
      <span className="sr-only">Copy to Clipboard</span>
      {!copied ? (
        <ClipboardIcon className="h-3 w-3 hover:text-orange-400" />
      ) : (
        <CheckCircleIcon className="h-3 w-3 text-orange-400" />
      )}
    </button>
  )
}

interface LinkToParentProps {
  parent: StatFieldsFragment['statable']
}

function LinkToParent({ parent }: LinkToParentProps) {
  if (!parent) return null

  switch (parent.__typename) {
    case 'Adapter':
      return (
        <Button size="xs" as={Link} to={`/adapters/${parent.id}/details`}>
          Go to Adapter Dashboard
        </Button>
      )
    case 'ResourceGroup':
      return (
        <Button
          size="xs"
          as={Link}
          to={`/adapters/${parent.adapter?.id}/resource_groups/${parent.id}`}>
          Go to Resource Group
        </Button>
      )
    case 'Resource':
      return (
        <Button
          size="xs"
          as={Link}
          to={`/adapters/${parent.resourceGroup?.adapter?.id}/resource_groups/${parent.resourceGroup?.id}/resources/${parent.id}`}>
          Go to Resource
        </Button>
      )
    default:
      return null
  }
}

function LogLine({
  logLine,
  job,
  status,
  totalRecords,
  tookHuman
}: StatFieldsFragment) {
  if (logLine) {
    switch (status) {
      case 'in_progress':
        return <p className="cursor-pointer">{logLine}</p>
      default:
        return (
          <p className="cursor-pointer">
            {logLine} in {tookHuman}
          </p>
        )
    }
  } else {
    switch (status) {
      case 'ready':
        return <>{job} scheduled </>
      case 'in_progress':
        return <>{job} is working </>
      case 'hold':
        return <>{job} is waiting </>
      default:
        return (
          <>
            <>{job}</> {totalRecords} records in {tookHuman}
          </>
        )
    }
  }
}
