import { useUpdateSavedSearchMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation updateSavedSearch(
    $id: ID!
    $listingServiceId: Int
    $rawCriteria: String
  ) {
    updateSavedSearch(
      id: $id
      listing_service_id: $listingServiceId
      raw_criteria: $rawCriteria
    ) {
      id
    }
  }
`
export function useUpdateSavedSearch() {
  const queryClient = useQueryClient()
  return useUpdateSavedSearchMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['SavedSearch'])
    }
  })
}
