interface Activation {
  id: string | null
  apiUser?: { name?: string | null } | null
}

export function hasApp(activations?: Activation[] | null, app?: string | null) {
  return !!getApp(activations, app)
}

export function getApp(activations?: Activation[] | null, app?: string | null) {
  return activations?.find(({ apiUser }) => apiUser?.name === app)
}
