import { usePopulateMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation Populate(
    $resourceId: Int
    $startDate: String
    $filters: [PopulateFilterType!]
  ) {
    startPopulate(
      resource_id: $resourceId
      start_date: $startDate
      filters: $filters
    ) {
      id
      totalRecords: total_records
      statableId: statable_id
    }
  }
`
export function usePopulate() {
  const queryClient = useQueryClient()
  return usePopulateMutation({
    onSuccess() {
      queryClient.invalidateQueries(['Resource'])
    },
    onError(error) {
      console.error('ERROR POPULATING', error)
    }
  })
}
