import { Attribute } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingDescriptionProps {
  adapterId: string
  description?: string | null
  fields: Record<string, FieldPartsFragment>
  resourceGroupId: string
}

export function ListingDescription({
  adapterId,
  description,
  fields,
  resourceGroupId
}: ListingDescriptionProps) {
  return (
    <Attribute
      adapterId={adapterId}
      fieldId={fields?.['remarks']?.id}
      label="remarks"
      resourceGroupId={resourceGroupId}
      value={description}
    />
  )
}
