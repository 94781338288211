import { classNames, Spinner } from '@forge/common'
import {
  AgentResultType,
  ListingResultType,
  OfficeResultType,
  ResourceGroupRole
} from '@forge/graphql/generated'
import { AnimatePresence, motion } from 'framer-motion'
import { Suspense, useEffect } from 'react'
import { InteractiveSetup } from '.'
import { ResourceRecordData } from './SetupDetails'

interface SetupRecordDetailsProps {
  adapterId: string
  isFullscreen?: boolean
  isOpen: boolean
  onClose: () => void
  record: ListingResultType | AgentResultType | OfficeResultType
  resourceGroupId: string
  resourceSample: ResourceRecordData
  role: ResourceGroupRole
  showDraft: boolean
}

export function SetupRecordDetails({
  adapterId,
  isFullscreen,
  isOpen,
  onClose,
  record,
  resourceGroupId,
  resourceSample,
  role,
  showDraft
}: SetupRecordDetailsProps) {
  useEffect(() => {
    function onKeyPress(event: KeyboardEvent) {
      if (event.key === 'Escape' && isOpen) {
        onClose()
      }
    }
    document.body.addEventListener('keydown', onKeyPress)
    return () => {
      document.body.removeEventListener('keydown', onKeyPress)
    }
  }, [isOpen, onClose])

  return (
    <AnimatePresence>
      {record && isOpen && (
        <>
          <motion.div
            className="absolute inset-0 bg-white/40 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: isFullscreen ? 0 : undefined }}
          />
          <motion.div
            className={classNames(
              'absolute z-10 mx-auto flex flex-col overflow-y-auto bg-white',
              {
                'top-8 left-8 right-8 bottom-0 rounded-t-xl shadow-xl':
                  !isFullscreen,
                'inset-0': isFullscreen
              }
            )}
            initial={{ scale: 0.97, translateY: 25, opacity: 0 }}
            animate={{ scale: 1, translateY: 0, opacity: 1 }}
            exit={{ scale: 0.97, translateY: 25, opacity: 0 }}
            transition={{ duration: isFullscreen ? 0 : 0.25 }}
            // TODO: Re-enable this later once we figure out what's going on w/ overflow-hidden not being removed onAnimationEnd
            // onAnimationStart={() =>
            //   document.body.classList.add('overflow-hidden')
            // }
            // onAnimationEnd={() =>
            //   document.body.classList.remove('overflow-hidden')
            // }
          >
            <div className="p-4">
              <Suspense
                fallback={
                  <div className="w-full p-5">
                    <div className="w-5 h-5 mx-auto text-orange-400">
                      <Spinner />
                    </div>
                  </div>
                }>
                <InteractiveSetup
                  adapterId={adapterId}
                  onClose={onClose}
                  resourceGroupId={resourceGroupId}
                  resourceSample={resourceSample}
                  role={role}
                  showDraft={showDraft}
                />
              </Suspense>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}
