import { AdminRole } from '@forge/graphql/generated'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import {
  Button,
  Checkbox,
  FormField,
  Input,
  Select,
  toast
} from '@forge/common'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useCreateAdmin } from './useCreateAdmin'

export const schema = yup.object({
  role: yup.string().required('Role is required'),
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string(),
  timezone: yup.string(),
  alerts: yup.boolean()
})

export function NewAdminForm() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })

  const { mutate: createAdmin, isLoading, isSuccess, error } = useCreateAdmin()

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          createAdmin(
            {
              name: data.name,
              alerts: data.alerts,
              email: data.email,
              phone: data.phone,
              timezone: data.timezone,
              role: data.role as AdminRole
            },
            {
              onSuccess() {
                toast(
                  <div className="space-y-0.5">
                    <strong>Admin Created Successfully</strong>
                  </div>,
                  { variant: 'success' }
                )
                navigate(`/admins`)
              }
            }
          )
        })}
        className="space-y-3">
        <div className="w-full">
          <div className="pb-2">
            <FormField label="Role" error={errors.role?.message} required>
              <Select {...register('role')}>
                <option value="">Select a role</option>
                {(Object.keys(AdminRole) as Array<keyof typeof AdminRole>).map(
                  (role) => (
                    <option value={AdminRole[role]} key={role}>
                      {role}
                    </option>
                  )
                )}
              </Select>
            </FormField>
          </div>
          <div className="pb-2">
            <FormField label="Name" error={errors.name?.message} required>
              <Input {...register('name')} placeholder="Enter your name" />
            </FormField>
          </div>
          <div className="pb-2">
            <FormField label="Email" error={errors.name?.message} required>
              <Input {...register('email')} placeholder="Enter your email" />
            </FormField>
          </div>
          <div className="pb-2">
            <FormField label="Phone" error={errors.name?.message} required>
              <Input {...register('phone')} placeholder="Enter your phone" />
            </FormField>
          </div>
          <div className="pb-2">
            <FormField
              label="Request Time Zone"
              error={errors.timezone?.message}
              required>
              <Select {...register('timezone')}>
                <option value="">Select a Time Zone</option>
                <option value="UTC">UTC</option>
                <option value="Atlantic Time (Canada)">
                  Atlantic Time (Canada)
                </option>
                <option value="Eastern Time (US & Canada)">
                  Eastern Time (US & Canada)
                </option>
                <option value="Central Time (US & Canada)">
                  Central Time (US & Canada)
                </option>
                <option value="Mountain Time (US & Canada)">
                  Mountain Time (US & Canada)
                </option>
                <option value="Arizona">Arizona</option>
                <option value="Pacific Time (US & Canada)">
                  Pacific Time (US & Canada)
                </option>
                <option value="Hawaii">Hawaii</option>
                <option value="Alaska">Alaska</option>
              </Select>
            </FormField>
          </div>

          <div className="pb-2">
            <FormField>
              <Checkbox
                variant="primary"
                size="lg"
                defaultChecked={false}
                {...register('alerts')}>
                Alarm Alerts
              </Checkbox>
            </FormField>
          </div>

          <div className="flex">
            <Button
              fullWidth
              variant={!error ? 'primary' : 'danger'}
              type="submit"
              loading={isLoading}
              leftIcon={
                error ? (
                  <ExclamationCircleIcon />
                ) : isSuccess ? (
                  <BeakerIcon />
                ) : undefined
              }>
              {!!error && <>An Error Occured. Try again?</>}
              {!error && (
                <>
                  {isLoading
                    ? 'Creating admin'
                    : isSuccess
                    ? 'Success'
                    : 'Create Admin'}
                </>
              )}
            </Button>
          </div>
        </div>

        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
