import {
  Button,
  Checkbox,
  Menu,
  PercentageStat,
  Progress,
  Spinner,
  Table
} from '@forge/common'
import {
  AdapterEsStatus,
  AdapterResourceGroupFragment,
  ResourceGroupStatus
} from '@forge/graphql/generated'
import {
  BanIcon,
  CheckCircleIcon,
  ClipboardListIcon,
  CloudUploadIcon,
  PresentationChartLineIcon,
  SearchIcon,
  TemplateIcon,
  XIcon
} from '@heroicons/react/outline'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { Link, useParams } from 'react-router-dom'
import { useAdapter } from '../adapters'
import { useResourceHealth, useUpdateResource } from '../resource'
import { useAdapterResourceGroups } from './useAdapterResourceGroups'
import { useResourceGroupPurge } from './useResourceGroupPurge'
import { useResourceGroupPurgeClear } from './useResourceGroupPurgeClear'
import { useUpdateResourceGroup } from './useUpdateResourceGroup'

// TODO: Handle Photo Resource Groups Better
export function ResourceGroups() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { data: { adapter: adapterResourceGroups } = {} } =
    useAdapterResourceGroups({ id: adapterId || '' })

  if (!adapter) return null

  return (
    <>
      {adapterResourceGroups?.resourceGroups?.map((resourceGroup) => (
        <ResourceGroup key={resourceGroup.id} resourceGroup={resourceGroup} />
      ))}
    </>
  )
}

interface ResourceGroupProps {
  resourceGroup: AdapterResourceGroupFragment
}

function ResourceGroup({ resourceGroup }: ResourceGroupProps) {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { mutate: clearDeletes, isLoading: clearDeletesLoading } =
    useResourceGroupPurgeClear()
  const { mutate: purgeDeletes, isLoading: purgeDeletesLoading } =
    useResourceGroupPurge()
  const { mutate: updateResourceGroup } = useUpdateResourceGroup()

  const progress = Number(resourceGroup.loadingProgress ?? 0)
  const deleteStatus = resourceGroup.deletesStatus?.pending || 0

  if (!adapter) return null

  return (
    <div className="w-full space-y-4 py-6" key={resourceGroup.role}>
      <div className="space-y-2">
        <h1 className="text-xl capitalize">{resourceGroup.role}</h1>
        <div className="flex items-center justify-between">
          {resourceGroup.role !== 'photo' && (
            <div className="flex w-1/2 justify-between px-2">
              <Checkbox
                size="lg"
                checked={!!resourceGroup.searchData}
                onChange={(e) => {
                  updateResourceGroup({
                    id: resourceGroup.id,
                    adapterId: Number(adapter.id),
                    searchData: e.target.checked
                  })
                }}>
                Search
              </Checkbox>

              <Checkbox
                size="lg"
                checked={!!resourceGroup.indexData}
                onChange={(e) => {
                  updateResourceGroup({
                    id: resourceGroup.id,
                    adapterId: Number(adapter.id),
                    indexData: e.target.checked
                  })
                }}>
                Index
              </Checkbox>

              <Checkbox
                size="lg"
                checked={!!resourceGroup.auditData}
                onChange={(e) => {
                  updateResourceGroup({
                    id: resourceGroup.id,
                    adapterId: Number(adapter.id),
                    auditData: e.target.checked
                  })
                }}>
                Audit
              </Checkbox>
            </div>
          )}
          <div className="flex">
            <Button
              as={Link}
              rightIcon={<PresentationChartLineIcon />}
              to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}`}
              size="xs"
              variant="primary">
              {resourceGroup.role} Dashboard
            </Button>
            {resourceGroup.role !== 'photo' && (
              <>
                <span className="px-2"></span>
                <Button
                  size="xs"
                  variant="primary"
                  rightIcon={<SearchIcon />}
                  as={Link}
                  to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/search`}>
                  Search
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="min-h-fit w-full space-y-2 overflow-x-scroll rounded-lg bg-white shadow">
        {deleteStatus > 0 && (
          <div className="flex w-full items-center justify-between gap-2 p-5">
            <span className="text-sm text-red-500">
              {deleteStatus} records waiting to be purged!
              <br /> Last Purged: {resourceGroup.deletesStatus?.lastPurge} ago
            </span>
            {resourceGroup.deletesStatus?.status === 'working' ? (
              <Button size="xs" loading={true} variant="secondary">
                Purging Records
              </Button>
            ) : (
              <div className="flex gap-2">
                <Button
                  size="xs"
                  loading={purgeDeletesLoading}
                  onClick={() =>
                    purgeDeletes({
                      resourceGroupId: resourceGroup.id
                    })
                  }>
                  Purge
                </Button>
                <Button
                  size="xs"
                  variant="danger"
                  loading={clearDeletesLoading}
                  onClick={() =>
                    clearDeletes({
                      resourceGroupId: resourceGroup.id
                    })
                  }>
                  Clear
                </Button>
              </div>
            )}
          </div>
        )}
        {resourceGroup.status === 'draft' && (
          <div className="flex w-full justify-between bg-white p-5">
            <span className="text-sm text-red-500">Pending Field Changes!</span>
            <Menu
              button={
                <DotsHorizontalIcon
                  className="h-5 w-5 text-gray-600"
                  aria-hidden="true"
                />
              }>
              <Menu.Item icon={<TemplateIcon />}>
                <Link
                  className="flex gap-2"
                  to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/setup`}>
                  Setup
                </Link>
              </Menu.Item>
              <Menu.Item icon={<ClipboardListIcon />}>
                <Link
                  className="flex gap-2"
                  to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/fields`}>
                  Fields
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<CloudUploadIcon />}
                onClick={() =>
                  updateResourceGroup({
                    id: resourceGroup.id,
                    adapterId: Number(adapter.id),
                    status: ResourceGroupStatus.Loading,
                    action_type: 'reindex'
                  })
                }>
                Commit
              </Menu.Item>
              <Menu.Item
                variant="danger"
                icon={<XIcon />}
                onClick={() =>
                  updateResourceGroup({
                    id: resourceGroup.id,
                    adapterId: Number(adapter.id),
                    status: ResourceGroupStatus.Stable
                  })
                }>
                Cancel
              </Menu.Item>
            </Menu>
          </div>
        )}
        {resourceGroup.status === ResourceGroupStatus.Loading &&
          adapter.esStatus !== AdapterEsStatus.Loading && (
            <div className="flex w-full justify-between bg-white p-5">
              <div className="flex-1 flex-col">
                <div className="pb-2">
                  Reindexing {resourceGroup.loadingProgress} %
                </div>

                <Progress progress={progress} />
              </div>
              <Menu
                button={
                  <DotsHorizontalIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                }>
                <Menu.Item
                  variant="danger"
                  icon={<XIcon />}
                  onClick={() =>
                    updateResourceGroup({
                      id: resourceGroup.id,
                      adapterId: Number(adapter.id),
                      status: ResourceGroupStatus.Draft
                    })
                  }>
                  Stop
                </Menu.Item>
                <Menu.Item icon={<TemplateIcon />}>
                  <Link
                    className="flex gap-2"
                    to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/setup`}>
                    Setup
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<ClipboardListIcon />}>
                  <Link
                    className="flex gap-2"
                    to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/fields`}>
                    Fields
                  </Link>
                </Menu.Item>
              </Menu>
            </div>
          )}
        <>
          {resourceGroup?.resources?.map((resource) => {
            if (resource?.populateStatus == null) return null
            else {
              // Extracting progress from status format: "populating: 0%"
              const progress = Number(
                resource?.populateStatus?.slice(
                  11,
                  resource?.populateStatus?.length - 1
                ) ?? 0
              )

              if (progress <= 0) return null
              else {
                return (
                  <div
                    className="flex w-full justify-between bg-white p-5"
                    key={resource?.id}>
                    <div className="flex-1 flex-col">
                      <div className="pb-2">
                        Populating Resource {resource?.name} : {progress}%
                      </div>
                      <Progress progress={progress} />
                    </div>
                    <Menu
                      button={
                        <DotsHorizontalIcon
                          className="h-5 w-5 text-gray-600"
                          aria-hidden="true"
                        />
                      }>
                      <Menu.Item icon={<PresentationChartLineIcon />}>
                        <Link
                          className="flex gap-2"
                          to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/resources/${resource.id}?tab=populates`}>
                          Status
                        </Link>
                      </Menu.Item>
                    </Menu>
                  </div>
                )
              }
            }
          })}
        </>

        {resourceGroup.status === ResourceGroupStatus.Loading &&
          adapter.esStatus === AdapterEsStatus.Loading && (
            <div className="flex-col bg-white p-5">
              <div className="pb-2">
                ES Upgrade Reindex {resourceGroup.loadingProgress} %
              </div>
              <Progress progress={progress} />
            </div>
          )}
        {resourceGroup.status === ResourceGroupStatus.Pending &&
          adapter.esStatus !== AdapterEsStatus.Loading &&
          adapter.esStatus !== AdapterEsStatus.Pending && (
            <div className="flex w-full justify-between bg-white p-5">
              <span className="w-full text-left text-green-500">
                Reindex Complete!
              </span>
              <Menu
                button={
                  <DotsHorizontalIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                }>
                <Menu.Item
                  variant="success"
                  icon={<CheckCircleIcon />}
                  onClick={() =>
                    updateResourceGroup({
                      id: resourceGroup.id,
                      adapterId: Number(adapter.id),
                      status: ResourceGroupStatus.Stable
                    })
                  }>
                  Activate
                </Menu.Item>
                <hr />
                <Menu.Item
                  variant="danger"
                  icon={<BanIcon />}
                  onClick={() =>
                    updateResourceGroup({
                      id: resourceGroup.id,
                      adapterId: Number(adapter.id),
                      status: ResourceGroupStatus.Draft
                    })
                  }>
                  Abort
                </Menu.Item>
                <Menu.Item icon={<TemplateIcon />}>
                  <Link
                    className="flex gap-2"
                    to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/setup`}>
                    Setup
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<ClipboardListIcon />}>
                  <Link
                    className="flex gap-2"
                    to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/fields`}>
                    Fields
                  </Link>
                </Menu.Item>
              </Menu>
            </div>
          )}
        {resourceGroup.status === ResourceGroupStatus.Pending &&
          adapter?.esStatus === AdapterEsStatus.Loading && (
            <div className="text-green-500">
              ES Upgrade Reindex Complete! 🥳
            </div>
          )}
        {resourceGroup.status === ResourceGroupStatus.Pending &&
          adapter?.esStatus === AdapterEsStatus.Pending && (
            <div className="text-green-500">
              ES Upgrade Reindex Complete! 🥳
            </div>
          )}
        {resourceGroup.role !== 'photo' && (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Resource</Table.Header>
                <>
                  <Table.Header align="center">Sync</Table.Header>
                  <Table.Header align="center">Audits</Table.Header>
                  <Table.Header align="center">Updates</Table.Header>
                </>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {resourceGroup.resources?.map((resource) => {
                return (
                  <ResourceRow
                    adapterId={adapterId}
                    id={resource?.id}
                    key={resource?.id}
                    name={resource?.name}
                    resourceGroupId={resourceGroup?.id}
                    updateData={resource?.updateData || false}
                  />
                )
              })}
            </Table.Body>
          </Table>
        )}
      </div>

      {resourceGroup.needsSetup && (
        <div className="mt-4 animate-bounce">
          <Button
            as={Link}
            to={`/adapters/${adapter?.id}/resource_groups/${resourceGroup.id}/settings`}
            size="xs">
            Configure Resource Group
          </Button>
        </div>
      )}
    </div>
  )
}

interface ResourceRowProps {
  id: string
  adapterId: string | undefined
  name: string | null | undefined
  resourceGroupId: string
  updateData: boolean | undefined
}

function ResourceRow({
  id,
  adapterId,
  name,
  resourceGroupId,
  updateData
}: ResourceRowProps) {
  const { data, isLoading } = useResourceHealth({ id }, { suspense: false })
  const { mutate: updateResource } = useUpdateResource()

  return (
    <Table.Row>
      <Table.Data align="left">
        <Link
          className="w-full hover:text-orange-400"
          to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/resources/${id}`}>
          <p className="max-w-xxs">{name}</p>
        </Link>
      </Table.Data>
      <Table.Data align="center">
        {isLoading ? (
          <Loader />
        ) : data?.resource?.health?.sync ? (
          <PercentageStat value={data?.resource?.health.sync} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="center">
        {isLoading ? (
          <Loader />
        ) : data?.resource?.health?.audit ? (
          <PercentageStat value={data?.resource?.health.audit} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="center">
        <Checkbox
          size="lg"
          checked={updateData}
          onChange={(e) => {
            updateResource({
              id: id,
              resourceGroupId: Number(resourceGroupId),
              updateData: e.target.checked
            })
          }}
        />
      </Table.Data>
    </Table.Row>
  )
}

function Loader() {
  return (
    <div className="flex justify-center text-gray-500">
      <div className="h-5 w-5">
        <Spinner />
      </div>
    </div>
  )
}
