import classNames from 'classnames'

interface PercentageStatProps {
  value: number
}

export function PercentageStat({ value }: PercentageStatProps) {
  if (value <= 0) return <div className="text-red-500">{value}%</div>

  return (
    <div
      className={classNames({
        'text-red-500': value < 75 || value === 0,
        'text-yellow-500': value > 74 && value < 95,
        'text-green-500': value >= 95
      })}>
      {value}%
    </div>
  )
}
