import {
  AdapterResourceGroupsQuery,
  ResourceGroupQuery,
  useUpdateResourceGroupMutation
} from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { AdapterResourceGroupFields } from './useResourceGroup'

gql`
  mutation updateResourceGroup(
    $action_type: String
    $id: ID!
    $searchData: Boolean
    $indexData: Boolean
    $auditData: Boolean
    $casInvites: Boolean
    $skipPhotos: Boolean
    $adapterId: Int
    $updated_at_field: String
    $populate_date_field: String
    $photos_updated_at_field: String
    $unique_id_field: String
    $track_fields: [String]
    $photo_strategy: ResourceGroupPhotoStrategy
    $photo_settings: PhotoSettingsInput
    $status: ResourceGroupStatus
    $replication: Boolean
  ) {
    updateResourceGroup(
      action_type: $action_type
      id: $id
      adapter_id: $adapterId
      updated_at_field: $updated_at_field
      photos_updated_at_field: $photos_updated_at_field
      populate_date_field: $populate_date_field
      unique_id_field: $unique_id_field
      track_fields: $track_fields
      photo_strategy: $photo_strategy
      photo_settings: $photo_settings
      search_data: $searchData
      index_data: $indexData
      audit_data: $auditData
      status: $status
      cas_invites: $casInvites
      skip_photos: $skipPhotos
      replication: $replication
    ) {
      ...AdapterResourceGroupFields
    }
  }
  ${AdapterResourceGroupFields}
`

export function useUpdateResourceGroup() {
  const queryClient = useQueryClient()
  return useUpdateResourceGroupMutation({
    onMutate: async ({ adapterId, ...newResourceGroupData }) => {
      await queryClient.cancelQueries([
        'AdapterResourceGroups',
        { id: String(adapterId) }
      ])

      await queryClient.cancelQueries([
        'ResourceGroup',
        { id: newResourceGroupData.id }
      ])

      const previousResourceGroups =
        queryClient.getQueryData<AdapterResourceGroupsQuery>([
          'AdapterResourceGroups',
          { id: String(adapterId) }
        ])

      if (previousResourceGroups?.adapter) {
        queryClient.setQueryData<AdapterResourceGroupsQuery>(
          ['AdapterResourceGroups', { id: String(adapterId) }],
          {
            ...previousResourceGroups,
            adapter: {
              ...previousResourceGroups.adapter,
              resourceGroups: (
                previousResourceGroups.adapter.resourceGroups || []
              ).map((rg) => {
                if (rg.id === newResourceGroupData.id) {
                  return {
                    ...rg,
                    ...newResourceGroupData
                  }
                }
                return rg
              })
            }
          }
        )
      }

      const previousResourceGroup =
        queryClient.getQueryData<ResourceGroupQuery>([
          'ResourceGroup',
          { id: newResourceGroupData.id }
        ])

      if (previousResourceGroup?.resourceGroup) {
        queryClient.setQueryData<ResourceGroupQuery>(
          ['ResourceGroup', { id: newResourceGroupData.id }],
          {
            ...previousResourceGroup,
            resourceGroup: {
              ...previousResourceGroup.resourceGroup,
              replication: newResourceGroupData.replication
            }
          }
        )
      }

      return { previousResourceGroup, previousResourceGroups }
    },
    onError: (_, { adapterId, id: ResourceId }, context) => {
      queryClient.setQueryData(
        ['AdapterResourceGroups', { id: String(adapterId) }],
        context?.previousResourceGroups
      )
      queryClient.setQueryData(
        ['ResourceGroup', { id: ResourceId }],
        context?.previousResourceGroup
      )
    },
    onSettled(data) {
      queryClient.invalidateQueries(['ResourceGroup'])
      queryClient.invalidateQueries(['AdapterResourceGroups'])
      queryClient.invalidateQueries(['AdapterResourceGroupFields'])
    }
  })
}
