import { Attribute, Tooltip } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingOfficeFieldsProps {
  adapterId: string
  fields: Record<string, FieldPartsFragment>
  officeListEmail?: string | null
  officeListId?: string | null
  officeListName?: string | null
  officeListPhone?: string | null
  officeSellEmail?: string | null
  officeSellId?: string | null
  officeSellName?: string | null
  officeSellPhone?: string | null
  resourceGroupId: string
}

export function ListingOfficeFields({
  adapterId,
  fields,
  officeListEmail,
  officeListId,
  officeListName,
  officeListPhone,
  officeSellEmail,
  officeSellId,
  officeSellName,
  officeSellPhone,
  resourceGroupId
}: ListingOfficeFieldsProps) {
  return (
    <div className="2xl:flex">
      <div>
        <h3 className="p-2 text-lg">Office List</h3>
        <ul className="min-w-sm overflow-auto pb-4 sm:max-w-full lg:max-w-[15rem]">
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="A system unique identifier. Specifically, in aggregation systems, the Key is the system unique identifier from the system that the record was just retrieved. This may be identical to the related xxxId identifier, but the key is guaranteed unique for this record set. This is a foreign key relating to the Office resource's OfficeKey.">
            <li className="p-2">
              Unique ID:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_list_id']?.id}
                label="office_list_id"
                resourceGroupId={resourceGroupId}
                value={officeListId}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The legal name of the brokerage representing the seller.">
            <li className="p-2">
              Name:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_list_name']?.id}
                label="office_list_name"
                resourceGroupId={resourceGroupId}
                value={officeListName}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The email address of the Listing Office">
            <li className="p-2">
              Email:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_list_email']?.id}
                label="office_list_email"
                resourceGroupId={resourceGroupId}
                value={officeListEmail}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol.">
            <li className="p-2">
              Phone:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_list_phone']?.id}
                label="office_list_phone"
                resourceGroupId={resourceGroupId}
                value={officeListPhone}
              />
            </li>
          </Tooltip>
        </ul>
      </div>
      <div>
        <h3 className="p-2 text-lg">Office Sell</h3>
        <ul className="min-w-sm overflow-auto xs:max-w-[15rem] sm:max-w-full">
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="A system unique identifier. Specifically, in aggregation systems, the Key is the system unique identifier from the system that the record was just retrieved. This may be identical to the related xxxId identifier, but the key is guaranteed unique for this record set. This is a foreign key relating to the Office resource's OfficeKey.">
            <li className="p-2">
              Unique ID:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_sell_id']?.id}
                label="office_sell_id"
                resourceGroupId={resourceGroupId}
                value={officeSellId}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The legal name of the brokerage representing the buyer.">
            <li className="p-2">
              Name:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_sell_name']?.id}
                label="office_sell_name"
                resourceGroupId={resourceGroupId}
                value={officeSellName}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The email address of the Buyer's Office.">
            <li className="p-2">
              Email:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_sell_email']?.id}
                label="office_sell_email"
                resourceGroupId={resourceGroupId}
                value={officeSellEmail}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol.">
            <li className="p-2">
              Phone:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['office_sell_phone']?.id}
                label="office_sell_phone"
                resourceGroupId={resourceGroupId}
                value={officeSellPhone}
              />
            </li>
          </Tooltip>
        </ul>
      </div>
    </div>
  )
}
