import { AnimatePresence, motion } from 'framer-motion'

interface ListingFullscreenTourProps {
  tour: string
}

export function ListingFullscreenTour({ tour }: ListingFullscreenTourProps) {
  return (
    <div className="relative flex h-full w-full overflow-scroll bg-[#262626]">
      <div className="relative flex w-full group grow">
        <AnimatePresence initial={false} mode="popLayout">
          <motion.iframe
            animate="center"
            className="w-full max-h-full text-white select-none "
            exit="exit"
            height="600"
            initial="enter"
            layout
            src={tour}
            transition={{ translate: { ease: 'easeInOut' }, duration: 0.2 }}
          />
        </AnimatePresence>
      </div>
    </div>
  )
}
