import { Attribute, classNames } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { useResourceGroupSearchFields } from '@forge/features/resourcegroups'
import { OfficeBadge } from './OfficeBadge'

interface OfficeSampleProps {
  active?: boolean | null
  adapterId: string
  email?: string | null
  id?: string | null
  isOpen?: boolean
  name?: string | null
  officeId?: string | null
  onClick?: () => void
  phone?: string | null
  resourceGroupId: string
}

export function OfficeSample({
  active,
  adapterId,
  email,
  id,
  isOpen,
  name,
  officeId,
  onClick,
  phone,
  resourceGroupId
}: OfficeSampleProps) {
  const { data: resourceGroupSearchFields = {} } = useResourceGroupSearchFields(
    {
      resourceGroupId
    }
  )

  const searchFields = resourceGroupSearchFields?.resourceGroup?.fields?.sort(
    (a, b) => (a?.displayName || '').localeCompare(b?.displayName || '')
  )

  const fields =
    searchFields?.reduce<Record<string, FieldPartsFragment>>(
      (fields, field) => {
        const key = field.aliases?.[0] || field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}
  return (
    <div>
      <div
        className={classNames('rounded-md bg-white', {
          'shadow-raised ring-2 ring-orange-500': isOpen,
          'shadow-sticky': !isOpen
        })}>
        <div
          role="button"
          tabIndex={0}
          className="flex items-center p-1 space-x-2"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onClick?.()
            }
          }}
          onClick={onClick}>
          <div className="grow space-y-0.5 pl-1">
            {!id && (
              <div className="hover:cursor-pointer">
                Configure{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['id']?.id}
                  label="id"
                  resourceGroupId={resourceGroupId}
                  value={id}
                />{' '}
                to load a sample record.
              </div>
            )}
            {name !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={name || ''}>
                {name}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['name']?.id}
                  label="name"
                  resourceGroupId={resourceGroupId}
                  value={name}
                />
              </div>
            )}
            {email !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={email}>
                {email}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['email']?.id}
                  label="email"
                  resourceGroupId={resourceGroupId}
                  value={email}
                />
              </div>
            )}
            {phone !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={phone}>
                {phone}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['phone']?.id}
                  label="phone"
                  resourceGroupId={resourceGroupId}
                  value={phone}
                />
              </div>
            )}
          </div>
          <div className="space-y-0.5 pr-1 text-right">
            {officeId !== null ? (
              <div
                className="p-1 text-sm font-semibold text-gray-900 truncate"
                title={officeId}>
                {officeId}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['office_id']?.id}
                  label="office_id"
                  resourceGroupId={resourceGroupId}
                  value={officeId}
                />
              </div>
            )}

            <div className="flex float-right">
              <OfficeBadge active={active} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
