import { Breadcrumbs, Spinner, Tooltip, Wrapper } from '@forge/common'
import { ApiUserNewForm } from '@forge/features/apiusers'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

export function NewApiUser() {
  return (
    <>
      <Helmet>
        <title>New API User</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/api_users">API Users</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/api_users/new`}>
            New API User
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            New API User
            <div className="px-2 py-2">
              <Tooltip delay={700} placement="right" content="Add an API User">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center p-4">
              <div className="h-5 w-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <ApiUserNewForm />
        </Suspense>
      </Wrapper>
    </>
  )
}
