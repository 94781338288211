import { useUpdateListingServiceMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation updateListingService(
    $id: ID!
    $key: String
    $name: String
    $address: String
    $timeZone: String
    $lat: Float
    $lon: Float
    $state: [String]
    $defaultSearch: [DefaultSearchInput]
    $config: String
  ) {
    updateListingService(
      id: $id
      key: $key
      name: $name
      address: $address
      time_zone: $timeZone
      lat: $lat
      lon: $lon
      state: $state
      default_search: $defaultSearch
      config: $config
    ) {
      id
      key
      name
      createAt: created_at
      updateAt: updated_at
      config
    }
  }
`
export function useUpdateListingService() {
  const queryClient = useQueryClient()
  return useUpdateListingServiceMutation({
    onSuccess() {
      queryClient.invalidateQueries(['ListingService'])
    }
  })
}
