import {
  OfficeSearchQuery,
  OfficeSearchQueryVariables,
  useOfficeSearchQuery
} from '@forge/graphql/generated'
import { UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

// ! Spoke w/ dennis about keeping OfficeSearchResult keys snake_case
// ! We're doing this due to the JSON returned in instances like raw_data where we're unable to alias said key
// ! This keeps the listnig detail raw data tab from breaking
// ! We'll revisit after __old__ is removed

export const OfficeSearchResult = gql`
  fragment OfficeSearchResult on OfficeResultType {
    email
    id
    name
    office_id
    phone
    updated_at
    active
    wr_resource_id
    raw_data {
      serialized
      raw
    }
  }
`

gql`
  fragment OfficeSearchFieldParts on Field {
    aliases
    colName: col_name
    id
  }

  query OfficeSearch(
    $id: ID!
    $limit: Int
    $page: Int
    $filter: [FilterQueryType]
    $sort: QuerySortType
    $source: String
  ) {
    officeRg: resource_group(id: $id) {
      id
      offices(
        limit: $limit
        page: $page
        filter: $filter
        sort: $sort
        source: $source
      ) {
        count
        explain
        results {
          ...OfficeSearchResult
        }
      }
    }
    resourceGroupFieldsSearchValues: resource_group(id: $id) {
      id
      fields(limit: 10000) {
        ...OfficeSearchFieldParts
      }
    }
  }
  ${OfficeSearchResult}
`

export function useOfficeSearch(
  vars: OfficeSearchQueryVariables,
  options?: Omit<
    UseQueryOptions<OfficeSearchQuery, Error>,
    'queryKey' | 'queryFn'
  >
) {
  return useOfficeSearchQuery(vars, options)
}
