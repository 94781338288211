import { useDestroyAllLookupsMutation } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  mutation DestroyAllLookups($fieldId: ID!) {
    deleteAllLookups(field_id: $fieldId) {
      id
    }
  }
`

export function useDestroyAllLookups() {
  return useDestroyAllLookupsMutation()
}
