import { useUpdateApiUserMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { apiUserFragment } from './useApiUser'

gql`
  mutation updateApiUser(
    $id: ID!
    $name: String
    $search: Boolean
    $replication: Boolean
    $forgeApi: Boolean
    $fullAccess: Boolean
    $environment: ApiUserEnvironment
    $percolatorNotifications: ApiUserPercolatorNotification
    $percolatorEndpoint: String
  ) {
    updateApiUser(
      id: $id
      name: $name
      search: $search
      replication: $replication
      forge_api: $forgeApi
      full_access: $fullAccess
      environment: $environment
      percolator_notifications: $percolatorNotifications
      percolator_endpoint: $percolatorEndpoint
    ) {
      ...apiUserFields
    }
  }
  ${apiUserFragment}
`

export function useUpdateApiUser() {
  const queryClient = useQueryClient()
  return useUpdateApiUserMutation({
    onSuccess: async ({ updateApiUser }) => {
      await queryClient.invalidateQueries(['ApiUsers'])
      await queryClient.invalidateQueries([
        'ApiUser',
        { id: updateApiUser?.id }
      ])
    }
  })
}
