import { RawData } from '@forge/graphql/generated'
import { Link } from 'react-router-dom'

interface AttributeProps {
  adapterId: string
  currency?: boolean
  fieldId?: string
  label?: string
  resourceGroupId: string
  value?: string | number | boolean | RawData | null
}

export const Attribute = ({
  adapterId,
  currency,
  fieldId,
  label,
  resourceGroupId,
  value
}: AttributeProps) => {
  if (typeof value !== 'undefined' && typeof fieldId !== 'undefined') {
    if (!!value) {
      return (
        <Link
          to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/fields/${fieldId}`}
          className="cursor-pointer hover:text-orange-400">
          {currency
            ? value?.toLocaleString()
            : typeof value === 'object' || typeof value === 'boolean'
            ? JSON.stringify(value)
            : value}
        </Link>
      )
    } else {
      return (
        <Link
          to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/fields/${fieldId}`}
          className="italic text-gray-400 cursor-pointer hover:text-orange-400">
          {label}
        </Link>
      )
    }
  }

  return (
    <Link
      to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/fields`}
      className="italic text-red-500 cursor-pointer hover:text-orange-400">
      {label}
    </Link>
  )
}
