import { useState } from 'react'

type CopiedValue = string | null
type CopiedFn = (value: string) => Promise<void>

export function useCopyToClipboard(): [CopiedValue, CopiedFn] {
  const [copiedValue, setCopiedValue] = useState<CopiedValue>(null)

  const copyToClipboard: CopiedFn = async (value) => {
    try {
      await navigator.clipboard.writeText(value)
      setCopiedValue(value)
    } catch (error) {
      console.warn(error)
      setCopiedValue(null)
    }
  }

  return [copiedValue, copyToClipboard]
}
