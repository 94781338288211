import { Checkbox, Label, Tabs } from '@forge/common'
import { StatsCondensed } from '@forge/features/resource'
import { StatJob } from '@forge/graphql/generated'
import { useParams, useSearchParams } from 'react-router-dom'
import { DefaultCriteriaTable } from '../defaultCriteria'
import { ResourceGroupCasInvitesTable } from './ResourceGroupCasInvitesTable'
import { ResourceGroupFactsConsumerTable } from './ResourceGroupFactsConsumerTable'
import { ResourceGroupPercolatorStatsTable } from './ResourceGroupPercolatorStatsTable'
import { ResourceGroupRecordsConsumerTable } from './ResourceGroupRecordsConsumerTable'
import { ResourceGroupReindexConsumerTable } from './ResourceGroupReindexConsumerTable'
import { ResourceGroupReplicationApiTable } from './ResourceGroupReplicationApiTable'
import { ResourceGroupResourcesTable } from './ResourceGroupResourcesTable'
import { ResourceGroupSavedSearchConsumerTable } from './ResourceGroupSavedSearchConsumerTable'
import { useResourceGroup } from './useResourceGroup'
import { useUpdateResourceGroup } from './useUpdateResourceGroup'

enum TabEnum {
  resources,
  recordsConsumer,
  reindexConsumer,
  savedSearchConsumer,
  factsConsumer,
  percolator,
  replicationApi,
  casInvites,
  casInvitesConsumer
}

export function ResourceGroupTabs() {
  const { resourceGroupId } = useParams()
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })
  const { mutate: updateResourceGroup } = useUpdateResourceGroup()

  const [searchParams, setSearchParams] = useSearchParams({ tab: 'resources' })
  const selectedTab = searchParams.get('tab') as keyof typeof TabEnum
  const selectedTabIndex = TabEnum[selectedTab]

  return (
    <div>
      <Tabs selectedIndex={selectedTabIndex}>
        <Tabs.List>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'resources' })}>
            Resources
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'recordsConsumer' })}>
            Records Consumer
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'reindexConsumer' })}>
            Reindex Consumer
          </Tabs.Tab>
          {resourceGroup?.role === 'listing' && (
            <>
              <Tabs.Tab
                onClick={() => setSearchParams({ tab: 'savedSearchConsumer' })}>
                SavedSearch Consumer
              </Tabs.Tab>
              <Tabs.Tab
                onClick={() => setSearchParams({ tab: 'factsConsumer' })}>
                Facts Consumer
              </Tabs.Tab>
              <Tabs.Tab onClick={() => setSearchParams({ tab: 'percolator' })}>
                Percolator
              </Tabs.Tab>
            </>
          )}
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'replicationApi' })}>
            Replication API
          </Tabs.Tab>
          {resourceGroup?.role === 'agent' && (
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'casInvites' })}>
              CAS Auto Invites
            </Tabs.Tab>
          )}
          {!!resourceGroup?.casInvites && (
            <Tabs.Tab
              onClick={() => setSearchParams({ tab: 'casInvitesConsumer' })}>
              Invites Consumer
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panels>
          <Tabs.Panel>
            <ResourceGroupResourcesTable />
          </Tabs.Panel>
          <Tabs.Panel>
            <ResourceGroupRecordsConsumerTable />
          </Tabs.Panel>
          <Tabs.Panel>
            <ResourceGroupReindexConsumerTable />
          </Tabs.Panel>
          {resourceGroup?.role === 'listing' && (
            <>
              <Tabs.Panel>
                <ResourceGroupSavedSearchConsumerTable />
              </Tabs.Panel>
              <Tabs.Panel>
                <ResourceGroupFactsConsumerTable />
              </Tabs.Panel>
              <Tabs.Panel>
                <ResourceGroupPercolatorStatsTable />
              </Tabs.Panel>
            </>
          )}
          <Tabs.Panel>
            <ResourceGroupReplicationApiTable resourceGroup={resourceGroup} />
          </Tabs.Panel>
          {!!resourceGroup?.casInvites && (
            <Tabs.Panel>
              <ResourceGroupCasInvitesTable />
            </Tabs.Panel>
          )}
          {resourceGroup?.role === 'agent' && (
            <Tabs.Panel>
              <div className="p-2">
                <Checkbox
                  size="lg"
                  checked={!!resourceGroup?.casInvites}
                  onChange={(e) => {
                    updateResourceGroup({
                      id: resourceGroup.id,
                      casInvites: e.target.checked
                    })
                  }}>
                  <Label>CAS Invites</Label>
                </Checkbox>
              </div>

              {resourceGroup?.resources?.map((r) => (
                <div key={r.id}>
                  <div className="text-xl"> Set criteria for {r.name}s </div>
                  <DefaultCriteriaTable
                    parentType="Resource"
                    parentId={Number(r.id)}
                    casInvites
                    showScope={false}
                  />
                </div>
              ))}
            </Tabs.Panel>
          )}
          {!!resourceGroup?.casInvites && (
            <Tabs.Panel>
              <StatsCondensed
                showTotal
                job={StatJob.InvitesConsumer}
                resourceGroupId={resourceGroup?.id}
              />
            </Tabs.Panel>
          )}
        </Tabs.Panels>
      </Tabs>
    </div>
  )
}
