import { Breadcrumbs, Spinner, Tooltip, Wrapper } from '@forge/common'
import { EditAdminForm, useAdminUser } from '@forge/features/adminusers'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function EditAdmin() {
  const { admin_id } = useParams<{ admin_id: string }>()
  const { data: { admin } = {} } = useAdminUser({ id: admin_id })

  return (
    <>
      <Helmet>
        <title>Edit Admin User</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/admins">Admin Users</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/admins/${admin_id}/edit`}>
            Edit Admin
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <h1 className="flex text-4xl font-medium">
          Edit Admin User
          <div className="p-2">
            <Tooltip delay={700} content={`Edit Admin User ${admin?.name}`}>
              <InformationCircleIcon className="h-5 w-5" />
            </Tooltip>
          </div>
        </h1>

        <Suspense
          fallback={
            <div className="h-5 w-5 text-orange-500">
              <Spinner />
            </div>
          }>
          <div className="m-auto max-w-lg">
            <EditAdminForm admin={admin} />
          </div>
        </Suspense>
      </Wrapper>
    </>
  )
}
