import { gql } from 'graphql-request'
import { useDestroyTransformMutation } from '@forge/graphql/generated'

gql`
  mutation DestroyTransform($id: ID!) {
    deleteTransform(id: $id)
  }
`

export function useDestroyTransform() {
  return useDestroyTransformMutation()
}
