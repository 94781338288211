import { Modal, Table } from '@forge/common'
import { ApiUserFieldsFragment } from '@forge/graphql/generated'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'

interface DetailsProps {
  apiUser: ApiUserFieldsFragment
}

export function ApiUsersDetailsTable({ apiUser }: DetailsProps) {
  const [showToken, setShowToken] = useState(false)

  return (
    <div className="rounded-lg bg-white p-1 shadow">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Name</Table.Header>
            <Table.Header align="left">Internal Search</Table.Header>
            <Table.Header align="left">Replication</Table.Header>
            <Table.Header align="left">Forge API</Table.Header>
            <Table.Header align="center">Token</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Data>{apiUser.name}</Table.Data>
            <Table.Data>{apiUser.search ? 'Yes' : 'No'}</Table.Data>
            <Table.Data>{apiUser.replication ? 'Yes' : 'No'}</Table.Data>
            <Table.Data>{apiUser.forgeApi ? 'Yes' : 'No'}</Table.Data>
            <Table.Data align="center">
              <div className="flex justify-center">
                <InformationCircleIcon
                  className="h-5 w-5 cursor-pointer text-orange-400"
                  onClick={() => setShowToken(true)}
                />
              </div>
            </Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
      {showToken && (
        <Modal size="2xl" isOpen onClose={() => setShowToken(false)}>
          <Modal.Title>Bearer Token</Modal.Title>
          <p className="overflow-auto p-2">{apiUser.jwt}</p>
        </Modal>
      )}
    </div>
  )
}
