import { Checkbox, PercentageStat, Spinner, Table } from '@forge/common'
import { Suspense } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAdapter } from '../adapters'
import { useResourceHealth, useUpdateResource } from '../resource'
import { useResourceGroup } from './useResourceGroup'

export function RGResourcesTableProvider() {
  const { adapterId, resourceGroupId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  if (!adapter) return null

  return (
    <div className="p-1 overflow-auto bg-white rounded-lg shadow">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Resource</Table.Header>
            <Table.Header align="center">Sync Rate</Table.Header>
            <Table.Header align="center">Audit Rate</Table.Header>
            <Table.Header align="center">Resolved Rate</Table.Header>
            <Table.Header align="center">Weekly Audit Rate</Table.Header>
            <Table.Header align="center">Monthly Audit Rate</Table.Header>
            <Table.Header align="center">Updates Enabled</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {resourceGroup?.resources?.map((resource) => (
            <ResourceRow key={resource.id} resourceId={resource.id} />
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

interface ResourceRowProps {
  resourceId: string
}

function ResourceRow({ resourceId }: ResourceRowProps) {
  const { adapterId, resourceGroupId } = useParams()
  const { data: { resource } = {}, isLoading } = useResourceHealth(
    {
      id: resourceId
    },
    { suspense: false }
  )
  const { mutate: updateResource } = useUpdateResource()

  return (
    <Table.Row key={resourceId}>
      <Table.Data>
        <Link
          className="w-full hover:text-orange-400"
          to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/resources/${resourceId}`}>
          {resource?.name}
        </Link>
      </Table.Data>
      <Table.Data align="center">
        {isLoading ? (
          <LoadSpinner />
        ) : resource?.health?.sync ? (
          <PercentageStat value={resource.health.sync} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="center">
        {isLoading ? (
          <LoadSpinner />
        ) : resource?.health?.audit ? (
          <PercentageStat value={resource.health.audit} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="center">
        {isLoading ? (
          <LoadSpinner />
        ) : resource?.health?.auditResolution ? (
          <PercentageStat value={resource.health.auditResolution} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="center">
        {isLoading ? (
          <LoadSpinner />
        ) : resource?.health?.weeklyAudit ? (
          <PercentageStat value={resource.health.weeklyAudit} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="center">
        {isLoading ? (
          <LoadSpinner />
        ) : resource?.health?.monthlyAudit ? (
          <PercentageStat value={resource.health.monthlyAudit} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="center">
        <Checkbox
          size="lg"
          checked={!!resource?.updateData}
          onChange={(e) => {
            updateResource({
              id: resourceId,
              resourceGroupId: Number(resourceGroupId),
              updateData: e.target.checked
            })
          }}
        />
      </Table.Data>
    </Table.Row>
  )
}

function LoadSpinner() {
  return (
    <div className="flex justify-center text-gray-500">
      <div className="w-5 h-5">
        <Spinner />
      </div>
    </div>
  )
}

export function ResourceGroupResourcesTable() {
  return (
    <Suspense
      fallback={
        <div className="p-1 overflow-auto bg-white rounded-lg shadow">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Resource</Table.Header>
                <Table.Header align="center">Sync Rate</Table.Header>
                <Table.Header align="center">Audit Rate</Table.Header>
                <Table.Header align="center">Resolved Rate</Table.Header>
                <Table.Header align="center">Weekly Audit Rate</Table.Header>
                <Table.Header align="center">Monthly Audit Rate</Table.Header>
                <Table.Header align="center">Updates Enabled</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Data>
                  <div className="flex items-center gap-2">
                    <div className="w-5 h-5 text-orange-500">
                      <Spinner />
                    </div>
                    <div>Loading Resources</div>
                  </div>
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      }>
      <RGResourcesTableProvider />
    </Suspense>
  )
}
