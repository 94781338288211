import {
  Alert,
  SearchFilterInput,
  Table,
  Tooltip,
  useCopyToClipboard,
  useDebounce
} from '@forge/common'
import { AdminFieldsFragment } from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import {
  ClipboardIcon,
  PencilIcon,
  RefreshIcon,
  TrashIcon
} from '@heroicons/react/solid'
import { ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { useAdminUsers } from './useAdminUsers'
import { useDeleteAdminUser } from './useDeleteAdminUser'
import { useUpdateAdmin } from './useUpdateAdmin'

const filterAdmins = (
  admins: AdminFieldsFragment[] | undefined | null,
  searchTerm: string
) => {
  const result: AdminFieldsFragment[] = []

  if (!admins) return

  admins?.forEach((admin) => {
    if (JSON.stringify(admin)?.toLowerCase().includes(searchTerm.toLowerCase()))
      if (result.indexOf(admin) === -1) result.push(admin)
  })
  return result
}

export function AdminUsersTable() {
  const { data: { admins } = {} } = useAdminUsers()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const filteredAdmins = filterAdmins(admins, debouncedSearchTerm)

  return (
    <div>
      <div className="flex flex-row justify-end">
        <div className=" w-full pb-4 md:w-[30%]">
          <SearchFilterInput
            value={searchTerm}
            placeholder="Search by Admin Name, Email or Role"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
        </div>
      </div>
      <div className="space-y-2 overflow-auto bg-white rounded-lg shadow print:border print:shadow-none">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>Email</Table.Header>
              <Table.Header>Role</Table.Header>
              <Table.Header align="center">Reset Password</Table.Header>
              <Table.Header align="center">Alarm Alerts</Table.Header>
              <Table.Header align="center">Edit</Table.Header>
              <Table.Header align="center">Delete</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {filteredAdmins?.length === 0 ? (
              <Table.Row>
                <Table.Data colSpan={7}>
                  <p className="p-4 text-lg text-center">
                    No admins found{' '}
                    {debouncedSearchTerm && 'for the search term'}
                    {debouncedSearchTerm && (
                      <span className="font-bold text-orange-400">
                        {' '}
                        "{debouncedSearchTerm}"{' '}
                      </span>
                    )}
                    .
                  </p>
                </Table.Data>
              </Table.Row>
            ) : (
              filteredAdmins?.map((admin) => {
                return <AdminUserRow key={admin.id} admin={admin} />
              })
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

interface AdminUserRowProps {
  admin: AdminFieldsFragment
}

const AdminUserRow = ({ admin }: AdminUserRowProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openResetPwdModal, setOpenResetPwdModal] = useState(false)

  const [, copy] = useCopyToClipboard()

  const {
    mutate: deleteAdmin,
    isLoading: isDestroying,
    error: deleteAdminError
  } = useDeleteAdminUser()
  const {
    mutate: updateAdmin,
    isLoading: isUpdating,
    error: updateAdminError
  } = useUpdateAdmin()

  return (
    <Table.Row highlightOnHover>
      <Table.Data>
        <div className="cursor-pointer hover:text-orange-400">{admin.name}</div>
      </Table.Data>
      <Table.Data>
        <div className="cursor-pointer hover:text-orange-400">
          {admin.email}
        </div>
      </Table.Data>
      <Table.Data>
        <div className="cursor-pointer hover:text-orange-400">{admin.role}</div>
      </Table.Data>
      <Table.Data align="center">
        {admin.resetUrl ? (
          <div>
            <Tooltip
              delay={700}
              placement="right"
              maxWidth={150}
              content="Copy Reset URL">
              <button onClick={() => copy(`${admin.resetUrl}`)}>
                <ClipboardIcon className="w-5 h-5 hover:text-orange-400" />
              </button>
            </Tooltip>
          </div>
        ) : (
          <>
            <Tooltip
              delay={700}
              placement="right"
              maxWidth={150}
              content="Reset Password">
              <button onClick={() => setOpenResetPwdModal(true)}>
                <RefreshIcon className="w-5 h-5 hover:text-orange-400" />
              </button>
            </Tooltip>
            <Alert isOpen={openResetPwdModal}>
              <Alert.Title>Reset Password</Alert.Title>
              <Alert.Content>
                Are you sure you want to reset the password for (
                <strong>{admin.name}</strong>)?
              </Alert.Content>
              {updateAdminError && (
                <div role="alert" aria-live="polite" className="text-red-500">
                  {updateAdminError.message}
                </div>
              )}
              <Alert.Cancel onClick={() => setOpenResetPwdModal(false)}>
                Cancel
              </Alert.Cancel>
              <Alert.Confirm
                loading={isUpdating}
                onClick={() => {
                  updateAdmin(
                    {
                      id: admin.id,
                      resetToken: uuid().replaceAll('-', '')
                    },
                    {
                      onSuccess() {
                        setOpenResetPwdModal(false)
                      }
                    }
                  )
                }}>
                Yes
              </Alert.Confirm>
            </Alert>
          </>
        )}
      </Table.Data>
      <Table.Data align="center">
        <div className="cursor-pointer hover:text-orange-400">
          {admin.alerts?.toString()}
        </div>
      </Table.Data>
      <Table.Data>
        <Link
          className="text-center cursor-pointer hover:text-orange-400"
          to={`/admins/${admin.id}/edit`}>
          <PencilIcon className="w-5 h-5 mx-auto" />
        </Link>
      </Table.Data>
      <Table.Data>
        <button
          className="flex items-center justify-center mx-auto cursor-pointer hover:text-red-500"
          onClick={() => setOpenDeleteModal(true)}>
          <TrashIcon className="w-5 h-5" />
        </button>
        <Alert variant="danger" isOpen={openDeleteModal}>
          <Alert.Title>Delete Admin User</Alert.Title>
          <Alert.Content>
            <div className="space-y-2">
              <div>
                Are you sure you want to delete this user (
                <strong className="font-medium text-gray-700">
                  {admin.name}
                </strong>
                )? This action cannot be undone.
              </div>
              {deleteAdminError && (
                <div className="text-red-500" role="alert" aria-live="polite">
                  {deleteAdminError.message}
                </div>
              )}
            </div>
          </Alert.Content>
          <Alert.Cancel onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Alert.Cancel>
          <Alert.Confirm
            leftIcon={<ExclamationCircleIcon />}
            loading={isDestroying}
            onClick={() => {
              deleteAdmin(
                { id: admin.id },
                {
                  onSuccess() {
                    setOpenDeleteModal(false)
                  }
                }
              )
            }}>
            Delete Forever
          </Alert.Confirm>
        </Alert>
      </Table.Data>
    </Table.Row>
  )
}
