import { useEffect } from 'react'

export function useKeyShortcut(cb: () => void) {
  useEffect(() => {
    const onKeydown = (evt: KeyboardEvent) => {
      if (
        (evt.metaKey && evt.key === 'k') ||
        (evt.ctrlKey && evt.key === 'k')
      ) {
        cb()
      }
    }
    document.addEventListener('keydown', onKeydown)
    return () => {
      document.removeEventListener('keydown', onKeydown)
    }
  }, [cb])
}
