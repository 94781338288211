import {
  AdapterQueryVariables,
  useAdapterQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

export const adapterFragment = gql`
  fragment MetadataResourceFields on MdResource {
    name
    objects {
      name
      description
    }
    classes {
      name
      fields
    }
  }
  fragment adapterFields on Adapter {
    id
    adapterType: adapter_type
    key
    url
    username
    password
    retsVersion: rets_version
    status
    userAgent: user_agent
    userAgentPassword: user_agent_password
    requestTimeZone: request_time_zone
    responseTimeZone: response_time_zone
    currentJob: current_job
    authEndpoint: auth_endpoint
    scope
    updateDom: update_dom
    esVersion: es_version
    esStatus: es_status
    recordCounts: record_counts {
      today
      listings
      agents
      offices
    }
  }
  fragment MetadataFields on Metadata {
    systemInfo: system_info {
      connected
      date
      systemDescription: system_description
      systemId: system_id
      timezoneOffset: timezone_offset
      version
    }
    resources {
      ...MetadataResourceFields
    }
    logs
  }
`

gql`
  query Adapter($id: ID) {
    adapter(id: $id) {
      ...adapterFields
      updatesByHour: updates_by_hour
      alarms(active: true) {
        id
        description
        createdAtIso: created_at_iso
      }
      resourceGroups: resource_groups {
        id
        role
        status
        indexData: index_data
      }
      resources {
        id
        name
      }
      listingServices: listing_services {
        id
        key
        name
        address
        timeZone: time_zone
        lat
        lon
        state
        defaultSearch: default_search
        flags {
          key
          value
        }
        activations(limit: 10000) {
          id
          apiUser: api_user {
            id
            name
          }
        }
      }
    }
  }
  ${adapterFragment}
`

export function useAdapter(vars: AdapterQueryVariables) {
  return useAdapterQuery(vars, { enabled: !!vars.id })
}
