import { HomeIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { Link, useMatch } from 'react-router-dom'

export function Breadcrumbs({ children }: PropsWithChildren<unknown>) {
  return (
    <nav
      className="sticky z-20 flex overflow-x-auto bg-white rounded-lg shadow top-16"
      aria-label="Breadcrumb">
      <ol className="flex w-full max-w-screen-xl px-4 space-x-4 sm:px-6 lg:px-8">
        <li className="flex items-center">
          <HomeIcon
            className="flex-shrink-0 w-5 h-5 mr-2 text-gray-500"
            aria-hidden="true"
          />
          <Link
            to="/"
            className="text-sm font-medium text-gray-500 hover:text-orange-400 hover:underline">
            Forge Dashboard
          </Link>
        </li>
        {children}
      </ol>
    </nav>
  )
}

Breadcrumbs.Crumb = Breadcrumb

interface BreadcrumbProps {
  to: string
}

function Breadcrumb({ to, children }: PropsWithChildren<BreadcrumbProps>) {
  const isCurrent = useMatch(to)
  return (
    <li className="flex">
      <svg
        className="flex-shrink-0 w-6 h-full text-gray-200"
        viewBox="0 0 24 44"
        preserveAspectRatio="none"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true">
        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
      </svg>
      <Link
        to={to}
        className={classNames(
          'group ml-4 flex items-center rounded-md py-2 text-sm font-medium capitalize',
          {
            'cursor-default text-orange-400 hover:underline': isCurrent,
            'text-gray-600 hover:text-orange-400 hover:underline': !isCurrent
          }
        )}
        aria-current={isCurrent ? 'page' : undefined}>
        {children}
      </Link>
    </li>
  )
}
