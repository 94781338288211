import { useResetPasswordMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation resetPassword(
    $resetToken: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      reset_token: $resetToken
      password: $password
      password_confirmation: $passwordConfirmation
    ) {
      id
      email
      name
      phone
      role
    }
  }
`

export function useResetPassword() {
  const queryClient = useQueryClient()
  return useResetPasswordMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['Admins'])
    }
  })
}
