import {
  ResourceHealthQuery,
  ResourceHealthQueryVariables,
  useResourceHealthQuery
} from '@forge/graphql/generated'
import { UseQueryOptions } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  query ResourceHealth($id: ID!) {
    resource: resource(id: $id) {
      id
      name
      updateData: update_data
      health {
        audit
        auditResolution: audit_resolution
        monthlyAudit: monthly_audit
        sync
        weeklyAudit: weekly_audit
      }
    }
  }
`

export function useResourceHealth(
  vars: ResourceHealthQueryVariables,
  options?: Omit<
    UseQueryOptions<ResourceHealthQuery>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined
  }
) {
  return useResourceHealthQuery(vars, options)
}
