import { useLoginMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useLocation, useNavigate } from 'react-router-dom'
import { CurrentUser } from './useCurrentUser'
import { getAvatar, setPersistedCurrentUser } from './utils'

gql`
  fragment AdminUser on Admin {
    id
    name
    email
    role
  }

  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      admin {
        ...AdminUser
      }
    }
  }
`

export function useLogin() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()

  return useLoginMutation({
    onSuccess: async (data) => {
      if (!data.login) {
        throw new Error('Invalid username or password')
      }

      const token = data.login.token
      const currentUser = data.login.admin

      if (currentUser && token) {
        const avatar = await getAvatar(currentUser.email)
        const currentUserWithAdditionalFields = {
          token,
          avatar,
          ...currentUser
        }
        setPersistedCurrentUser(currentUserWithAdditionalFields)

        queryClient.setQueryData<CurrentUser>(
          ['CurrentUser'],
          currentUserWithAdditionalFields
        )

        // Redirect back to intended page or to the home page
        navigate((location?.state as { from?: string })?.from ?? '/')
      }
    }
  })
}
