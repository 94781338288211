import { Button, Combobox, FormField, Input, Select } from '@forge/common'
import {
  agentAliasOptions,
  listingAliasOptions,
  officeAliasOptions
} from '@forge/features/field/utils'
import {
  FieldTemplateFieldCategoryName,
  FieldTemplateRecordType
} from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useCreateFieldTemplate } from './useCreateFieldTemplate'

const schema = yup.object({
  displayName: yup.string(),
  aliases: yup.array(yup.string().required()),
  alternateNames: yup.array(yup.string().required()),
  name: yup.string().required('Name is required'),
  fieldType: yup.string().required('Column Type is required'),
  recordType: yup
    .mixed<FieldTemplateRecordType>()
    .oneOf(Object.values(FieldTemplateRecordType)),
  fieldCategoryName: yup
    .mixed<FieldTemplateFieldCategoryName>()
    .oneOf(Object.values(FieldTemplateFieldCategoryName))
})

export function NewFieldTemplateForm() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })

  const {
    mutate: createFieldTemplate,
    isLoading: isCreating,
    error
  } = useCreateFieldTemplate()

  const recordType = watch('recordType')

  const aliasOptions = (
    recordType === 'agent'
      ? agentAliasOptions
      : recordType === 'listing'
      ? listingAliasOptions
      : recordType === 'office'
      ? officeAliasOptions
      : []
  ).map((option) => ({
    label: option,
    value: option
  }))

  const [altNamesOptions, setAltNamesOptions] = useState<
    { label: string; value: string }[]
  >([])

  return (
    <div>
      <form
        className="space-y-3"
        onSubmit={handleSubmit((data) => {
          createFieldTemplate(data, {
            onSuccess() {
              navigate('/field_templates')
            }
          })
        })}>
        <div className="w-full">
          <FormField
            label="Record Type"
            error={errors.recordType?.message}
            required>
            <Select {...register('recordType')}>
              <option value="">Select a Record Type</option>
              <option value="listing">listing</option>
              <option value="agent">agent</option>
              <option value="photo">photo</option>
              <option value="office">office</option>
            </Select>
          </FormField>
        </div>
        <div className="w-full">
          <FormField label="Category Name">
            <Select {...register('fieldCategoryName')}>
              <option value="">Select a Category Name</option>
              <option value="root">root</option>
              <option value="features">features</option>
              <option value="agent_list">agent_list</option>
              <option value="agent_sell">agent_sell</option>
              <option value="office_list">office_list</option>
              <option value="office_sell">office_sell</option>
              <option value="agent_only">agent_only</option>
              <option value="ignored">ignored</option>
              <option value="standard">standard</option>
            </Select>
          </FormField>
        </div>
        <div className="w-full">
          <FormField
            required
            label="Column Type"
            error={errors.fieldType?.message}>
            <Select {...register('fieldType')}>
              <option value="">Select a Column Type</option>
              <option value="array">array</option>
              <option value="boolean">boolean</option>
              <option value="date">date</option>
              <option value="geo_point">geo_point</option>
              <option value="geo_shape">geo_shape</option>
              <option value="keyword">keyword</option>
              <option value="integer">integer</option>
              <option value="float">float</option>
              <option value="text">text</option>
              <option value="long">long</option>
            </Select>
          </FormField>
        </div>
        <div className="w-full">
          <FormField required label="Name" error={errors.name?.message}>
            <Input placeholder="Enter Name" {...register('name')} />
          </FormField>
        </div>
        <div className="w-full">
          <FormField label="Display Name">
            <Input
              placeholder="Enter Display Name"
              {...register('displayName')}
            />
          </FormField>
        </div>
        <div className="w-full">
          <FormField label="Aliases">
            <Combobox
              multiple
              options={aliasOptions}
              selected={aliasOptions.filter((option) => {
                return watch('aliases')?.includes(option.value)
              })}
              onSelect={(options) => {
                setValue(
                  'aliases',
                  options.map((option) => option.value)
                )
              }}
            />
          </FormField>
        </div>
        <div className="w-full">
          <FormField label="Alternate Names">
            <Combobox
              multiple
              options={altNamesOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              selected={altNamesOptions.filter((option) => {
                return watch('alternateNames')?.includes(option.value)
              })}
              onCreateOption={({ label, value }) => {
                setAltNamesOptions([...altNamesOptions, { label, value }])
              }}
              onSelect={(options) => {
                setValue(
                  'alternateNames',
                  options.map((option) => option.value)
                )
              }}
            />
          </FormField>
        </div>
        <Button fullWidth loading={isCreating}>
          Create Field Template
        </Button>
        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
