import { Map, Tooltip } from '@forge/common'
import { ListingServicesListingServiceFragment } from '@forge/graphql/generated'
import { LocationMarkerIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

interface ListingServicesMapProps {
  listingServices: ListingServicesListingServiceFragment[] | undefined
}

export function ListingServicesMap({
  listingServices
}: ListingServicesMapProps) {
  return (
    <Map
      viewportState={{
        latitude: 39.45473,
        longitude: -95.788712,
        pitch: 50,
        zoom: 3.2
      }}>
      {listingServices?.map(({ id, lon, lat, key, name }) => (
        <Map.Marker key={id} latitude={lat || -1} longitude={lon || -1}>
          <Link
            to={`/listing_services/${id}/details`}
            className="cursor-pointer">
            <div className="w-6 text-orange-400 h-7 hover:text-orange-300">
              <Tooltip
                delay={700}
                placement="right"
                content={`${key?.toUpperCase()}: ${name}`}>
                <LocationMarkerIcon />
              </Tooltip>
            </div>
          </Link>
        </Map.Marker>
      ))}
      <Map.Zoom />
    </Map>
  )
}
