import {
  Attribute,
  formatCurrency,
  formatDate,
  formatNumber
} from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingPriceProps {
  price?: number | null
  priceListPerSqft?: number | null
  beds?: number | null
  baths?: number | null
  sqft?: number | null
  dateList?: string | null
  dateSold?: string | null
  priceListOrig?: number | null
  priceList?: number | null
  priceSold?: number | null
  fields: Record<string, FieldPartsFragment>
  adapterId: string
  resourceGroupId: string
}

export function ListingPrice({
  price,
  priceListPerSqft,
  beds,
  baths,
  sqft,
  dateList,
  dateSold,
  priceListOrig,
  priceList,
  priceSold,
  fields,
  adapterId,
  resourceGroupId
}: ListingPriceProps) {
  return (
    <div className="divide-y [&>div:first-child]:pt-0 [&>div:last-child]:pb-0">
      <div className="flex items-baseline gap-3 py-3">
        <div className="text-3xl font-semibold text-gray-900">
          <span className="p-2 hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['price']?.id}
              label="price"
              resourceGroupId={resourceGroupId}
              value={formatCurrency(price || 0)}
            />
          </span>
        </div>
        <div className="text-sm text-gray-700">
          <span className="p-2 hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['price_list_per_sqft']?.id}
              label="price_list_per_sqft"
              resourceGroupId={resourceGroupId}
              value={formatCurrency(priceListPerSqft || 0)}
            />{' '}
            / sqft
          </span>
        </div>
      </div>
      <div className="flex gap-3 py-3">
        <div>
          <span className="font-semibold text-gray-900">
            <div className="hover:cursor-pointer">
              <Attribute
                adapterId={adapterId}
                fieldId={fields?.['beds']?.id}
                label="beds"
                resourceGroupId={resourceGroupId}
                value={formatNumber(beds || 0)}
              />{' '}
              <span className="font-normal">beds</span>
            </div>
          </span>
        </div>
        <div>
          <span className="font-semibold text-gray-900">
            <div className="hover:cursor-pointer">
              <Attribute
                adapterId={adapterId}
                fieldId={fields?.['baths']?.id}
                label="baths"
                resourceGroupId={resourceGroupId}
                value={formatNumber(baths || 0)}
              />{' '}
              <span className="font-normal">baths</span>
            </div>
          </span>
        </div>
        <div>
          <span className="font-semibold text-gray-900">
            <div className="hover:cursor-pointer">
              <Attribute
                adapterId={adapterId}
                fieldId={fields?.['sqft']?.id}
                label="sqft"
                resourceGroupId={resourceGroupId}
                value={formatNumber(sqft || 0)}
              />{' '}
              <span className="font-normal">sqft</span>
            </div>
          </span>
        </div>
      </div>
      <div className="flex gap-3 py-3">
        <div className="text-xs text-gray-700">
          <div className="hover:cursor-pointer">
            Listed:{' '}
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['date_list']?.id}
              label="date_list"
              resourceGroupId={resourceGroupId}
              value={formatDate(dateList || 0, 'MM/dd/yyyy')}
            />
          </div>
        </div>
        <div className="text-xs text-gray-700">
          <div className="hover:cursor-pointer">
            Sold:{' '}
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['date_sold']?.id}
              label="date_sold"
              resourceGroupId={resourceGroupId}
              value={formatDate(dateSold || 0, 'MM/dd/yyyy')}
            />
          </div>
        </div>
      </div>
      <div className="divide-y p-3 [&>div:first-child]:pt-0 [&>div:last-child]:pb-0">
        <div className="flex items-center justify-between gap-3 py-3">
          <div className="text-sm text-gray-800">Original List Price</div>
          <div className="font-bold text-gray-900">
            <span className="hover:cursor-pointer">
              <Attribute
                adapterId={adapterId}
                fieldId={fields?.['price_list_orig']?.id}
                label="price_list_orig"
                resourceGroupId={resourceGroupId}
                value={formatCurrency(priceListOrig || 0)}
              />
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between gap-3 py-3">
          <div className="text-sm text-gray-800">List Price</div>
          <div className="font-bold text-gray-900">
            <span className="hover:cursor-pointer">
              <Attribute
                adapterId={adapterId}
                fieldId={fields?.['price_list']?.id}
                label="price_list"
                resourceGroupId={resourceGroupId}
                value={formatCurrency(priceList || 0)}
              />
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between gap-3 py-3">
          <div className="text-sm text-gray-800">Sold Price</div>
          <div className="font-bold text-gray-900">
            <span className="hover:cursor-pointer">
              <Attribute
                adapterId={adapterId}
                fieldId={fields?.['price_sold']?.id}
                label="price_sold"
                resourceGroupId={resourceGroupId}
                value={formatCurrency(priceSold || 0)}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
