import { Spinner, toTitleCase } from '@forge/common'
import { useResourceGroup } from '@forge/features/resourcegroups'
import { Search } from '@forge/features/search'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function ResourceGroupSearch() {
  const { adapterId, resourceGroupId } = useParams()

  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  return (
    <>
      <Helmet>
        <title>{toTitleCase(resourceGroup?.role || '')} Search</title>
      </Helmet>

      {!!adapterId && !!resourceGroupId && (
        <Suspense
          fallback={
            <div className="flex w-full p-5">
              <div className="w-5 h-5 mx-auto text-orange-400 ">
                <Spinner />
              </div>
            </div>
          }>
          <Search adapterId={adapterId} resourceGroupId={resourceGroupId} />
        </Suspense>
      )}
    </>
  )
}
