import { Attribute, SearchFilterInput, Table, useDebounce } from '@forge/common'
import { useResourceGroupFields } from '@forge/features/resourcegroups'
import { ResourceRecordDataType } from '@forge/features/setup'
import {
  DefaultSchemaPartsFragment,
  FieldPartsFragment
} from '@forge/graphql/generated'
import { get, merge } from 'lodash'
import { ChangeEvent, useState } from 'react'

const filterFields = (
  fields: FieldPartsFragment[] | undefined | null,
  searchTerm: string
) => {
  return fields?.filter((field) =>
    JSON.stringify(field)?.toLowerCase().includes(searchTerm?.toLowerCase())
  )
}

const filterDefaultSchema = (
  defaultSchema: DefaultSchemaPartsFragment[] | undefined | null,
  searchTerm: string
) => {
  if (!defaultSchema) return

  return defaultSchema?.filter((field) =>
    JSON.stringify(field)?.toLowerCase().includes(searchTerm?.toLowerCase())
  )
}

interface DefaultSchemaFieldsProps {
  adapterId: string
  defaultSchema?: DefaultSchemaPartsFragment[] | null
  mode: string
  previous?: ResourceRecordDataType
  record: ResourceRecordDataType
  resourceGroupId: string
}

export const DefaultSchemaFields = ({
  adapterId,
  defaultSchema,
  mode,
  record,
  resourceGroupId
}: DefaultSchemaFieldsProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const { data: { resourceGroupFields } = {} } = useResourceGroupFields({
    resourceGroupId
  })

  const fields = resourceGroupFields?.fields?.sort((a, b) =>
    (a?.displayName || '').localeCompare(b?.displayName || '')
  )

  const filteredFields = filterFields(fields, debouncedSearchTerm)
  const filteredDefaultSchema = filterDefaultSchema(
    defaultSchema,
    debouncedSearchTerm
  )

  const filteredFieldsMap =
    filteredFields?.reduce<Record<string, FieldPartsFragment>>(
      (fields, field) => {
        const key = field.aliases?.[0] || field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}
  const filteredDefaultSchemaMap =
    filteredDefaultSchema?.reduce<Record<string, DefaultSchemaPartsFragment>>(
      (fields, field) => {
        const key = field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}

  const mergedFields = merge(filteredFieldsMap, filteredDefaultSchemaMap)
  // ! We coerce the record to a generic record so we can dynamically find properties which aren't present
  const genericRecordField = record as Record<string, string>

  const displayFor = (field: {
    id?: string | null
    sourceNames?: string[] | null
    standardName?: string | null
  }) => {
    if (
      (mode === 'standardName' && field.standardName === null) ||
      (mode === 'sourceNames' && !field.sourceNames?.length) ||
      (mode === 'path' && field.id === null)
    ) {
      return 'none'
    }
  }

  return (
    <>
      <div className="mb-4 flex justify-end space-x-2">
        <div className="w-full md:max-w-[30%]">
          <SearchFilterInput
            value={searchTerm}
            placeholder="Search Fields"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
        </div>
      </div>
      <div className="max-h-[calc(100vh-13.75rem)] overflow-auto rounded-lg bg-white shadow">
        {/* API Response Tab */}
        {mode === 'path' && (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Key</Table.Header>
                <Table.Header align="left">Value</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {filteredDefaultSchema?.map((field, id) => {
                const [parentKey, childKey] = field.path?.split('.') || [
                  field.path
                ]
                const parent = parseData(
                  get(genericRecordField, `[${parentKey}]`)
                )
                const child = get(parent, `[${childKey}]`)

                return (
                  <Table.Row key={id} style={{ display: displayFor(field) }}>
                    <Table.Data>{field.path}</Table.Data>
                    <Table.Data>
                      <div className="overflow-x-auto whitespace-normal xs:max-w-[8rem] sm:max-w-xs md:max-w-sm">
                        <Attribute
                          adapterId={adapterId}
                          fieldId={mergedFields[field.colName || '']?.id}
                          label={field.path || 'N/A'}
                          resourceGroupId={resourceGroupId}
                          value={typeof parent === 'object' ? child : parent}
                        />
                      </div>
                    </Table.Data>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        )}

        {/* Data Dictionary Tab */}
        {mode === 'standardName' && (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Standard Name</Table.Header>
                <Table.Header align="left">Value</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {filteredDefaultSchema?.map((field, id) => (
                <Table.Row key={id} style={{ display: displayFor(field) }}>
                  <Table.Data>{field?.standardName}</Table.Data>
                  <Table.Data>
                    <div className="overflow-x-auto whitespace-normal xs:max-w-[8rem] sm:max-w-xs md:max-w-sm">
                      <Attribute
                        adapterId={adapterId}
                        resourceGroupId={resourceGroupId}
                        fieldId={
                          mergedFields[field.colName || '']?.id ||
                          mergedFields[field.standardName || '']?.id
                        }
                        value={genericRecordField[field.standardName || '']}
                        label={field.standardName || 'N/A'}
                      />
                    </div>
                  </Table.Data>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        {/* Raw Field Data Tab */}
        {mode === 'sourceNames' && (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Source Name</Table.Header>
                <Table.Header align="left">Value</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {filteredFields?.map((field, id) => (
                <Table.Row key={id} style={{ display: displayFor(field) }}>
                  <Table.Data>
                    <div className="overflow-auto xs:max-w-[8rem] sm:max-w-xs md:max-w-sm xl:max-w-md">
                      {field?.sourceNames?.join(', ') || 'N/A'}
                    </div>
                  </Table.Data>
                  <Table.Data>
                    <div className="overflow-x-auto whitespace-normal xs:max-w-[8rem] sm:max-w-xs md:max-w-sm xl:max-w-md">
                      <Attribute
                        adapterId={adapterId}
                        resourceGroupId={resourceGroupId}
                        fieldId={field.id}
                        value={
                          genericRecordField[
                            field.sourceNames?.join(', ') || ''
                          ]
                        }
                        label={field.sourceNames?.join(', ') || 'N/A'}
                      />
                    </div>
                  </Table.Data>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  )
}

function parseData(data?: string | number | boolean) {
  try {
    return JSON.parse(String(data))
  } catch (error) {
    return data
  }
}
