import { Alert } from '@forge/common'
import { FieldQuery } from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useDestroyAllLookups } from './useDestroyAllLookups'

interface DestroyAllLookupsProps {
  fieldId: string
  isOpen: boolean
  onClose: () => void
}

export function DestroyAllLookups({
  fieldId,
  isOpen,
  onClose
}: DestroyAllLookupsProps) {
  const queryClient = useQueryClient()
  const {
    mutate: destroyAllLookups,
    isLoading: isDestroying,
    error
  } = useDestroyAllLookups()

  return (
    <Alert variant="danger" isOpen={isOpen}>
      <Alert.Title>Delete All Lookups</Alert.Title>
      <Alert.Content>
        <div className="space-y-2">
          <div>
            Are you sure you want to delete all lookups? This action cannot be
            undone.
          </div>
          {error && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {error.message}
            </div>
          )}
        </div>
      </Alert.Content>
      <Alert.Cancel onClick={onClose}>Cancel</Alert.Cancel>
      <Alert.Confirm
        leftIcon={<ExclamationCircleIcon />}
        loading={isDestroying}
        onClick={() => {
          destroyAllLookups(
            { fieldId },
            {
              onSuccess() {
                onClose()

                const field = queryClient.getQueryData<FieldQuery>([
                  'Field',
                  { id: String(fieldId) }
                ])

                if (field) {
                  queryClient.setQueryData(['Field', { id: String(fieldId) }], {
                    ...field,
                    field: {
                      ...field.field,
                      lookups: []
                    }
                  })

                  queryClient.invalidateQueries([
                    'Field',
                    { id: String(fieldId) }
                  ])
                }
              }
            }
          )
        }}>
        Delete Forever
      </Alert.Confirm>
    </Alert>
  )
}
