import {
  Button,
  Diff,
  Menu,
  SearchFilterInput,
  Table,
  useDebounce
} from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'
import {
  CloudUploadIcon,
  DocumentTextIcon,
  DotsHorizontalIcon,
  PencilAltIcon
} from '@heroicons/react/outline'
import { ClipboardListIcon, PlusIcon } from '@heroicons/react/solid'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { FieldActionsMenu } from '../field/FieldActionsMenu'
import { ResourceGroupFieldsTableEditRow } from './ResourceGroupFieldsTableEditRow'
import { useResourceGroup } from './useResourceGroup'
import { useResourceGroupFields } from './useResourceGroupFields'

interface ResourceGroupFieldsTableProps {
  adapterId: string
  resourceGroupId: string
}

const filterFields = (
  fields: FieldPartsFragment[] | undefined | null,
  searchTerm: string
) => {
  return fields?.filter((field) =>
    JSON.stringify(field)?.toLowerCase().includes(searchTerm?.toLowerCase())
  )
}

const draftFields = (
  fields: FieldPartsFragment[] | undefined | null,
  searchTerm: string
) => {
  return fields?.filter(
    (field) =>
      field?.isDraft === true &&
      JSON.stringify(field)?.toLowerCase().includes(searchTerm?.toLowerCase())
  )
}

export function ResourceGroupFieldsTable({
  adapterId,
  resourceGroupId
}: ResourceGroupFieldsTableProps) {
  const [editing, setEditing] = useState(false)
  const [showDrafts, setShowDrafts] = useState(false)

  const queryClient = useQueryClient()
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: String(resourceGroupId)
  })
  const { data: { resourceGroupFields } = {} } = useResourceGroupFields({
    resourceGroupId: String(resourceGroupId)
  })
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const Fields = resourceGroupFields?.fields
  const filteredFields = filterFields(Fields, debouncedSearchTerm)
  const draftedFields = draftFields(Fields, debouncedSearchTerm)

  const toggleDrafts = async () => {
    if (showDrafts) await queryClient.invalidateQueries(['ResourceGroupFields'])
    setShowDrafts(!showDrafts)
  }

  const toggleEditing = async () => {
    if (editing) await queryClient.invalidateQueries(['ResourceGroupFields'])
    setEditing(!editing)
  }

  return (
    <div>
      <div className="mb-4 flex justify-end space-x-2">
        <div className="w-full md:max-w-[30%]">
          <div className="float-right -mt-[4.5rem]">
            <Menu
              button={
                <Button as="span" aria-label="Fields Options">
                  <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                </Button>
              }>
              <Menu.Item
                icon={<PlusIcon />}
                as={Link}
                to={`/adapters/${adapterId}/resource_groups/${resourceGroupId}/fields/new`}>
                Add Field
              </Menu.Item>
              {resourceGroup?.status !== 'stable' && (
                <Menu.Item
                  as="button"
                  icon={
                    showDrafts ? <ClipboardListIcon /> : <DocumentTextIcon />
                  }
                  onClick={() => toggleDrafts()}>
                  {showDrafts ? (
                    <p>Show All Fields</p>
                  ) : (
                    <p>Show Draft Fields</p>
                  )}
                </Menu.Item>
              )}

              <Menu.Item
                as="button"
                icon={editing ? <CloudUploadIcon /> : <PencilAltIcon />}
                onClick={() => toggleEditing()}>
                {editing ? `Apply Changes` : `Bulk Edit`}
              </Menu.Item>
            </Menu>
          </div>
          <SearchFilterInput
            value={searchTerm}
            placeholder="Search Fields"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
        </div>
      </div>

      <div className="max-h-[calc(100vh-22rem)] min-h-[25rem] space-y-2 overflow-auto rounded-lg bg-white shadow">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Display Name</Table.Header>
              <Table.Header align="center">Source Names</Table.Header>
              <Table.Header align="center">Aliases</Table.Header>
              <Table.Header align="center">Category</Table.Header>
              <Table.Header align="center">Type</Table.Header>
              <Table.Header align="center">Searchable</Table.Header>
              {!editing && <Table.Header align="center">Actions</Table.Header>}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {!showDrafts && filteredFields?.length === 0 ? (
              <Table.Row>
                <Table.Data colSpan={8}>
                  <p className="p-4 text-center text-lg">
                    No results found {debouncedSearchTerm && 'for'}
                    {debouncedSearchTerm && (
                      <span className="font-bold text-orange-400">
                        {' '}
                        "{debouncedSearchTerm}"{' '}
                      </span>
                    )}
                    in All Fields.
                  </p>
                </Table.Data>
              </Table.Row>
            ) : (
              !showDrafts &&
              filteredFields?.map((field) => {
                return (
                  <Table.Row key={field?.id}>
                    {editing ? (
                      <ResourceGroupFieldsTableEditRow
                        role={resourceGroup?.role || undefined}
                        field={field}
                        resourceGroupId={resourceGroupId}
                      />
                    ) : (
                      <>
                        <Table.Data>
                          <div
                            className={classNames(
                              'w-22 absolute top-0 left-0 h-full overflow-auto',
                              {
                                'bg-yellow-500': field?.isDraft
                              }
                            )}
                          />
                          <div className="flex justify-center pb-2">
                            {field?.pendingChanges?.displayName ? (
                              <Diff
                                value={field?.displayName || ''}
                                newValue={field?.pendingChanges?.displayName}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          {field?.displayName}
                        </Table.Data>
                        <Table.Data align="center">
                          <div className="mx-auto w-32 overflow-auto">
                            <Diff
                              value={field?.sourceNames?.join(', ')}
                              newValue={field?.pendingChanges?.sourceNames?.join(
                                ', '
                              )}
                            />
                          </div>
                        </Table.Data>
                        <Table.Data align="center">
                          <div className="mx-auto w-20 overflow-auto">
                            {field?.aliases?.join(', ') || ''}
                          </div>
                        </Table.Data>
                        <Table.Data align="center">
                          <Diff
                            value={field?.fieldCategory?.name}
                            newValue={
                              field?.pendingChanges?.fieldCategory?.name
                            }
                          />
                        </Table.Data>
                        <Table.Data align="center">
                          <Diff
                            value={field?.colType}
                            newValue={field?.pendingChanges?.colType}
                          />
                        </Table.Data>
                        <Table.Data align="center">
                          {(field?.queryable && 'Yes') || 'No'}
                        </Table.Data>
                        {editing ? (
                          <Table.Data align="center"></Table.Data>
                        ) : (
                          <Table.Data align="center">
                            <FieldActionsMenu
                              adapterId={adapterId}
                              resourceGroupId={resourceGroupId}
                              field={field}
                            />
                          </Table.Data>
                        )}
                      </>
                    )}
                  </Table.Row>
                )
              })
            )}
            {showDrafts && draftedFields?.length === 0 ? (
              <Table.Row>
                <Table.Data colSpan={8}>
                  <p className="p-4 text-center text-lg">
                    No results found {debouncedSearchTerm && 'for'}
                    {debouncedSearchTerm && (
                      <span className="font-bold text-orange-400">
                        {' '}
                        "{debouncedSearchTerm}"{' '}
                      </span>
                    )}
                    in Draft Fields.
                  </p>
                </Table.Data>
              </Table.Row>
            ) : (
              showDrafts &&
              draftedFields?.map((field) => {
                return (
                  <Table.Row key={field?.id}>
                    {editing ? (
                      <ResourceGroupFieldsTableEditRow
                        role={resourceGroup?.role || undefined}
                        field={field}
                        resourceGroupId={resourceGroupId}
                      />
                    ) : (
                      <>
                        <Table.Data>
                          <div
                            className={classNames(
                              'w-22 absolute top-0 left-0 h-full overflow-auto',
                              {
                                'bg-yellow-500': field?.isDraft
                              }
                            )}
                          />
                          {field?.displayName}
                        </Table.Data>
                        <Table.Data align="center">
                          <div className="mx-auto w-32 overflow-auto">
                            <Diff
                              value={field?.sourceNames?.join(', ')}
                              newValue={field?.pendingChanges?.sourceNames?.join(
                                ', '
                              )}
                            />
                          </div>
                        </Table.Data>
                        <Table.Data align="center">
                          <div className="mx-auto w-20 overflow-auto">
                            {field?.aliases?.join(', ') || ''}
                          </div>
                        </Table.Data>
                        <Table.Data align="center">
                          <Diff
                            value={field?.fieldCategory?.name}
                            newValue={
                              field?.pendingChanges?.fieldCategory?.name
                            }
                          />
                        </Table.Data>
                        <Table.Data align="center">
                          <Diff
                            value={field?.colType}
                            newValue={field?.pendingChanges?.colType}
                          />
                        </Table.Data>
                        <Table.Data align="center">
                          {(field?.queryable && 'Yes') || 'No'}
                        </Table.Data>
                        {editing ? (
                          <Table.Data align="center"></Table.Data>
                        ) : (
                          <Table.Data align="center">
                            <FieldActionsMenu
                              adapterId={adapterId}
                              resourceGroupId={resourceGroupId}
                              field={field}
                            />
                          </Table.Data>
                        )}
                      </>
                    )}
                  </Table.Row>
                )
              })
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
