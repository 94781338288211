import { Avatar } from '@forge/common'
import { useCurrentUser } from '@forge/features/auth'

export function Welcome() {
  const { data: currentUser } = useCurrentUser()

  return (
    <div className="rounded-lg bg-white p-6 shadow">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:space-x-5">
          <div className="flex xs:justify-center">
            <Avatar
              src={currentUser?.avatar || undefined}
              name={currentUser?.name || undefined}
              size="3xl"
            />
          </div>
          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p className="text-sm font-medium text-gray-600">Welcome back,</p>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl">
              {currentUser?.name}
            </p>
            <p className="text-sm font-medium capitalize text-gray-600">
              {currentUser?.role} Admin
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
