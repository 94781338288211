import {
  DashboardAlarmsQueryVariables,
  useDashboardAlarmsQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query DashboardAlarms($active: Boolean) {
    alarms(active: $active, limit: 1000) {
      id
      active
      description
      createdAtIso: created_at_iso
      clearedAtIso: cleared_at_iso
      urlPath: url_path
      alarmable {
        __typename
        ... on Adapter {
          id
          key
        }
        ... on ListingService {
          id
          key
        }
      }
    }
  }
`
export function useDashboardAlarms(vars?: DashboardAlarmsQueryVariables) {
  return useDashboardAlarmsQuery(vars)
}
