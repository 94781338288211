import { Button, FormField } from '@forge/common'
import { BeakerIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import Editor from '@monaco-editor/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useListingService } from './useListingService'
import { useUpdateListingService } from './useUpdateListingService'

const schema = yup.object({
  config: yup.string().required()
})

export function ListingServiceConfigEditor() {
  const { id } = useParams()
  const listingServiceId = id
  const { data: { listingService } = {} } = useListingService({
    id: listingServiceId
  })
  const [hasSyntaxError, setHasSyntaxError] = useState(false)
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      config: listingService?.config || undefined
    }
  })
  const {
    mutate: updateListingService,
    isLoading,
    error
  } = useUpdateListingService()

  const [hasUpdated, setHasUpdated] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasUpdated(false)
    }, 3000)
    return () => {
      clearTimeout(timeout)
    }
  }, [hasUpdated])

  return (
    <form
      className="rounded-lg bg-white pt-4 shadow"
      onSubmit={handleSubmit((data) => {
        if (listingServiceId) {
          updateListingService(
            {
              id: listingServiceId,
              config: JSON.stringify(JSON.parse(data?.config), null, 2)
            },
            {
              onSuccess() {
                setHasUpdated(true)
              }
            }
          )
        }
      })}>
      <FormField error={errors.config?.message}>
        <Editor
          height="60vh"
          language="json"
          defaultValue={listingService?.config || ''}
          onChange={(val) => setValue('config', val || '')}
          onMount={(_, monaco) => {
            monaco.editor.defineTheme('forge', {
              base: 'vs',
              inherit: true,
              rules: [],
              colors: {
                'scrollbar.shadow': '#ffffff',
                'widget.shadow': '#ffffff',
                'editor.background': '#ffffff',
                'editorCursor.foreground': '#fb923c'
              }
            })
            monaco.editor.setTheme('forge')
          }}
          onValidate={(markers) => setHasSyntaxError(!!markers.length)}
          options={{
            scrollBeyondLastLine: false,
            minimap: {
              enabled: false
            },
            fontSize: 14,
            suggestFontSize: 14,
            tabSize: 2,
            multiCursorModifier: 'ctrlCmd'
          }}
        />
      </FormField>
      <div className="space-y-4 p-4">
        <Button
          variant={!error ? 'primary' : 'danger'}
          loading={isLoading}
          disabled={hasSyntaxError || !watch('config')}
          leftIcon={<BeakerIcon />}>
          Update Listing Service Config
        </Button>
        {hasUpdated && (
          <p role="alert" aria-live="polite" className="text-sm text-green-600">
            Config updated!
          </p>
        )}
        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </div>
    </form>
  )
}
