import { MinusIcon, PlusIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { LngLatBounds } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { PropsWithChildren, useEffect, useState } from 'react'
import ReactMapGL, {
  MapProvider,
  Marker,
  useMap,
  ViewState
} from 'react-map-gl'
import { formatCurrencyShort } from './utils'

interface MapProps {
  viewportState?: Partial<ViewState> & {
    bounds?: mapboxgl.LngLatBoundsLike | undefined
    fitBoundsOptions?: mapboxgl.FitBoundsOptions | undefined
  }
  onClick?: () => void
}

export function Map({
  viewportState,
  onClick,
  children
}: PropsWithChildren<MapProps>) {
  const [error, setError] = useState<string>()

  return (
    <MapProvider>
      <div className="h-full w-full space-y-1">
        <ReactMapGL
          id="map"
          attributionControl={false}
          scrollZoom={false}
          initialViewState={
            viewportState || {
              fitBoundsOptions: { padding: 200 },
              latitude: 33.65804,
              longitude: -118.00156,
              pitch: 50,
              zoom: 18
            }
          }
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          onRender={(e) => e.target.resize()}
          onClick={onClick}
          onError={(error) => setError(error.error.message)}>
          {children}
        </ReactMapGL>
        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error}
          </p>
        )}
      </div>
    </MapProvider>
  )
}

Map.fitBounds = fitBounds
Map.Listing = MapListing
Map.Marker = Marker
Map.Zoom = Zoom

function Zoom() {
  const { map } = useMap()
  const minZoom = map?.getMinZoom() || 0
  const maxZoom = map?.getMaxZoom() || 0
  const currentZoom = map?.getZoom() || 0

  const [, forceUpdate] = useState({})

  useEffect(() => {
    map?.on('zoom', forceUpdate)
    return () => {
      map?.off('zoom', forceUpdate)
    }
  }, [map])

  return (
    <div className="absolute bottom-0 right-0 m-4 divide-y overflow-hidden rounded bg-white shadow">
      <button
        aria-label="Zoom In"
        className="block p-2 text-gray-500 hover:bg-gray-100"
        disabled={currentZoom >= maxZoom}
        onClick={() => map?.zoomIn()}>
        <PlusIcon className="h-6 w-6" />
      </button>
      <button
        aria-label="Zoom Out"
        className="block p-2 text-gray-500 hover:bg-gray-100"
        disabled={currentZoom <= minZoom}
        onClick={() => map?.zoomOut()}>
        <MinusIcon className="h-6 w-6" />
      </button>
    </div>
  )
}

function fitBounds(latLngs: [lng: number, lat: number][]) {
  const bounds = new LngLatBounds()

  latLngs.forEach((latLngs) => {
    bounds.extend(latLngs)
  })

  return bounds
}

interface MapListingProps {
  hide?: boolean
  lat: number
  lon: number
  mappedStatus?: string
  onClick?: () => void
  price?: number
  statusColor?: string
}

function MapListing({
  hide,
  lat,
  lon,
  mappedStatus,
  onClick,
  price,
  statusColor
}: MapListingProps) {
  const { map } = useMap()

  return (
    <Marker
      latitude={lat || 33.65804}
      longitude={lon || -118.00156}
      offset={[0, -11]}
      style={{ zIndex: hide ? 0 : 1 }}>
      <button
        style={
          !hide
            ? { backgroundColor: statusColor, color: statusColor || 'darkgray' }
            : {}
        }
        className={classNames(
          'relative rounded border-2 border-white px-1 text-sm font-medium text-white [text-shadow:0.08rem_0.08rem_0_rgba(0,0,0,0.2)]',
          'before:absolute before:left-1/2 before:-bottom-0.5 before:block before:h-2 before:w-2 before:-translate-x-1/2 before:translate-y-1/2 before:rotate-45 before:rounded-br-sm before:bg-white',
          'after:absolute after:left-1/2 after:bottom-0 after:block after:h-2 after:w-2 after:-translate-x-1/2 after:translate-y-1/2 after:rotate-45 after:rounded-br-sm after:bg-current',
          {
            'bg-slate-400 text-slate-400 opacity-60': !!hide,

            '!bg-green-600 !text-green-600':
              mappedStatus === 'active' && !statusColor,
            '!bg-light-blue-600 !text-light-blue-600':
              mappedStatus === 'backup' && !statusColor,
            '!bg-red-600 !text-red-600':
              (mappedStatus === 'closed' || mappedStatus === 'deleted') &&
              !statusColor,
            '!bg-yellow-600 !text-yellow-600':
              mappedStatus === 'pending' && !statusColor
          }
        )}
        onClick={(e) => {
          e.stopPropagation()
          map?.easeTo({ center: [lon, lat] })
          onClick?.()
        }}>
        <span
          style={!hide ? { backgroundColor: statusColor || 'darkgray' } : {}}
          className={classNames('relative z-10 text-white', {
            'bg-slate-400': !!hide,

            '!bg-green-600': mappedStatus === 'active' && !statusColor,
            '!bg-light-blue-600': mappedStatus === 'backup' && !statusColor,
            '!bg-red-600':
              (mappedStatus === 'closed' || mappedStatus === 'deleted') &&
              !statusColor,
            '!bg-yellow-600': mappedStatus === 'pending' && !statusColor
          })}>
          {formatCurrencyShort(price || 0)}
        </span>
      </button>
    </Marker>
  )
}
