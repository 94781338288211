import {
  Breadcrumbs,
  Spinner,
  Tooltip,
  toTitleCase,
  Wrapper
} from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import {
  ResourceGroupSettingsForm,
  useResourceGroup
} from '@forge/features/resourcegroups'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'

export function ResourceGroupSettings() {
  const { adapterId, resourceGroupId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId || '' })
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>{toTitleCase(resourceGroup?.role || '')} Settings</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${
              resourceGroupId || ''
            }`}>
            {resourceGroup?.role || ''} Resource Group
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${
              resourceGroupId ?? ''
            }/settings`}>
            {resourceGroup?.role || ''} Settings
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium capitalize">
            {resourceGroup?.role || ''} Resource Group Settings
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Configure Resource Group">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center p-4">
              <div className="h-5 w-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <ResourceGroupSettingsForm
            resourcecGroupId={resourceGroupId || ''}
            adapterId={adapter?.id || ''}
            onSave={() =>
              navigate(
                `/adapters/${adapterId || ''}/resource_groups/${
                  resourceGroupId || ''
                }`
              )
            }
          />
        </Suspense>
      </Wrapper>
    </>
  )
}
