import { Table } from '@forge/common'
import { MetadataFieldsFragment } from '@forge/graphql/generated'

interface SystemInfoProps {
  systemInfo: MetadataFieldsFragment['systemInfo']
}

export function SystemInfo({ systemInfo }: SystemInfoProps) {
  return (
    <div className="rounded-lg bg-white p-1 shadow">
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Data>
              Connected: {systemInfo?.connected ? 'Yes' : 'No'}
            </Table.Data>
            <Table.Data>SystemID: {systemInfo?.systemId}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>
              Description: {systemInfo?.systemDescription}
            </Table.Data>
            <Table.Data>Date: {systemInfo?.date}</Table.Data>
          </Table.Row>
          <Table.Row>
            <Table.Data>
              Timezone Offset: {systemInfo?.timezoneOffset}
            </Table.Data>
            <Table.Data>Version: {systemInfo?.version}</Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}
