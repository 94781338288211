import {
  Checkbox,
  Combobox,
  Diff,
  FormField,
  Input,
  Table
} from '@forge/common'
import {
  ColTypeEnum,
  FieldMapValue,
  FieldPartsFragment,
  ResourceGroupRole
} from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useUpdateField } from '../field/useUpdateField'
import {
  agentAliasOptions,
  listingAliasOptions,
  officeAliasOptions
} from '../field/utils'
import { useResourceGroup } from './useResourceGroup'

const schema = yup.object({
  fieldCategoryId: yup.number(),
  sourceNames: yup.array(yup.string().required()),
  displayName: yup.string(),
  colName: yup.string(),
  colType: yup.mixed<ColTypeEnum>().oneOf(Object.values(ColTypeEnum)),
  aliases: yup.array(yup.string().required()),
  mapValues: yup.mixed<FieldMapValue>().oneOf(Object.values(FieldMapValue)),
  standardName: yup.string(),
  queryable: yup.bool(),
  autocomplete: yup.bool()
})

export interface EditFieldProps {
  role?: ResourceGroupRole
  field: FieldPartsFragment
  resourceGroupId: string | number
}

export function ResourceGroupFieldsTableEditRow({
  role,
  field,
  resourceGroupId
}: EditFieldProps) {
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: String(resourceGroupId)
  })

  const fieldCategoryOptions =
    resourceGroup?.fieldCategories?.map((option) => ({
      label: option.name || '',
      value: option.id || ''
    })) || []

  const columnTypeOptions = Object.values(ColTypeEnum).map((option) => ({
    label: option,
    value: option
  }))
  const aliasOptions = (
    role === ResourceGroupRole.Listing
      ? listingAliasOptions
      : role === ResourceGroupRole.Office
      ? officeAliasOptions
      : role === ResourceGroupRole.Agent
      ? agentAliasOptions
      : []
  ).map((option) => ({
    label: option,
    value: option
  }))

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      fieldCategoryId:
        field.pendingChanges?.fieldCategoryId ||
        field.fieldCategoryId ||
        undefined,
      sourceNames: field.pendingChanges?.sourceNames || field.sourceNames || [],
      displayName:
        field.pendingChanges?.displayName || field.displayName || undefined,
      colName: field.pendingChanges?.colName || field.colName || undefined,
      colType: field.pendingChanges?.colType || field.colType || undefined,
      aliases: field.pendingChanges?.aliases || field.aliases || [],
      mapValues:
        field.pendingChanges?.mapValues || field.mapValues || undefined,
      standardName:
        field.pendingChanges?.standardName || field.standardName || undefined,
      queryable:
        field.pendingChanges?.queryable || field.queryable || undefined,
      autocomplete:
        field.pendingChanges?.autocomplete || field.autocomplete || undefined
    }
  })

  const [hasUpdated, setHasUpdated] = useState(false)
  const { mutateAsync: updateField, error: updateError } = useUpdateField()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasUpdated(false)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [hasUpdated])

  return (
    <>
      <Table.Data>
        <div
          className={classNames('absolute top-0 left-0 h-full w-[0.3125rem]', {
            'bg-yellow-500': field.isDraft
          })}
        />
        <div className="flex justify-center pb-2">
          {field?.pendingChanges?.displayName && (
            <Diff
              value={field?.displayName || ''}
              newValue={field?.pendingChanges?.displayName}
            />
          )}
        </div>
        <div className="min-w-[8rem] overflow-auto">
          <form
            onChange={handleSubmit(async (data) => {
              await updateField({ id: field.id, ...data })
              setHasUpdated(true)
            })}>
            <FormField error={errors.displayName?.message}>
              <Input {...register('displayName')} />
            </FormField>
          </form>
        </div>
      </Table.Data>
      <Table.Data align="center">
        <div className="overflow-auto max-w-xxs">
          <Diff
            value={field?.sourceNames?.join(', ') || ''}
            newValue={field?.pendingChanges?.sourceNames?.join(', ')}
          />
        </div>
      </Table.Data>
      <Table.Data>
        <div className="flex justify-center pb-2">
          {field?.pendingChanges?.aliases && (
            <Diff
              value={field?.aliases || ''}
              newValue={field?.pendingChanges?.aliases}
            />
          )}
        </div>
        <div className="min-w-[14rem]">
          <form
            onClick={handleSubmit(async (data) => {
              await updateField({ id: field.id, ...data })
              setHasUpdated(true)
            })}>
            <FormField error={errors.aliases?.map?.((value) => value?.message)}>
              <Combobox
                multiple
                selected={aliasOptions.filter((option) =>
                  watch('aliases')?.includes(option.value)
                )}
                options={aliasOptions}
                onSelect={(options) => {
                  setValue(
                    'aliases',
                    options.map((option) => option.value)
                  )
                }}
              />
            </FormField>
          </form>
        </div>
      </Table.Data>
      <Table.Data>
        <div className="flex justify-center pb-2">
          {field?.pendingChanges?.fieldCategory && (
            <Diff
              value={field?.fieldCategory?.name || ''}
              newValue={field?.pendingChanges?.fieldCategory?.name}
            />
          )}
        </div>
        <div className="min-w-[10rem]">
          <form
            onSelect={handleSubmit(async (data) => {
              await updateField({ id: field.id, ...data })
              setHasUpdated(true)
            })}>
            <FormField error={errors.fieldCategoryId?.message}>
              {(props) => (
                <Combobox
                  {...props}
                  selected={fieldCategoryOptions?.find(
                    (option) =>
                      Number(option.value) === watch('fieldCategoryId')
                  )}
                  options={fieldCategoryOptions}
                  onSelect={(option) => {
                    setValue('fieldCategoryId', Number(option.value))
                  }}
                />
              )}
            </FormField>
          </form>
        </div>
      </Table.Data>
      <Table.Data>
        <div className="flex justify-center pb-2">
          {field?.pendingChanges?.colType && (
            <Diff
              value={field?.colType}
              newValue={field?.pendingChanges?.colType}
            />
          )}
        </div>
        <div className="min-w-[10rem]">
          <form
            onSelect={handleSubmit(async (data) => {
              await updateField({ id: field.id, ...data })
              setHasUpdated(true)
            })}>
            <FormField error={errors.colType?.message}>
              <Combobox
                selected={columnTypeOptions.find(
                  (option) => option.value === watch('colType')
                )}
                options={columnTypeOptions}
                onSelect={(option) => {
                  setValue('colType', option.value as ColTypeEnum)
                }}
              />
            </FormField>
          </form>
        </div>
      </Table.Data>
      <Table.Data align="center">
        <form
          onChange={handleSubmit(async (data) => {
            await updateField({ id: field.id, ...data })
            setHasUpdated(true)
          })}>
          <div className="flex justify-center">
            <Checkbox
              checked={!!field?.queryable}
              size="lg"
              {...register('queryable')}
              onChange={(e) => setValue('queryable', e.target.checked)}
            />
            {updateError && (
              <ExclamationCircleIcon className="w-5 h-5 mx-auto red-500" />
            )}
          </div>
        </form>
      </Table.Data>
      <div className="absolute ml-4 mt-7">
        {updateError && (
          <p role="alert" aria-live="polite" className="text-sm text-red-500">
            {updateError.message}
          </p>
        )}
      </div>
    </>
  )
}
