import { Checkbox, Dropdown, FullScreenHeader, Label } from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import { ListingMap, ListingSample } from '@forge/features/listing'
import { useResourceGroup } from '@forge/features/resourcegroups'
import {
  AgentResultType,
  ListingResultType,
  OfficeResultType,
  ResourceGroupRole
} from '@forge/graphql/generated'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlaceholderMap } from '../../common/PlaceholderMap'
import { AgentSample } from '../agent/AgentSample'
import { OfficeSample } from '../office'
import { useResourceSample } from '../resource/useResourceSample'
import { SetupRecordDetails } from './SetupRecordDetails'

export type ResourceRecordDataType =
  | ListingResultType
  | AgentResultType
  | OfficeResultType

export interface ResourceRecordData {
  raw: ResourceRecordDataType
  serialized: ResourceRecordDataType
  serializedDraft: ResourceRecordDataType
  normalized: ResourceRecordDataType
  normalizedDraft: ResourceRecordDataType
  translated: ResourceRecordDataType
  translatedDraft: ResourceRecordDataType
}

interface SetupDetailsProps {
  adapterId: string
  resourceGroupId: string
  role: ResourceGroupRole
}

export function SetupDetails({
  adapterId,
  resourceGroupId,
  role
}: SetupDetailsProps) {
  const navigate = useNavigate()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  const [showDraft, setShowDraft] = useState(resourceGroup?.status !== 'stable')

  const resources =
    resourceGroup?.resources?.sort((a, b) =>
      (a?.name || '').localeCompare(b?.name || '')
    ) || []

  const [selectedResource, setSelectedResource] = useState(resources[0])

  const [selectedRecord, setSelectedRecord] = useState<ListingResultType>()

  const { data: { resource } = {} } = useResourceSample({
    id: String(selectedResource?.id ?? '')
  })

  const raw: ListingResultType = JSON.parse(resource?.sampleData?.raw || '{}')
  const serialized: ListingResultType = JSON.parse(
    String(resource?.sampleData?.serialized)
  )
  const serializedDraft: ListingResultType = JSON.parse(
    String(resource?.sampleData?.serializedDraft)
  )
  const normalized: ListingResultType = JSON.parse(
    resource?.sampleData?.normalized || '{}'
  )
  const normalizedDraft: ListingResultType = JSON.parse(
    resource?.sampleData?.normalizedDraft || '{}'
  )
  const translated: ListingResultType = JSON.parse(
    resource?.sampleData?.translated || '{}'
  )
  const translatedDraft: ListingResultType = JSON.parse(
    resource?.sampleData?.translatedDraft || '{}'
  )
  const resourceRecordData: ResourceRecordData = {
    raw,
    serialized,
    serializedDraft,
    normalized,
    normalizedDraft,
    translated,
    translatedDraft
  }

  const records = [showDraft ? serializedDraft : serialized].filter(
    (item): item is ListingResultType => !!item
  )

  return (
    <div className="flex h-screen flex-col">
      <FullScreenHeader
        title={`Setup ${role} Resource Group`}
        onBackClick={() =>
          navigate(
            `/adapters/${adapter?.id}/resource_groups/${resourceGroup?.id}`
          )
        }
      />
      <div className="flex grow">
        <div className="sm:shadow-raised z-[9] flex h-[calc(100vh-3.75rem)] w-full shrink-0 flex-col overflow-y-auto bg-gray-50 sm:max-w-[26.25rem]">
          <div className="space-y-4 p-4">
            {resources.length > 1 && (
              <div className="my-auto">
                <Label htmlFor="-title">Select a Resource</Label>
                <div className="float-right w-full min-w-fit py-2">
                  <Dropdown
                    value={selectedResource?.name || 'Select a Resource Option'}
                    onChange={(v) => {
                      const value = resources?.find((r) => r.id === v)
                      if (value) setSelectedResource(value)
                    }}>
                    <Dropdown.Button>{selectedResource?.name}</Dropdown.Button>
                    {resources.map((resource) => (
                      <Dropdown.Option key={resource?.id} value={resource?.id}>
                        {resource?.name}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                </div>
              </div>
            )}
            <div>
              {resourceGroup?.status !== 'stable' && (
                <Checkbox
                  size="lg"
                  checked={showDraft}
                  onChange={(e) => setShowDraft(!showDraft)}>
                  <Label>Show Draft Data</Label>
                </Checkbox>
              )}
            </div>

            <div>
              <label htmlFor="Sample Record">Sample Record</label>
              <div className="space-y-4">
                <div className="-mx-8 overflow-hidden px-8">
                  {!!records.length ? (
                    <AnimatePresence initial={false}>
                      {records.map((record) => (
                        <AnimatedRecord
                          adapterId={adapterId}
                          isSelected={selectedRecord?.id === record.id}
                          key={record.id}
                          record={showDraft ? serializedDraft : serialized}
                          resourceGroupId={resourceGroupId}
                          role={role}
                          onClick={() =>
                            setSelectedRecord(
                              selectedRecord?.id !== record.id
                                ? record
                                : undefined
                            )
                          }
                          onDoneClick={() => setSelectedRecord(undefined)}
                        />
                      ))}
                    </AnimatePresence>
                  ) : (
                    <p
                      role="alert"
                      aria-live="polite"
                      className="text-sm text-red-600">
                      No sample records available
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden grow bg-gray-100 sm:block">
          {role === 'listing' && (
            <ListingMap
              listings={records}
              onPinClick={(listing) => setSelectedRecord(listing)}
              onDoneClick={() => setSelectedRecord(undefined)}
            />
          )}
          {role === 'agent' && <PlaceholderMap />}
          {role === 'office' && <PlaceholderMap />}
          <div className="hidden lg:block">
            <SetupRecordDetails
              adapterId={adapterId}
              isOpen={!!selectedRecord}
              onClose={() => setSelectedRecord(undefined)}
              record={showDraft ? serializedDraft : serialized}
              resourceGroupId={resourceGroupId}
              resourceSample={resourceRecordData}
              role={role}
              showDraft={showDraft}
            />
          </div>
        </div>
        <div className="block lg:hidden">
          <SetupRecordDetails
            adapterId={adapterId}
            isFullscreen
            isOpen={!!selectedRecord}
            onClose={() => setSelectedRecord(undefined)}
            record={showDraft ? serializedDraft : serialized}
            resourceGroupId={resourceGroupId}
            resourceSample={resourceRecordData}
            role={role}
            showDraft={showDraft}
          />
        </div>
      </div>
    </div>
  )
}

interface AnimatedRecordProps {
  adapterId: string
  resourceGroupId: string
  record: ListingResultType
  role: ResourceGroupRole
  isSelected?: boolean
  onClick: () => void
  onDoneClick: () => void
}

function AnimatedRecord({
  adapterId,
  resourceGroupId,
  record,
  role,
  isSelected,
  onClick
}: AnimatedRecordProps) {
  const listing = record as ListingResultType
  const agent = record as AgentResultType
  const office = record as OfficeResultType

  return (
    <motion.div
      layout
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ height: { ease: 'easeInOut', duration: 0.15 } }}>
      <div className="relative p-1.5">
        {role === 'listing' && (
          <ListingSample
            adapterId={adapterId}
            address={listing.address}
            city={listing.city}
            id={listing.id}
            isOpen={isSelected}
            mappedStatus={listing.mapped_status}
            onClick={onClick}
            photos={listing.photos}
            price={listing.price}
            resourceGroupId={resourceGroupId}
            state={listing.state}
            status={listing.status}
            zip={listing.zip}
          />
        )}
        {role === 'agent' && (
          <AgentSample
            active={agent.active}
            adapterId={adapterId}
            agentId={agent.agent_id}
            email={agent.email}
            fullName={agent.full_name}
            id={agent.id}
            isOpen={isSelected}
            officeName={agent.office_name}
            onClick={onClick}
            phone={agent.phone}
            resourceGroupId={resourceGroupId}
          />
        )}
        {role === 'office' && (
          <OfficeSample
            active={office.active}
            adapterId={adapterId}
            email={office.email}
            id={office.id}
            isOpen={isSelected}
            name={office.name}
            officeId={office.office_id}
            onClick={onClick}
            phone={office.phone}
            resourceGroupId={resourceGroupId}
          />
        )}
      </div>
    </motion.div>
  )
}
