import { Attribute } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { CloseIcon } from '@forge/icons'

interface ListingHeaderProps {
  adapterId: string
  address?: string | null
  city?: string | null
  icon?: JSX.Element
  onClose: () => void
  resourceGroupId: string
  state?: string | null
  zip?: string | null
  fields: Record<string, FieldPartsFragment>
}

export function ListingHeader({
  adapterId,
  address,
  city,
  icon = <CloseIcon />,
  onClose,
  resourceGroupId,
  state,
  zip,
  fields
}: ListingHeaderProps) {
  const cityStateZip = [city, [state, zip].filter(Boolean).join(' ')]
    .filter(Boolean)
    .join(', ')

  return (
    <div className="shadow-raised sticky top-0 z-10 flex h-[4.25rem] shrink-0 items-center justify-between space-x-2 bg-white">
      <div className="flex items-center min-w-0 gap-2">
        {address || cityStateZip ? (
          <div className="items-baseline hidden min-w-0 gap-2 font-semibold md:flex">
            {address && (
              <div className="text-2xl text-gray-900 truncate" title={address}>
                {address}
              </div>
            )}
            {cityStateZip && (
              <div className="text-gray-500 truncate" title={cityStateZip}>
                {cityStateZip}
              </div>
            )}
          </div>
        ) : (
          <div className="hover:cursor-pointer">
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['address']?.id}
              label="address"
              resourceGroupId={resourceGroupId}
              value={address}
            />
          </div>
        )}
      </div>
      <button
        className="flex items-center justify-center w-10 h-10 text-gray-600"
        onClick={onClose}>
        <span className="w-5 h-5">{icon}</span>
      </button>
    </div>
  )
}
