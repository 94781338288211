import { Button, Modal } from '@forge/common'
import { useCreateLookupsFromAggs } from './useCreateLookupsFromAggs'
import { useCreateLookupsFromMetadata } from './useCreateLookupsFromMetadata'

interface ImportLookupsProps {
  fieldId: string
  name: string
  isOpen: boolean
  onClose: () => void
}

export function ImportLookups({
  fieldId,
  name,
  isOpen,
  onClose
}: ImportLookupsProps) {
  const {
    mutate: createLookupsFromAggs,
    isLoading: isCreatingLookupsFromAggs
  } = useCreateLookupsFromAggs()
  const {
    mutate: createLookupsFromMetadata,
    isLoading: isCreatingLookupsFromMetadata
  } = useCreateLookupsFromMetadata()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Import Lookups</Modal.Title>
      <div className="mt-4 flex items-center space-x-2">
        <Button
          loading={isCreatingLookupsFromAggs}
          onClick={() => createLookupsFromAggs({ fieldId })}>
          Import from indexed data
        </Button>
        <Button
          disabled={!name}
          loading={isCreatingLookupsFromMetadata}
          onClick={() => createLookupsFromMetadata({ fieldId, name })}>
          Import from metadata
        </Button>
      </div>
    </Modal>
  )
}
