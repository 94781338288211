import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

export interface ComboboxOption {
  label: string
  value: string
}

interface ComboboxOptionWithCreate extends ComboboxOption {
  _create: true
}

interface BaseComboboxProps {
  autoFocus?: boolean
  disabled?: boolean
  loading?: boolean
  options: ComboboxOption[]
  placeholder?: string
  readOnly?: boolean
  variant?: 'primary' | 'secondary' | 'danger'
  restrictMenuWidth?: boolean
  onCreateOption?: (options: ComboboxOption) => void
}

interface ComboboxPropsWithMultiple extends BaseComboboxProps {
  multiple: true
  selected?: ComboboxOption[]
  onSelect: (options: ComboboxOption[]) => void
}

interface ComboboxPropsWithoutMultiple extends BaseComboboxProps {
  multiple?: false
  selected?: ComboboxOption
  onSelect: (option: ComboboxOption) => void
}

export const Combobox = forwardRef<
  HTMLInputElement,
  ComboboxPropsWithoutMultiple | ComboboxPropsWithMultiple
>(function Combobox(
  {
    autoFocus,
    disabled,
    loading,
    multiple,
    onCreateOption,
    onSelect,
    options,
    placeholder,
    readOnly,
    selected,
    variant = 'primary',
    restrictMenuWidth,
    ...rest
  },
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState('')
  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      ),
    [options, query]
  )
  const optionsByValue = useMemo(
    () =>
      options.reduce<Record<string, ComboboxOption>>(
        (byValue, option) => ({
          ...byValue,
          [option.value.toLowerCase()]: option
        }),
        {}
      ),
    [options]
  )
  const optionsByLabel = useMemo(
    () =>
      options.reduce<Record<string, ComboboxOption>>(
        (byLabel, option) => ({
          ...byLabel,
          [option.label.toLowerCase()]: option
        }),
        {}
      ),
    [options]
  )

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => inputRef.current
  )

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  return (
    <HeadlessCombobox
      multiple={multiple}
      value={selected}
      onChange={(
        item: ComboboxOptionWithCreate | ComboboxOptionWithCreate[] | undefined
      ) => {
        if (onCreateOption) {
          // Grab the newly created item
          const newItem = Array.isArray(item)
            ? item?.find((item) => item._create)
            : item?._create
            ? item
            : undefined
          const newItemWithoutCreate = newItem
            ? {
                label: newItem.label,
                value: newItem.value
              }
            : undefined

          // If we have a newly created item, call onCreateOption with the item
          if (newItemWithoutCreate) {
            onCreateOption(newItemWithoutCreate)
          }
        }

        // Remove the _create option before sending it back
        const itemWithoutCreate: ComboboxOption | ComboboxOption[] | undefined =
          Array.isArray(item)
            ? item.map((item) => ({ label: item.label, value: item.value }))
            : item
            ? { label: item.label, value: item.value }
            : undefined

        // If the component is in multiple mode, ensure we're sending back an array value
        if (multiple && Array.isArray(itemWithoutCreate)) {
          onSelect(itemWithoutCreate)
        }
        // If the component is in single mode, ensure we're sending back a single value
        else if (
          !multiple &&
          !Array.isArray(itemWithoutCreate) &&
          !!itemWithoutCreate
        ) {
          onSelect(itemWithoutCreate)
        }
      }}
      disabled={loading}>
      <div className="relative">
        <HeadlessCombobox.Input
          {...rest}
          ref={inputRef}
          autoFocus={autoFocus}
          className={classNames(
            'block w-full appearance-none rounded-md border pt-2 pb-2 pl-2 pr-8 shadow-sm focus:ring-0 sm:text-sm',
            {
              'border-gray-300': variant === 'primary',
              'focus:border-orange-400':
                variant === 'primary' && !disabled && !readOnly,

              'border-red-300': variant === 'danger',
              'focus:border-red-500':
                variant === 'danger' && !disabled && !readOnly,

              'cursor-not-allowed bg-gray-50': disabled,
              'cursor-default bg-gray-50': readOnly
            }
          )}
          placeholder={
            loading ? 'Loading...' : placeholder || 'Choose an Option'
          }
          displayValue={(option: ComboboxOption | ComboboxOption[]) =>
            Array.isArray(option) ? '' : option?.label || ''
          }
          value={query}
          onChange={(e) => setQuery?.(e.target.value)}
        />
        <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </HeadlessCombobox.Button>
        <Transition
          afterLeave={() => setQuery('')}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <HeadlessCombobox.Options
            className={classNames(
              'absolute z-50 mt-1 max-h-60 overflow-y-auto rounded-md bg-white p-2 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              {
                'w-full': restrictMenuWidth
              }
            )}>
            {onCreateOption &&
            query.length >= 2 &&
            !optionsByValue[query.toLowerCase()] &&
            !optionsByLabel[query.toLowerCase()] ? (
              <HeadlessCombobox.Option
                value={{ label: query, value: query, _create: true }}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none rounded-md py-2 px-12 pl-4',
                    {
                      'bg-gray-100': active,
                      'text-gray-900': !active
                    }
                  )
                }>
                <span className="block">Create {query}</span>
              </HeadlessCombobox.Option>
            ) : (
              !filteredOptions.length &&
              !!query && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found
                </div>
              )
            )}
            {filteredOptions.map((option) => (
              <HeadlessCombobox.Option
                key={JSON.stringify(option)}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none rounded-md py-2 px-12 pl-4',
                    {
                      'bg-gray-100': active,
                      'text-gray-900': !active
                    }
                  )
                }>
                {({ selected }) => (
                  <>
                    <span
                      className={classNames('block whitespace-nowrap', {
                        'font-medium': selected,
                        'font-normal': !selected
                      })}>
                      {option.label}
                    </span>
                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-4 flex items-center text-orange-400'
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </HeadlessCombobox.Option>
            ))}
          </HeadlessCombobox.Options>
        </Transition>
      </div>
      {multiple && Array.isArray(selected) && (
        <div className="mt-1 overflow-hidden">
          <div className="-m-0.5 flex flex-wrap">
            {selected.map((option) => (
              <div key={option.value} className="p-0.5">
                <div className="flex items-center space-x-2 rounded bg-orange-200 px-1 py-0.5 text-xs text-orange-900">
                  <div>{option.label}</div>
                  <button
                    type="button"
                    onClick={() => {
                      onSelect(
                        selected.filter(
                          (selectedOption) =>
                            selectedOption.value !== option.value
                        )
                      )
                    }}>
                    <XIcon className="h-3 w-3 shrink-0" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </HeadlessCombobox>
  )
})
