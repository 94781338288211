import {
  AdapterResourceGroupsQueryVariables,
  useAdapterResourceGroupsQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  fragment AdapterResourceGroup on ResourceGroup {
    id
    uid
    role
    version
    indexData: index_data
    searchData: search_data
    auditData: audit_data
    skipPhotos: skip_photos
    status
    needsSetup: needs_setup
    loadingProgress: loading_progress
    deletesStatus: deletes_status {
      lastPurge: last_purge
      pending
      status
      total
    }
    resources {
      id
      name
      populateStatus: populate_status
      updateData: update_data
    }
  }

  query AdapterResourceGroups(
    $id: ID!
    $adapterId: Int
    $role: ResourceGroupRole
  ) {
    adapter(id: $id) {
      id
      key
      resourceGroups: resource_groups(adapter_id: $adapterId, role: $role) {
        ...AdapterResourceGroup
      }
    }
  }
`

export function useAdapterResourceGroups(
  vars: AdapterResourceGroupsQueryVariables
) {
  return useAdapterResourceGroupsQuery(vars, {
    enabled: !!vars.id
  })
}
