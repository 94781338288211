import { useGlobalCountsQuery } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

// These counts are typed as strings to compensate for counts like '100K', and '5M'.
gql`
  query GlobalCounts {
    counts: global_counts {
      adapters
      listingServices: listing_services
      updatesToday: updates_today
      listings
      agents
      offices
    }
  }
`

export function useDashboardGlobalCounts() {
  return useGlobalCountsQuery()
}
