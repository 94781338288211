import {
  Alert,
  Breadcrumbs,
  Button,
  Menu,
  NotFound,
  Table,
  TimeAgo,
  Tooltip,
  Wrapper
} from '@forge/common'
import {
  AdapterCounts,
  AdapterStatsLog,
  AdapterTabs,
  AdapterUpdatesByHour,
  useAdapter,
  useDeleteAdapter
} from '@forge/features/adapters'
import { useCurrentUser } from '@forge/features/auth'
import {
  DotsHorizontalIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/outline'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export function Adapter() {
  const navigate = useNavigate()
  const isActive = useLocation()
  const { adapterId } = useParams()
  const { data: currentUser } = useCurrentUser()
  const { data: { adapter } = {}, isSuccess } = useAdapter({
    id: adapterId
  })

  const {
    mutate: deleteAdapter,
    isLoading: isDeletingAdapter,
    error: errorDeletingAdapter
  } = useDeleteAdapter()

  const [openDeleteAdapterModal, setOpenDeleteAdapterModal] = useState(false)

  const adapterAlarms = adapter?.alarms

  const pages = [
    { name: 'Data Adapters', href: '/adapters', current: false },
    {
      name: `${adapter?.key?.toUpperCase()}`,
      href: `/adapters/${adapter?.id}/details`,
      current: isActive.pathname === `/adapters/${adapter?.id}/details`
    }
  ]

  if (isSuccess && !adapter) {
    return (
      <>
        <NotFound />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Adapter: {adapter?.key?.toUpperCase()}</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          {pages.map((page) => (
            <Breadcrumbs.Crumb key={page.href} to={page.href}>
              {page.name}
            </Breadcrumbs.Crumb>
          ))}
        </Breadcrumbs>
        <div className="flex items-center justify-between space-x-2">
          <h1 className="flex text-4xl font-medium">
            {adapter?.key?.toUpperCase()} Dashboard
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="A Data Adapter is a RETS or Web API feed pertaining to a
                particular MLS/AOR or Datashare.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>

          <div className="relative flex flex-shrink-0 justify-end">
            <Menu
              button={
                <Button as="span" aria-label="Adapter Options">
                  <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                </Button>
              }>
              <Menu.Item
                title="Edit Adapter"
                onClick={() => {
                  navigate(`/adapters/${adapter?.id}/edit`)
                }}>
                Edit {adapter?.key?.toUpperCase()} Credentials
              </Menu.Item>
              <Menu.Item
                title="Adapter Metadata"
                onClick={() => {
                  navigate(`/adapters/${adapter?.id}/metadata`)
                }}>
                View {adapter?.key?.toUpperCase()} Metadata
              </Menu.Item>
              <Menu.Item
                title="Adapter Alarms"
                onClick={() => {
                  navigate(`/adapters/${adapter?.id}/alarms`)
                }}>
                Manage {adapter?.key?.toUpperCase()} Alarms
              </Menu.Item>
              <Menu.Item
                title="Adapter Debugger"
                onClick={() => {
                  navigate(`/adapters/${adapter?.id}/debugger`)
                }}>
                Debug {adapter?.key?.toUpperCase()}
              </Menu.Item>
              {currentUser?.role === 'super' && (
                <Menu.Item
                  variant="danger"
                  title="Delete Adapter"
                  onClick={() => setOpenDeleteAdapterModal(true)}>
                  Delete {adapter?.key?.toUpperCase()} Adapter
                </Menu.Item>
              )}
            </Menu>
          </div>
        </div>

        <div>
          <AdapterCounts />
          <AdapterUpdatesByHour />

          {adapterAlarms?.length !== 0 && (
            <div className="mb-4 overflow-x-auto rounded-lg bg-white shadow">
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header>
                      <p className="text-red-500">
                        {adapterAlarms?.length} Active Alarms
                      </p>
                    </Table.Header>
                    <Table.Header align="right">
                      <p className="text-red-500">Triggered</p>
                    </Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {adapterAlarms?.map(({ id, description, createdAtIso }) => (
                    <Table.Row key={id}>
                      <Table.Data>
                        <p className="text-red-500">{description}</p>
                      </Table.Data>
                      <Table.Data align="right">
                        <i>
                          <p className="text-red-500">
                            <TimeAgo date={createdAtIso || ''} />
                          </p>
                        </i>
                      </Table.Data>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}

          <div className="xs:col-span-1 md:col-span-2 md:flex">
            <div className="mb-8 mr-8 xs:w-full md:w-1/2">
              <AdapterTabs />
            </div>
            <div className="mb-8 xs:w-full md:w-1/2">
              <AdapterStatsLog />
            </div>
          </div>
        </div>

        {adapter && (
          <Alert variant="danger" isOpen={openDeleteAdapterModal}>
            <Alert.Title>Delete Adapter</Alert.Title>
            <Alert.Content>
              Are you sure you want to delete this Adapter (
              <strong>{adapter.key?.toString()}</strong>)? This action cannot be
              undone.
            </Alert.Content>
            {errorDeletingAdapter && (
              <p
                role="alert"
                aria-live="polite"
                className="text-sm text-red-600">
                {errorDeletingAdapter.message}
              </p>
            )}
            <Alert.Cancel onClick={() => setOpenDeleteAdapterModal(false)}>
              Cancel
            </Alert.Cancel>
            <Alert.Confirm
              loading={isDeletingAdapter}
              leftIcon={<ExclamationCircleIcon />}
              onClick={() => {
                deleteAdapter(
                  { id: adapter.id },
                  {
                    onSuccess() {
                      navigate('/adapters')
                    }
                  }
                )
              }}>
              Delete Forever
            </Alert.Confirm>
          </Alert>
        )}
      </Wrapper>
    </>
  )
}
