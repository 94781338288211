import { useImportMetadataMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation importMetadata(
    $adapterId: ID!
    $resourceName: String!
    $classNames: [String!]
    $role: ResourceGroupRole!
  ) {
    importMetadata(
      adapter_id: $adapterId
      resource_name: $resourceName
      class_names: $classNames
      role: $role
    ) {
      id
      adapterId: adapter_id
    }
  }
`

export function useImportMetadata() {
  const queryClient = useQueryClient()
  return useImportMetadataMutation({
    async onSuccess({ importMetadata }) {
      await queryClient.invalidateQueries([
        'ResourceGroup',
        { id: importMetadata?.id }
      ])
    }
  })
}
