import { PercentageStat, Spinner, Table } from '@forge/common'
import { AdapterAdapterType, AdapterStatus } from '@forge/graphql/generated'
import classNames from 'classnames'
import { Suspense } from 'react'
import { Link } from 'react-router-dom'
import { useAdapters } from './useAdapters'
import { useAdapterHealth } from './useAdapterHealth'
import { visibilityOptions, adapterTypeOptions } from './utils'

interface AdapterTableProps {
  limit?: number
  page?: number
  status?: AdapterStatus | ''
  adapterType?: AdapterAdapterType | ''
  searchTerm?: string
}

function AdaptersTable({
  limit = 1000,
  page,
  status,
  adapterType,
  searchTerm
}: AdapterTableProps) {
  const { data: { adapters } = {} } = useAdapters({ limit, page })

  // ! We search on the front end because forge doesn't have search functionality for adapters.
  const filteredAdapters = adapters
    ?.filter((adapter) =>
      searchTerm ? adapter.key?.includes(searchTerm) : true
    )
    .filter((adapter) =>
      adapterType ? adapter.adapterType === adapterType : true
    )
    .filter((adapter) => (status ? adapter.status === status : true))

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header>Adapter Key</Table.Header>
          <Table.Header align="center">Current Job</Table.Header>
          <Table.Header align="right">Indexing</Table.Header>
          <Table.Header align="right">Updating</Table.Header>
          <Table.Header align="right">Audit</Table.Header>
          <Table.Header align="right">Resolution</Table.Header>
          <Table.Header align="right">Weekly</Table.Header>
          <Table.Header align="right">Monthly</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {!filteredAdapters?.length && (
          <Table.Row>
            <Table.Data colSpan={8}>
              <p className="p-4 text-lg text-center">
                No results found {searchTerm && 'for'}
                {searchTerm && (
                  <span className="font-bold text-orange-400">
                    {' '}
                    "{searchTerm}"{' '}
                  </span>
                )}
                in
                <span className="font-bold text-orange-400">
                  {' '}
                  {visibilityOptions[status || '']} /{' '}
                  {adapterTypeOptions[adapterType || '']}{' '}
                </span>
                Adapters.
              </p>
            </Table.Data>
          </Table.Row>
        )}
        {filteredAdapters?.map((adapter) => {
          return (
            <AdapterRow
              key={adapter.id}
              id={adapter.id}
              adapterKey={adapter.key}
              adapterStatus={adapter.status}
              currentJob={adapter.currentJob}
            />
          )
        })}
      </Table.Body>
    </Table>
  )
}

interface AdapterRowProps {
  id: string
  adapterKey?: string | null
  adapterStatus?: AdapterStatus | null
  currentJob?: string | null
}

function AdapterRow({
  id,
  adapterKey,
  currentJob,
  adapterStatus
}: AdapterRowProps) {
  const { data, isLoading } = useAdapterHealth(
    { adapterId: id },
    { suspense: false }
  )

  return (
    <Table.Row>
      <Table.Data>
        <div
          className={classNames('absolute top-0 left-0 h-full w-[0.3125rem]', {
            'bg-red-500': adapterStatus === 'not_started',
            'bg-yellow-500': adapterStatus === 'in_progress',
            'bg-green-500': adapterStatus === 'live',
            'bg-gray-200':
              adapterStatus !== AdapterStatus.NotStarted &&
              adapterStatus !== AdapterStatus.InProgress &&
              adapterStatus !== AdapterStatus.Live
          })}
        />

        <Link
          to={`/adapters/${id}/details`}
          className="cursor-pointer hover:text-orange-400">
          {adapterKey?.toUpperCase()}
        </Link>
      </Table.Data>
      <Table.Data align="center">
        <Link
          to={`/adapters/${id}/details`}
          className="cursor-pointer hover:text-orange-400">
          {currentJob || '--'}
        </Link>
      </Table.Data>
      <Table.Data align="right">
        {isLoading ? (
          <Loader />
        ) : data?.adapter?.health?.consumers ? (
          <PercentageStat value={data?.adapter?.health.consumers} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="right">
        {isLoading ? (
          <Loader />
        ) : data?.adapter?.health?.sync ? (
          <PercentageStat value={data?.adapter?.health.sync} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="right">
        {isLoading ? (
          <Loader />
        ) : data?.adapter?.health?.audit ? (
          <PercentageStat value={data?.adapter?.health.audit} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="right">
        {isLoading ? (
          <Loader />
        ) : data?.adapter?.health?.auditResolution ? (
          <PercentageStat value={data?.adapter?.health.auditResolution} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="right">
        {isLoading ? (
          <Loader />
        ) : data?.adapter?.health?.weeklyAudit ? (
          <PercentageStat value={data?.adapter?.health.weeklyAudit} />
        ) : (
          '-'
        )}
      </Table.Data>
      <Table.Data align="right">
        {isLoading ? (
          <Loader />
        ) : data?.adapter?.health?.monthlyAudit ? (
          <PercentageStat value={data?.adapter?.health.monthlyAudit} />
        ) : (
          '-'
        )}
      </Table.Data>
    </Table.Row>
  )
}

function Loader() {
  return (
    <div className="flex justify-end text-gray-500">
      <div className="w-5 h-5">
        <Spinner />
      </div>
    </div>
  )
}

export function AdaptersTableProvider(props: AdapterTableProps) {
  return (
    <Suspense
      fallback={
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Adapter Key</Table.Header>
              <Table.Header align="center">Current Job</Table.Header>
              <Table.Header align="center">Indexing</Table.Header>
              <Table.Header align="center">Updating</Table.Header>
              <Table.Header align="center">Audit</Table.Header>
              <Table.Header align="center">Resolution</Table.Header>
              <Table.Header align="center">Weekly</Table.Header>
              <Table.Header align="center">Monthly</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.Data colSpan={8}>
                <div className="flex items-center gap-2">
                  <div className="w-5 h-5 text-orange-400">
                    <Spinner />
                  </div>
                  <div>Loading Adapters</div>
                </div>
              </Table.Data>
            </Table.Row>
          </Table.Body>
        </Table>
      }>
      <AdaptersTable {...props} />
    </Suspense>
  )
}
