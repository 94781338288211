import {
  Alert,
  Breadcrumbs,
  Button,
  Menu,
  NotFound,
  Tooltip,
  Wrapper
} from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import { useCurrentUser } from '@forge/features/auth'
import {
  ResourceDefaultCriteria,
  ResourcePopulate,
  ResourceRecentAudits,
  ResourceSyncRecords,
  ResourceTabs,
  ResourceUpdatesByHour,
  useDeleteResource,
  useResource
} from '@forge/features/resource'
import {
  ResourceGroupReindex,
  useResourceGroup
} from '@forge/features/resourcegroups'
import {
  DotsHorizontalIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/outline'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'

export function Resource() {
  const { data: currentUser } = useCurrentUser()

  const [isShowingResourcePopulateModal, setIsShowingResourcePopulateModal] =
    useState(false)

  const [
    isShowingResourceGroupReindexModal,
    setIsShowingResourceGroupReindexModal
  ] = useState(false)

  const [openDeleteResourceModal, setOpenDeleteResourceModal] = useState(false)

  const navigate = useNavigate()

  const { id: resourceId, adapterId, resourceGroupId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })
  const { data: { resource } = {}, isSuccess } = useResource({
    id: resourceId || ''
  })

  const {
    mutate: deleteResource,
    isLoading: isDeletingResource,
    error: errorDeletingResource
  } = useDeleteResource()

  if (isSuccess && !resource) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <>
      <Helmet>
        <title>{resource?.name || ''} Resource</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}`}>
            {resourceGroup?.role || ''} Resource Group
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/resources/${resource?.id}`}>
            Resource: {resource?.name || ''}
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex items-center justify-between space-x-2">
          <h1 className="flex text-4xl font-medium capitalize">
            {resourceGroup?.role || ''} Resource: {resource?.name || ''}
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="A Resource is a subset of a resource group; particular
                to a specific scope of data.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>

          <div className="relative flex flex-shrink-0 justify-end">
            <Menu
              button={
                <Button as="span" aria-label="Resource Options">
                  <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                </Button>
              }>
              <Menu.Item
                disabled={
                  resourceGroup?.role === 'photo' ||
                  resourceGroup?.status !== 'stable' ||
                  currentUser?.role !== 'super'
                }
                title="Populate"
                onClick={() => setIsShowingResourcePopulateModal(true)}>
                Populate {resource?.name || ''} Resource {''}
                Records
              </Menu.Item>
              <Menu.Item
                title="Settings"
                onClick={() => {
                  navigate(
                    `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/settings`
                  )
                }}>
                {resourceGroup?.role || ''} Resource Group Settings
              </Menu.Item>
              <Menu.Item
                title="Setup"
                onClick={() => {
                  navigate(
                    `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/setup`
                  )
                }}>
                {resourceGroup?.role || ''} Resource Group Setup
              </Menu.Item>
              <Menu.Item
                title="Fields"
                onClick={() => {
                  navigate(
                    `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/fields`
                  )
                }}>
                {resourceGroup?.role || ''} Resource Group Fields
              </Menu.Item>
              <Menu.Item
                title="Search"
                onClick={() => {
                  navigate(
                    `/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/search`
                  )
                }}>
                Search All {resourceGroup?.role || ''} Records
              </Menu.Item>
              {currentUser?.role === 'super' && (
                <>
                  <Menu.Item
                    disabled={
                      resourceGroup?.role === 'photo' ||
                      resourceGroup?.status !== 'stable'
                    }
                    title="Reindex"
                    onClick={() => setIsShowingResourceGroupReindexModal(true)}>
                    Reindex All {resourceGroup?.role || ''} Records
                  </Menu.Item>
                  <Menu.Item
                    variant="danger"
                    onClick={() => setOpenDeleteResourceModal(true)}>
                    Delete {resource?.name || ''} Resource {''}
                  </Menu.Item>
                </>
              )}
            </Menu>
            <ResourcePopulate
              isOpen={isShowingResourcePopulateModal}
              onClose={() => setIsShowingResourcePopulateModal(false)}
            />
            <ResourceGroupReindex
              isOpen={isShowingResourceGroupReindexModal}
              onClose={() => setIsShowingResourceGroupReindexModal(false)}
            />
          </div>
        </div>

        <div className="gap-6 xs:col-span-1 md:col-span-2 md:flex">
          <div className="xs:w-full md:w-1/2 xl:w-2/3">
            {resourceGroup?.role !== 'photo' && (
              <div className="flex flex-col gap-6">
                <ResourceUpdatesByHour />
                <ResourceRecentAudits />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <h2 className="text-xl ">Sync Records</h2>
              <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-md ">
                <ResourceSyncRecords />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="text-xl">Default Criteria</h2>
              <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-md">
                <ResourceDefaultCriteria />
              </div>
            </div>
          </div>
        </div>
        <ResourceTabs />
        {resource && (
          <Alert variant="danger" isOpen={openDeleteResourceModal}>
            <Alert.Title>Delete Resource</Alert.Title>
            <Alert.Content>
              <p>
                Are you sure you want to delete this Resource (
                <strong>{resource.name}</strong>)? This action cannot be undone.
              </p>
              {errorDeletingResource && (
                <div className="text-red-500" role="alert" aria-live="polite">
                  {errorDeletingResource.message}
                </div>
              )}
            </Alert.Content>
            <Alert.Cancel onClick={() => setOpenDeleteResourceModal(false)}>
              Cancel
            </Alert.Cancel>
            <Alert.Confirm
              loading={isDeletingResource}
              leftIcon={<ExclamationCircleIcon />}
              onClick={() => {
                deleteResource(
                  { id: resource.id },
                  {
                    onSuccess() {
                      navigate(`/adapters/${adapterId}/details`)
                    }
                  }
                )
              }}>
              Delete Forever
            </Alert.Confirm>
          </Alert>
        )}
      </Wrapper>
    </>
  )
}
