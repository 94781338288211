import {
  SavedSearchQueryVariables,
  useSavedSearchQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query SavedSearch($listingServiceId: Int, $id: ID) {
    savedSearch: saved_search(id: $id, listing_service_id: $listingServiceId) {
      rawCriteria: raw_criteria
      queries
    }
  }
`

export function useSavedSearch(vars: SavedSearchQueryVariables) {
  return useSavedSearchQuery(vars)
}
