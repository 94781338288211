import { Breadcrumbs, Tooltip, Wrapper } from '@forge/common'
import { AlarmProfilesTable, useAdapter } from '@forge/features/adapters'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function AlarmProfiles() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })

  return (
    <>
      <Helmet>
        <title>Adapter: {adapter?.key?.toUpperCase()} - Alarms</title>
      </Helmet>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/alarms`}>
            Alarm Triggers
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            {adapter?.key?.toUpperCase()} Alarm Triggers
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Adapter alarm triggers can be configured to monitor jobs and notify admins when things go wrong.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>
        <AlarmProfilesTable />
      </Wrapper>
    </>
  )
}
