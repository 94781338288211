import { useResourceGroup } from '@forge/features/resourcegroups'
import { ResourceGroupRole } from '@forge/graphql/generated'
import { SetupDetails } from '.'

interface ResourceGroupSetupProps {
  adapterId: string
  resourceGroupId: string
}

export function Setup({ adapterId, resourceGroupId }: ResourceGroupSetupProps) {
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId
  })

  const role = resourceGroup?.role as ResourceGroupRole

  return (
    <div>
      <SetupDetails
        adapterId={adapterId}
        resourceGroupId={resourceGroupId}
        role={role}
      />
    </div>
  )
}
