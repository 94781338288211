import {
  SavedSearchesQueryVariables,
  useSavedSearchesQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query SavedSearches($page: Int, $listingServiceId: ID) {
    listingService: listing_service(id: $listingServiceId) {
      id
      savedSearchHealth: saved_search_health {
        count
        registeredCount: registered_count
        unregistered(page: $page) {
          id
          matchCount: match_count
          lastMatchAt: last_match_at
        }
      }
    }
  }
`

export function useSavedSearches(vars: SavedSearchesQueryVariables) {
  return useSavedSearchesQuery(vars)
}
