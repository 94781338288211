import { Combobox } from '@forge/common'
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { JumperService, useJumper } from './useJumper'
import { Option, subOptionsFor } from './utils'

interface JumperProps {
  onJump?: () => void
}

export function Jumper({ onJump }: JumperProps) {
  const [selectedService, setSelectedService] = useState<JumperService>()

  return (
    <Suspense fallback={<Combobox options={[]} onSelect={() => {}} loading />}>
      <div className="space-y-4">
        <Services
          key={JSON.stringify(selectedService)}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
        {selectedService && (
          <ServiceOptions
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            onJump={onJump}
          />
        )}
      </div>
    </Suspense>
  )
}

interface ServicesProps {
  selectedService?: JumperService
  setSelectedService: Dispatch<SetStateAction<JumperService | undefined>>
}

function Services({ selectedService, setSelectedService }: ServicesProps) {
  const comboxboxRef = useRef<HTMLInputElement>(null)
  const { data: jumperData } = useJumper()
  const allOptions = useMemo(
    () => [
      ...(jumperData?.listingServices || []),
      ...(jumperData?.adapters || [])
    ],
    [jumperData]
  )

  const options = useMemo(() => {
    return allOptions.map((data, index) => {
      return {
        label:
          data.__typename === 'ListingService'
            ? `Listing Service: ${data.name}`
            : `Data Adapter: ${data.key?.toUpperCase()}`,
        value: `key_${index}_${data.key}`
      }
    })
  }, [allOptions])
  const selectedOption = useMemo(
    () =>
      selectedService
        ? {
            label:
              selectedService.__typename === 'ListingService'
                ? `${selectedService.name}`
                : `${selectedService.key?.toUpperCase()}`,
            value: selectedService.key || ''
          }
        : undefined,
    [selectedService]
  )

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Alt') {
        e.preventDefault()
        comboxboxRef.current?.focus()
      }
    }
    document.body.addEventListener('keydown', handler)
    return () => {
      document.body.removeEventListener('keydown', handler)
    }
  }, [])

  return (
    <Combobox
      autoFocus
      ref={comboxboxRef}
      restrictMenuWidth
      options={options}
      selected={selectedOption}
      placeholder={`Search for an Adapter or Listing Service`}
      onSelect={(selected) => {
        const isAdapter = selected.label.startsWith('Data Adapter: ')
        const key = selected.value.replace(/key_\d+_/gi, '')
        const selectedService = allOptions.find((data) => {
          if (isAdapter) {
            if (data.__typename === 'Adapter') {
              return data.key === key
            }
            return false
          } else {
            return data.key === key
          }
        })

        setSelectedService(selectedService)
      }}
    />
  )
}

interface ServiceOptionsProps {
  selectedService?: JumperService
  setSelectedService: Dispatch<SetStateAction<JumperService | undefined>>
  onJump?: () => void
}

function ServiceOptions({
  selectedService,
  setSelectedService,
  onJump
}: ServiceOptionsProps) {
  const options = subOptionsFor(selectedService)
  const [selected, setSelected] = useState<Option>()
  const navigate = useNavigate()

  return (
    <Combobox
      autoFocus
      options={options}
      restrictMenuWidth
      selected={selected}
      onSelect={(selected) => {
        setSelected(selected)
        navigate(selected.value)
        setSelectedService(undefined)
        onJump?.()
        // TODO: prevent form field values persisting if/when jumping from one form to another
        // Temp fix to prevent forms from persisting when jumping from form to form
        // window.location.reload()
      }}
    />
  )
}
