import { Table } from '@forge/common'

interface ListingFeaturesProps {
  features?: string
}

export function ListingFeatures({ features }: ListingFeaturesProps) {
  const parsedFeatures: Record<string, string | boolean | number> =
    typeof features === 'object' ? features : JSON.parse(features || '{}')

  return (
    <div className="2xl:mx-24">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Feature Display Name</Table.Header>
            <Table.Header align="center">Value</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {Object.entries(parsedFeatures).map(([key, value], index) => {
            return (
              <Table.Row key={index}>
                <Table.Data>
                  <span className="font-medium">{key}:</span>
                </Table.Data>
                <Table.Data align="center">
                  <div className="mx-auto overflow-auto md:max-w-[15rem] xl:max-w-[30rem]">
                    {(typeof value === 'boolean'
                      ? JSON.stringify(value)
                      : value) || '--'}
                  </div>
                </Table.Data>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}
