import {
  Address,
  classNames,
  Input,
  InputProps,
  useAddressSearch,
  useDebounce
} from '@forge/common'
import { Combobox } from '@headlessui/react'
import { ForwardedRef, forwardRef, useRef, useState } from 'react'

interface AddressSearchProps extends Omit<InputProps, 'loading'> {
  onSelect?: (address: Address) => void
  onInputChange?: (value: string) => void
}

export const AddressSearch = forwardRef(function AddressSearch(
  { onSelect, onInputChange, ...props }: AddressSearchProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [selected, setSelected] = useState<Address>()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const hasChangedRef = useRef(false)
  const { data: addresses, isFetching } = useAddressSearch(
    { address: debouncedQuery },
    {
      enabled: hasChangedRef.current ? false : undefined
    }
  )

  return (
    <div className="relative">
      <Combobox
        value={selected}
        onChange={(address: Address) => {
          hasChangedRef.current = true
          setQuery(address.label)
          setSelected(address)
          onSelect?.(address)
        }}>
        <Combobox.Input
          {...props}
          ref={ref}
          as={Input}
          displayValue={() => query}
          onChange={(event) => {
            hasChangedRef.current = false
            setQuery(event.target.value)
            onInputChange?.(event.target.value)
          }}
          loading={isFetching}
          autoComplete="off"
        />
        {!!addresses?.length && (
          <Combobox.Options className="absolute z-10 mt-2 max-h-52 w-full overflow-auto rounded-md bg-white p-2 py-1 shadow-lg">
            {addresses.map((address, index) => (
              <Combobox.Option
                key={address.label + index}
                value={address}
                className={({ active, selected }) =>
                  classNames('rounded-md px-4 py-2 text-sm text-gray-900', {
                    'cursor-pointer bg-gray-100': active || selected
                  })
                }>
                {address.label}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  )
})
