import { useDeleteAdminMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation deleteAdmin($id: ID!) {
    deleteAdmin(id: $id)
  }
`

export function useDeleteAdminUser() {
  const queryClient = useQueryClient()
  return useDeleteAdminMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['Admins'])
    }
  })
}
