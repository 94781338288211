import { useUpdateLookupMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation UpdateLookup(
    $id: ID!
    $fieldId: Int
    $order: Int
    $value: String
    $sourceId: String
    $mappedValue: String
  ) {
    updateLookup(
      id: $id
      field_id: $fieldId
      order: $order
      value: $value
      source_id: $sourceId
      mapped_value: $mappedValue
    ) {
      id
      field {
        id
        lookups {
          id
          order
          value
          mappedValue: mapped_value
          sourceId: source_id
        }
      }
    }
  }
`

export function useUpdateLookup() {
  const queryClient = useQueryClient()
  return useUpdateLookupMutation({
    async onSuccess(data) {
      await queryClient.invalidateQueries([
        'Field',
        { id: String(data.updateLookup?.field?.id) }
      ])
    }
  })
}
