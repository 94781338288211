import { Attribute, Tooltip } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingAgentFieldsProps {
  adapterId: string
  agentListEmail?: string | null
  agentListId?: string | null
  agentListName?: string | null
  agentListPhone?: string | null
  agentSellEmail?: string | null
  agentSellId?: string | null
  agentSellName?: string | null
  agentSellPhone?: string | null
  fields: Record<string, FieldPartsFragment>
  resourceGroupId: string
}

export function ListingAgentFields({
  adapterId,
  agentListEmail,
  agentListId,
  agentListName,
  agentListPhone,
  agentSellEmail,
  agentSellId,
  agentSellName,
  agentSellPhone,
  fields,
  resourceGroupId
}: ListingAgentFieldsProps) {
  return (
    <div className="2xl:flex">
      <div>
        <h3 className="p-2 text-lg">Agent List</h3>
        <ul className="min-w-sm overflow-auto pb-4 sm:max-w-full 2xl:max-w-[15rem]">
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="A system unique identifier. Specifically, in aggregation systems, the ListAgentKey is the system unique identifier from the system that the record was retrieved. This may be identical to the related xxxId. This is a foreign key relating to the Member resource's MemberKey.">
            <li className="p-2">
              Unique ID:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_list_id']?.id}
                label="agent_list_id"
                resourceGroupId={resourceGroupId}
                value={agentListId}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The full name of the listing agent.">
            <li className="p-2">
              Name:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_list_name']?.id}
                label="agent_list_name"
                resourceGroupId={resourceGroupId}
                value={agentListName}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The email address of the Listing Agent">
            <li className="p-2">
              Email:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_list_email']?.id}
                label="agent_list_email"
                resourceGroupId={resourceGroupId}
                value={agentListEmail}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol.">
            <li className="p-2">
              Phone:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_list_phone']?.id}
                label="agent_list_phone"
                resourceGroupId={resourceGroupId}
                value={agentListPhone}
              />
            </li>
          </Tooltip>
        </ul>
      </div>
      <div>
        <h3 className="p-2 text-lg">Agent Sell</h3>
        <ul className="overflow-auto sm:max-w-xl">
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="A system unique identifier. Specifically, in aggregation systems, the Key is the system unique identifier from the system that the record was just retrieved. This may be identical to the related xxxId identifier, but the key is guaranteed unique for this record set. This is a foreign key relating to the Member resource's MemberKey.">
            <li className="p-2">
              Unique ID:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_sell_id']?.id}
                label="agent_sell_id"
                resourceGroupId={resourceGroupId}
                value={agentSellId}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content={<span>The full name of the buyer's agent.</span>}>
            <li className="p-2">
              Name:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_sell_name']?.id}
                label="agent_sell_name"
                resourceGroupId={resourceGroupId}
                value={agentSellName}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The email address of the Buyer's Agent">
            <li className="p-2">
              Email:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_sell_email']?.id}
                label="agent_sell_email"
                resourceGroupId={resourceGroupId}
                value={agentSellEmail}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol.">
            <li className="p-2">
              Phone:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['agent_sell_phone']?.id}
                label="agent_sell_phone"
                resourceGroupId={resourceGroupId}
                value={agentSellPhone}
              />
            </li>
          </Tooltip>
        </ul>
      </div>
    </div>
  )
}
