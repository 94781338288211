import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Children, cloneElement, ReactElement, ReactNode, useRef } from 'react'
import { Label } from './Label'

interface ChildProps {
  id: string
  'aria-invalid': boolean
  'aria-label'?: string
  'aria-describedby'?: string
  variant?: 'danger'
}

interface FormFieldProps {
  label?: ReactNode
  secondaryLabel?: ReactNode
  tip?: string
  error?: string | (string | undefined)[]
  showErrorIcon?: boolean
  required?: boolean
  children: ReactElement | ((props: ChildProps) => ReactElement)
  'aria-label'?: string
}

export function FormField({
  label,
  secondaryLabel,
  required,
  tip,
  error,
  showErrorIcon,
  children,
  ...formProps
}: FormFieldProps) {
  const errors = (Array.isArray(error) ? error : [error]).filter(Boolean)
  const hasErrors = !!errors.length
  const fieldId = useRef(Math.random().toString(16).substr(2))
  const props: ChildProps = {
    id: `field-${fieldId.current}`,
    'aria-label': formProps['aria-label'],
    'aria-invalid': hasErrors,
    'aria-describedby': hasErrors ? `error-${fieldId.current}` : undefined,
    variant: hasErrors ? 'danger' : undefined
  }

  return (
    <div className="space-y-1">
      {!!label && (
        <div className="flex items-baseline justify-between space-x-2">
          <Label htmlFor={`field-${fieldId.current}`}>
            {label}
            {required && (
              <span className="ml-0.5 text-red-500">
                <span className="sr-only">required</span>
                <span aria-hidden>*</span>
              </span>
            )}
          </Label>
          {!!secondaryLabel && (
            <div className="text-xs italic text-gray-400">{secondaryLabel}</div>
          )}
        </div>
      )}
      <div>
        {typeof children === 'function'
          ? children(props)
          : Children.map(children, (child) => cloneElement(child, props))}
      </div>
      {tip && <i className="inline-block text-xs text-gray-400">{tip}</i>}
      {hasErrors && (
        <div className="space-y-1">
          {errors.map((error) => (
            <div key={error} className="flex space-x-1 text-xs text-red-600">
              {showErrorIcon && <ExclamationCircleIcon className="w-4 h-4" />}
              <p
                id={`error-${fieldId.current}`}
                role="alert"
                aria-live="polite">
                {error}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
