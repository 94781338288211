import {
  AdaptersQuery,
  useDeleteAdapterMutation
} from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation deleteAdapter($id: ID!) {
    deleteAdapter(id: $id)
  }
`

export function useDeleteAdapter() {
  const queryClient = useQueryClient()

  return useDeleteAdapterMutation({
    onMutate: async (deleteAdapter) => {
      await queryClient.cancelQueries(['Adapters', { limit: 1000 }])

      const previousTodos = queryClient.getQueryData<AdaptersQuery>([
        'Adapters',
        { limit: 1000 }
      ])

      queryClient.setQueryData<AdaptersQuery>(
        ['Adapters', { limit: 1000 }],
        (data) => ({
          adapters: data?.adapters?.filter(
            (adapter) => adapter.id !== deleteAdapter.id
          )
        })
      )

      return { previousTodos }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData<AdaptersQuery>(
        ['Adapters', { limit: 1000 }],
        context?.previousTodos
      )
    },
    onSettled() {
      queryClient.invalidateQueries(['Adapters', { limit: 1000 }])
    }
  })
}
