import { Breadcrumbs, Tooltip, Wrapper } from '@forge/common'
import {
  AdapterEditForm,
  AdapterTimezoneDebug,
  useAdapter
} from '@forge/features/adapters'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function AdapterEdit() {
  const { adapterId } = useParams()
  const { data: { adapter } = {}, dataUpdatedAt } = useAdapter({
    id: adapterId
  })

  return (
    <>
      <Helmet>
        <title>Adapter: {adapter?.key?.toUpperCase()} - Edit</title>
      </Helmet>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/edit`}>
            Edit
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
        <h1 className="flex text-4xl font-medium">
          Edit {adapter?.key?.toUpperCase()} Adapter
          <div className="px-2 py-2">
            <Tooltip
              delay={700}
              placement="right"
              content="Edit Adapter Credentials">
              <InformationCircleIcon className="h-5" />
            </Tooltip>
          </div>
        </h1>
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-2 lg:gap-16">
          <AdapterEditForm key={dataUpdatedAt} />
          <div>
            <h3 className="mb-4 text-2xl font-normal text-gray-700">
              Debug Timezones
            </h3>
            <div className="overflow-hidden rounded-lg bg-white p-4 shadow">
              {adapter?.id && <AdapterTimezoneDebug adapterId={adapter.id} />}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
