import {
  Combobox,
  ComboboxOption,
  FormField,
  Spinner,
  Tooltip
} from '@forge/common'
import { useFieldSearchValues } from '@forge/features/resourcegroups'
import { FilterQueryType } from '@forge/graphql/generated'
import { AdjustmentsIcon } from '@heroicons/react/outline'
import { parse } from 'qs'
import { Suspense, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MoreCriteria } from './MoreCriteria'

interface QuickListingCriteriaProps {
  bathsFieldId?: string | null
  bedsFieldId?: string | null
  cityFieldId?: string | null
  handleFilter: (field: FilterQueryType) => void
  propSubTypeFieldId?: string | null
  propTypeFieldId?: string | null
  statusFieldId?: string | null
  yearBuiltFieldId?: string | null
}

export function QuickListingCriteria({
  bathsFieldId,
  bedsFieldId,
  cityFieldId,
  handleFilter,
  propSubTypeFieldId,
  propTypeFieldId,
  statusFieldId,
  yearBuiltFieldId
}: QuickListingCriteriaProps) {
  const [moreCriteria, setMoreCriteria] = useState(false)

  const statusFieldSearchValues = useFieldSearchValues(statusFieldId)
  const statusesOptions =
    statusFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const propTypeFieldSearchValues = useFieldSearchValues(propTypeFieldId)
  const propertyTypesOptions =
    propTypeFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const propSubTypeFieldSearchValues = useFieldSearchValues(propSubTypeFieldId)
  const propertySubTypesOptions =
    propSubTypeFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const cityFieldSearchValues = useFieldSearchValues(cityFieldId)
  const citiesOptions = useMemo(
    () =>
      cityFieldSearchValues?.map((option) => ({
        label: option,
        value: option
      })) || [],
    [cityFieldSearchValues]
  )

  const yearBuiltFieldSearchValues = useFieldSearchValues(yearBuiltFieldId)
  const yearsBuiltOptions =
    yearBuiltFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const bedsFieldSearchValues = useFieldSearchValues(bedsFieldId)
  const bedsOptions =
    bedsFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const bathsFieldSearchValues = useFieldSearchValues(bathsFieldId)
  const bathsOptions =
    bathsFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const [uniqueIdsOptions, setUniqueIdsOptions] = useState<ComboboxOption[]>([])
  const [zipcodesOptions, setZipcodesOptions] = useState<ComboboxOption[]>([])
  const [mlsNumsOptions, setMlsNumsOptions] = useState<ComboboxOption[]>([])
  const [agentListNamesOptions, setAgentListNamesOptions] = useState<
    ComboboxOption[]
  >([])
  const [officeListNamesOptions, setOfficeListNamesOptions] = useState<
    ComboboxOption[]
  >([])

  const [searchParams] = useSearchParams()
  const parsedParams = parse(searchParams.toString()) as Record<
    string,
    FilterQueryType
  >

  return (
    <>
      <div>
        <div className="grid grid-cols-3 gap-3 pb-2 sm:gap-6 lg:grid-cols-3 2xl:grid-cols-6">
          <FormField>
            <Combobox
              placeholder="Status"
              multiple
              selected={statusesOptions.filter((option) =>
                parsedParams?.['status']?.eq?.includes(option.value)
              )}
              options={statusesOptions}
              onSelect={(options) => {
                handleFilter({
                  field: 'status',
                  eq: options.map((option) => option.value)
                })
              }}
            />
          </FormField>
          <FormField>
            <Combobox
              placeholder="Prop Type"
              multiple
              selected={propertyTypesOptions.filter((option) =>
                parsedParams?.['prop_type']?.eq?.includes(option.value)
              )}
              options={propertyTypesOptions}
              onSelect={(options) => {
                handleFilter({
                  field: 'prop_type',
                  eq: options.map((option) => option.value)
                })
              }}
            />
          </FormField>
          <FormField>
            <Combobox
              placeholder="Prop Sub Type"
              multiple
              selected={propertySubTypesOptions.filter((option) =>
                parsedParams?.['prop_sub_type']?.eq?.includes(option.value)
              )}
              options={propertySubTypesOptions}
              onSelect={(options) => {
                handleFilter({
                  field: 'prop_sub_type',
                  eq: options.map((option) => option.value)
                })
              }}
            />
          </FormField>
          <FormField>
            <Combobox
              placeholder="City"
              multiple
              selected={citiesOptions.filter((option) =>
                parsedParams?.['city']?.eq?.includes(option.value)
              )}
              options={citiesOptions}
              onSelect={(options) => {
                handleFilter({
                  field: 'city',
                  eq: options.map((option) => option.value)
                })
              }}
            />
          </FormField>
          <div className="xs:hidden 2xl:block">
            <FormField>
              <Combobox
                placeholder="Zipcode"
                multiple
                selected={zipcodesOptions.filter((option: ComboboxOption) =>
                  parsedParams?.['zipcode']?.eq?.includes(option.value)
                )}
                options={zipcodesOptions}
                onSelect={(options) => {
                  handleFilter({
                    field: 'zipcode',
                    eq: options.map((option) => option.value)
                  })
                }}
                onCreateOption={(newOption) => {
                  setZipcodesOptions((options) => [
                    ...(options || []),
                    newOption
                  ])
                }}
              />
            </FormField>
          </div>
          <div className="xs:hidden 2xl:block">
            <FormField>
              <Combobox
                placeholder="Year Built"
                multiple
                selected={yearsBuiltOptions.filter((option) =>
                  parsedParams?.['year_built']?.eq?.includes(option.value)
                )}
                options={yearsBuiltOptions}
                onSelect={(options) => {
                  handleFilter({
                    field: 'year_built',
                    eq: options.map((option) => option.value)
                  })
                }}
              />
            </FormField>
          </div>
          <div className="xs:hidden 2xl:block">
            <FormField>
              <Combobox
                placeholder="Beds"
                multiple
                selected={bedsOptions.filter((option) =>
                  parsedParams?.['beds']?.eq?.includes(option.value)
                )}
                options={bedsOptions}
                onSelect={(options) => {
                  handleFilter({
                    field: 'beds',
                    eq: options.map((option) => option.value)
                  })
                }}
              />
            </FormField>
          </div>
          <div className="xs:hidden 2xl:block">
            <FormField>
              <Combobox
                placeholder="Baths"
                multiple
                selected={bathsOptions.filter((option) =>
                  parsedParams?.['baths']?.eq?.includes(option.value)
                )}
                options={bathsOptions}
                onSelect={(options) => {
                  handleFilter({
                    field: 'baths',
                    eq: options.map((option) => option.value)
                  })
                }}
              />
            </FormField>
          </div>
          <FormField>
            <Combobox
              placeholder="Unique ID"
              multiple
              selected={uniqueIdsOptions?.filter((option: ComboboxOption) =>
                parsedParams?.['id']?.eq?.includes(option.value)
              )}
              options={uniqueIdsOptions}
              onSelect={(options) => {
                handleFilter({
                  field: 'id',
                  eq: options.map((option) => option.value)
                })
              }}
              onCreateOption={(newOption) => {
                setUniqueIdsOptions((options) => [
                  ...(options || []),
                  newOption
                ])
              }}
            />
          </FormField>

          <FormField>
            <Combobox
              placeholder="MLSnum"
              multiple
              selected={mlsNumsOptions?.filter((option: ComboboxOption) =>
                parsedParams?.['mlsnum']?.eq?.includes(option.value)
              )}
              options={mlsNumsOptions}
              onSelect={(options) => {
                handleFilter({
                  field: 'mlsnum',
                  eq: options.map((option) => option.value)
                })
              }}
              onCreateOption={(newOption) => {
                setMlsNumsOptions((options) => [...(options || []), newOption])
              }}
            />
          </FormField>
          <div className="xs:hidden 2xl:block">
            <FormField>
              <Combobox
                placeholder="Agent List Name"
                multiple
                selected={agentListNamesOptions?.filter(
                  (option: ComboboxOption) =>
                    parsedParams?.['agent_list_name']?.eq?.includes(
                      option.value
                    )
                )}
                options={agentListNamesOptions}
                onSelect={(options) => {
                  handleFilter({
                    field: 'agent_list_name',
                    eq: options.map((option) => option.value)
                  })
                }}
                onCreateOption={(newOption) => {
                  setAgentListNamesOptions((options) => [...options, newOption])
                }}
              />
            </FormField>
          </div>
          <div className="xs:hidden 2xl:block">
            <FormField>
              <Combobox
                placeholder="Office List Name"
                multiple
                selected={officeListNamesOptions?.filter(
                  (option: ComboboxOption) =>
                    parsedParams?.['office_list_name']?.eq?.includes(
                      option.value
                    )
                )}
                options={officeListNamesOptions}
                onSelect={(options) => {
                  handleFilter({
                    field: 'office_list_name',
                    eq: options.map((option) => option.value)
                  })
                }}
                onCreateOption={(newOption) => {
                  setOfficeListNamesOptions((options) => [
                    ...options,
                    newOption
                  ])
                }}
              />
            </FormField>
          </div>
        </div>
        <div>
          <div className="h-5 w-5 pb-2 hover:cursor-pointer hover:text-orange-400">
            <Tooltip delay={700} placement="right" content="More fields">
              <AdjustmentsIcon onClick={() => setMoreCriteria(!moreCriteria)} />
            </Tooltip>
          </div>
          <Suspense
            fallback={
              <div className="flex items-center gap-2 py-7 text-gray-500">
                <div className="h-5 w-5">
                  <Spinner />
                </div>
                <div className="text-sm">Loading Filters</div>
              </div>
            }>
            {moreCriteria && <MoreCriteria handleFilter={handleFilter} />}
          </Suspense>
        </div>
      </div>
    </>
  )
}

interface QuickCriteriaProps {
  handleFilter: (field: FilterQueryType) => void
}

export function QuickAgentCriteria({ handleFilter }: QuickCriteriaProps) {
  const [moreCriteria, setMoreCriteria] = useState(false)

  const [firstNamesOptions, setFirstNamesOptions] = useState<ComboboxOption[]>(
    []
  )
  const [lastNamesOptions, setLastNamesOptions] = useState<ComboboxOption[]>([])
  const [officeNamesOptions, setOfficeNamesOptions] = useState<
    ComboboxOption[]
  >([])
  const [emailsOptions, setEmailsOptions] = useState<ComboboxOption[]>([])
  const [uniqueIdsOptions, setUniqueIdsOptions] = useState<ComboboxOption[]>([])
  const [agentIdsOptions, setAgentIdsOptions] = useState<ComboboxOption[]>([])
  const [phoneNumbersOptions, setPhoneNumbersOptions] = useState<
    ComboboxOption[]
  >([])

  const activeFieldSearchValues = ['true', 'false']

  const activesOptions =
    activeFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const [searchParams] = useSearchParams()
  const parsedParams = parse(searchParams.toString()) as Record<
    string,
    FilterQueryType
  >

  return (
    <>
      <div className="grid grid-cols-1 gap-6 pb-2 sm:grid-cols-2 2xl:grid-cols-4">
        <FormField>
          <Combobox
            placeholder="First Name"
            multiple
            selected={firstNamesOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['firstname']?.eq?.includes(option.value)
            )}
            options={firstNamesOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'firstname',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setFirstNamesOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Last Name"
            multiple
            selected={lastNamesOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['lastname']?.eq?.includes(option.value)
            )}
            options={lastNamesOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'lastname',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setLastNamesOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Office Name"
            multiple
            selected={officeNamesOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['office_name']?.eq?.includes(option.value)
            )}
            options={officeNamesOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'office_name',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setOfficeNamesOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Email"
            multiple
            selected={emailsOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['email']?.eq?.includes(option.value)
            )}
            options={emailsOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'email',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setEmailsOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Unique Id"
            multiple
            selected={uniqueIdsOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['id']?.eq?.includes(option.value)
            )}
            options={uniqueIdsOptions || []}
            onSelect={(options) => {
              handleFilter({
                field: 'id',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setUniqueIdsOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Agent Id"
            multiple
            selected={agentIdsOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['agent_id']?.eq?.includes(option.value)
            )}
            options={agentIdsOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'agent_id',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setAgentIdsOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Phone Number"
            multiple
            selected={phoneNumbersOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['phone']?.eq?.includes(option.value)
            )}
            options={phoneNumbersOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'phone',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setPhoneNumbersOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Active"
            multiple
            selected={activesOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['active']?.eq?.includes(option.value)
            )}
            options={activesOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'active',
                eq: options.map((option) => option.value)
              })
            }}
          />
        </FormField>
      </div>
      <div>
        <div className="h-5 w-5 pb-2 hover:cursor-pointer hover:text-orange-400">
          <Tooltip delay={700} placement="right" content="More fields">
            <AdjustmentsIcon onClick={() => setMoreCriteria(!moreCriteria)} />
          </Tooltip>
        </div>
        <Suspense
          fallback={
            <div className="flex items-center gap-2 py-7 text-gray-500">
              <div className="h-5 w-5">
                <Spinner />
              </div>
              <div className="text-sm">Loading Filters</div>
            </div>
          }>
          {moreCriteria && <MoreCriteria handleFilter={handleFilter} />}
        </Suspense>
      </div>
    </>
  )
}

export function QuickOfficeCriteria({ handleFilter }: QuickCriteriaProps) {
  const [moreCriteria, setMoreCriteria] = useState(false)

  const [uniqueIdsOptions, setUniqueIdsOptions] = useState<ComboboxOption[]>([])
  const [officeIdsOptions, setOfficeIdsOptions] = useState<ComboboxOption[]>([])
  const [officeNamesOptions, setOfficeNamesOptions] = useState<
    ComboboxOption[]
  >([])
  const [emailsOptions, setEmailsOptions] = useState<ComboboxOption[]>([])
  const [phoneNumbersOptions, setPhoneNumbersOptions] = useState<
    ComboboxOption[]
  >([])

  const activeFieldSearchValues = ['true', 'false']

  const activesOptions =
    activeFieldSearchValues?.map((option) => ({
      label: option,
      value: option
    })) || []

  const [searchParams] = useSearchParams()
  const parsedParams = parse(searchParams.toString()) as Record<
    string,
    FilterQueryType
  >

  return (
    <>
      <div className="grid grid-cols-1 gap-6 pb-2 sm:grid-cols-2 lg:grid-cols-4">
        <FormField>
          <Combobox
            placeholder="Unique Id"
            multiple
            selected={uniqueIdsOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['id']?.eq?.includes(option.value)
            )}
            options={uniqueIdsOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'id',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setUniqueIdsOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Office Id"
            multiple
            selected={officeIdsOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['office_id']?.eq?.includes(option.value)
            )}
            options={officeIdsOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'office_id',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setOfficeIdsOptions((options) => [...options, newOption])
            }}
          />
        </FormField>

        <FormField>
          <Combobox
            placeholder="Office Name"
            multiple
            selected={officeNamesOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['name']?.eq?.includes(option.value)
            )}
            options={officeNamesOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'name',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setOfficeNamesOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Email"
            multiple
            selected={emailsOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['email']?.eq?.includes(option.value)
            )}
            options={emailsOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'email',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setEmailsOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Phone Number"
            multiple
            selected={phoneNumbersOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['phone']?.eq?.includes(option.value)
            )}
            options={phoneNumbersOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'phone',
                eq: options.map((option) => option.value)
              })
            }}
            onCreateOption={(newOption) => {
              setPhoneNumbersOptions((options) => [...options, newOption])
            }}
          />
        </FormField>
        <FormField>
          <Combobox
            placeholder="Active"
            multiple
            selected={activesOptions?.filter((option: ComboboxOption) =>
              parsedParams?.['active']?.eq?.includes(option.value)
            )}
            options={activesOptions}
            onSelect={(options) => {
              handleFilter({
                field: 'active',
                eq: options.map((option) => option.value)
              })
            }}
          />
        </FormField>
      </div>
      <div>
        <div className="h-5 w-5 pb-2 hover:cursor-pointer hover:text-orange-400">
          <Tooltip delay={700} placement="right" content="More fields">
            <AdjustmentsIcon onClick={() => setMoreCriteria(!moreCriteria)} />
          </Tooltip>
        </div>
        <Suspense
          fallback={
            <div className="flex items-center gap-2 py-7 text-gray-500">
              <div className="h-5 w-5">
                <Spinner />
              </div>
              <div className="text-sm">Loading Filters</div>
            </div>
          }>
          {moreCriteria && <MoreCriteria handleFilter={handleFilter} />}
        </Suspense>
      </div>
    </>
  )
}
