import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { removePersistedCurrentUser } from './utils'

export function useLogout() {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    // await queryClient.cancelMutations()
    await queryClient.cancelQueries()

    removePersistedCurrentUser()
    queryClient.setQueryData(['CurrentUser'], null)
    queryClient.clear()
  }, [queryClient])
}
