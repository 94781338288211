import {
  Children,
  createContext,
  PropsWithChildren,
  RefObject,
  useContext,
  useRef
} from 'react'
import { Button, ButtonProps } from './Button'
import { Modal } from './Modal'

type Variant = 'primary' | 'secondary' | 'danger'

const AlertContext = createContext<{
  variant: Variant
  cancelButtonRef: RefObject<HTMLButtonElement>
}>({
  variant: 'primary',
  cancelButtonRef: { current: null }
})

interface AlertProps {
  isOpen: boolean
  variant?: Variant
}

export function Alert({
  isOpen,
  variant = 'primary',
  children
}: PropsWithChildren<AlertProps>) {
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const childrenArray = Children.toArray(children)

  // TODO: Correctly type this.
  const title = childrenArray.find(
    (child) => (child as any).type.name === AlertTitle.name
  )
  const content = childrenArray.find(
    (child) => (child as any).type.name === AlertContent.name
  )
  const cancel = childrenArray.find(
    (child) => (child as any).type.name === AlertCancel.name
  )
  const confirm = childrenArray.find(
    (child) => (child as any).type.name === AlertConfirm.name
  )

  return (
    <AlertContext.Provider value={{ variant, cancelButtonRef }}>
      <Modal
        hideClose
        isOpen={isOpen}
        onClose={() => {
          // We noop here on close because we want the user to cancel or confirm
        }}>
        {title}
        <div className="mt-2">{content}</div>
        <div className="mt-5 flex justify-end space-x-2 sm:mt-4">
          {cancel}
          {confirm}
        </div>
      </Modal>
    </AlertContext.Provider>
  )
}

Alert.Title = AlertTitle
Alert.Content = AlertContent
Alert.Cancel = AlertCancel
Alert.Confirm = AlertConfirm

function AlertTitle(props: PropsWithChildren<unknown>) {
  return <Modal.Title {...props} />
}

function AlertContent({ children }: PropsWithChildren<unknown>) {
  return <div className="text-sm text-gray-500">{children}</div>
}

function AlertCancel({
  children = 'Cancel',
  ...props
}: Omit<ButtonProps, 'variant'>) {
  const { cancelButtonRef, variant } = useContext(AlertContext)
  return (
    <Button {...props} variant={variant} outline ref={cancelButtonRef}>
      {children}
    </Button>
  )
}

function AlertConfirm({
  children = 'Confirm',
  ...props
}: Omit<ButtonProps, 'variant'>) {
  const { cancelButtonRef, variant } = useContext(AlertContext)
  return (
    <Button {...props} variant={variant} ref={cancelButtonRef}>
      {children}
    </Button>
  )
}
