import { Button, FormField, Input, Tabs } from '@forge/common'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useSyncRecords } from './useSyncRecords'

const schema = yup.object({
  ids: yup.string().required('Ids are required')
})

export function ResourceSyncRecords() {
  const { id } = useParams()
  const {
    mutate: syncRecords,
    data: syncedRecords,
    isLoading,
    isSuccess
  } = useSyncRecords()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const hasFailed = isSuccess && !syncedRecords?.resource?.reloadRecord.success

  return (
    <div className="space-y-4">
      <form
        className="space-y-4"
        onSubmit={handleSubmit((data) =>
          syncRecords({ id, recordIds: data.ids.split(/\n/gi) })
        )}>
        <FormField
          label="Ids"
          error={
            errors.ids?.message
              ? errors.ids.message
              : hasFailed
              ? 'Sync failed, please try again.'
              : undefined
          }>
          <Input
            {...register('ids')}
            as="textarea"
            rows={5}
            placeholder="Paste in MLSnums/AgentIDs/OfficeIDs separated by hard returns."
          />
        </FormField>
        <Button
          variant={hasFailed ? 'danger' : 'primary'}
          size="xs"
          loading={isLoading}>
          {hasFailed ? 'Try Again' : 'Sync Records'}
        </Button>
      </form>
      {syncedRecords && (
        <Tabs>
          <Tabs.List>
            <Tabs.Tab>Results</Tabs.Tab>
            <Tabs.Tab>Logs</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              <pre className="overflow-auto">
                {JSON.stringify(
                  syncedRecords.resource?.reloadRecord.records,
                  null,
                  2
                )}
              </pre>
            </Tabs.Panel>
            <Tabs.Panel>
              <div className="space-y-4">
                <div>{hasFailed ? 'Failed' : 'Success!'}</div>
                {syncedRecords.resource?.reloadRecord.logs && (
                  <pre
                    className="overflow-auto"
                    dangerouslySetInnerHTML={{
                      __html: syncedRecords.resource?.reloadRecord.logs
                    }}
                  />
                )}
              </div>
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      )}
    </div>
  )
}
