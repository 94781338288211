import classNames from 'classnames'

interface ProgressProps {
  progress: number | null
}

export function Progress({ progress }: ProgressProps) {
  return (
    <div className="relative w-full h-1 overflow-hidden bg-gray-200 rounded-full">
      {progress !== null && (
        <div
          className={classNames('absolute top-0 left-0 h-full', {
            'bg-green-500': progress >= 100,
            'bg-yellow-500': progress < 100
          })}
          style={{
            width: `${progress}%`
          }}
        />
      )}
    </div>
  )
}
