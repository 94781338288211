import { Modal, Table } from '@forge/common'
import { MetadataFieldsFragment } from '@forge/graphql/generated'
import {
  ClipboardListIcon,
  InformationCircleIcon
} from '@heroicons/react/solid'
import { useState } from 'react'

interface MetadataTableProps {
  fields: MetadataFieldsFragment[] | null | undefined
  search: string
}

export function MetadataTable({ fields, search }: MetadataTableProps) {
  const [field, setField] = useState<any | null>(null)
  const [lookups, setLookups] = useState<unknown[] | null>(null)

  if (!fields) return null

  const mdFields: Array<{ [key: string]: any }> = fields

  return (
    <div className="max-h-[calc(100vh-19rem)] overflow-y-auto rounded-lg bg-white px-1 shadow">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">System Name</Table.Header>
            <Table.Header align="left">Standard Name</Table.Header>
            <Table.Header align="left">Long Name</Table.Header>
            <Table.Header align="left">Type</Table.Header>
            <Table.Header align="center">Lookups</Table.Header>
            <Table.Header align="center">Info</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {mdFields
            .filter((field) => {
              return Object.keys(field).some((key) => {
                if (field[key]) {
                  return field[key]
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
                }
                return null
              })
            })
            .map((field) => (
              <tr
                key={field.SystemName}
                style={{
                  display: !field ? 'none' : 'table-row'
                }}>
                <Table.Data>
                  <div className="w-40 overflow-x-scroll">
                    {field.SystemName}
                  </div>
                </Table.Data>
                <Table.Data>
                  <div className="w-40 overflow-x-scroll">
                    {field.StandardName}
                  </div>
                </Table.Data>
                <Table.Data>{field.Interpretation}</Table.Data>
                <Table.Data>
                  <div className="w-24 overflow-x-scroll">{field.DataType}</div>
                </Table.Data>
                <Table.Data align="center">
                  {field.Lookups && field.Lookups.length > 0 && (
                    <ClipboardListIcon
                      className="mx-auto h-5 w-5 cursor-pointer text-orange-400"
                      onClick={() => setLookups(field.Lookups)}
                    />
                  )}
                </Table.Data>
                <Table.Data align="center">
                  <InformationCircleIcon
                    className="mx-auto h-5 w-5 cursor-pointer text-orange-400"
                    onClick={() => setField(field)}
                  />
                </Table.Data>
              </tr>
            ))}
        </Table.Body>
      </Table>
      {lookups && (
        <Modal size="3xl" isOpen onClose={() => setLookups(null)}>
          <Modal.Title>Lookups</Modal.Title>
          <div className="max-h-[calc(100vh-18.75rem)] overflow-y-auto">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Value</Table.Header>
                  <Table.Header>Long Value</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {lookups.length &&
                  lookups.map((lookup: any) => (
                    <Table.Row key={lookup.value}>
                      <Table.Data>{lookup.value}</Table.Data>
                      <Table.Data>{lookup.long_value}</Table.Data>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        </Modal>
      )}
      {field && (
        <Modal size="3xl" isOpen onClose={() => setField(null)}>
          <Modal.Title>Info</Modal.Title>
          <div className="max-h-[calc(100vh-18.75rem)] overflow-y-auto">
            <Table>
              <Table.Body>
                {Object.keys(field).map((key) => (
                  <tr
                    key={key}
                    style={{
                      display: key === 'Lookups' ? 'none' : 'table-row'
                    }}>
                    <Table.Data>{key}:</Table.Data>
                    <Table.Data>
                      {key === 'Lookups' ? '' : field[key]}
                    </Table.Data>
                  </tr>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal>
      )}
    </div>
  )
}
