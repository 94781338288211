import { Breadcrumbs, Button, Tooltip, Wrapper } from '@forge/common'
import { ApiUsersTable } from '@forge/features/apiusers'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

export function ApiUsers() {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>API Users</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/api_users">API Users</Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="space-y-4">
          <div className="flex items-center justify-between space-x-2">
            <h1 className="flex text-4xl font-medium">
              API Users
              <div className="px-2 py-2">
                <Tooltip
                  delay={700}
                  placement="right"
                  content="API Users are better thought of as applications that query
                Forge's GQL endpoint for data.">
                  <InformationCircleIcon className="h-5" />
                </Tooltip>
              </div>
            </h1>
            <Button onClick={() => navigate(`/api_users/new`)}>
              New API User
            </Button>
          </div>
          <ApiUsersTable />
        </div>
      </Wrapper>
    </>
  )
}
