import {
  ListingServiceQueryVariables,
  useListingServiceQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  fragment serviceAdapterFields on ServiceAdapter {
    id
    adapter {
      id
      key
      recordCounts: record_counts {
        today
        listings
        agents
        offices
      }
    }
  }

  query ListingService($id: ID) {
    listingService: listing_service(id: $id) {
      id
      key
      name
      address
      timeZone: time_zone
      lat
      lon
      state
      defaultSearch: default_search
      config
      flags {
        key
        value
      }
      activations(limit: 10000) {
        id
        apiUser: api_user {
          id
          name
        }
      }
      serviceAdapters: service_adapters {
        ...serviceAdapterFields
      }
    }
  }
`

export function useListingService(vars: ListingServiceQueryVariables) {
  return useListingServiceQuery(vars, {
    enabled: !!vars.id
  })
}
