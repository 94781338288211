import { useApiUserQuery } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

export const apiUserFragment = gql`
  fragment defaultCriteriaFields on DefaultCriterium {
    id
    value
    scope
    parentId: parent_id
    parentType: parent_type
    fieldName: field_name
    casCriterium: cas_criterium
    createdAt: created_at
    updatedAt: updated_at
  }

  fragment apiUserFields on ApiUser {
    id
    name
    search
    replication
    jwt
    environment
    apiKey: api_key
    forgeApi: forge_api
    fullAccess: full_access
    percolatorNotifications: percolator_notifications
    percolatorEndpoint: percolator_endpoint
    defaultCriteria: default_criteria {
      ...defaultCriteriaFields
    }
    activations(limit: 10000) {
      id
      createdAtIso: created_at_iso
      updatedAt: updated_at
      listingService: listing_service {
        id
        key
        name
      }
    }
  }
`

gql`
  query ApiUser($id: ID) {
    apiUser: api_user(id: $id) {
      ...apiUserFields
    }
  }
  ${apiUserFragment}
`

export function useApiUser(id: string) {
  return useApiUserQuery(
    { id },
    {
      enabled: !!id
    }
  )
}
