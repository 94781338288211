import { Breadcrumbs, Button, Tooltip, Wrapper } from '@forge/common'
import { AdminUsersTable } from '@forge/features/adminusers'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

export function AdminUsers() {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>Admin Users</title>
      </Helmet>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/admins">Admin Users</Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="space-y-4">
          <div className="flex items-center justify-between space-x-2">
            <h1 className="flex text-4xl font-medium">
              Admin Users
              <div className="px-2 py-2">
                <Tooltip
                  delay={700}
                  placement="right"
                  content="Admin Users are users of Forge with various roles which
                      determine app functionality.">
                  <InformationCircleIcon className="h-5" />
                </Tooltip>
              </div>
            </h1>
            <Button onClick={() => navigate(`/admins/new`)}>New Admin</Button>
          </div>
          <AdminUsersTable />
        </div>
      </Wrapper>
    </>
  )
}
