import {
  ResourceQueryVariables,
  useResourceQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'
import { statsFragment } from '../adapters/useAdapterStats'

gql`
  query Resource($id: ID!, $limit: Int, $page: Int, $job: StatJob) {
    resource: resource(id: $id) {
      id
      name
      latestAudit: latest_audit
      defaultCriteria: default_criteria {
        casCriterium: cas_criterium
        createdAt: created_at
        fieldName: field_name
        id
        parentId: parent_id
        parentType: parent_type
        scope
        updatedAt: updated_at
        value
      }
      recentAudits: recent_audits {
        id
        createdAt: created_at
        humanCreatedAgo: human_created_ago
        accuracyRate: accuracy_rate
        resolvedRate: resolved_rate
        humanCreatedAgo: human_created_ago
        tookHuman: took_human
        totalRecords: total_records
        status
      }
      updatesByHour: updates_by_hour
      stats(page: $page, limit: $limit, job: $job) {
        ...statFields
      }
    }
  }
  ${statsFragment}
`

export function useResource(vars: ResourceQueryVariables) {
  return useResourceQuery(vars, { enabled: !!vars.id })
}
