import { useCreateLookupMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation CreateLookup(
    $fieldId: Int
    $sourceId: String!
    $value: String!
    $mappedValue: String
  ) {
    createLookup(
      field_id: $fieldId
      source_id: $sourceId
      value: $value
      mapped_value: $mappedValue
    ) {
      id
    }
  }
`

export function useCreateLookup() {
  const queryClient = useQueryClient()
  return useCreateLookupMutation({
    async onSuccess(_, { fieldId }) {
      await queryClient.invalidateQueries(['Field', { id: String(fieldId) }])
    }
  })
}
