import {
  useDefaultSchemaQuery,
  DefaultSchemaQueryVariables
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  fragment DefaultSchemaParts on DefaultSchemaField {
    colName: col_name
    colType: col_type
    standardName: standard_name
    path
  }

  query DefaultSchema($role: ResourceGroupRole) {
    defaultSchema: default_schema(role: $role) {
      ...DefaultSchemaParts
    }
  }
`

export function useDefaultSchema(vars: DefaultSchemaQueryVariables) {
  return useDefaultSchemaQuery(vars)
}
