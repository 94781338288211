import { Diff, Table } from '@forge/common'
import { TransformPipelineMutation } from '@forge/graphql/generated'

interface TransformPipelineProps {
  pipeline: TransformPipelineMutation['transformPipeline']
}

export function TransformPipeline({ pipeline }: TransformPipelineProps) {
  return (
    <div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Raw Input</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Data>
              {pipeline?.rawSource ? (
                <pre>
                  {JSON.stringify(
                    JSON.parse(pipeline.rawSource || '{}'),
                    null,
                    2
                  )}
                </pre>
              ) : (
                'No input'
              )}
            </Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
      {pipeline?.steps?.map((step, index) => (
        <Table key={`${step.output}_${index}`}>
          <Table.Head>
            <Table.Row>
              <Table.Header align="left">{step.file}</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.Data>
                <Diff value={step.input} newValue={step.output} />
              </Table.Data>
            </Table.Row>
          </Table.Body>
        </Table>
      ))}
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Final Output</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Data>
              {pipeline?.finalOutput ? (
                <pre>
                  {JSON.stringify(
                    JSON.parse(pipeline?.finalOutput || '{}'),
                    null,
                    2
                  )}
                </pre>
              ) : (
                'No output'
              )}
            </Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}
