import { Attribute, copyTextToClipboard, formatDate } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { useEffect, useState } from 'react'

interface ListingDisclaimerProps {
  adapterId: string
  resourceGroupId: string
  fields: Record<string, FieldPartsFragment>
  mlsnum?: string | null
  updatedAt?: string | number | Date | null
  officeListName?: string | null
}

export function ListingDisclaimer({
  adapterId,
  resourceGroupId,
  fields,
  mlsnum,
  updatedAt,
  officeListName
}: ListingDisclaimerProps) {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!copied) return

    const timeout = setTimeout(() => {
      setCopied(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [copied])

  return (
    <div className="text-xs text-gray-900">
      <div className="mb-0.5 flex items-center gap-2">
        <button
          className="flex items-center gap-2"
          onClick={() => {
            copyTextToClipboard(mlsnum || '')
            setCopied(true)
          }}>
          <div className="text-blue hover:cursor-pointer">
            MLS #{' '}
            <Attribute
              adapterId={adapterId}
              fieldId={fields?.['mlsnum']?.id || fields?.['id']?.id}
              label="mlsnum"
              resourceGroupId={resourceGroupId}
              value={mlsnum}
            />
          </div>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 12"
            className="h-[0.75rem] w-[1rem] text-gray-500">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.405.5H3.23c-.658 0-1.196.45-1.196 1v7H3.23v-7h7.176v-1Zm1.794 2H5.621c-.658 0-1.196.45-1.196 1v7c0 .55.538 1 1.196 1H12.2c.658 0 1.196-.45 1.196-1v-7c0-.55-.538-1-1.196-1Zm0 8H5.621v-7H12.2v7Z"
              fill="currentColor"
            />
          </svg>
        </button>
        {copied && <div className="text-gray-500">Copied!</div>}
      </div>
      <div>
        <div className="hover:cursor-pointer">
          Last Updated:{' '}
          <Attribute
            adapterId={adapterId}
            resourceGroupId={resourceGroupId}
            fieldId={fields?.['updated_at']?.id}
            value={formatDate(updatedAt || 0, 'MM/dd/yyyy hh:mm aaaa')}
            label="updated_at"
          />
        </div>
      </div>
      <div>
        <div className="hover:cursor-pointer">
          Courtesy of{' '}
          <Attribute
            adapterId={adapterId}
            fieldId={fields?.['office_list_name']?.id}
            label="office_list_name"
            resourceGroupId={resourceGroupId}
            value={officeListName}
          />
        </div>
      </div>
    </div>
  )
}
