import { Menu as HeadlessMenu, Transition } from '@headlessui/react'
import {
  ElementType,
  ForwardedRef,
  forwardRef,
  Fragment,
  PropsWithChildren,
  ReactNode
} from 'react'
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef
} from 'react-polymorphic-types'
import { classNames } from './utils'

interface MenuProps {
  button: ReactNode
  direction?: 'left' | 'center' | 'right'
}

export function Menu({
  button,
  direction = 'right',
  children
}: PropsWithChildren<MenuProps>) {
  return (
    <HeadlessMenu as="div" className="relative inline-block text-left">
      <HeadlessMenu.Button className="flex items-center capitalize">
        <span className="sr-only">Open menu</span>
        {button}
      </HeadlessMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <HeadlessMenu.Items
          className={classNames(
            'absolute z-20 mt-2 overflow-hidden whitespace-nowrap rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            {
              'left-0 origin-top-left': direction === 'left',
              'origin-top-center left-1/2 -translate-x-1/2':
                direction === 'center',
              'right-0 origin-top-right': direction === 'right'
            }
          )}>
          {children}
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>
  )
}

const defaultElement = 'button'

interface MenuItemProps {
  icon?: ReactNode
  variant?: 'default' | 'danger' | 'success'
  disabled?: boolean
  active?: boolean
}

const MenuItem: PolymorphicForwardRefExoticComponent<
  MenuItemProps,
  typeof defaultElement
> = forwardRef(function MenuItem<T extends ElementType = typeof defaultElement>(
  {
    as,
    variant = 'default',
    active: controlledActive,
    disabled,
    children,
    icon,
    ...props
  }: PolymorphicPropsWithoutRef<MenuItemProps, T>,
  ref: ForwardedRef<Element>
) {
  // TODO: Figure out why we can't omit the className
  // TODO: Remove this once the above is fixed
  if (props.className) {
    throw new Error('You cannot add classNames to the menu item')
  }

  const Element: ElementType = as || defaultElement

  return (
    <HeadlessMenu.Item disabled={disabled}>
      {({ active }) => {
        // If we pass active in from above, override the local active
        const isActive = controlledActive ?? active

        return (
          <Element
            ref={ref}
            {...props}
            className={classNames(
              'flex w-full items-center justify-between rounded-md px-4 py-2 text-left text-sm capitalize',
              {
                'bg-gray-100 text-gray-900': isActive && variant === 'default',
                'text-gray-900': !isActive && variant === 'default',

                'bg-red-100 text-red-900': isActive && variant === 'danger',
                'text-red-700': !isActive && variant === 'danger',

                'bg-green-100 text-green-900':
                  isActive && variant === 'success',
                'text-green-700': !isActive && variant === 'success',

                // Only dim the item if we aren't controlling active
                'opacity-50': disabled && !controlledActive,
                'cursor-not-allowed': disabled
              }
            )}>
            <span>{children}</span>
            <span
              className={classNames('ml-2 h-4 w-4 text-gray-600', {
                'text-red-900': isActive && variant === 'danger',
                'text-red-700': !isActive && variant === 'danger'
              })}>
              {icon}
            </span>
          </Element>
        )
      }}
    </HeadlessMenu.Item>
  )
})

Menu.Item = MenuItem
