import { Alert } from '@forge/common'
import { useDeleteRecord } from '@forge/features/resourcegroups'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

interface DeleteRecordProps {
  resourceGroupId: string | number
  id: string | number | null | undefined
  name: string | null | undefined
  isOpen: boolean
  onClose: () => void
  onDelete?: () => void
}

export function DeleteRecord({
  resourceGroupId,
  id,
  name,
  isOpen,
  onClose,
  onDelete
}: DeleteRecordProps) {
  const {
    mutate: deleteRecord,
    isLoading: isDeletingRecord,
    error
  } = useDeleteRecord()

  return (
    <Alert variant="danger" isOpen={isOpen}>
      <Alert.Title>Delete Record</Alert.Title>
      <Alert.Content>
        <div className="space-y-2">
          <div>
            Are you sure you want to delete {name}? This action cannot be
            undone.
          </div>
          {error && (
            <div className="text-red-500" role="alert" aria-live="polite">
              {error.message}
            </div>
          )}
        </div>
      </Alert.Content>
      <Alert.Cancel onClick={onClose}>Cancel</Alert.Cancel>
      <Alert.Confirm
        leftIcon={<ExclamationCircleIcon />}
        loading={isDeletingRecord}
        onClick={() => {
          deleteRecord({ id: String(resourceGroupId), record_id: String(id) })
          onDelete?.()
        }}>
        Delete Forever
      </Alert.Confirm>
    </Alert>
  )
}
