import { Modal, Progress, Table, Tooltip } from '@forge/common'
import { useDashboardResourceGroups } from '@forge/features/dashboard'
import { ResourceGroupStatus } from '@forge/graphql/generated'
import { RefreshIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export function HeaderResourceGroups() {
  const [showModal, setShowModal] = useState(false)
  const { data: { resourceGroups: loadingResourceGroups } = {} } =
    useDashboardResourceGroups({ status: ResourceGroupStatus.Loading })
  const { data: { resourceGroups: pendingResourceGroups } = {} } =
    useDashboardResourceGroups({ status: ResourceGroupStatus.Pending })
  const resourceGroups = [
    ...(loadingResourceGroups || []),
    ...(pendingResourceGroups || [])
  ]
  const resourceGroupCount = resourceGroups?.length || 0

  return (
    <div>
      <Tooltip delay={700} placement="bottom" content="Reindexing">
        <button className="relative flex" onClick={() => setShowModal(true)}>
          <span className="sr-only">View Resource Groups</span>
          {resourceGroupCount > 0 && (
            <span className="absolute -top-1.5 -right-1.5 rounded-full bg-red-500 px-1.5 py-0.5 text-[0.5rem] text-white ring-2 ring-white">
              {resourceGroupCount > 99 ? '99+' : resourceGroupCount}
            </span>
          )}
          <RefreshIcon className="h-6 w-6 text-slate-400" aria-hidden="true" />
        </button>
      </Tooltip>
      <Modal
        size="2xl"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onAfterClose={() => {}}>
        <Modal.Title>
          Reindexing
          {resourceGroups?.length > 10 && (
            <span className="float-right pr-2 text-sm">
              {' '}
              Count: {resourceGroupCount}
            </span>
          )}
        </Modal.Title>
        <div className="max-h-[calc(100vh-18.75rem)] overflow-auto">
          {resourceGroupCount <= 0 && <h3>Nothing's Reindexing. 🥳</h3>}
          {resourceGroupCount > 0 && (
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header align="left">Adapter</Table.Header>
                  <Table.Header align="center">Group</Table.Header>
                  <Table.Header align="center">Progress</Table.Header>
                  <Table.Header align="center">Complete</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {resourceGroups?.map(
                  ({ id, role, loadingProgress, adapter }) => {
                    const progress = Number(loadingProgress ?? 100)
                    return (
                      <Table.Row key={id}>
                        <Table.Data align="left">
                          <Link
                            to={`/adapters/${adapter?.id}/details`}
                            onClick={() => setShowModal(false)}
                            className="hover:text-orange-400">
                            {adapter?.key?.toUpperCase()}
                          </Link>
                        </Table.Data>
                        <Table.Data align="center">
                          <i>{role}</i>
                        </Table.Data>
                        <Table.Data align="center">
                          <Progress progress={progress} />
                        </Table.Data>
                        <Table.Data align="center">
                          <span
                            className={classNames({
                              'text-green-500': progress >= 100,
                              'text-yellow-500': progress < 100
                            })}>
                            {progress}%
                          </span>
                        </Table.Data>
                      </Table.Row>
                    )
                  }
                )}
              </Table.Body>
            </Table>
          )}
        </div>
      </Modal>
    </div>
  )
}
