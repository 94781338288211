import { Attribute, Tooltip } from '@forge/common'
import { useResourceGroupSearchFields } from '@forge/features/resourcegroups'
import { FieldPartsFragment, OfficeResultType } from '@forge/graphql/generated'
import { OfficeHeader } from './OfficeHeader'

interface OfficeDetailProps {
  adapterId: string
  resourceGroupId: string
  office: OfficeResultType
  onClose: () => void
}

export function OfficeDetail({
  office,
  adapterId,
  resourceGroupId,
  onClose
}: OfficeDetailProps) {
  const { data: resourceGroupSearchFields = {} } = useResourceGroupSearchFields(
    {
      resourceGroupId
    }
  )

  const searchFields = resourceGroupSearchFields?.resourceGroup?.fields?.sort(
    (a, b) => (a?.displayName || '').localeCompare(b?.displayName || '')
  )

  const fields =
    searchFields?.reduce<Record<string, FieldPartsFragment>>(
      (fields, field) => {
        const key = field.aliases?.[0] || field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}

  return (
    <>
      <OfficeHeader onClose={onClose}></OfficeHeader>
      <div className="flex">
        <div className="w-9/12 mx-auto mt-4">
          <h3>Root</h3>
          <div className="mt-4 columns-2">
            <ul>
              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="A unique identifier for this record from the immediate source.">
                <li className="p-2 cursor-pointer">
                  Unique ID:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['id']?.id || fields['office_id']?.id}
                    label="id"
                    resourceGroupId={resourceGroupId}
                    value={office.id}
                  />
                </li>
              </Tooltip>
              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The local, well-known identifier for the Office. This value may not be unique, specifically in the case of aggregation systems, this value should be the identifier from the original system.">
                <li className="p-2 cursor-pointer">
                  Office MLS ID:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['office_id']?.id || fields['id']?.id}
                    label="office_id"
                    resourceGroupId={resourceGroupId}
                    value={office.office_id}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The full legal name of the Office">
                <li className="p-2 cursor-pointer">
                  Office Name:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['name']?.id}
                    label="name"
                    resourceGroupId={resourceGroupId}
                    value={office.name}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="The email address of the Office.">
                <li className="p-2 cursor-pointer">
                  Email:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['email']?.id}
                    label="email"
                    resourceGroupId={resourceGroupId}
                    value={office.email}
                  />
                </li>
              </Tooltip>
              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol.">
                <li className="p-2 cursor-pointer">
                  Phone:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['phone']?.id}
                    label="phone"
                    resourceGroupId={resourceGroupId}
                    value={office.phone}
                  />
                </li>
              </Tooltip>

              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="Date/time the roster (member) record was last modified.">
                <li className="p-2 cursor-pointer">
                  Updated At:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['updated_at']?.id}
                    label="updated_at"
                    resourceGroupId={resourceGroupId}
                    value={office.updated_at}
                  />
                </li>
              </Tooltip>
              <Tooltip
                delay={700}
                placement="left"
                maxWidth={150}
                content="Current Status of Office.">
                <li className="p-2 cursor-pointer">
                  Active:{' '}
                  <Attribute
                    adapterId={adapterId}
                    fieldId={fields['active']?.id}
                    label="active"
                    resourceGroupId={resourceGroupId}
                    value={office.active}
                  />
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
