import { Breadcrumbs, Spinner, Tooltip, Wrapper } from '@forge/common'
import { useAdapter, AdapterDebuggerRoute } from '@forge/features/adapters'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function AdapterDebugger() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })

  return (
    <>
      <Helmet>
        <title>Adapter: {adapter?.key?.toUpperCase()} - Debugger</title>
      </Helmet>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapterId}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapterId}/debugger`}>
            Adapter Debugger
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="mx-auto sm:px-6 lg:px-8">
          <div className="flex w-full">
            <h1 className="flex text-4xl font-medium">
              Adapter Debugger
              <div className="px-2 py-2">
                <Tooltip delay={700} placement="right" content="Debug Adapter">
                  <InformationCircleIcon className="h-5" />
                </Tooltip>
              </div>
            </h1>
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:px-6 lg:px-0">
            <Suspense
              fallback={
                <div className="w-full p-5">
                  <div className="mx-auto h-5 w-5 text-orange-400">
                    <Spinner />
                  </div>
                </div>
              }>
              <div className="w-full">
                <AdapterDebuggerRoute />
              </div>
            </Suspense>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
