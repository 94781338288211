import {
  TransformPipelineMutation,
  TransformPipelineMutationVariables,
  useTransformPipelineMutation
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'
import { useEffect, useState } from 'react'

gql`
  mutation TransformPipeline($fieldId: ID!, $rawSource: String) {
    transformPipeline(field_id: $fieldId, raw_source: $rawSource) {
      rawSource: raw_source
      finalOutput: final_output
      steps {
        file
        input
        output
      }
    }
  }
`

export function useTransformPipeline({
  fieldId,
  rawSource,
  timestamp
}: TransformPipelineMutationVariables & { timestamp?: number }) {
  const [data, setData] = useState<TransformPipelineMutation | undefined>()
  const { mutate: transformPipeline, ...rest } = useTransformPipelineMutation()

  useEffect(() => {
    let isMounted = true

    // TODO: This should really be a query instead of a mutation.
    transformPipeline(
      { fieldId, rawSource },
      {
        onSuccess(data) {
          // This avoids FOUC
          if (data && isMounted) {
            setData(data)
          }
        }
      }
    )

    return () => {
      isMounted = false
    }

    // Timestamp is to make this rerender since it's not a query but rather a mutation
  }, [transformPipeline, fieldId, rawSource, timestamp])

  return { ...rest, data }
}
