import { Notifier } from '@airbrake/browser'
import { format, formatDistanceToNowStrict } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import numeral from 'numeral'

const ianaTimeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone

export function classNames(...classNames: (string | object)[]) {
  return classNames
    .reduce<string[]>((classes, clazz) => {
      if (typeof clazz === 'string') return [...classes, clazz]
      if (typeof clazz === 'object')
        return [
          ...classes,
          ...Object.entries(clazz)
            .filter(([, value]) => !!value)
            .map(([key]) => key)
        ]
      return classes
    }, [])
    .join(' ')
}

export function copyTextToClipboard(text: string) {
  window.navigator.clipboard.writeText(text)
}

export function getDateInTimeZone(dateValue: number | Date) {
  if (!dateValue) return dateValue
  return utcToZonedTime(dateValue, ianaTimeZone)
}

export function getTimeAgo(dateValue: string | number | Date) {
  if (!dateValue) return dateValue
  const timeAgo = formatDistanceToNowStrict(
    getDateInTimeZone(new Date(dateValue))
  )
  return timeAgo.includes('seconds') ? 'just now' : `${timeAgo} ago`
}

export function formatDate(
  dateValue: string | number | Date,
  dateFormat: string
) {
  return format(getDateInTimeZone(new Date(dateValue)), dateFormat)
}

export function formatNumber(amount: string | number, format = '0,0') {
  return numeral(amount).format(format)
}

export function formatCurrency(amount: string | number, format = '$0[,]0') {
  return formatNumber(amount, format)
}

export function formatCurrencyShort(value: string | number) {
  let formattedValue = numeral(value).format('0.0a')
  const [leftDigits] = formattedValue.split('.')
  if (leftDigits.length === 1) {
    formattedValue = numeral(value).format('$0.[00]a')
  } else if (leftDigits.length === 2) {
    formattedValue = numeral(value).format('$0.[0]a')
  } else if (leftDigits.length >= 3) {
    formattedValue = numeral(value).format('$0a')
  }
  return formattedValue.toUpperCase()
}

// A helper for react router v6 active classNames
// This replaces the `activeClassName` prop which was removed
export function activeClassName(
  activeClassName: string,
  additionalClassNames?: string
) {
  return ({ isActive }: { isActive: boolean }) =>
    `${isActive ? activeClassName : ''} ${additionalClassNames ?? ''}`
}

export function generateKey() {
  return Math.random().toString(16).substr(2)
}

// export const SUPPORTED_IMAGE_FORMATS = [
//   'image/png',
//   'image/x-png',
//   'image/gif',
//   'image/jpg',
//   'image/jpeg'
// ]

// export function isCorrectFileSize(files: FileList | null) {
//   if (!files?.length) return true
//   return [...files].some((file) => file.size / 1024 / 1024 <= 5)
// }

// export function isSupportedFileType(files: FileList | null) {
//   if (!files?.length) return true
//   return ![...files].some(
//     (file) => !SUPPORTED_IMAGE_FORMATS.includes(file.type)
//   )
// }

const airbrakeProjectId = Number(process.env.REACT_APP_AIRBRAKE_PROJECT_ID)
const airbrakeProjectKey = process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
// {TODO: Get airbrake notifications for all environments.}
// const environment = process.env.MODE

export const airbrake =
  airbrakeProjectId && airbrakeProjectKey
    ? new Notifier({
        projectId: airbrakeProjectId,
        projectKey: airbrakeProjectKey,
        environment: 'production'
      })
    : undefined

export const states = [
  // USA
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',

  // USA Outlying Territories
  'American Samoa',
  'Guam',
  'Northern Mariana Islands',
  'Puerto Rico',
  'United States Minor Outlying Islands',
  'Virgin Islands',

  // Canada
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Northwest Territories',
  'Nunavut',
  'Yukon'
]

export function toTitleCase(string: String) {
  return string
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}
