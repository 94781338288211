import { useCreateFieldTemplateMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { fieldTemplateFragment } from './useFieldTemplates'

gql`
  mutation createFieldTemplate(
    $recordType: FieldTemplateRecordType
    $displayName: String
    $name: String!
    $fieldType: String!
    $aliases: [String]
    $alternateNames: [String]
    $fieldCategoryName: FieldTemplateFieldCategoryName
  ) {
    createFieldTemplate(
      display_name: $displayName
      name: $name
      field_type: $fieldType
      aliases: $aliases
      alternate_names: $alternateNames
      field_category_name: $fieldCategoryName
      record_type: $recordType
    ) {
      ...FieldTemplateFields
    }
  }
  ${fieldTemplateFragment}
`

export function useCreateFieldTemplate() {
  const queryClient = useQueryClient()

  return useCreateFieldTemplateMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['FieldTemplates'])
    }
  })
}
