import { JumperService } from './useJumper'

export interface Option {
  label: string
  value: string
}

export function subOptionsFor(obj?: JumperService): Option[] {
  switch (obj?.__typename) {
    case 'Adapter':
      return optionsForAdapter(obj)
    case 'ListingService':
      return optionsForListingService(obj)
    default:
      return []
  }
}

function optionsForListingService({ id }: JumperService): Option[] {
  return [
    {
      label: 'Dashboard',
      value: `/listing_services/${id}/details`
    },
    { label: 'Edit', value: `/listing_services/${id}/edit` }
  ]
}

function optionsForAdapter(service: JumperService): Option[] {
  let rg
  if (service.__typename === 'Adapter') {
    rg = service.resourceGroups?.[0]
  }

  if (!!rg) {
    return [
      { label: 'Dashboard', value: `/adapters/${service.id}/details` },
      { label: 'Edit', value: `/adapters/${service.id}/edit` },
      { label: 'Metadata', value: `/adapters/${service.id}/metadata` },
      { label: 'Alarm Triggers', value: `/adapters/${service.id}/alarms` },
      {
        label: 'Listing Dashboard',
        value: `/adapters/${service.id}/resource_groups/${rg.id}`
      },
      {
        label: 'Listing Settings',
        value: `/adapters/${service.id}/resource_groups/${rg.id}/settings`
      },
      {
        label: 'Listing Setup',
        value: `/adapters/${service.id}/resource_groups/${rg.id}/setup`
      },
      {
        label: 'Listing Search',
        value: `/adapters/${service.id}/resource_groups/${rg.id}/search`
      },
      {
        label: 'Listing Fields',
        value: `/adapters/${service.id}/resource_groups/${rg.id}/fields`
      }
    ]
  }

  return [
    { label: 'Dashboard', value: `/adapters/${service.id}/details` },
    { label: 'Edit', value: `/adapters/${service.id}/edit` },
    { label: 'Metadata', value: `/adapters/${service.id}/metadata` },
    { label: 'Alarm Triggers', value: `/adapters/${service.id}/alarms` }
  ]
}
