import { DefaultSchemaFields, Tabs, useDefaultSchema } from '@forge/common'
import { AgentDetail } from '@forge/features/agent'
import { ListingDetail } from '@forge/features/listing'
import { OfficeDetail } from '@forge/features/office'
import { ResourceGroupRole } from '@forge/graphql/generated'
import { useSearchParams } from 'react-router-dom'
import { ResourceRecordData } from './SetupDetails'

enum TabEnum {
  detail,
  apiResponse,
  dataDictionary,
  raw
}

interface InteractiveSetupProps {
  adapterId: string
  resourceGroupId: string
  resourceSample: ResourceRecordData
  showDraft: boolean
  role: ResourceGroupRole
  onClose: () => void
}

export function InteractiveSetup({
  adapterId,
  resourceGroupId,
  showDraft,
  role,
  resourceSample,
  onClose
}: InteractiveSetupProps) {
  const { data: { defaultSchema } = {} } = useDefaultSchema({ role })

  const { raw, serialized, serializedDraft, normalized, normalizedDraft } =
    resourceSample

  const [searchParams, setSearchParams] = useSearchParams()
  const type = (searchParams.get('tab') || 'detail') as keyof typeof TabEnum
  const selectedTabIndex = TabEnum[type]

  return (
    <>
      {role === 'listing' && (
        <Tabs selectedIndex={selectedTabIndex}>
          <Tabs.List>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'detail' })}>
              Listing Detail
            </Tabs.Tab>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'apiResponse' })}>
              API Response
            </Tabs.Tab>
            <Tabs.Tab
              onClick={() => setSearchParams({ tab: 'dataDictionary' })}>
              Data Dictionary
            </Tabs.Tab>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'raw' })}>
              Raw Field Data
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              <ListingDetail
                adapterId={adapterId}
                listing={showDraft ? serializedDraft : serialized}
                resourceGroupId={resourceGroupId}
                onClose={onClose}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* API Response */}
              <DefaultSchemaFields
                adapterId={adapterId}
                defaultSchema={defaultSchema}
                mode="path"
                record={showDraft ? serializedDraft : serialized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* Data Dictionary */}
              <DefaultSchemaFields
                adapterId={adapterId}
                defaultSchema={defaultSchema}
                mode="standardName"
                record={showDraft ? normalizedDraft : normalized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* Raw Record Data */}
              <DefaultSchemaFields
                adapterId={adapterId}
                mode="sourceNames"
                record={raw || ''}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      )}
      {role === 'agent' && (
        <Tabs>
          <Tabs.List>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'detail' })}>
              Agent Detail
            </Tabs.Tab>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'apiResponse' })}>
              API Response
            </Tabs.Tab>
            <Tabs.Tab
              onClick={() => setSearchParams({ tab: 'dataDictionary' })}>
              Data Dictionary
            </Tabs.Tab>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'raw' })}>
              Raw Field Data
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              <AgentDetail
                onClose={onClose}
                adapterId={adapterId}
                agent={showDraft ? serializedDraft : serialized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* API Response */}
              <DefaultSchemaFields
                adapterId={adapterId}
                defaultSchema={defaultSchema}
                mode="path"
                record={showDraft ? serializedDraft : serialized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* Data Dictionary */}
              <DefaultSchemaFields
                adapterId={adapterId}
                defaultSchema={defaultSchema}
                mode="standardName"
                record={showDraft ? normalizedDraft : normalized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* Raw Record Data  */}
              <DefaultSchemaFields
                adapterId={adapterId}
                mode="sourceNames"
                record={raw || ''}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      )}
      {role === 'office' && (
        <Tabs>
          <Tabs.List>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'detail' })}>
              Office Detail
            </Tabs.Tab>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'apiResponse' })}>
              API Response
            </Tabs.Tab>
            <Tabs.Tab
              onClick={() => setSearchParams({ tab: 'dataDictionary' })}>
              Data Dictionary
            </Tabs.Tab>
            <Tabs.Tab onClick={() => setSearchParams({ tab: 'raw' })}>
              Raw Field Data
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              <OfficeDetail
                onClose={onClose}
                adapterId={adapterId}
                office={showDraft ? serializedDraft : serialized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/*API Response  */}
              <DefaultSchemaFields
                adapterId={adapterId}
                defaultSchema={defaultSchema}
                mode="path"
                record={showDraft ? serializedDraft : serialized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* Data Dictionary  */}
              <DefaultSchemaFields
                adapterId={adapterId}
                defaultSchema={defaultSchema}
                mode="standardName"
                record={showDraft ? normalizedDraft : normalized}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              {/* Raw Record Data  */}
              <DefaultSchemaFields
                adapterId={adapterId}
                mode="sourceNames"
                record={raw || ''}
                resourceGroupId={resourceGroupId}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      )}
      {role === 'photo' && <div>Photo Detail</div>}
    </>
  )
}
