import { Logo } from '@forge/common'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export function PasswordRequestReset() {
  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className="m-auto flex min-h-full min-w-fit flex-col justify-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex flex-shrink-0 items-center px-4">
            <div className="m-auto h-8">
              <Logo />
            </div>
          </div>
          <h1 className="flex text-4xl font-medium">Forgot your password?</h1>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-400 focus:outline-none focus:ring-orange-400 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2">
                  <Link to="/login">Send Password Reset Email</Link>
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="mt-6 grid grid-cols-1 gap-3">
                <div className="mx-auto flex items-center justify-between">
                  <div className="text-sm">
                    <Link
                      to="/register"
                      className="font-medium text-orange-400 hover:text-orange-400">
                      Dont have an account?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
