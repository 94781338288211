import { useDeleteFieldMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation DeleteField($id: ID!) {
    deleteField(id: $id)
  }
`

export function useDeleteField() {
  const queryClient = useQueryClient()
  return useDeleteFieldMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['ResourceGroupFields'])
    }
  })
}
