import numeral from 'numeral'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { useDashboardStatsByHour } from './useDashboardStatsByHour'

export function DashboardStatsByHour() {
  const { data: stats } = useDashboardStatsByHour()

  return (
    <div>
      <h3 className="pb-4 text-xl">Stats by Hour</h3>
      <div className="px-4 pt-6 pb-4 mb-4 bg-white rounded-lg shadow">
        <ResponsiveContainer height={275}>
          <AreaChart
            width={500}
            height={400}
            data={stats}
            margin={{
              top: 10,
              right: 15,
              left: 15,
              bottom: 0
            }}>
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis />
            <Tooltip
              formatter={(value: String) => numeral(value).format('0.0a')}
            />
            <Area
              type="monotone"
              dataKey="records_consumer"
              stackId="1"
              stroke="#6d28d9"
              fill="#8b5cf6"
            />
            <Area
              type="monotone"
              dataKey="updater"
              stackId="2"
              stroke="#f97316"
              fill="#fb923c"
            />
            <Area
              type="monotone"
              dataKey="percolator"
              stackId="3"
              stroke="#ef4444"
              fill="#f87171"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
