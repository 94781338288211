import {
  AdapterTimestampsQueryVariables,
  useAdapterTimestampsQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query AdapterTimestamps($resourceId: ID!) {
    adapterTimestamps: modification_timestamps(id: $resourceId) {
      oldestTimestamp: oldest_timestamp
      newestTimestamp: newest_timestamp
      timestampVariance: timestamp_variance
      totalRecords: total_records
      recommendedRequestTimezone: recommended_request_timezone
      recommendedResponseTimezone: recommended_response_timezone
    }
  }
`

export function useAdapterTimestamps(vars: AdapterTimestampsQueryVariables) {
  return useAdapterTimestampsQuery(vars)
}
