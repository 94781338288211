import { Alert, Table } from '@forge/common'
import { FieldQuery, LookupPartsFragment } from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { DotsVerticalIcon, TrashIcon } from '@heroicons/react/solid'
import { useQueryClient } from '@tanstack/react-query'
import { forwardRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CreateUpdateLookup } from './CreateUpdateLookup'
import { useDestroyLookup } from './useDestroyLookup'
import { useReorderLookups } from './useReorderLookups'

interface LookupsProps {
  lookups: LookupPartsFragment[]
}
export function Lookups({ lookups }: LookupsProps) {
  const [_lookups, setLookups] = useState(lookups)
  const { mutate: reorderLookups } = useReorderLookups()
  const canDrag = _lookups.length > 1

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.destination) return

        const newLookups = Array.from(_lookups)
        const [removed] = newLookups.splice(result.source.index, 1)
        newLookups.splice(result.destination.index, 0, removed)

        const newIds = newLookups.map((transform) => transform.id)

        setLookups(newLookups)
        reorderLookups({ ids: newIds })
      }}>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="center">Value</Table.Header>
            <Table.Header align="center">Mapped Value</Table.Header>
            <Table.Header align="center">Source Id</Table.Header>
            <Table.Header align="center">Delete</Table.Header>
          </Table.Row>
        </Table.Head>
        <Droppable droppableId="droppable" isDropDisabled={!canDrag}>
          {(provided) => (
            <Table.Body ref={provided.innerRef} {...provided.droppableProps}>
              {!_lookups.length && (
                <Table.Row>
                  <Table.Data colSpan={4}>No lookups found.</Table.Data>
                </Table.Row>
              )}
              {_lookups.map((lookup, index) => (
                <Draggable
                  key={lookup.id}
                  draggableId={lookup.id}
                  index={index}
                  isDragDisabled={!canDrag}>
                  {(provided) => (
                    <Lookup
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      lookup={lookup}
                      canDrag={canDrag}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Table.Body>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  )
}

interface LookupProps {
  lookup: LookupPartsFragment
  canDrag: boolean
}

const Lookup = forwardRef<HTMLTableRowElement, LookupProps>(function Lookup(
  { lookup, canDrag, ...rest },
  ref
) {
  const [showDestroy, setShowDestroy] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const queryClient = useQueryClient()
  const {
    mutate: destroyLookup,
    isLoading: isDestroying,
    error
  } = useDestroyLookup()

  return (
    // Spreading here so we can get the drag and drop props
    <Table.Row ref={ref} {...rest}>
      <Table.Data align="center">
        <div className="flex space-x-2">
          {canDrag && (
            <DotsVerticalIcon className="-ml-6 h-5 w-5 cursor-move text-gray-400" />
          )}
          <button disabled={!lookup.fieldId} onClick={() => setShowEdit(true)}>
            <div className="left-0 max-w-[10rem] overflow-auto">
              {lookup.value}
            </div>
          </button>
          <CreateUpdateLookup
            fieldId={String(lookup.fieldId)}
            lookup={lookup}
            isOpen={showEdit}
            onClose={() => setShowEdit(false)}
          />
        </div>
      </Table.Data>
      <Table.Data align="center">{lookup.mappedValue}</Table.Data>
      <Table.Data align="center">
        <div className="max-w-[10rem] overflow-auto">{lookup.sourceId}</div>
      </Table.Data>
      <Table.Data width="10%" align="center">
        <button onClick={() => setShowDestroy(true)}>
          <TrashIcon className="h-5 w-5 text-gray-400 hover:cursor-pointer hover:text-red-500" />
        </button>
        <Alert variant="danger" isOpen={showDestroy}>
          <Alert.Title>Delete Lookup</Alert.Title>
          <Alert.Content>
            <div className="space-y-2">
              <div>
                Are you sure you want to delete this lookup? This action cannot
                be undone.
              </div>
              {error && (
                <div className="text-red-500" role="alert" aria-live="polite">
                  {error.message}
                </div>
              )}
            </div>
          </Alert.Content>
          <Alert.Cancel onClick={() => setShowDestroy(false)}>
            Cancel
          </Alert.Cancel>
          <Alert.Confirm
            leftIcon={<ExclamationCircleIcon />}
            loading={isDestroying}
            onClick={() => {
              destroyLookup(
                { id: lookup.id },
                {
                  onSuccess() {
                    setShowDestroy(false)

                    const field = queryClient.getQueryData<FieldQuery>([
                      'Field',
                      { id: String(lookup.fieldId) }
                    ])

                    if (field) {
                      queryClient.setQueryData(
                        ['Field', { id: String(lookup.fieldId) }],
                        {
                          ...field,
                          field: {
                            ...field.field,
                            lookups: field.field?.lookups?.filter(
                              (l) => l.id !== lookup.id
                            )
                          }
                        }
                      )
                    }

                    queryClient.invalidateQueries([
                      'Field',
                      { id: String(lookup.fieldId) }
                    ])
                  }
                }
              )
            }}>
            Delete Forever
          </Alert.Confirm>
        </Alert>
      </Table.Data>
    </Table.Row>
  )
})
