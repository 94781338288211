import { useUpdateAlarmProfileMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation UpdateAlarmProfile(
    $alarmProfileId: ID!
    $adapterId: Int
    $duration: Int
    $field: AlarmProfileField
    $job: AlarmProfileJob
    $jobStatus: AlarmProfileJobStatus
    $maxThreshold: Int
    $minThreshold: Int
    $name: String
    $operation: AlarmProfileOperation
    $role: AlarmProfileRole
    $status: AlarmProfileStatus
  ) {
    updateAlarmProfile(
      id: $alarmProfileId
      adapter_id: $adapterId
      duration: $duration
      field: $field
      job: $job
      job_status: $jobStatus
      max_threshold: $maxThreshold
      min_threshold: $minThreshold
      name: $name
      operation: $operation
      role: $role
      status: $status
    ) {
      id
    }
  }
`

export function useUpdateAlarmProfile() {
  const queryClient = useQueryClient()
  return useUpdateAlarmProfileMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['AlarmProfiles'])
      await queryClient.invalidateQueries(['AlarmProfile'])
    }
  })
}
