import { Button, Modal, Pagination, Table, toast } from '@forge/common'
import { PencilIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useReRegisterSavedSearches } from './useReRegisterSavedSearches'
import { useSavedSearches } from './useSavedSearches'

export function ListingServiceSavedSearchManager() {
  const { id: listingServiceId } = useParams()
  const [page, setPage] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const { mutate: reRegisterSavedSearches, isLoading } =
    useReRegisterSavedSearches()

  const { data: { listingService: savedSearches } = {} } = useSavedSearches({
    page,
    listingServiceId
  })

  const hasNextPage = !!useSavedSearches({ page: page + 1, listingServiceId })
    ?.data?.listingService?.savedSearchHealth?.unregistered?.length

  return (
    <>
      <div className="flex w-full flex-col gap-4 pt-2">
        <div className="flex">
          <Button
            size="xs"
            onClick={() => setShowModal(true)}
            loading={isLoading}>
            Re-Register All
          </Button>
        </div>
        <div className="grid grid-cols-2 gap-5 text-center lg:grid-cols-3 xl:grid-cols-4">
          <Count
            name="Total Count"
            count={String(savedSearches?.savedSearchHealth?.count ?? '')}
          />
          <Count
            name="Total Registered"
            count={String(
              savedSearches?.savedSearchHealth?.registeredCount ?? ''
            )}
          />
        </div>

        <div className="mb-2 rounded-lg bg-white p-1 shadow">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">ID</Table.Header>
                <Table.Header align="left">Match Count</Table.Header>
                <Table.Header align="left">Last Match</Table.Header>
                <Table.Header />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {savedSearches?.savedSearchHealth?.unregistered?.length === 0 && (
                <Table.Row>
                  <Table.Data colSpan={4}>
                    <div className="text-center font-medium">
                      No saved searches for this listing service yet.
                    </div>
                  </Table.Data>
                </Table.Row>
              )}
              {savedSearches?.savedSearchHealth?.unregistered?.map(
                (savedSearchRecord) => (
                  <Table.Row key={savedSearchRecord.id}>
                    <Table.Data> {savedSearchRecord.id} </Table.Data>
                    <Table.Data> {savedSearchRecord.matchCount} </Table.Data>
                    <Table.Data>
                      {savedSearchRecord.lastMatchAt || 'Never'}{' '}
                    </Table.Data>
                    <Table.Data>
                      <Link
                        className="hover:text-orange-400"
                        to={`/listing_services/${listingServiceId}/saved/searches/${savedSearchRecord.id}/edit`}>
                        <PencilIcon className="mx-auto h-5 w-5" />
                      </Link>
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="mt-4">
          <Pagination
            label={`Page ${page}`}
            disablePrev={page <= 1}
            disableNext={!hasNextPage}
            onPrevClick={() => setPage(page - 1)}
            onNextClick={() => setPage(page + 1)}
          />
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
        }}>
        <div className="flex flex-col gap-4">
          <h3 className="text-xl">
            Are you sure you want to re-register all saved searches?
          </h3>
          <div className="flex gap-4">
            <Button onClick={() => setShowModal(false)} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                reRegisterSavedSearches(
                  {
                    id: Number(listingServiceId)
                  },
                  {
                    onSuccess() {
                      toast('Saved searches re-registered successfully', {
                        variant: 'success'
                      })
                    },
                    onError() {
                      toast(
                        'Error occured while re-registering saved searches',
                        { variant: 'error' }
                      )
                    }
                  }
                )
                setShowModal(false)
              }}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

interface CountProps {
  name: string
  count: string
}

function Count({ name, count }: CountProps) {
  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500" title={name}>
        {name}
      </dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">{count}</dd>
    </div>
  )
}
