import { Button, FormField, Input, Logo, Wrapper } from '@forge/common'
import { useLogin } from '@forge/features/auth'
import { useCurrentUser } from '@forge/features/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  email: yup.string().email().required('Your email is required'),
  password: yup.string().required('Your password is required')
})

export function Login() {
  const { data: currentUser } = useCurrentUser()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const { mutate: login, isLoading, error } = useLogin()
  if (currentUser) {
    return <Navigate to="/" />
  }
  return (
    <>
      <Helmet>
        <title>Forge: Login</title>
      </Helmet>

      <Wrapper>
        <div className="flex flex-col">
          <div className="flex items-center flex-shrink-0 px-4">
            <div className="h-8 m-auto">
              <Logo />
            </div>
          </div>
          <h1 className="mx-auto text-4xl font-medium">Welcome to Forge</h1>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
              <form
                className="space-y-6"
                onSubmit={handleSubmit((data) => login(data))}>
                <FormField label="Email" error={errors.email?.message}>
                  <Input {...register('email')} type="email" />
                </FormField>
                <FormField label="Password" error={errors.password?.message}>
                  <Input {...register('password')} type="password" />
                </FormField>
                <div className="flex justify-center">
                  <Button loading={isLoading}>Sign In</Button>
                </div>
                {!!error && (
                  <div className="text-sm text-center text-red-500">
                    {error.message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
