import { Attribute, classNames } from '@forge/common'
import { useResourceGroupSearchFields } from '@forge/features/resourcegroups'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { AgentBadge } from './AgentBadge'

interface AgentSampleProps {
  active?: boolean | null
  adapterId: string
  agentId?: string | null
  email?: string | null
  fullName?: string | null
  id?: string | null
  isOpen?: boolean
  officeName?: string | null
  onClick?: () => void
  phone?: string | null
  resourceGroupId: string
  variant?: 'primary' | 'preview'
}

export function AgentSample({
  active,
  adapterId,
  agentId,
  email,
  fullName,
  id,
  isOpen,
  officeName,
  onClick,
  phone,
  resourceGroupId
}: AgentSampleProps) {
  const { data: resourceGroupSearchFields = {} } = useResourceGroupSearchFields(
    {
      resourceGroupId
    }
  )

  const searchFields = resourceGroupSearchFields?.resourceGroup?.fields?.sort(
    (a, b) => (a?.displayName || '').localeCompare(b?.displayName || '')
  )

  const fields =
    searchFields?.reduce<Record<string, FieldPartsFragment>>(
      (fields, field) => {
        const key = field.aliases?.[0] || field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}
  return (
    <div>
      <div
        className={classNames('rounded-md bg-white', {
          'shadow-raised ring-2 ring-orange-500': isOpen,
          'shadow-sticky': !isOpen
        })}>
        <div
          role="button"
          tabIndex={0}
          className="flex items-center p-1 space-x-2"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onClick?.()
            }
          }}
          onClick={onClick}>
          <div className="grow space-y-0.5 pl-1">
            {!id && (
              <div className="hover:cursor-pointer">
                Configure{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['id']?.id}
                  label="id"
                  resourceGroupId={resourceGroupId}
                  value={id}
                />{' '}
                to load a sample record.
              </div>
            )}
            {fullName !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={fullName || ''}>
                {fullName}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['full_name']?.id}
                  label="full_name"
                  resourceGroupId={resourceGroupId}
                  value={fullName}
                />
              </div>
            )}
            {officeName !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={officeName || ''}>
                {officeName}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['office_name']?.id}
                  label="office_name"
                  resourceGroupId={resourceGroupId}
                  value={officeName}
                />
              </div>
            )}
            {email !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={email}>
                {email}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['email']?.id}
                  label="email"
                  resourceGroupId={resourceGroupId}
                  value={email}
                />
              </div>
            )}
          </div>
          <div className="space-y-0.5 overflow-auto pr-1 text-right">
            {agentId !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={agentId}>
                {agentId}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['agent_id']?.id}
                  label="agent_id"
                  resourceGroupId={resourceGroupId}
                  value={agentId}
                />
              </div>
            )}
            {phone !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={phone}>
                {phone}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['phone']?.id}
                  label="phone"
                  resourceGroupId={resourceGroupId}
                  value={phone}
                />
              </div>
            )}
            <div className="flex float-right p-1">
              <AgentBadge active={active} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
