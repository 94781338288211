import { Breadcrumbs, Spinner, Tooltip, Wrapper } from '@forge/common'
import { useAdapter } from '@forge/features/adapters'
import { NewField } from '@forge/features/field/NewField'
import { useResourceGroup } from '@forge/features/resourcegroups'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { useParams } from 'react-router-dom'

export function NewResourceGroupField() {
  const { adapterId, resourceGroupId } = useParams()

  const { data: { adapter } = {} } = useAdapter({ id: adapterId })

  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  return (
    <>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}`}>
            {resourceGroup?.role || ''} Resource Group
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/fields`}>
            {resourceGroup?.role || ''} Fields
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/resource_groups/${resourceGroupId}/fields/new`}>
            New {resourceGroup?.role || ''} Field
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            New {resourceGroup?.role || ''} Resource Group Field
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Add a Resource Group field">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center p-4">
              <div className="w-5 h-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <div className="flex justify-center">
            {resourceGroup?.role && (
              <NewField
                role={resourceGroup.role}
                possibleSources={resourceGroup?.possibleSources || []}
                fieldCategories={resourceGroup?.fieldCategories || []}
              />
            )}
          </div>
        </Suspense>
      </Wrapper>
    </>
  )
}
