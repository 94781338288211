import { useDashboardGlobalCounts } from './useDashboardGlobalCounts'

export function DashboardGlobalCounts() {
  const { data: { counts } = {} } = useDashboardGlobalCounts()

  return (
    <dl className="grid grid-cols-2 gap-5 text-center lg:grid-cols-3 xl:grid-cols-6">
      <Count name="Data Adapters" count={counts?.adapters || '0'} />
      <Count name="Listing Services" count={counts?.listingServices || '0'} />
      <Count name="Listing Records" count={counts?.listings || '0'} />
      <Count name="Agent Records" count={counts?.agents || '0'} />
      <Count name="Office Records" count={counts?.offices || '0'} />
      <Count name="Daily Updates" count={counts?.updatesToday || '0'} />
    </dl>
  )
}

interface CountProps {
  name: string
  count: string
}

function Count({ name, count }: CountProps) {
  return (
    <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate" title={name}>{name}</dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">{count}</dd>
    </div>
  )
}
