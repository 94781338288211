import { classNames } from '@forge/common'
import { OfficeBadge } from './OfficeBadge'

interface OfficeCardProps {
  active?: boolean | null
  email?: string | null
  isOpen?: boolean
  name?: string | null
  officeId?: string | null
  onClick?: () => void
  phone?: string | null
}

export function OfficeCard({
  active,
  email,
  isOpen,
  name,
  officeId,
  onClick,
  phone
}: OfficeCardProps) {
  return (
    <div>
      <div
        className={classNames('rounded-md bg-white hover:shadow-2xl', {
          'shadow-raised ring-2 ring-orange-500': isOpen,
          shadow: !isOpen
        })}>
        <div
          role="button"
          tabIndex={0}
          className="flex items-center p-2 space-x-2"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick?.()
            }
          }}
          onClick={onClick}>
          <div className="w-2/3 grow space-y-0.5">
            <div
              className="overflow-auto text-xs font-semibold text-gray-900"
              title={name || ''}>
              {name}
            </div>
            <div
              className="overflow-auto text-xs font-semibold text-gray-900"
              title={phone || ''}>
              {phone}
            </div>
            <div
              className="overflow-auto text-xs font-semibold text-gray-900 "
              title={email || ''}>
              {email}
            </div>
          </div>
          <div className="w-1/3 space-y-2 text-right">
            <div
              className="overflow-auto text-xs font-semibold text-gray-900"
              title={officeId || ''}>
              {officeId}
            </div>
            <div className="float-right">
              <OfficeBadge active={active} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OfficeCard.Skeleton = function OfficeCardSkeleton() {
  return (
    <div className="flex p-2 bg-white rounded-md shadow">
      <div className="w-1/2 px-1 space-y-2">
        <div className="h-[1rem] w-[12.0625rem] animate-pulse bg-gray-200" />
        <div className="h-[1rem] w-[10.0625rem] animate-pulse bg-gray-200" />
        <div className="h-[1rem] w-[8.0625rem] animate-pulse bg-gray-200" />
      </div>
      <div className="w-1/2 px-1 my-auto space-y-2">
        <div className="float-right h-[1rem] w-[8.0625rem] animate-pulse bg-gray-200" />
        <div className="float-right h-[1rem] w-[6.0625rem] animate-pulse bg-gray-200" />
      </div>
    </div>
  )
}
