import {
  AddressSearch,
  Button,
  Combobox,
  FormField,
  Input,
  Select,
  states,
  toast
} from '@forge/common'
import {
  useListingService,
  useUpdateListingService
} from '@forge/features/listingservices'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  key: yup.string().required('Key is required'),
  name: yup.string().required(' Name is required'),
  timeZone: yup.string().required('Time Zone is required'),
  state: yup.array(yup.string().required('State is required')),
  address: yup.string().required('Address is required'),
  lat: yup.number(),
  lon: yup.number()
})

export function ListingServiceEditForm() {
  const { id } = useParams()
  const { data: { listingService } = {} } = useListingService({
    id
  })

  const listingServiceId = listingService?.id

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      key: listingService?.key || undefined,
      name: listingService?.name || undefined,
      timeZone: listingService?.timeZone || undefined,
      address: listingService?.address || undefined,
      state: listingService?.state || undefined
    }
  })
  const {
    mutate: updateListingService,
    isLoading,
    isSuccess,
    error
  } = useUpdateListingService()

  const navigate = useNavigate()

  const stateOptions = states.map((option) => ({
    label: option,
    value: option
  }))

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          updateListingService(
            {
              id: listingServiceId || '',
              key: data.key,
              name: data.name,
              timeZone: data.timeZone,
              address: data.address,
              state: data.state,
              lat: data.lat,
              lon: data.lon
            },
            {
              onSuccess({ updateListingService }) {
                toast(
                  <div className="space-y-0.5">
                    <strong>Listing Service updated successfully</strong>
                  </div>,
                  { variant: 'success' }
                )
                navigate(
                  `/listing_services/${updateListingService?.id}/details`
                )
              }
            }
          )
        })}
        className="space-y-3">
        <div className="w-full">
          <FormField
            label="Listing Service Key"
            error={errors.key?.message}
            required>
            <Input
              {...register('key')}
              placeholder="Enter Listing Service Key"
              disabled
            />
          </FormField>
        </div>
        <div className="w-full">
          <FormField
            label="Listing Service Name"
            error={errors.name?.message}
            required>
            <Input
              {...register('name')}
              placeholder="Enter Listing Service Name"
            />
          </FormField>
        </div>
        <div>
          <FormField
            label="Time Zone"
            error={errors.timeZone?.message}
            required>
            <Select {...register('timeZone')}>
              <option value="">Select a Time Zone</option>
              <option value="UTC">UTC</option>
              <option value="Atlantic Time (Canada)">
                Atlantic Time (Canada)
              </option>
              <option value="Eastern Time (US & Canada)">
                Eastern Time (US & Canada)
              </option>
              <option value="Central Time (US & Canada)">
                Central Time (US & Canada)
              </option>
              <option value="Mountain Time (US & Canada)">
                Mountain Time (US & Canada)
              </option>
              <option value="Arizona">Arizona</option>
              <option value="Pacific Time (US & Canada)">
                Pacific Time (US & Canada)
              </option>
              <option value="Hawaii">Hawaii</option>
              <option value="Alaska">Alaska</option>
            </Select>
          </FormField>
        </div>
        <div>
          <FormField
            label="State / Province"
            error={errors.state?.map?.((error) => error?.message)}
            required>
            <Combobox
              multiple
              selected={stateOptions.filter((option) =>
                watch('state')?.includes(option.value)
              )}
              options={stateOptions}
              onSelect={(options) => {
                setValue(
                  'state',
                  options.map((option) => option.value)
                )
              }}
            />
          </FormField>
        </div>
        <div>
          <FormField label="Address" error={errors.address?.message} required>
            <AddressSearch
              {...register('address')}
              placeholder={
                listingService?.address || 'Enter Listing Service Address'
              }
              onSelect={(value) => {
                setValue('lat', Number(value.value.lat))
                setValue('lon', Number(value.value.lon))
              }}
            />
          </FormField>
        </div>
        <div>
          <Button
            fullWidth
            variant={!error ? 'primary' : 'danger'}
            type="submit"
            loading={isLoading}
            leftIcon={
              error ? (
                <ExclamationCircleIcon />
              ) : isSuccess ? (
                <BeakerIcon />
              ) : undefined
            }>
            {!!error && <>An Error Occured. Try again?</>}
            {!error && (
              <>
                {isLoading
                  ? 'Updating Listing Service'
                  : isSuccess
                  ? 'Success'
                  : `Update Listing Service`}
              </>
            )}
          </Button>
        </div>
        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
