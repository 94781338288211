import { Progress, Table } from '@forge/common'
import { ResourceGroupStatus } from '@forge/graphql/generated'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useDashboardResourceGroups } from './useDashboardResourceGroups'

export function DashboardResourceGroups() {
  const { data: { resourceGroups: loadingResourceGroups } = {} } =
    useDashboardResourceGroups({
      status: ResourceGroupStatus.Loading
    })
  const { data: { resourceGroups: pendingResourceGroups } = {} } =
    useDashboardResourceGroups({
      status: ResourceGroupStatus.Pending
    })
  const resourceGroups = [
    ...(loadingResourceGroups || []),
    ...(pendingResourceGroups || [])
  ]

  if (!resourceGroups?.length) {
    return (
      <div className="md:w-1/2">
        <h2 className="py-4 text-xl">Reindexing</h2>
        <small>All Resource Groups are stable. 🥳</small>
      </div>
    )
  }

  return (
    <div className="md:w-1/2">
      <h3 className="py-4 text-xl">Reindexing</h3>
      <div className="max-h-[calc(100vh-13.75rem)] overflow-auto rounded-lg bg-white px-1 shadow">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header align="left">Adapter</Table.Header>
              <Table.Header align="center">Group</Table.Header>
              <Table.Header align="center">Progress</Table.Header>
              <Table.Header align="center">Complete</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {resourceGroups?.map(({ id, role, loadingProgress, adapter }) => {
              const progress = loadingProgress ?? 100
              return (
                <Table.Row key={id}>
                  <Table.Data align="left">
                    <Link
                      to={`/adapters/${adapter?.id}/details`}
                      className="hover:text-orange-400">
                      {adapter?.key?.toUpperCase()}
                    </Link>
                  </Table.Data>
                  <Table.Data align="center">
                    <i>{role}</i>
                  </Table.Data>
                  <Table.Data align="center">
                    <Progress progress={Number(progress || 0)} />
                  </Table.Data>
                  <Table.Data align="center">
                    <span
                      className={classNames({
                        'text-green-500': progress >= 100,
                        'text-yellow-500': progress < 100
                      })}>
                      {progress}%
                    </span>
                  </Table.Data>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
