import { useCreateServiceAdapterMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation createServiceAdapter($listingServiceId: Int, $adapterId: Int) {
    createServiceAdapter(
      listing_service_id: $listingServiceId
      adapter_id: $adapterId
    ) {
      id
      adapter {
        id
        key
        recordCounts: record_counts {
          today
          listings
          agents
          offices
        }
      }
    }
  }
`

export function useCreateServiceAdapter() {
  const queryClient = useQueryClient()

  return useCreateServiceAdapterMutation({
    async onSuccess(_, { listingServiceId, adapterId }) {
      await queryClient.invalidateQueries([
        'ListingService',
        { id: String(listingServiceId) }
      ])
      await queryClient.invalidateQueries([
        'Adapter',
        { id: String(adapterId) }
      ])
    }
  })
}
