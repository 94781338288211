import { useCreateListingServiceMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation createListingService(
    $name: String!
    $key: String!
    $timeZone: String!
    $address: String
    $state: [String]
    $lat: Float
    $lon: Float
  ) {
    createListingService(
      name: $name
      key: $key
      time_zone: $timeZone
      address: $address
      state: $state
      lat: $lat
      lon: $lon
    ) {
      id
    }
  }
`
export function useCreateListingService() {
  const queryClient = useQueryClient()
  return useCreateListingServiceMutation({
    onSuccess(data) {
      queryClient.invalidateQueries(['ListingServices'])
      queryClient.setQueryData(
        ['listing_service', data.createListingService?.id],
        data.createListingService
      )
    }
  })
}
