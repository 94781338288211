import { Button, Modal } from '@forge/common'
import { ResourceGroupStatus } from '@forge/graphql/generated'
import { Link, useParams } from 'react-router-dom'
import { useAdapter } from '../adapters'
import { useResourceGroup } from './useResourceGroup'
import { useUpdateResourceGroup } from './useUpdateResourceGroup'

interface ResourceGroupReindexProps {
  isOpen: boolean
  onClose: () => void
}

export function ResourceGroupReindex({
  isOpen,
  onClose
}: ResourceGroupReindexProps) {
  const { adapterId, resourceGroupId } = useParams()

  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  const rgId = resourceGroup?.id || ''

  const { mutate: updateResourceGroup, isLoading } = useUpdateResourceGroup()

  const { data: { adapter } = {} } = useAdapter({ id: adapterId })

  if (!adapter) return null

  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} onAfterClose={() => {}}>
      <Modal.Title>
        <span className="text-center leading-none sm:block">
          <span className="truncate text-xl font-semibold capitalize leading-6 text-gray-900">
            Reindex {resourceGroup?.role || ''} Resource Group
          </span>
        </span>
      </Modal.Title>
      <div className="flex">
        <div className="mx-auto">
          <h3>Are you sure you want start a reindex?</h3>
          <div className="flex">
            <div className="mx-auto pt-4">
              <Button size="xs" variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <span className="px-2"></span>
              <Button
                as={Link}
                to={`/adapters/${adapter?.id}/details`}
                size="xs"
                loading={isLoading}
                onClick={() =>
                  updateResourceGroup({
                    id: rgId,
                    adapterId: Number(adapter.id),
                    action_type: 'reindex',
                    status: ResourceGroupStatus.Loading
                  })
                }>
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
