import { Breadcrumbs, Spinner, Tooltip, Wrapper } from '@forge/common'
import { useAdapter, useAlarmProfile } from '@forge/features/adapters'
import { AlarmProfileEditForm } from '@forge/features/adapters'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function AlarmProfileEdit() {
  const { adapterId, alarmProfileId } = useParams()
  const { data: { adapter } = {} } = useAdapter({
    id: adapterId
  })

  const { data: { alarmProfile } = {} } = useAlarmProfile({
    alarmProfileId
  })
  return (
    <>
      <Helmet>
        <title>Edit Alarm Trigger</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/details`}>
            {adapter?.key?.toUpperCase()}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/adapters/${adapter?.id}/alarms`}>
            Alarm Triggers
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/adapters/${adapter?.id}/alarms/${alarmProfileId}/edit`}>
            {alarmProfile?.name?.toUpperCase()}
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            Edit {alarmProfile?.name?.toUpperCase()} Alarm Trigger
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Enter data feed credentials to update alarm trigger.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center p-4">
              <div className="h-5 w-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <div className="flex justify-center">
            <AlarmProfileEditForm />
          </div>
        </Suspense>
      </Wrapper>
    </>
  )
}
