import { SearchFilterInput, Tabs } from '@forge/common'
import { MetadataResourceFieldsFragment } from '@forge/graphql/generated'
import { ChangeEvent, useState } from 'react'
import { MetadataTable } from './MetadataTable'

interface ResourceTabsProps {
  mdResource: MetadataResourceFieldsFragment | null
}

export function ResourceTabs({ mdResource }: ResourceTabsProps) {
  const [search, setSearch] = useState<string>('')

  if (!mdResource) return null

  return (
    <>
      <div className="self-end md:w-1/4">
        <SearchFilterInput
          value={search}
          placeholder={!!search ? '' : 'Search for fields'}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
            setSearch(target.value)
          }}
        />
      </div>
      <div>
        <Tabs>
          <Tabs.List>
            {mdResource.classes?.map((rc) => (
              <Tabs.Tab key={rc.name}>{rc.name}</Tabs.Tab>
            ))}
          </Tabs.List>
          <Tabs.Panels>
            {mdResource.classes?.map((rc) => (
              <Tabs.Panel key={rc.name}>
                <MetadataTable fields={rc.fields} search={search} />
              </Tabs.Panel>
            ))}
          </Tabs.Panels>
        </Tabs>
      </div>
    </>
  )
}
