import { Menu } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CreateUpdateLookup } from './CreateUpdateLookup'
import { DestroyAllLookups } from './DestroyAllLookups'
import { ImportLookups } from './ImportLookups'

interface LookupsMenuProps {
  field: FieldPartsFragment
}

export function LookupsMenu({ field }: LookupsMenuProps) {
  const [showCreate, setShowCreate] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [showDestroy, setShowDestroy] = useState(false)

  return (
    <div>
      <Menu
        button={
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100">
            <DotsHorizontalIcon
              className="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </span>
        }>
        <Menu.Item onClick={() => setShowCreate(true)}>Add Lookup</Menu.Item>
        <Menu.Item onClick={() => setShowImport(true)}>
          Import Lookups
        </Menu.Item>
        <Menu.Item variant="danger" onClick={() => setShowDestroy(true)}>
          Delete all Lookups
        </Menu.Item>
      </Menu>
      <CreateUpdateLookup
        fieldId={field.id}
        isOpen={showCreate}
        onClose={() => setShowCreate(false)}
      />
      <ImportLookups
        fieldId={field.id}
        name={field.sourceNames?.[0] || ''}
        isOpen={showImport}
        onClose={() => setShowImport(false)}
      />
      <DestroyAllLookups
        fieldId={field.id}
        isOpen={showDestroy}
        onClose={() => setShowDestroy(false)}
      />
    </div>
  )
}
