import {
  FieldTemplatesQueryVariables,
  useFieldTemplatesQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

export const fieldTemplateFragment = gql`
  fragment FieldTemplateFields on FieldTemplate {
    id
    displayName: display_name
    name
    aliases
    alternateNames: alternate_names
    fieldCategoryName: field_category_name
    fieldType: field_type
    recordType: record_type
  }
`

gql`
  query FieldTemplates($page: Int, $limit: Int, $name: String) {
    fieldTemplates: field_templates(page: $page, limit: $limit, name: $name) {
      ...FieldTemplateFields
    }
  }
  ${fieldTemplateFragment}
`

interface UseFieldTemplatesArgs
  extends Omit<FieldTemplatesQueryVariables, 'name'> {
  search?: string
}

export function useFieldTemplates({
  page,
  search,
  limit = 15
}: UseFieldTemplatesArgs) {
  return useFieldTemplatesQuery(
    { page, limit, name: search?.length ? search : undefined },
    { keepPreviousData: true }
  )
}
