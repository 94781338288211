import { CloseIcon } from '@forge/icons'

interface OfficeHeaderProps {
  icon?: JSX.Element
  onClose: () => void
}

export function OfficeHeader({
  icon = <CloseIcon />,
  onClose
}: OfficeHeaderProps) {
  return (
    <div className="shadow-raised sticky top-0 z-10 flex h-[4.25rem] shrink-0 items-center justify-between space-x-2 bg-white">
      <div className="flex items-center min-w-0 gap-2"></div>
      <button
        className="flex items-center justify-center w-10 h-10 text-gray-600"
        onClick={onClose}>
        <span className="w-5 h-5">{icon}</span>
      </button>
    </div>
  )
}
