import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { useState, ReactNode } from 'react'
import { classNames, formatNumber } from './utils'

interface PaginationProps {
  label?: ReactNode
  total?: number
  limit?: number
  disablePrev?: boolean
  disableNext?: boolean
  onPrevClick?: () => void
  onNextClick?: () => void
}

export function Pagination({
  label,
  total,
  limit,
  disablePrev,
  disableNext,
  onPrevClick,
  onNextClick
}: PaginationProps) {
  const [page, setPage] = useState(0)

  return (
    <div className="flex items-center justify-between space-x-4">
      {label ? (
        <div className="text-sm text-gray-500">{label}</div>
      ) : (
        <>
          {typeof total === 'number' && typeof limit === 'number' && (
            <div className="text-sm text-gray-500">
              Showing{' '}
              <span className="text-gray-900">
                {`${formatNumber(
                  Math.min(page * limit + 1, total || 0)
                )} - ${formatNumber(
                  Math.min((page + 1) * limit, total || 0)
                )}`}{' '}
              </span>{' '}
              of{' '}
              <span className="text-gray-900">{formatNumber(total || 0)}</span>{' '}
              results
            </div>
          )}
        </>
      )}

      <nav
        className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination">
        <button
          disabled={disablePrev}
          className={classNames(
            'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500',
            {
              'hover:bg-gray-50': !disablePrev,
              'cursor-not-allowed opacity-50': disablePrev
            }
          )}
          onClick={() => {
            setPage(page - 1)
            onPrevClick?.()
          }}>
          <span className="sr-only">Previous Page</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          disabled={disableNext}
          className={classNames(
            'relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500',
            {
              'hover:bg-gray-50': !disableNext,
              'cursor-not-allowed opacity-50': disableNext
            }
          )}
          onClick={() => {
            setPage(page + 1)
            onNextClick?.()
          }}>
          <span className="sr-only">Next Page</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </nav>
    </div>
  )
}
