import { Breadcrumbs, Spinner, Tooltip, Wrapper } from '@forge/common'
import { NewAdminForm } from '@forge/features/adminusers/NewAdminForm'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

export function NewAdmin() {
  return (
    <>
      <Helmet>
        <title>New Admin User</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/admins">Admin Users</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/admins/new`}>New Admin</Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            New Admin User
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Add a new admin user.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center p-4">
              <div className="w-5 h-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <div className="justify-center max-w-lg mx-auto">
            <NewAdminForm />
          </div>
        </Suspense>
      </Wrapper>
    </>
  )
}
