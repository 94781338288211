import { Alert, Button, Table } from '@forge/common'
import {
  ExclamationCircleIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/outline'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAlarmProfiles } from './useAlarmProfiles'
import { useDeleteAlarmProfile } from './useDeleteAlarmProfile'

export function AlarmProfilesTable() {
  const { adapterId } = useParams()
  const { data: { alarmProfiles } = {} } = useAlarmProfiles({
    adapterId: Number(adapterId || '')
  })
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const {
    mutate: deleteAlarmProfile,
    isLoading: isDestroying,
    error
  } = useDeleteAlarmProfile()

  return (
    <div className="space-y-2 overflow-auto rounded-lg bg-white shadow print:border print:shadow-none">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>Name</Table.Header>
            <Table.Header>Criteria</Table.Header>
            <Table.Header>Status</Table.Header>
            <Table.Header></Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {alarmProfiles?.map((alarmProfile) => {
            return (
              <Table.Row key={alarmProfile.id}>
                <Table.Data>{alarmProfile.name}</Table.Data>
                <Table.Data>
                  Triggered when the {alarmProfile.explain}
                  {alarmProfile.id}
                </Table.Data>
                <Table.Data>{alarmProfile.status}</Table.Data>
                <Table.Data>
                  <div className="flex gap-2">
                    <Link
                      className="cursor-pointer text-center hover:text-orange-400"
                      to={`/adapters/${adapterId}/alarms/${alarmProfile.id}/edit`}>
                      <PencilIcon className="mx-auto h-5 w-5" />
                    </Link>

                    <button
                      onClick={() => setIsOpen(true)}
                      className="mx-auto flex cursor-pointer items-center justify-center hover:text-red-500">
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                  <Alert variant="danger" isOpen={isOpen}>
                    <Alert.Title>Delete Alarm Trigger</Alert.Title>
                    <Alert.Content>
                      <div className="space-y-2">
                        <div>
                          Are you sure you want to delete this Alarm Trigger?
                        </div>
                        {error && (
                          <div
                            className="text-red-500"
                            role="alert"
                            aria-live="polite">
                            {error.message}
                          </div>
                        )}
                      </div>
                    </Alert.Content>
                    <Alert.Cancel onClick={() => setIsOpen(false)}>
                      Cancel
                    </Alert.Cancel>
                    <Alert.Confirm
                      leftIcon={<ExclamationCircleIcon />}
                      loading={isDestroying}
                      onClick={() => {
                        deleteAlarmProfile(
                          { id: alarmProfile.id },
                          {
                            onSuccess() {
                              setIsOpen(false)
                            }
                          }
                        )
                      }}>
                      Delete
                    </Alert.Confirm>
                  </Alert>
                </Table.Data>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <div>
        <div className="p-4">
          <Button
            size="xs"
            onClick={() => navigate(`/adapters/${adapterId}/alarms/new`)}>
            Add Alarm Trigger
          </Button>
        </div>
      </div>
    </div>
  )
}
