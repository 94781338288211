import { useDeleteFieldTemplateMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation deleteFieldTemplate($id: ID!) {
    deleteFieldTemplate(id: $id)
  }
`

export function useDeleteFieldTemplate() {
  const queryClient = useQueryClient()
  return useDeleteFieldTemplateMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['FieldTemplates'])
    }
  })
}
