import { Attribute, Tooltip } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingRemarksProps {
  adapterId: string
  resourceGroupId: string
  fields: Record<string, FieldPartsFragment>
  remarks?: string | null
  privateRemarks?: string | null
  directions?: string | null
}

export function ListingRemarks({
  adapterId,
  remarks,
  privateRemarks,
  directions,
  fields,
  resourceGroupId
}: ListingRemarksProps) {
  return (
    <div className="space-y-6">
      <div className="p-2 text-sm text-justify cursor-pointer">
        <h2 className="py-2 text-xl">Listing Remarks</h2>
        <Tooltip
          delay={700}
          placement="top-start"
          maxWidth={400}
          content="Text remarks that may be displayed to the public. In an MLS, it is the field where information is entered for the public. This information is intended to be visible on-line. This is typically information that describes the selling points of the building and/or land for sale. Local conditions and rules will determine what such content can contain. Generally, the following information is excluded: any information pertaining to entry to the property, the seller and/or tenant, listing member contact information. In other systems, these remarks will be determined by local business rules.">
          <div>
            <Attribute
              adapterId={adapterId}
              fieldId={fields['remarks']?.id}
              label="remarks"
              resourceGroupId={resourceGroupId}
              value={remarks}
            />
          </div>
        </Tooltip>
      </div>
      <div className="p-2 text-sm text-justify cursor-pointer">
        <h2 className="py-2 text-xl ">Private Remarks</h2>
        <Tooltip
          delay={700}
          placement="top-start"
          maxWidth={400}
          content="Remarks that may contain security or proprietary information and
                should be restricted from public view.">
          <div>
            <Attribute
              adapterId={adapterId}
              fieldId={fields['remarks_private']?.id}
              label="remarks_private"
              resourceGroupId={resourceGroupId}
              value={privateRemarks}
            />
          </div>
        </Tooltip>
      </div>

      <div className="p-2 text-sm text-justify cursor-pointer">
        <h2 className="py-2 text-xl">Directions</h2>

        <Tooltip
          delay={700}
          placement="top-start"
          maxWidth={400}
          content="Remarks that detail the seller's instructions for showing the subject property. Showing instructions may include: contact information, showing times, notice required or other information. These remarks are privileged and are not for public viewing.">
          <div>
            <Attribute
              adapterId={adapterId}
              fieldId={fields['directions']?.id}
              label="directions"
              resourceGroupId={resourceGroupId}
              value={directions}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
