import numeral from 'numeral'
import { useParams } from 'react-router-dom'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { useAdapter } from './useAdapter'
export interface Job {
  label: string | null
  listing: number | null
  agent: number | null
  office: number | null
}

export function AdapterUpdatesByHour() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })
  const stats = JSON.parse(adapter?.updatesByHour || '[]') as Job[]

  return (
    <div>
      <h2 className="pb-4 text-xl">Updates by Hour</h2>
      <div className="mb-4 rounded-lg bg-white pt-6 pb-4 pr-4 shadow">
        <ResponsiveContainer height={275}>
          <AreaChart
            width={500}
            height={400}
            data={stats}
            margin={{
              top: 0,
              right: 15,
              left: 15,
              bottom: 0
            }}>
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis />
            <Tooltip
              formatter={(value: String) => numeral(value).format('0.0a')}
            />
            <Area
              type="monotone"
              dataKey="listing"
              stackId="1"
              stroke="#6d28d9"
              fill="#8b5cf6"
            />
            <Area
              type="monotone"
              dataKey="agent"
              stackId="2"
              stroke="#f97316"
              fill="#fb923c"
            />
            <Area
              type="monotone"
              dataKey="office"
              stackId="3"
              stroke="#ef4444"
              fill="#f87171"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
