import {
  Breadcrumbs,
  Button,
  Dropdown,
  SearchFilterInput,
  Tooltip,
  useDebounce,
  Wrapper
} from '@forge/common'
import {
  AdaptersTableProvider,
  adapterTypeOptions,
  visibilityOptions
} from '@forge/features/adapters'
import { AdapterAdapterType, AdapterStatus } from '@forge/graphql/generated'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { ChangeEvent, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

export function Adapters() {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const [status, setStatus] = useState<AdapterStatus | ''>('')
  const [adapterType, setAdapterType] = useState<AdapterAdapterType | ''>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  return (
    <>
      <Helmet>
        <title>Data Adapters</title>
      </Helmet>
      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/adapters">Data Adapters</Breadcrumbs.Crumb>
        </Breadcrumbs>
        <div className="space-y-4">
          <div className="flex items-center justify-between space-x-2">
            <h1 className="flex text-4xl font-medium">
              Data Adapters
              <div className="px-2 py-2">
                <Tooltip
                  delay={700}
                  placement="right"
                  content="Data Adapters are data feeds that provide listing, agent, and office records from various MLS systems to Forge by way of RETS or RESO Web API.">
                  <InformationCircleIcon className="h-5" />
                </Tooltip>
              </div>
            </h1>
            <Button onClick={() => navigate(`/adapters/new`)}>
              New Adapter
            </Button>
          </div>
          <div className="flex justify-end space-x-2">
            <div className="w-full md:max-w-[20%]">
              <Dropdown
                value={status}
                onChange={(value) => setStatus(value as AdapterStatus)}>
                <Dropdown.Button>{visibilityOptions[status]}</Dropdown.Button>
                {Object.entries(visibilityOptions)?.map(([value, label]) => (
                  <Dropdown.Option key={value} value={value}>
                    {label}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            </div>
            <div className="w-full md:max-w-[20%]">
              <Dropdown
                value={adapterType}
                onChange={(value) =>
                  setAdapterType(value as AdapterAdapterType)
                }>
                <Dropdown.Button>
                  {adapterTypeOptions[adapterType]}
                </Dropdown.Button>
                {Object.entries(adapterTypeOptions)?.map(([value, label]) => (
                  <Dropdown.Option key={value} value={value}>
                    {label}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            </div>
            <div className="w-full md:max-w-[30%]">
              <SearchFilterInput
                value={searchTerm}
                placeholder="Search by Adapter Key"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(e.target.value)
                }
              />
            </div>
          </div>
          <div className="max-h-[calc(100vh-20rem)] overflow-scroll rounded-lg bg-white shadow">
            <AdaptersTableProvider
              searchTerm={debouncedSearchTerm}
              status={status}
              adapterType={adapterType}
            />
          </div>
        </div>
      </Wrapper>
    </>
  )
}
