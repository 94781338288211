import { Button, Checkbox, Combobox, FormField, Select } from '@forge/common'
import { useAdapterMetadata } from '@forge/features/adapters'
import { ResourceGroupPhotoStrategy } from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useResourceGroup } from './useResourceGroup'
import { useUpdateResourceGroup } from './useUpdateResourceGroup'

const schema = yup.object({
  uniqueIdField: yup.string(),
  updatedAtField: yup.string(),
  populateDateField: yup.string(),
  photosUpdatedAtField: yup.string(),
  skipPhotos: yup.bool(),
  photoStrategy: yup
    .mixed<ResourceGroupPhotoStrategy>()
    .oneOf(Object.values(ResourceGroupPhotoStrategy)),
  photoSettings: yup
    .object({
      listingIdField: yup.string().nullable(),
      objectType: yup.string().nullable(),
      orderField: yup.string().nullable(),
      photosCountField: yup.string().nullable(),
      photosUpdatedAtField: yup.string().nullable(),
      resource: yup.string().nullable(),
      resourceId: yup.string().nullable(),
      urlField: yup.string().nullable()
    })
    .required()
})

interface ResourceGroupSettingsFormProps {
  resourcecGroupId: string | undefined
  adapterId: string
  onSave: () => void
}

export function ResourceGroupSettingsForm({
  resourcecGroupId,
  adapterId,
  onSave
}: ResourceGroupSettingsFormProps) {
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourcecGroupId || ''
  })

  const { data: { adapter: adapterMetaData } = {} } = useAdapterMetadata({
    id: adapterId
  })

  const {
    mutate: updateResourceGroup,
    isLoading,
    error: updateError
  } = useUpdateResourceGroup()

  const { handleSubmit, register, setValue, watch } = useForm<
    yup.InferType<typeof schema>
  >({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      uniqueIdField: resourceGroup?.uniqueIdField ?? '',
      updatedAtField: resourceGroup?.updatedAtField ?? '',
      populateDateField: resourceGroup?.populateDateField ?? '',
      photosUpdatedAtField: resourceGroup?.photosUpdatedAtField ?? '',
      photoStrategy: resourceGroup?.photoStrategy ?? undefined,
      photoSettings: resourceGroup?.photoSettings ?? undefined,
      skipPhotos: resourceGroup?.skipPhotos ?? undefined
    }
  })

  const [trackFields, setTrackFields] = useState<string[]>(
    resourceGroup?.trackFields ?? []
  )

  const possibleSourcesOptions =
    resourceGroup?.possibleSources?.map((option) => ({
      label: option?.label ?? '',
      value: option?.value ?? ''
    })) || []

  const metadataResourceNameOptions =
    adapterMetaData?.metadata?.resources?.map((option) => ({
      label: option?.name ?? '',
      value: option?.name ?? ''
    })) || []

  const metadataResourceObjectOptions =
    adapterMetaData?.metadata?.resources
      ?.find((option) => watch('photoSettings.resource') === option.name)
      ?.objects?.map((option) => ({
        label: `${option.name} - ${option.description}`,
        value: option.name ?? ''
      })) || []

  const photoResourceOptions =
    resourceGroup?.adapter?.resources?.map((options) => ({
      label: options.name,
      value: options.id
    })) || []

  const RgPossibleSourcesOptions = (
    resourceGroup?.adapter?.resourceGroups
      ?.map((source) => source.possibleSources)
      .flat() || []
  )
    .map((options) => ({
      label: options?.label ?? '',
      value: options?.label ?? ''
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <div className="max-w-xl mx-auto">
      <form
        onSubmit={handleSubmit(({ photoSettings, ...data }) => {
          resourceGroup?.role === 'listing'
            ? updateResourceGroup(
                {
                  id: resourceGroup?.id || '',
                  unique_id_field: data.uniqueIdField,
                  updated_at_field: data.updatedAtField,
                  track_fields: trackFields,
                  populate_date_field: data.populateDateField,
                  photos_updated_at_field: data.photosUpdatedAtField,
                  photo_strategy: data.photoStrategy,
                  skipPhotos: data.skipPhotos,
                  photo_settings: {
                    listing_id_field: photoSettings.listingIdField,
                    object_type: photoSettings.objectType,
                    order_field: photoSettings.orderField,
                    photos_count_field: photoSettings.photosCountField,
                    photos_updated_at_field: photoSettings.photosUpdatedAtField,
                    url_field: photoSettings.urlField,
                    resource_id: photoSettings.resourceId,
                    resource: photoSettings.resource
                  }
                },
                {
                  onSuccess: () => {
                    onSave()
                  }
                }
              )
            : updateResourceGroup(
                {
                  id: resourceGroup?.id || '',
                  unique_id_field: data.uniqueIdField,
                  updated_at_field: data.updatedAtField,
                  populate_date_field: data.populateDateField
                },
                {
                  onSuccess: () => {
                    onSave()
                  }
                }
              )
        })}
        className="flex flex-col space-y-6">
        <div className="flex space-x-3">
          <div className="flex-col w-full space-y-3">
            <FormField
              label="Choose Unique ID field"
              aria-label="Unique ID field">
              <Combobox
                onSelect={(e) => setValue('uniqueIdField', e.value)}
                selected={possibleSourcesOptions?.find(
                  (option) => option.value === watch('uniqueIdField')
                )}
                placeholder="Choose Unique ID field"
                options={possibleSourcesOptions}
              />
            </FormField>

            <FormField
              label="Choose Updated at field"
              aria-label="Updated at field">
              <Combobox
                onSelect={(e) => setValue('updatedAtField', e.value)}
                selected={possibleSourcesOptions.find(
                  (option) => option.value === watch('updatedAtField')
                )}
                placeholder="Choose Updated at field"
                options={possibleSourcesOptions}></Combobox>
            </FormField>

            <FormField
              label="Choose Populate Date field"
              aria-label="Populate Date field">
              <Combobox
                onSelect={(e) => setValue('populateDateField', e.value)}
                selected={possibleSourcesOptions.find(
                  (option) => option.value === watch('populateDateField')
                )}
                placeholder="Choose Populate Date field"
                options={possibleSourcesOptions}
              />
            </FormField>

            {resourceGroup?.role === 'listing' && (
              <>
                <FormField
                  label="Choose Photos Updated at field"
                  aria-label="Photos Updated at field">
                  <Combobox
                    selected={possibleSourcesOptions.find(
                      (option) => option.value === watch('photosUpdatedAtField')
                    )}
                    onSelect={(e) => setValue('photosUpdatedAtField', e.value)}
                    placeholder="Choose Photos Updated at field"
                    options={possibleSourcesOptions}
                  />
                </FormField>

                <FormField
                  label="Choose Fields to track"
                  aria-label="Fields to track changes for">
                  <Combobox
                    multiple
                    selected={possibleSourcesOptions.filter((option) =>
                      trackFields?.includes(option.value)
                    )}
                    onSelect={(selectedOptions) => {
                      const selectedTrackFields = selectedOptions.map(
                        (option) => option.value
                      )
                      setTrackFields(selectedTrackFields)
                    }}
                    placeholder="Choose Fields to track"
                    options={possibleSourcesOptions.filter(
                      (option) => trackFields.indexOf(option.value) === -1
                    )}
                  />
                </FormField>
              </>
            )}
            {resourceGroup?.role === 'listing' &&
              [
                'bridge',
                'flex',
                'mlsgrid_v2',
                'mlsgrid',
                'paragon',
                'rapattoni',
                'reso_token',
                'reso_transport',
                'reso',
                'trestle'
              ].includes(resourceGroup.adapter?.adapterType ?? '') && (
                <>
                  <FormField>
                    <Checkbox
                      {...register('skipPhotos')}
                      variant="primary"
                      defaultChecked={resourceGroup?.skipPhotos || false}>
                      Skip Photos <br></br>(Check this box if we do not want to
                      expand media)
                    </Checkbox>
                  </FormField>
                </>
              )}
          </div>

          {resourceGroup?.role === 'listing' &&
            [
              'rets',
              'rets_reso',
              'rets_sub_class',
              'reso',
              'mlsgrid',
              'mlsgrid_v2',
              'paragon'
            ].includes(resourceGroup.adapter?.adapterType ?? '') && (
              <div className="flex-col w-full space-y-3">
                <FormField
                  label="Select a Photo Strategy"
                  aria-label="Photo strategies to select">
                  <Select {...register('photoStrategy')}>
                    <option value="">Select a Photo Strategy</option>
                    <option value="download_photos">download_photos</option>
                    <option value="get_object_photos">get_object_photos</option>
                    <option value="mlsgrid_photos">mlsgrid_photos</option>
                    <option value="photos_resource">photos_resource</option>
                  </Select>
                </FormField>
                {watch('photoStrategy') ===
                  ResourceGroupPhotoStrategy.DownloadPhotos && (
                  <>
                    <FormField label="Resource">
                      <Combobox
                        placeholder="Choose a Photo Setting Resource"
                        onSelect={(option) =>
                          setValue('photoSettings.resource', option.value)
                        }
                        selected={metadataResourceNameOptions.find((option) => {
                          return (
                            watch('photoSettings.resource') ?? ''
                          ).includes(option.value)
                        })}
                        options={metadataResourceNameOptions}
                      />
                    </FormField>
                    <FormField label="Object Type">
                      <Combobox
                        placeholder="Choose a Photo Setting Object Type"
                        onSelect={(option) =>
                          setValue('photoSettings.objectType', option.value)
                        }
                        selected={metadataResourceObjectOptions.find(
                          (option) => {
                            return (
                              watch('photoSettings.objectType') ?? ''
                            ).includes(option.value)
                          }
                        )}
                        options={metadataResourceObjectOptions}
                      />
                    </FormField>
                    <FormField
                      label="Photos Count Field"
                      aria-label="Photos Count Field">
                      <Combobox
                        selected={possibleSourcesOptions.find((option) =>
                          (
                            watch('photoSettings.photosCountField') ?? ''
                          ).includes(option.value)
                        )}
                        onSelect={(e) =>
                          setValue('photoSettings.photosCountField', e.value)
                        }
                        placeholder="Choose Photos Count Field"
                        options={possibleSourcesOptions}
                      />
                    </FormField>
                  </>
                )}
                {watch('photoStrategy') ===
                  ResourceGroupPhotoStrategy.GetObjectPhotos && (
                  <>
                    <FormField label="Resource">
                      <Combobox
                        placeholder="Choose a Photo Setting Resource"
                        onSelect={(option) =>
                          setValue('photoSettings.resource', option.value)
                        }
                        selected={metadataResourceNameOptions.find((option) => {
                          return (
                            watch('photoSettings.resource') ?? ''
                          ).includes(option.value)
                        })}
                        options={metadataResourceNameOptions}
                      />
                    </FormField>
                    <FormField label="Object Type">
                      <Combobox
                        placeholder="Choose a Photo Setting Object Type"
                        onSelect={(option) =>
                          setValue('photoSettings.objectType', option.value)
                        }
                        selected={metadataResourceObjectOptions.find(
                          (option) => {
                            return (
                              watch('photoSettings.objectType') ?? ''
                            ).includes(option.value)
                          }
                        )}
                        options={metadataResourceObjectOptions}
                      />
                    </FormField>
                    <FormField
                      label="Photos Count Field"
                      aria-label="Photos Count Field">
                      <Combobox
                        selected={possibleSourcesOptions.find((option) =>
                          (
                            watch('photoSettings.photosCountField') ?? ''
                          ).includes(option.value)
                        )}
                        onSelect={(e) =>
                          setValue('photoSettings.photosCountField', e.value)
                        }
                        placeholder="Choose Photos Count Field"
                        options={possibleSourcesOptions}
                      />
                    </FormField>
                  </>
                )}
                {watch('photoStrategy') ===
                  ResourceGroupPhotoStrategy.PhotosResource && (
                  <>
                    <FormField label="Photos Resource">
                      <Select {...register('photoSettings.resourceId')}>
                        <option value="">Select a Photos Resource</option>
                        {photoResourceOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                    </FormField>
                    {RgPossibleSourcesOptions.length && (
                      <>
                        <FormField
                          label="Choose ListingID Field"
                          aria-label="Choose ListingID field">
                          <Combobox
                            onSelect={(e) =>
                              setValue('photoSettings.listingIdField', e.value)
                            }
                            selected={RgPossibleSourcesOptions.find(
                              (option) =>
                                option.value ===
                                watch('photoSettings.listingIdField')
                            )}
                            placeholder="Choose ListingID Field"
                            options={RgPossibleSourcesOptions}
                          />
                        </FormField>
                        <FormField
                          label="Choose Order Field"
                          aria-label="Choose Order field">
                          <Combobox
                            onSelect={(e) =>
                              setValue('photoSettings.orderField', e.value)
                            }
                            selected={RgPossibleSourcesOptions.find(
                              (option) =>
                                option.value ===
                                watch('photoSettings.orderField')
                            )}
                            placeholder="Choose Order Field"
                            options={RgPossibleSourcesOptions}
                          />
                        </FormField>
                        <FormField
                          label="Choose URL Field"
                          aria-label="Choose URL field">
                          <Combobox
                            onSelect={(e) =>
                              setValue('photoSettings.urlField', e.value)
                            }
                            selected={RgPossibleSourcesOptions.find(
                              (option) =>
                                option.value === watch('photoSettings.urlField')
                            )}
                            placeholder="Choose URL Field"
                            options={RgPossibleSourcesOptions}
                          />
                        </FormField>
                      </>
                    )}
                  </>
                )}
                {watch('photoStrategy') ===
                  ResourceGroupPhotoStrategy.MlsgridPhotos && (
                  <>
                    <FormField
                      label="Photos Count Field"
                      aria-label="Photos Count Field">
                      <Combobox
                        selected={possibleSourcesOptions.find((option) =>
                          (
                            watch('photoSettings.photosCountField') ?? ''
                          ).includes(option.value)
                        )}
                        onSelect={(e) =>
                          setValue('photoSettings.photosCountField', e.value)
                        }
                        placeholder="Choose Photos Count Field"
                        options={possibleSourcesOptions}
                      />
                    </FormField>
                  </>
                )}
              </div>
            )}
        </div>

        <Button type="submit" loading={isLoading}>
          Save Settings
        </Button>

        {updateError && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {updateError?.message}
          </p>
        )}
      </form>
    </div>
  )
}
