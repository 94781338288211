import {
  ResourceGroupFieldsQueryVariables,
  useResourceGroupFieldsQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  fragment ResourceGroupFieldParts on Field {
    id
    aliases
    calculateDom: calculate_dom
    colName: col_name
    colType: col_type
    displayName: display_name
    fieldCategory: field_category {
      id
      name
    }
    fieldCategoryId: field_category_id
    isDraft: is_draft
    pendingChanges: pending_changes {
      aliases
      colName: col_name
      colType: col_type
      compose
      displayName: display_name
      fieldCategory: field_category {
        id
        name
        resourceGroupId: resource_group_id
      }
      fieldCategoryId: field_category_id
      mapValue: map_values
      queryable
      autocomplete
      calculateDom: calculate_dom
      sourceNames: source_names
    }
    queryable
    searchDisplay: search_display
    sourceNames: source_names
  }

  query ResourceGroupFields($resourceGroupId: ID!) {
    resourceGroupFields: resource_group(id: $resourceGroupId) {
      id
      fields(limit: 10000) {
        ...ResourceGroupFieldParts
      }
    }
  }
`

export function useResourceGroupFields(
  vars: ResourceGroupFieldsQueryVariables
) {
  return useResourceGroupFieldsQuery(vars)
}
