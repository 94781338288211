import {
  Button,
  Checkbox,
  FormField,
  Input,
  Select,
  toast
} from '@forge/common'
import { useUpdateAdmin } from '@forge/features/adminusers'
import { AdminFieldsFragment, AdminRole } from '@forge/graphql/generated'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  phone: yup.string(),
  alerts: yup.boolean(),
  timezone: yup.string(),
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
  role: yup
    .mixed<AdminRole>()
    .oneOf(Object.values(AdminRole))
    .required('Role is required')
})

interface EditAdminFormProps {
  admin?: AdminFieldsFragment | null
}

export function EditAdminForm({ admin }: EditAdminFormProps) {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      name: admin?.name ?? '',
      email: admin?.email ?? '',
      phone: admin?.phone ?? '',
      timezone: admin?.timezone ?? '',
      role: admin?.role ?? undefined,
      alerts: admin?.alerts ?? undefined
    },
    resolver: yupResolver(schema)
  })

  const {
    mutate: updateAdmin,
    isLoading: isUpdating,
    isSuccess,
    error
  } = useUpdateAdmin()

  return (
    <form
      className="space-y-2"
      onSubmit={handleSubmit((data) => {
        updateAdmin(
          { id: admin?.id ?? '', ...data },
          {
            onSuccess() {
              toast(<strong>Admin Updated Successfully</strong>, {
                variant: 'success'
              })
              navigate('/admins')
            }
          }
        )
      })}>
      <FormField required label="Role" error={errors?.role?.message}>
        <Select {...register('role')}>
          <option value="">Select a role</option>
          {Object.keys(AdminRole).map((role) => (
            <option key={role} value={role.toLowerCase()}>
              {role}
            </option>
          ))}
        </Select>
      </FormField>
      <FormField required label="Name" error={errors?.name?.message}>
        <Input {...register('name')} placeholder="Enter your name" />
      </FormField>
      <FormField required label="Email" error={errors?.email?.message}>
        <Input {...register('email')} placeholder="Enter your email" />
      </FormField>
      <FormField required label="Phone" error={errors?.phone?.message}>
        <Input {...register('phone')} placeholder="Enter your phone" />
      </FormField>
      <FormField
        label="Request Time Zone"
        error={errors.timezone?.message}
        required>
        <Select {...register('timezone')}>
          <option value="">Select a Time Zone</option>
          <option value="UTC">UTC</option>
          <option value="Atlantic Time (Canada)">Atlantic Time (Canada)</option>
          <option value="Eastern Time (US & Canada)">
            Eastern Time (US & Canada)
          </option>
          <option value="Central Time (US & Canada)">
            Central Time (US & Canada)
          </option>
          <option value="Mountain Time (US & Canada)">
            Mountain Time (US & Canada)
          </option>
          <option value="Arizona">Arizona</option>
          <option value="Pacific Time (US & Canada)">
            Pacific Time (US & Canada)
          </option>
          <option value="Hawaii">Hawaii</option>
          <option value="Alaska">Alaska</option>
        </Select>
      </FormField>
      <FormField>
        <Checkbox size="lg" defaultChecked={false} {...register('alerts')}>
          Alarm Alerts
        </Checkbox>
      </FormField>
      <Button
        fullWidth
        type="submit"
        loading={isUpdating}
        variant={!error ? 'primary' : 'danger'}
        leftIcon={
          error ? (
            <ExclamationCircleIcon />
          ) : isSuccess ? (
            <BeakerIcon />
          ) : undefined
        }>
        {!!error && <>An Error Occured. Try again?</>}
        {!error && (
          <>
            {isUpdating
              ? 'Updating admin'
              : isSuccess
              ? 'Success'
              : 'Update Admin'}
          </>
        )}
      </Button>
      {error && (
        <p role="alert" aria-live="polite" className="text-sm text-red-600">
          {error.message}
        </p>
      )}
    </form>
  )
}
