import {
  ResourceGroupQueryVariables,
  useResourceGroupQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

export const AdapterResourceGroupFields = gql`
  fragment FieldCategoryParts on FieldCategory {
    id
    name
  }

  fragment AdapterResourceGroupFields on ResourceGroup {
    id
    role
    updatesByHour: updates_by_hour
    status
    loadingProgress: loading_progress
    casInvites: cas_invites
    skipPhotos: skip_photos
    replication
    replicationLag: replication_lag
    fieldCategories: field_categories {
      ...FieldCategoryParts
    }
    deletesStatus: deletes_status {
      total
      pending
      last_purge
    }
    resources {
      resourceGroupId: resource_group_id
      id
      name
      updateData: update_data
    }
    possibleSources: possible_sources {
      label
      value
    }
    adapter {
      id
      adapterType: adapter_type
      resources {
        id
        name
        resourceGroupId: resource_group_id
      }
      resourceGroups: resource_groups {
        possibleSources: possible_sources {
          label
          value
        }
      }
    }
    uniqueIdField: unique_id_field
    updatedAtField: updated_at_field
    trackFields: track_fields
    populateDateField: populate_date_field
    photosUpdatedAtField: photos_updated_at_field
    photoStrategy: photo_strategy
    photoSettings: photo_settings {
      listingIdField: listing_id_field
      objectType: object_type
      orderField: order_field
      photosCountField: photos_count_field
      photosUpdatedAtField: photos_updated_at_field
      resource: resource
      resourceId: resource_id
      urlField: url_field
    }
  }
`

gql`
  query ResourceGroup($id: ID!) {
    resourceGroup: resource_group(id: $id) {
      ...AdapterResourceGroupFields
    }
  }
  ${AdapterResourceGroupFields}
`

export function useResourceGroup(vars: ResourceGroupQueryVariables) {
  return useResourceGroupQuery(vars, {
    enabled: !!vars.id
  })
}
