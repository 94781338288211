import { useDestroyLookupMutation } from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  mutation DestroyLookup($id: ID!) {
    deleteLookup(id: $id)
  }
`

export function useDestroyLookup() {
  return useDestroyLookupMutation()
}
