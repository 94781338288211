import {
  Button,
  DefaultSchemaFields,
  FormField,
  Input,
  Tabs,
  useDefaultSchema
} from '@forge/common'
import { AgentDetail } from '@forge/features/agent'
import { useSyncRecords } from '@forge/features/resource'
import { DeleteRecord } from '@forge/features/resourcegroups'
import {
  AgentResultType, ResourceGroupRole
} from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

enum TabEnum {
  detail,
  serialized,
  raw,
  actions
}

interface AgentResultProps {
  adapterId: string
  resourceGroupId: string
  record: AgentResultType
  role: ResourceGroupRole
  onClose: () => void
}

export function AgentResult({
  adapterId,
  resourceGroupId,
  role,
  record,
  onClose
}: AgentResultProps) {
  const { data: { defaultSchema } = {} } = useDefaultSchema({ role })

  const [searchParams, setSearchParams] = useSearchParams()
  const type = (searchParams.get('tab') || 'detail') as keyof typeof TabEnum
  const selectedTabIndex = TabEnum[type]

  const parsedRawData = JSON.parse(record?.raw_data?.raw || '[]')

  return (
    <>
      <Tabs selectedIndex={selectedTabIndex}>
        <Tabs.List>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'detail' })}>
            Agent Detail
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'serialized' })}>
            Serialized Data
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'raw' })}>
            Raw Field Data
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'actions' })}>
            Record Actions
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panels>
          <Tabs.Panel>
            <AgentDetail
              onClose={onClose}
              adapterId={adapterId}
              agent={record}
              resourceGroupId={resourceGroupId}
            />
          </Tabs.Panel>
          <Tabs.Panel>
            {/* Serialized Data */}
            <DefaultSchemaFields
              adapterId={adapterId}
              defaultSchema={defaultSchema}
              mode="path"
              record={record}
              resourceGroupId={resourceGroupId}
            />
          </Tabs.Panel>
          <Tabs.Panel>
            {/* Raw Record Data  */}
            <DefaultSchemaFields
              adapterId={adapterId}
              mode="sourceNames"
              record={parsedRawData}
              resourceGroupId={resourceGroupId}
            />
          </Tabs.Panel>
          <Tabs.Panel>
            <RecordActions
              onClose={onClose}
              resourceGroupId={resourceGroupId}
              record={record}
            />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </>
  )
}

const schema = yup.object({
  ids: yup.string().required('Ids are required')
})

interface RecordActionsProps {
  resourceGroupId: string | number
  record: AgentResultType
  onClose: () => void
}

function RecordActions({
  resourceGroupId,
  record,
  onClose
}: RecordActionsProps) {
  const resourceId = record.wr_resource_id || 0

  const {
    mutate: syncRecords,
    data: syncedRecords,
    isLoading,
    isSuccess
  } = useSyncRecords()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      ids: record?.agent_id || ''
    }
  })
  const hasFailed = isSuccess && !syncedRecords?.resource?.reloadRecord.success

  const [showDestroy, setShowDestroy] = useState(false)

  return (
    <div className="space-y-4">
      <form
        className="space-y-4"
        onSubmit={handleSubmit((data) =>
          syncRecords({
            id: String(resourceId),
            recordIds: data.ids.split(/\n/gi)
          })
        )}>
        <FormField
          label="Ids"
          error={
            errors.ids?.message
              ? errors.ids.message
              : hasFailed
              ? 'Sync failed, please try again.'
              : undefined
          }>
          <Input
            {...register('ids')}
            as="textarea"
            rows={5}
            placeholder="Paste in MLSnums/AgentIDs/OfficeIDs separated by hard returns."
          />
        </FormField>
        <div>
          <Button
            variant={hasFailed ? 'danger' : 'primary'}
            size="xs"
            loading={isLoading}>
            {hasFailed ? 'Try Again' : 'Sync Records'}
          </Button>
        </div>
      </form>
      <Button variant="danger" size="xs" onClick={() => setShowDestroy(true)}>
        Delete Record
      </Button>
      <DeleteRecord
        resourceGroupId={resourceGroupId}
        id={record.id}
        name={record.agent_id}
        isOpen={showDestroy}
        onClose={() => setShowDestroy(false)}
        onDelete={onClose}
      />
      {syncedRecords && (
        <Tabs>
          <Tabs.List>
            <Tabs.Tab>Results</Tabs.Tab>
            <Tabs.Tab>Logs</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              <pre className="overflow-auto whitespace-pre-wrap">
                {JSON.stringify(
                  syncedRecords.resource?.reloadRecord.records,
                  null,
                  2
                )}
              </pre>
            </Tabs.Panel>
            <Tabs.Panel>
              <div className="space-y-4 ">
                <div>{hasFailed ? 'Failed' : 'Success!'}</div>
                {syncedRecords.resource?.reloadRecord.logs && (
                  <pre
                    className="overflow-auto whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: syncedRecords.resource?.reloadRecord.logs
                    }}
                  />
                )}
              </div>
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      )}
    </div>
  )
}
