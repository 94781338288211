import { Attribute, Tooltip } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'

interface ListingMetaProps {
  adapterId: string
  fields: Record<string, FieldPartsFragment>
  id?: string | null
  address?: string | null
  fullAddress?: string | null
  mlsnum?: string | null
  photosUpdatedAt?: string | null
  photoCount?: string | null
  createdAt?: string | null
  location?: string | null
  gpsLoc?: string | null
  resourceGroupId: string
}

export function ListingMeta({
  adapterId,
  id,
  address,
  photosUpdatedAt,
  photoCount,
  gpsLoc,
  createdAt,
  fields,
  mlsnum,
  location,
  fullAddress,
  resourceGroupId
}: ListingMetaProps) {
  return (
    <div className="2xl:flex">
      <div>
        <h2 className="p-2 text-xl">Meta Fields</h2>
        <h3 className="p-2 text-lg">Root</h3>
        <ul className="overflow-auto xs:min-w-sm sm:max-w-full">
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="A text representation of the address with the full civiclocation as a single entity. It MUST include City, State or Province, and Postal Code.">
            <li className="p-2">
              Full Address:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['full_address']?.id || fields['address']?.id}
                label="full_address"
                resourceGroupId={resourceGroupId}
                value={fullAddress}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="A text representation of the address with the full civiclocation as a single entity. Excluding City, State or Province, and Postal Code.">
            <li className="p-2">
              Address:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['address']?.id || fields['full_address']?.id}
                label="address"
                resourceGroupId={resourceGroupId}
                value={address}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="A unique identifier for this record from the immediate source. This is a string that can include URI or other forms. Alternatively use the ListingKeyNumeric for a numeric only key field. This is the local key of the system. When records are received from other systems, a local key is commonly applied. If conveying the original keys from the source or originating systems, see SourceSystemKey and OriginatingSystemKey.">
            <li className="p-2">
              Unique Listing ID:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['id']?.id || fields['mlsnum']?.id}
                label="id"
                resourceGroupId={resourceGroupId}
                value={id}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The well known identifier for the listing. The value may be identical to that of the Listing Key, but the Listing ID is intended to be the value used by a human to retrieve the information about a specific listing. In a multiple originating system or a merged system, this value may not be unique and may require the use of the provider system to create a synthetic unique value.">
            <li className="p-2">
              MLSnum:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['mlsnum']?.id || fields['id']?.id}
                label="mlsnum"
                resourceGroupId={resourceGroupId}
                value={mlsnum}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The transactional timestamp automatically recorded by the MLS system representing the date/time the listing was entered and made visible to members of the MLS.">
            <li className="p-2">
              Created At:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['created_at']?.id || fields['date_list']?.id}
                label="created_at"
                resourceGroupId={resourceGroupId}
                value={createdAt}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="System generated timestamp of when the last update or change to the photos for this listing was made.">
            <li className="p-2">
              Photos Updated At:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['photos_updated_at']?.id}
                label="photos_updated_at"
                resourceGroupId={resourceGroupId}
                value={photosUpdatedAt}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="The total number of pictures or photos included with the listing.">
            <li className="p-2">
              Photo Count:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['photo_count']?.id}
                label="photo_count"
                resourceGroupId={resourceGroupId}
                value={photoCount}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="Geo point: [longitude,latitude]">
            <li className="p-2">
              Location:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['location']?.id}
                label="location"
                resourceGroupId={resourceGroupId}
                value={location}
              />
            </li>
          </Tooltip>
          <Tooltip
            delay={700}
            placement="top-start"
            maxWidth={400}
            content="Geo shape: [longitude,latitude]">
            <li className="p-2">
              GPS Location:{' '}
              <Attribute
                adapterId={adapterId}
                fieldId={fields['gps_loc']?.id}
                label="gps_loc"
                resourceGroupId={resourceGroupId}
                value={gpsLoc}
              />
            </li>
          </Tooltip>
        </ul>
      </div>
    </div>
  )
}
