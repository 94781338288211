import { Breadcrumbs, Tooltip, Wrapper } from '@forge/common'
import { NewFieldTemplateForm } from '@forge/features/fieldtemplates'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'

export function NewFieldTemplate() {
  return (
    <>
      <Helmet>
        <title>New Field Template</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/field_templates">
            Field Templates
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/field_templates/new`}>
            New Field Template
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            New Field Template
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="New Field Template">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>
        <div className="mx-auto max-w-lg justify-center">
          <NewFieldTemplateForm />
        </div>
      </Wrapper>
    </>
  )
}
