import { Table, TimeAgo } from '@forge/common'
import { Link } from 'react-router-dom'
import { useDashboardAlarms } from './useDashboardAlarms'

export function DashboardAlarms() {
  const { data: { alarms } = {} } = useDashboardAlarms()

  if (!alarms?.length) {
    return (
      <div className="md:w-1/2">
        <h2 className="py-4 text-xl">Adapter Alarms</h2>
        <small>Nothing's on fire. 🥳</small>
      </div>
    )
  }

  return (
    <div className="md:w-1/2">
      <h3 className="py-4 text-xl">Adapter Alarms</h3>
      <div className="max-h-[calc(100vh-13.75rem)] overflow-auto rounded-lg bg-white px-1 shadow">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header align="left">Alarm</Table.Header>
              <Table.Header align="right">Created</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {alarms?.map(({ id, urlPath, description, createdAtIso }) => (
              <Table.Row key={id}>
                <Table.Data>
                  <Link
                    to={`/${urlPath || '404'}`}
                    className="text-sm text-red-500 hover:text-orange-400">
                    {description}
                  </Link>
                </Table.Data>
                <Table.Data align="right">
                  <i>{createdAtIso ? <TimeAgo date={createdAtIso} /> : '-'}</i>
                </Table.Data>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
