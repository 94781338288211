import { ErrorBoundary, NotFound, Spinner, ToastContainer } from '@forge/common'
import { AuthLayout, useCurrentUser } from '@forge/features/auth'
import { Header } from '@forge/features/header'
import { SearchLayout } from '@forge/features/search'
import { SetupLayout } from '@forge/features/setup'
import {
  Adapter,
  AdapterDebugger,
  AdapterEdit,
  AdapterMetadata,
  AdapterNew,
  Adapters,
  AlarmProfileEdit,
  AlarmProfileNew,
  AlarmProfiles
} from '@forge/pages/adapters'
import { AdminUsers, EditAdmin, NewAdmin } from '@forge/pages/admin-users'
import {
  ApiUser,
  ApiUserEdit,
  ApiUsers,
  NewApiUser
} from '@forge/pages/api-users'
import {
  Login,
  PasswordRequestReset,
  PasswordReset,
  Register
} from '@forge/pages/auth'
import { Dashboard } from '@forge/pages/dashboard'
import {
  EditFieldTemplate,
  FieldTemplates,
  NewFieldTemplate
} from '@forge/pages/field-templates'
import {
  ListingService,
  ListingServiceEdit,
  ListingServiceNew,
  ListingServices
} from '@forge/pages/listing-services'
import { ListingServiceSavedSearchEdit } from '@forge/pages/listing-services'
import { Resource } from '@forge/pages/resource'
import {
  NewResourceGroupField,
  ResourceGroup,
  ResourceGroupField,
  ResourceGroupFields,
  ResourceGroupSearch,
  ResourceGroupSettings,
  ResourceGroupSetup
} from '@forge/pages/resource-group'
import { UserProfile } from '@forge/pages/settings'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense, useEffect, useRef } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
      suspense: true
    }
  }
})

export function App() {
  return (
    <Suspense
      fallback={
        <div className="w-full p-5">
          <div className="mx-auto h-5 w-5 text-orange-400">
            <Spinner />
          </div>
        </div>
      }>
      <ErrorBoundary
        fallback={(error) => (
          <div className="p-5 ">
            <h1 className="text-2xl text-red-500">Error loading Forge</h1>
            <p className="text-gray-600">{error.message}</p>
            {error.stack && <p className="text-gray-600">{error.stack}</p>}
          </div>
        )}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <Helmet>
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i"
              />
              <link
                rel="stylesheet"
                href="https://unpkg.com/react-selectize@3.0.1/dist/index.min.css"
              />
            </Helmet>
            <BrowserRouter>
              <ToastProvider placement="bottom-right">
                <Routes>
                  <Route path="/" element={<AppLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/settings" element={<UserProfile />} />

                    {/* Adapters Routes */}
                    <Route path="/adapters" element={<Adapters />} />
                    <Route path="/adapters/new" element={<AdapterNew />} />
                    <Route path="/adapters/:adapterId" element={<Outlet />}>
                      <Route path="details" element={<Adapter />} />
                      <Route path="metadata" element={<AdapterMetadata />} />
                      <Route path="debugger" element={<AdapterDebugger />} />
                      <Route path="edit" element={<AdapterEdit />} />
                      <Route path="alarms" element={<AlarmProfiles />} />
                      <Route path="alarms/new" element={<AlarmProfileNew />} />
                      <Route
                        path="alarms/:alarmProfileId/edit"
                        element={<AlarmProfileEdit />}
                      />
                      <Route
                        path="resource_groups/:resourceGroupId"
                        element={
                          <div>
                            <Outlet />
                          </div>
                        }>
                        <Route index element={<ResourceGroup />} />
                        <Route
                          path="settings"
                          element={<ResourceGroupSettings />}
                        />
                        <Route
                          path="fields"
                          element={<ResourceGroupFields />}
                        />
                        <Route
                          path="fields/new"
                          element={<NewResourceGroupField />}
                        />
                        <Route
                          path="fields/:fieldId"
                          element={<ResourceGroupField />}
                        />
                        <Route path="resources/:id" element={<Resource />} />
                      </Route>
                    </Route>

                    {/* Listing Services Routes */}
                    <Route
                      path="/listing_services"
                      element={<ListingServices />}
                    />
                    <Route
                      path="/listing_services/new"
                      element={<ListingServiceNew />}
                    />
                    <Route path="/listing_services/:id" element={<Outlet />}>
                      <Route path="details" element={<ListingService />} />
                      <Route path="edit" element={<ListingServiceEdit />} />
                      <Route
                        path="saved/searches/:savedSearchId/edit"
                        element={<ListingServiceSavedSearchEdit />}
                      />
                    </Route>

                    {/* Field Template Routes */}
                    <Route
                      path="/field_templates"
                      element={<FieldTemplates />}
                    />
                    <Route
                      path="/field_templates/new"
                      element={<NewFieldTemplate />}
                    />
                    <Route
                      path="/field_templates/:fieldTemplateId/edit"
                      element={<EditFieldTemplate />}
                    />

                    {/* API Users Routes */}
                    <Route path="/api_users" element={<ApiUsers />} />
                    <Route path="/api_users/new" element={<NewApiUser />} />
                    <Route path="/api_user/:apiUserId" element={<Outlet />}>
                      <Route index element={<div>API User Index</div>} />
                      <Route path="details" element={<ApiUser />} />
                      <Route path="edit" element={<ApiUserEdit />} />
                    </Route>

                    {/* Admins Routes */}
                    <Route path="/admins" element={<AdminUsers />} />
                    <Route path="/admins/new" element={<NewAdmin />} />
                    <Route
                      path="/admins/:admin_id/edit"
                      element={<EditAdmin />}
                    />
                  </Route>

                  {/* FullScreen Adapter Resource Group Setup */}
                  <Route
                    path="/adapters/:adapterId/resource_groups/:resourceGroupId/setup"
                    element={<SetupLayout />}>
                    <Route index element={<ResourceGroupSetup />}></Route>
                  </Route>

                  {/* FullScreen Adapter Resource Group Search */}
                  <Route
                    path="/adapters/:adapterId/resource_groups/:resourceGroupId/search"
                    element={<SearchLayout />}>
                    <Route index element={<ResourceGroupSearch />}></Route>
                  </Route>

                  {/* Public routes */}
                  <Route path="/" element={<AuthLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/password/new"
                      element={<PasswordRequestReset />}
                    />
                    <Route
                      path="/reset_password/:resetToken"
                      element={<PasswordReset />}
                    />
                    <Route path="/register" element={<Register />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              </ToastProvider>
            </BrowserRouter>
            <ToastContainer />
          </HelmetProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

function AppLayout() {
  const { data: currentUser } = useCurrentUser()
  const location = useLocation()

  const mainRef = useRef<HTMLElement>(null)

  useEffect(() => {
    mainRef.current?.scrollTo(0, 0)
  }, [location.pathname])

  if (!currentUser) {
    const params = new URLSearchParams(location.search)
    params.delete('token')

    return (
      <Navigate
        to="/login"
        state={{ from: { ...location, search: `?${params.toString()}` } }}
      />
    )
  }

  return (
    <>
      <Header />
      <main ref={mainRef}>
        <Suspense
          fallback={
            <div className="flex w-full p-5">
              <div className="mx-auto h-5 w-5 text-orange-400 ">
                <Spinner />
              </div>
            </div>
          }>
          <Outlet />
        </Suspense>
      </main>
    </>
  )
}
