import { Button, FormField, Input, toast } from '@forge/common'
import { useCreateApiUser } from '@forge/features/apiusers'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as yup from 'yup'

const schema = yup.object({
  name: yup.string(),
  password: yup.string(),
  apiKey: yup.string().required('The api key is required')
})

export function ApiUserNewForm() {
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      name: '',
      apiKey: uuid().replaceAll('-', ''),
      password: uuid().replaceAll('-', '')
    },
    resolver: yupResolver(schema)
  })

  const {
    mutate: createApiUser,
    isLoading: isCreating,
    isSuccess,
    error
  } = useCreateApiUser()

  return (
    <div className="max-w-lg m-auto">
      <form
        className="space-y-3"
        onSubmit={handleSubmit((data) => {
          createApiUser(data, {
            onSuccess() {
              toast(<strong>API user added successfully</strong>, {
                variant: 'success'
              })
              navigate('/api_users')
            }
          })
        })}>
        <FormField label="Name">
          <Input placeholder="Name of API User" {...register('name')} />
        </FormField>
        <FormField label="API KEY">
          <Input readOnly placeholder="API Key" {...register('apiKey')} />
        </FormField>
        <FormField label="API SECRET">
          <Input readOnly placeholder="API Secret" {...register('password')} />
        </FormField>
        <div>
          <div className="mt-5 mb-2 ml-2">
            <p className="text-xs font-medium text-red-500">
              This is the ONLY time this value will be shown! Write it down!
            </p>
          </div>
          <Button
            fullWidth
            variant={!error ? 'primary' : 'danger'}
            loading={isCreating}
            leftIcon={
              error ? (
                <ExclamationCircleIcon />
              ) : isSuccess ? (
                <BeakerIcon />
              ) : undefined
            }>
            {!!error && <>An Error Occured. Try again?</>}
            {!error && (
              <>
                {isCreating
                  ? 'Creating User'
                  : isSuccess
                  ? 'Success'
                  : 'Create Api User'}
              </>
            )}
          </Button>
        </div>
        {error && (
          <p role="alert" aria-label="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
