import { Badge } from '@forge/common'

interface OfficeBadgeProps {
  active: boolean | null | undefined
}

export function OfficeBadge({ active }: OfficeBadgeProps) {
  return (
    <Badge
      variant={
        active === true ? 'success' : active === false ? 'danger' : 'default'
      }>
      {String(active) || 'N/A'}
    </Badge>
  )
}
