import { Breadcrumbs, Tooltip, Wrapper } from '@forge/common'
import {
  ListingServiceSavedSearchEditForm,
  useListingService
} from '@forge/features/listingservices'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function ListingServiceSavedSearchEdit() {
  const { id: listingServiceId, savedSearchId } = useParams()
  const { data: { listingService } = {} } = useListingService({
    id: listingServiceId
  })
  return (
    <>
      <Helmet>
        <title>{listingService?.key?.toUpperCase()} - Edit</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/listing_services">
            Listing Services
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/listing_services/${listingService?.id}/details`}>
            {listingService?.name}
          </Breadcrumbs.Crumb>

          <Breadcrumbs.Crumb
            to={`/listing_services/${listingService?.id}/details?tab=savedSearches`}>
            Saved Searches
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/listing_services/${listingServiceId}/saved/searches/${savedSearchId}/edit`}>
            Edit Saved Search
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            Edit Service Saved Search
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Edit Listing Service Saved Search">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>
        <div className="w-full">
          <ListingServiceSavedSearchEditForm />
        </div>
      </Wrapper>
    </>
  )
}
