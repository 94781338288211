import {
  Alert,
  Breadcrumbs,
  Button,
  Menu,
  NotFound,
  Tooltip,
  Wrapper
} from '@forge/common'
import { useCurrentUser } from '@forge/features/auth'
import {
  ListingServiceApiUsers,
  ListingServiceTabs,
  useDeleteListingService,
  useListingService
} from '@forge/features/listingservices'
import {
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/outline'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'

export function ListingService() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [openDeleteServiceModal, setOpenDeleteServiceModal] = useState(false)

  const { data: currentUser } = useCurrentUser()

  const { data: { listingService } = {}, isSuccess } = useListingService({
    id
  })

  const {
    mutate: deleteListingService,
    isLoading: isDeletingListingService,
    error: errorDeletingService
  } = useDeleteListingService()

  if (isSuccess && !listingService) {
    return <NotFound />
  }

  return (
    <>
      <Helmet>
        <title>Listing Service: {listingService?.key?.toUpperCase()}</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/listing_services">
            Listing Services
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/listing_services/${listingService?.id}/details`}>
            {listingService?.name}
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex items-baseline justify-between space-x-2">
          <h1 className="flex text-4xl font-medium">
            {listingService?.key?.toUpperCase()} Dashboard
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="A Listing Service is best thought of as an MLS/AOR that an
                appplication will reference when requesting data.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
          <Menu
            button={
              <Button as="span" aria-label="Listing Service Options">
                <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Button>
            }>
            <Menu.Item
              onClick={() =>
                navigate(`/listing_services/${listingService?.id}/edit`)
              }>
              Edit {listingService?.name} listing service
            </Menu.Item>
            {currentUser?.role === 'super' && (
              <Menu.Item
                variant="danger"
                onClick={() => setOpenDeleteServiceModal(true)}>
                Delete {listingService?.name} listing service
              </Menu.Item>
            )}
          </Menu>
        </div>
        <div className="pb-8">
          <div className="xs:col-span-1 md:flex 2xl:col-span-2">
            <div className="col-span-1 h-fit w-full md:flex">
              <div className="w-full">
                <ListingServiceTabs />
              </div>
            </div>
            <div className="xs:mt-6 xs:max-w-full md:mt-0 md:ml-6 md:w-full lg:max-w-fit">
              <ListingServiceApiUsers />
            </div>
          </div>
        </div>
        {listingService && (
          <Alert variant="danger" isOpen={openDeleteServiceModal}>
            <Alert.Title>Delete Listing Service</Alert.Title>
            <Alert.Content>
              <p>
                Are you sure you want to delete this Listing Service (
                <strong>{listingService.name}</strong>)? This action cannot be
                undone.
              </p>
              {errorDeletingService && (
                <div className="text-red-500" role="alert" aria-live="polite">
                  {errorDeletingService.message}
                </div>
              )}
            </Alert.Content>
            <Alert.Cancel onClick={() => setOpenDeleteServiceModal(false)}>
              Cancel
            </Alert.Cancel>
            <Alert.Confirm
              loading={isDeletingListingService}
              leftIcon={<ExclamationCircleIcon />}
              onClick={() => {
                deleteListingService(
                  { id: listingService.id },
                  {
                    onSuccess() {
                      navigate('/listing_services')
                    }
                  }
                )
              }}>
              Delete Forever
            </Alert.Confirm>
          </Alert>
        )}
      </Wrapper>
    </>
  )
}
