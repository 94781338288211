import { Breadcrumbs, Button, Tooltip, Wrapper } from '@forge/common'
import {
  ListingServicesTable
} from '@forge/features/listingservices'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export function ListingServices() {
  return (
    <>
      <Helmet>
        <title>Listing Services</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/listing_services">
            Listing Services
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex items-center justify-between space-x-2">
          <h1 className="flex text-4xl font-medium">
            Listing Services
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Listing Services hook up to Data Adapters to serve
                  listing, agent, and office records from Forge to Cloud
                  Agent Suite applications.">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>

          <Button as={Link} to={`/listing_services/new`}>
            New Listing Service
          </Button>
        </div>
        <ListingServicesTable />
      </Wrapper>
    </>
  )
}
