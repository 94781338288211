import { Attribute, Spinner, Wrapper } from '@forge/common'
import {
  ListingAgentFields,
  ListingDescription,
  ListingDisclaimer,
  ListingFeatures,
  ListingFields,
  ListingFullscreenPhotos,
  ListingFullscreenTour,
  ListingHeader,
  ListingMeta,
  ListingOfficeFields,
  ListingPhotos,
  ListingPrice,
  ListingRemarks,
  ListingShowings,
  ListingStats
} from '@forge/features/listing'
import { useResourceGroupSearchFields } from '@forge/features/resourcegroups'
import { FieldPartsFragment, ListingResultType } from '@forge/graphql/generated'
import { ArrowLeftIcon } from '@forge/icons'
import { at } from 'lodash'
import { useState } from 'react'

interface ListingDetailProps {
  adapterId: string
  listing: ListingResultType
  onClose: () => void
  resourceGroupId: string
}

export function ListingDetail({
  adapterId,
  listing,
  onClose,
  resourceGroupId
}: ListingDetailProps) {
  const { data: resourceGroupSearchFields = {}, isFetching } =
    useResourceGroupSearchFields({
      resourceGroupId
    })

  const searchFields = resourceGroupSearchFields?.resourceGroup?.fields?.sort(
    (a, b) => (a?.displayName || '').localeCompare(b?.displayName || '')
  )

  const fields =
    searchFields?.reduce<Record<string, FieldPartsFragment>>(
      (fields, field) => {
        const key = field.aliases?.[0] || field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}

  return (
    <>
      <Details
        adapterId={adapterId}
        key={listing.id}
        listing={listing}
        onClose={onClose}
        resourceGroupId={resourceGroupId}
        fields={fields}
      />
      <Wrapper>
        <div className="max-h-[calc(100vh-23rem)] overflow-auto">
          <ListingFeatures features={listing.features || ''} />
        </div>
      </Wrapper>
      {isFetching && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-white/50 backdrop-blur-sm">
          <div className="h-12 w-12 text-orange-500">
            <Spinner />
          </div>
        </div>
      )}
    </>
  )
}

interface DetailsProps {
  adapterId: string
  listing: ListingResultType
  onClose: () => void
  resourceGroupId: string
  fields: Record<string, FieldPartsFragment>
}

function Details({
  adapterId,
  listing,
  onClose,
  resourceGroupId,
  fields
}: DetailsProps) {
  const [fullscreenPhotoPage, setFullscreenPhotoPage] = useState<number | null>(
    null
  )
  const [fullscreenTourPage, setFullscreenTourPage] = useState<number | null>(
    null
  )

  const hasTourUrl: boolean =
    at(listing.features, 'Virtual Tour')[0] !== '' || false

  const hasTourField: boolean =
    at(listing.features, 'Virtual Tour')[0] !== undefined || false

  return (
    <>
      <ListingHeader
        adapterId={adapterId}
        resourceGroupId={resourceGroupId}
        fields={fields}
        address={listing.address}
        city={listing.city}
        state={listing.state}
        zip={listing.zip}
        icon={
          fullscreenPhotoPage !== null || fullscreenTourPage !== null ? (
            <ArrowLeftIcon />
          ) : undefined
        }
        onClose={() => {
          fullscreenPhotoPage !== null
            ? setFullscreenPhotoPage(null)
            : fullscreenTourPage !== null
            ? setFullscreenTourPage(null)
            : onClose()
        }}></ListingHeader>

      {fullscreenPhotoPage !== null && (
        <div>
          <ListingFullscreenPhotos
            key={`photos_${listing.id}`}
            photos={listing.photos || []}
            initialPage={fullscreenPhotoPage || undefined}
          />
        </div>
      )}
      {fullscreenTourPage !== null && (
        <div>
          <ListingFullscreenTour
            tour={at(listing.features, 'Virtual Tour')[0] || ''}
          />
        </div>
      )}
      {fullscreenPhotoPage === null && fullscreenTourPage === null && (
        <div>
          {listing?.photos?.length ? (
            <ListingPhotos
              photos={listing.photos || []}
              hasTourUrl={hasTourUrl}
              hasTourField={hasTourField}
              onPhotoClick={(index) => setFullscreenPhotoPage(index)}
              onTourClick={(index) => setFullscreenTourPage(index)}
            />
          ) : (
            <>
              <span className="p-2 hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['photos']?.id}
                  label="photos"
                  resourceGroupId={resourceGroupId}
                  value={''}
                />
              </span>
              <span className="p-2 hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['virtual_tour']?.id}
                  label="virtual_tour"
                  resourceGroupId={resourceGroupId}
                  value={at(listing.features, 'Virtual Tour')[0]}
                />
              </span>
            </>
          )}
          <div className="mx-auto flex max-w-4xl flex-col items-start justify-between gap-6 overflow-auto xs:w-fit xs:p-1 md:w-full md:p-6 2xl:flex-row">
            <div className="space-y-6">
              <div className="2xl:flex 2xl:justify-between">
                <div className="mb-4 space-y-6 2xl:max-w-lg">
                  <ListingStats
                    adapterId={adapterId}
                    baths={listing.baths}
                    beds={listing.beds}
                    fields={fields}
                    garages={listing.garages}
                    lotsize={listing.lotsize}
                    resourceGroupId={resourceGroupId}
                    sqft={listing.sqft}
                    yearBuilt={listing.year_built}
                  />
                  <div className="max-w-fit overflow-auto text-justify text-sm text-gray-900">
                    <ListingDescription
                      adapterId={adapterId}
                      description={listing.remarks}
                      fields={fields}
                      resourceGroupId={resourceGroupId}
                    />
                  </div>
                  <div className="mx-auto w-full space-y-6 overflow-auto rounded-2xl bg-gray-200 p-4">
                    <ListingShowings
                      adapterId={adapterId}
                      agentListEmail={listing.agent_list?.email}
                      agentListName={listing.agent_list?.name}
                      agentListPhone={listing.agent_list?.phone}
                      fields={fields}
                      officeListName={listing.office_list?.name}
                      resourceGroupId={resourceGroupId}
                      showingInst={listing.showing_inst}
                    />
                  </div>
                </div>
                <div className="sticky top-4 w-full shrink-0 space-y-6 2xl:max-w-[16.5rem]">
                  <div className="shadow-raised divide-y rounded bg-white">
                    <div className="mx-auto px-2 py-3">
                      <ListingPrice
                        adapterId={adapterId}
                        baths={listing.baths}
                        beds={listing.beds}
                        dateList={listing.date_list}
                        dateSold={listing.date_sold}
                        fields={fields}
                        price={listing.price}
                        priceList={listing.price_list}
                        priceListOrig={listing.price_list_orig}
                        priceListPerSqft={listing.price_list_per_sqft}
                        priceSold={listing.price_sold}
                        resourceGroupId={resourceGroupId}
                        sqft={listing.sqft}
                      />
                    </div>
                  </div>
                  <ListingDisclaimer
                    adapterId={adapterId}
                    fields={fields}
                    mlsnum={listing.mlsnum}
                    officeListName={listing.office_list?.name}
                    resourceGroupId={resourceGroupId}
                    updatedAt={listing.updated_at}
                  />
                </div>
              </div>
              <div className="overflow-auto text-justify text-sm xs:max-w-[20rem] md:max-w-fit md:p-2">
                <ListingMeta
                  adapterId={adapterId}
                  address={listing.address}
                  createdAt={listing.created_at}
                  fields={fields}
                  fullAddress={listing.full_address}
                  gpsLoc={listing.gps_loc}
                  id={listing.id}
                  location={listing.location}
                  mlsnum={listing.mlsnum}
                  photoCount={listing.photo_count}
                  photosUpdatedAt={listing.photos_updated_at}
                  resourceGroupId={resourceGroupId}
                />
              </div>
              <div className="overflow-auto p-2 text-justify text-sm xs:max-w-[20rem] sm:max-w-lg 2xl:max-w-full">
                <ListingFields
                  acres={listing.acres}
                  adapterId={adapterId}
                  area={listing.area}
                  associationFee={listing.assoc_fee}
                  baths={listing.baths}
                  bathsFull={listing.baths_full}
                  bathsHalf={listing.baths_half}
                  bathsSearch={listing.baths_search}
                  city={listing.city}
                  county={listing.county}
                  dateList={listing.date_list}
                  dateOffmarket={listing.date_offmarket}
                  datePending={listing.date_pending}
                  dateSold={listing.date_sold}
                  dom={listing.dom}
                  fields={fields}
                  garages={listing.garages}
                  latitude={listing.lat}
                  longitude={listing.lon}
                  lotDim={listing.lotdim}
                  lotSize={listing.lotsize}
                  mappedStatus={listing.mapped_status}
                  parcel={listing.parcel}
                  price={listing.price}
                  priceList={listing.price_list}
                  priceListOrig={listing.price_list_orig}
                  priceSold={listing.price_sold}
                  propSubType={listing.prop_sub_type}
                  propType={listing.prop_type}
                  resourceGroupId={resourceGroupId}
                  schoolDistrict={listing.school_district}
                  schoolElementary={listing.school_elementary}
                  schoolHigh={listing.school_high}
                  schoolMiddle={listing.school_middle}
                  state={listing.state}
                  status={listing.status}
                  stories={listing.features_stories_total}
                  streetName={listing.streetname}
                  streetNum={listing.streetnum}
                  style={listing.style}
                  subdivision={listing.subdivision}
                  taxes={listing.taxes}
                  unit={listing.unit}
                  virtualTour={at(listing.features, 'Virtual Tour')[0]}
                  zip={listing.zip}
                  zipcode={listing.zipcode || listing.zip}
                />
              </div>
              <div className="w-fit p-2 text-justify text-sm">
                <ListingAgentFields
                  fields={fields}
                  adapterId={adapterId}
                  resourceGroupId={resourceGroupId}
                  agentListId={listing.agent_list?.id}
                  agentListName={listing.agent_list?.name}
                  agentListEmail={listing.agent_list?.email}
                  agentListPhone={listing.agent_list?.phone}
                  agentSellId={listing.agent_sell?.id}
                  agentSellName={listing.agent_sell?.name}
                  agentSellEmail={listing.agent_sell?.email}
                  agentSellPhone={listing.agent_sell?.phone}
                />
              </div>
              <div className="p-2 text-justify text-sm xs:max-w-[20rem] sm:max-w-lg">
                <ListingOfficeFields
                  fields={fields}
                  adapterId={adapterId}
                  resourceGroupId={resourceGroupId}
                  officeListId={listing.office_list?.id}
                  officeListName={listing.office_list?.name}
                  officeListEmail={listing.office_list?.email}
                  officeListPhone={listing.office_list?.phone}
                  officeSellId={listing.office_sell?.id}
                  officeSellName={listing.office_sell?.name}
                  officeSellEmail={listing.office_sell?.email}
                  officeSellPhone={listing.office_sell?.phone}
                />
              </div>
              <ListingRemarks
                fields={fields}
                adapterId={adapterId}
                resourceGroupId={resourceGroupId}
                remarks={listing.remarks}
                privateRemarks={listing.remarks_private}
                directions={listing.directions}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
