import { Tabs } from '@forge/common'
import { ResourceGroups } from '../resourcegroups'
import { AdapterConnections } from './AdapterConnections'
import { AdapterDetails } from './AdapterDetails'

export function AdapterTabs() {
  return (
    <>
      <Tabs>
        <Tabs.List>
          <Tabs.Tab>Resource Groups</Tabs.Tab>
          <Tabs.Tab>Details</Tabs.Tab>
          <Tabs.Tab>Listing Services</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panels>
          <Tabs.Panel>
            <ResourceGroups />
          </Tabs.Panel>
          <Tabs.Panel>
            <AdapterDetails />
          </Tabs.Panel>
          <Tabs.Panel>
            <AdapterConnections />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </>
  )
}
