import {
  Modal,
  SearchFilterInput,
  Table,
  Tabs,
  Tooltip,
  useCopyToClipboard
} from '@forge/common'
import { ClipboardIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdapterMetadata } from '../adapters'
import { useResourceGroup } from '../resourcegroups'
import { useResourceSample } from './useResourceSample'

interface Field {
  SystemName: string
  StandardName: string
  LongName: string
  DBName: string
  ShortName: string
  MaxLength: number
  Interpretation: string
  DataType: string
  Lookups: Lookup[]
}

interface Lookup {
  value: string
  long_value: string
}

interface SampleDataTableProps {
  onFieldNameClick: (name: string) => void
}

export function SampleDataTable({ onFieldNameClick }: SampleDataTableProps) {
  const { adapterId, resourceGroupId } = useParams()
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: String(resourceGroupId ?? '')
  })

  const [selectedResourceId] = useState<string>(
    resourceGroup?.resources?.[0]?.id || ''
  )
  const { data: { resource } = {} } = useResourceSample({
    id: String(selectedResourceId ?? '')
  })
  const { data: { adapter } = {} } = useAdapterMetadata({
    id: adapterId
  })
  const [, copy] = useCopyToClipboard()
  const [expandedField, setExpandedField] = useState<Field | null>(null)

  const rawJSON = JSON.parse(resource?.sampleData?.raw || '')
  const metadataResource = adapter?.metadata?.resources?.find(
    (r) => r.name === resource?.sourceType
  )
  const metadataClasses = metadataResource?.classes?.find(
    (c) => c?.name === resource?.sourceClass
  )
  const metadataFields = metadataClasses?.fields ?? ''
  const [searchTerm, setSearchTerm] = useState('')

  const filterMdFields = (
    metadataFields: Field[] | undefined | null,
    searchTerm: string
  ) => {
    if (!metadataFields) return
    else {
      return metadataFields.filter((mdField) =>
        ['SystemName', 'StandardName', 'LongName', 'DBName', 'ShortName'].some(
          (key) => {
            return (mdField[key as keyof Field] || '')
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          }
        )
      )
    }
  }

  return (
    <div>
      <div className="py-4">
        <SearchFilterInput
          value={searchTerm}
          placeholder="Search Metadata Fields"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
        />
      </div>
      <div className="max-h-[calc(100vh-22rem)] overflow-y-auto rounded-lg bg-white px-1 shadow">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header align="left">Field Long Name</Table.Header>
              <Table.Header align="left">Raw Values</Table.Header>
              <Table.Header></Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {filterMdFields(metadataFields, searchTerm)?.map((field: Field) => (
              <Table.Row key={field.SystemName}>
                <Table.Data onClick={() => onFieldNameClick(field.SystemName)}>
                  <div className="overflow-x-auto whitespace-normal">
                    <div className="max-w-[10rem] overflow-auto">
                      {field.SystemName}
                    </div>
                  </div>
                </Table.Data>
                <Table.Data>
                  <div className="max-w-[10rem] overflow-auto">
                    {/* Raw data may newer than cached metadata and sometimes a field in the table may no longer exist in the raw data.
                    Use optional chaining to guard against a field systemName being unavailable. */}
                    {rawJSON?.[field.SystemName]}
                  </div>
                </Table.Data>
                <Table.Data>
                  <button onClick={() => setExpandedField(field)}>
                    <InformationCircleIcon className="h-5 w-5 cursor-pointer text-orange-400" />
                  </button>
                </Table.Data>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Modal
          size="3xl"
          isOpen={!!expandedField}
          onClose={() => setExpandedField(null)}>
          <Modal.Title>Field Info</Modal.Title>
          <Tabs>
            <Tabs.List>
              <Tabs.Tab>Info</Tabs.Tab>
              <Tabs.Tab>Lookups</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panels>
              <Tabs.Panel>
                <div className="max-h-[calc(100vh-18.75rem)] overflow-y-auto">
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Header align="left">Field</Table.Header>
                        <Table.Header align="left">Value</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {expandedField &&
                        Object.entries(expandedField).map(([field, value]) => (
                          <Table.Row key={field}>
                            <Table.Data>{field}:</Table.Data>
                            <Table.Data>
                              <span className="flex">
                                <span className="px-2">
                                  {' '}
                                  {field === 'Lookups' ? '' : value}
                                </span>
                                {field === 'Lookups'
                                  ? ''
                                  : value && (
                                      <span className="flex w-fit">
                                        <Tooltip
                                          delay={700}
                                          placement="right"
                                          maxWidth={150}
                                          content="Click to copy">
                                          <button
                                            onClick={() =>
                                              copy(
                                                `${
                                                  field === 'Lookups'
                                                    ? ''
                                                    : value
                                                }`
                                              )
                                            }>
                                            <ClipboardIcon
                                              onClick={() =>
                                                setExpandedField(null)
                                              }
                                              className="h-3 w-3 hover:text-orange-400"
                                            />
                                          </button>
                                        </Tooltip>
                                      </span>
                                    )}
                              </span>
                            </Table.Data>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </div>
              </Tabs.Panel>
              <Tabs.Panel>
                <div className="max-h-[calc(100vh-18.75rem)] overflow-y-auto">
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Header>Value</Table.Header>
                        <Table.Header>Long Value</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {expandedField?.Lookups?.map((lu) => (
                        <Table.Row key={lu.value}>
                          <Table.Data>{lu.value}</Table.Data>
                          <Table.Data>{lu.long_value}</Table.Data>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        </Modal>
      </div>
    </div>
  )
}
