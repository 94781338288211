import { useDeleteApiUserMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation deleteApiUser($id: ID!) {
    deleteApiUser(id: $id)
  }
`

export function useDeleteApiUser() {
  const queryClient = useQueryClient()
  return useDeleteApiUserMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['ApiUsers'])
    }
  })
}
