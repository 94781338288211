import { Tooltip, Map } from '@forge/common'
import { LocationMarkerIcon } from '@heroicons/react/solid'
import { useParams } from 'react-router-dom'
import { useListingService } from './useListingService'

export function ListingServiceMap() {
  const { id } = useParams()
  const { data: { listingService } = {} } = useListingService({ id })

  return (
    <div className="relative min-h-[20rem] w-full overflow-hidden rounded-lg">
      <Map
        viewportState={{
          latitude: listingService?.lat ?? 0,
          longitude: listingService?.lon ?? 0,
          pitch: 50,
          zoom: 11
        }}>
        <Map.Marker
          latitude={listingService?.lat ?? 0}
          longitude={listingService?.lon ?? 0}>
          <div className="h-8 w-7 text-orange-400">
            <Tooltip
              delay={700}
              placement="right"
              content={`${listingService?.key?.toUpperCase()}: ${
                listingService?.name
              }`}>
              <LocationMarkerIcon />
            </Tooltip>
          </div>
        </Map.Marker>
        <Map.Zoom />
      </Map>
    </div>
  )
}
