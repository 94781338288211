import { Combobox, ComboboxOption, FormField } from '@forge/common'
import { useResourceGroupFieldsSearchValues } from '@forge/features/resourcegroups'
import {
  FilterQueryType,
  ResourceGroupFieldPartsFragment
} from '@forge/graphql/generated'
import { TrashIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  searchFields: yup.array(yup.string()),
  fieldSearchValues: yup.array(yup.string())
})

interface MoreCriteriaProps {
  handleFilter: (field: FilterQueryType) => void
}

export function MoreCriteria({ handleFilter }: MoreCriteriaProps) {
  const params = useParams()
  const resourceGroupId = String(params.resourceGroupId)

  const [selectedSearchFields, setSelectedSearchFields] = useState<
    ResourceGroupFieldPartsFragment[]
  >([])

  const { data: { resourceGroupFieldsSearchValues } = {} } =
    useResourceGroupFieldsSearchValues({
      resourceGroupId,
      includeSearchValues: false
    })

  const { watch } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })

  const { fields } = resourceGroupFieldsSearchValues || {}

  const searchFieldsOptions =
    (fields?.map((field) => ({
      label: field.searchDisplay,
      value: field.id
    })) as ComboboxOption[]) || []

  const findField = (id: string) =>
    fields?.filter((field) => field.id === id)[0]

  return (
    <div className="grid grid-cols-3 gap-3 pt-4 sm:grid-cols-3 2xl:grid-cols-4">
      <div className="min-w-fit md:mr-2">
        <Combobox
          placeholder="Choose Field"
          multiple
          selected={searchFieldsOptions.filter((option) =>
            watch('searchFields')?.includes(option.value)
          )}
          options={searchFieldsOptions}
          onSelect={(options) => {
            setSelectedSearchFields([
              ...(selectedSearchFields as any),
              findField(String(options.map((option) => option.value)))
            ])
          }}
        />
      </div>

      {selectedSearchFields.map((field) => (
        <SelectedSearchField
          key={field.id}
          field={field}
          onSelect={(field, options) =>
            handleFilter({
              field: `${field.colName}`,
              eq: options.map((option) => option.value)
            })
          }
          onDelete={(field) => {
            setSelectedSearchFields(
              selectedSearchFields.filter(
                (selectedField) => selectedField.id !== field.id
              )
            )
            handleFilter({
              field: field.colName || '',
              eq: null
            })
          }}
        />
      ))}
    </div>
  )
}

interface SelectedSearchFieldProps {
  field: ResourceGroupFieldPartsFragment
  onSelect: (
    field: ResourceGroupFieldPartsFragment,
    options: ComboboxOption[]
  ) => void
  onDelete: (field: ResourceGroupFieldPartsFragment) => void
}

function SelectedSearchField({
  field,
  onSelect,
  onDelete
}: SelectedSearchFieldProps) {
  const params = useParams()
  const resourceGroupId = String(params.resourceGroupId)

  const { data: { resourceGroupFieldsSearchValues } = {} } =
    useResourceGroupFieldsSearchValues({
      resourceGroupId,
      includeSearchValues: !!field.colName,
      fields: field.colName ? [field.colName] : undefined
    })

  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const [options, setOptions] = useState<ComboboxOption[]>(
    resourceGroupFieldsSearchValues?.fields?.[0].searchValues?.map(
      (option) => ({
        label: option,
        value: option
      })
    ) || []
  )

  return (
    <div className="min-w-full">
      <div className="flex items-center space-x-3">
        <div className="flex-1">
          <FormField>
            <Combobox
              placeholder={field.searchDisplay || ''}
              multiple
              selected={options.filter((option) => {
                return selectedValues.includes(option.value)
              })}
              options={options}
              onSelect={(options) => {
                onSelect(field, options)
                setSelectedValues(options.map((option) => option.value))
              }}
              onCreateOption={(newOption) => {
                setOptions((options) => [...options, newOption])
              }}
            />
          </FormField>
        </div>
        <div className="float-right hover:cursor-pointer hover:text-red-500">
          <button
            aria-label={`Trash ${field.searchDisplay} Field`}
            onClick={() => {
              onDelete(field)
            }}>
            <TrashIcon aria-hidden="true" className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  )
}
