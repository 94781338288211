import {
  ResourceSampleQueryVariables,
  useResourceSampleQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query ResourceSample($id: ID!) {
    resource(id: $id) {
      id
      name
      sourceType: source_type
      sourceClass: source_class
      sampleData: sample_data {
        raw
        serialized
        translated
        normalized
        serializedDraft: serialized_draft
        translatedDraft: translated_draft
        normalizedDraft: normalized_draft
      }
    }
  }
`

export function useResourceSample(vars: ResourceSampleQueryVariables) {
  return useResourceSampleQuery(vars, {
    enabled: !!vars.id
  })
}
