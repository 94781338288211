import {
  ResourceGroupFieldsSearchValuesQueryVariables,
  useResourceGroupFieldsSearchValuesQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  fragment ResourceGroupFieldsSearchValuesParts on Field {
    id
    aliases
    colName: col_name
    searchDisplay: search_display
    searchValues: search_values @include(if: $includeSearchValues)
  }

  query ResourceGroupFieldsSearchValues(
    $resourceGroupId: ID!
    $includeSearchValues: Boolean!
    $fields: [String!]
  ) {
    resourceGroupFieldsSearchValues: resource_group(id: $resourceGroupId) {
      id
      fields(limit: 10000, filter: { field: "col_name", in: $fields }) {
        ...ResourceGroupFieldsSearchValuesParts
      }
    }
  }
`

export function useResourceGroupFieldsSearchValues(
  vars: ResourceGroupFieldsSearchValuesQueryVariables
) {
  return useResourceGroupFieldsSearchValuesQuery(vars)
}
