import { Avatar, classNames, Menu } from '@forge/common'
import { useCurrentUser, useLogout } from '@forge/features/auth'
import { ArrowLeftLongIcon } from '@forge/icons'
import { Link } from 'react-router-dom'

interface FullScreenHeaderProps {
  title?: string
  onBackClick?: () => void
}

export function FullScreenHeader({
  onBackClick,
  title
}: FullScreenHeaderProps) {
  const { data: currentUser } = useCurrentUser()
  const logout = useLogout()

  return (
    <nav className="sticky top-0 z-30 flex h-[3.75rem] shrink-0 items-center justify-between gap-2 bg-white px-6 shadow sm:gap-4">
      <div className="w-1/2 sm:w-1/3">
        <button
          disabled={!onBackClick}
          onClick={onBackClick}
          className={classNames(
            'flex items-center space-x-4 text-sm text-orange-600',
            {
              'cursor-pointer': !!onBackClick
            }
          )}>
          {onBackClick && <ArrowLeftLongIcon className="w-5 h-5 shrink-0" />}
          {onBackClick && <span>Back</span>}
        </button>
      </div>
      <div className="items-center justify-center hidden w-1/3 sm:flex">
        <div
          className="text-xl font-semibold text-center text-gray-900 capitalize truncate"
          title={title}>
          {title}
        </div>
      </div>
      <div className="flex items-center justify-end w-1/2 gap-3 sm:w-1/3">
        <Menu
          button={
            <>
              <span className="sr-only">Open user menu</span>
              <Avatar
                src={currentUser?.avatar || undefined}
                name={currentUser?.name || undefined}
                size="sm"
              />
            </>
          }>
          <>
            <Menu.Item title="Dashboard" as={Link} to="/">
              Dashboard
            </Menu.Item>
            <Menu.Item title="Data Adapters" as={Link} to="/adapters">
              Data Adapters
            </Menu.Item>
            <Menu.Item
              title="Listing Services"
              as={Link}
              to="/listing_services">
              Listing Services
            </Menu.Item>
            {currentUser?.role === 'super' && (
              <>
                <Menu.Item title="Admin Users" as={Link} to="/admins">
                  Admin Users
                </Menu.Item>
                <Menu.Item title="API Users" as={Link} to="/api_users">
                  API Users
                </Menu.Item>
                <Menu.Item
                  title="Field Templates"
                  as={Link}
                  to="/field_templates">
                  Field Templates
                </Menu.Item>
              </>
            )}
            <div className="my-1.5 border-t border-slate-200"></div>
          </>

          <Menu.Item title="Sign Out" onClick={logout}>
            Sign Out
          </Menu.Item>
        </Menu>
      </div>
    </nav>
  )
}
