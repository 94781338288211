import {
  ListingServicesQueryVariables,
  useListingServicesQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  fragment ListingServicesListingService on ListingService {
    id
    key
    name
    lat
    lon
    address
    state
    activations(limit: 10000) {
      id
      apiUser: api_user {
        id
        name
      }
    }
  }

  query ListingServices {
    listingServices: listing_services(limit: 1000) {
      ...ListingServicesListingService
    }
  }
`

export function useListingServices(vars?: ListingServicesQueryVariables) {
  return useListingServicesQuery(vars, { keepPreviousData: true })
}
