import { useDeleteAlarmProfileMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation DeleteAlarmProfile($id: ID!) {
    deleteAlarmProfile(id: $id)
  }
`

export function useDeleteAlarmProfile() {
  const queryClient = useQueryClient()
  return useDeleteAlarmProfileMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['AlarmProfiles'])
    }
  })
}
