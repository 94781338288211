import classNames from 'classnames'
import { ForwardedRef, forwardRef } from 'react'

interface SelectProps
  extends Omit<JSX.IntrinsicElements['select'], 'className'> {
  variant?: 'primary' | 'danger'
}

export const Select = forwardRef(function Select(
  { variant = 'primary', disabled, ...props }: SelectProps,
  ref: ForwardedRef<HTMLSelectElement>
) {
  return (
    <div className="relative rounded-md bg-white">
      <select
        ref={ref}
        {...props}
        disabled={disabled}
        className={classNames(
          'relative w-full appearance-none rounded-md border bg-transparent p-2 pr-6 shadow-sm focus:outline-none focus:ring-0 sm:text-sm',
          {
            'border-gray-300 ': variant === 'primary',
            'focus:border-orange-400': variant === 'primary' && !disabled,

            'border-red-300': variant === 'danger',
            'focus:border-red-500': variant === 'danger' && !disabled,

            'cursor-pointer': !disabled,
            'cursor-not-allowed bg-gray-50': disabled
          }
        )}
      />
    </div>
  )
})
