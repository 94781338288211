import {
  AdapterStatsQueryVariables,
  useAdapterStatsQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

export const statsFragment = gql`
  fragment statFields on Stat {
    id
    job
    totalRecords: total_records
    createdAt: created_at
    createdAtIso: created_at_iso
    updatedAt: updated_at
    updatedAtIso: updated_at_iso
    status
    tookHuman: took_human
    jsonData: json_data
    humanCreatedAgo: human_created_ago
    accuracyRate: accuracy_rate
    resolvedRate: resolved_rate
    progress
    logLine: log_line
    statable {
      ... on Stat {
        __typename
        id
      }
      ... on Adapter {
        __typename
        id
        key
      }
      ... on ResourceGroup {
        __typename
        id
        adapter {
          id
          key
        }
      }
      ... on Resource {
        __typename
        id
        name
        resourceGroup: resource_group {
          id
          adapter {
            id
            key
          }
        }
      }
    }
    stats(limit: 5000, sort: { field: "updated_at", dir: desc }) {
      id
      job
      updatedAtIso: updated_at_iso
      status
      logLine: log_line
      tookHuman: took_human
      totalRecords: total_records
    }
  }
`

gql`
  query AdapterStats($id: ID!, $limit: Int, $page: Int) {
    adapterStats: adapter(id: $id) {
      id
      currentJob: current_job
      currentInProgress: current_in_progress {
        ...statFields
      }
      aggregatedStats: aggregated_stats(page: $page, limit: $limit) {
        ...statFields
      }
    }
  }
  ${statsFragment}
`

export function useAdapterStats(variables: AdapterStatsQueryVariables) {
  return useAdapterStatsQuery(variables, {
    enabled: !!variables.id
  })
}
