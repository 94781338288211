import { useCreateLookupsFromAggsMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation CreateLookupsFromAggs($fieldId: ID!) {
    createLookupsFromAggs(field_id: $fieldId) {
      id
      lookups {
        id
        order
        value
        mappedValue: mapped_value
        sourceId: source_id
      }
    }
  }
`

export function useCreateLookupsFromAggs() {
  const queryClient = useQueryClient()
  return useCreateLookupsFromAggsMutation({
    async onSuccess(_, { fieldId }) {
      await queryClient.invalidateQueries(['Field', { id: String(fieldId) }])
    }
  })
}
