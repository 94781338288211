import { Breadcrumbs, Tooltip, Wrapper } from '@forge/common'
import {
  ListingServiceEditForm,
  useListingService
} from '@forge/features/listingservices'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export function ListingServiceEdit() {
  const { id } = useParams()
  const { data: { listingService } = {} } = useListingService({ id })
  return (
    <>
      <Helmet>
        <title>{listingService?.key?.toUpperCase()} - Edit</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/listing_services">
            Listing Services
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/listing_services/${listingService?.id}/details`}>
            {listingService?.name}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            to={`/listing_services/${listingService?.id}/edit`}>
            Edit
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            Edit {listingService?.key?.toUpperCase()} Listing Service
            <div className="px-2 py-2">
              <Tooltip
                delay={700}
                placement="right"
                content="Edit Listing Service">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>
        <div className="mx-auto max-w-lg justify-center">
          <ListingServiceEditForm />
        </div>
      </Wrapper>
    </>
  )
}
