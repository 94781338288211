import { useUpdateAdminMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation updateAdmin(
    $id: ID!
    $name: String
    $email: String
    $phone: String
    $title: String
    $resetToken: String
    $timezone: String
    $role: AdminRole
    $alerts: Boolean
    $password: String
    $passwordConfirmation: String
  ) {
    updateAdmin(
      id: $id
      name: $name
      email: $email
      phone: $phone
      title: $title
      reset_token: $resetToken
      timezone: $timezone
      role: $role
      alerts: $alerts
      password: $password
      password_confirmation: $passwordConfirmation
    ) {
      id
    }
  }
`

export function useUpdateAdmin() {
  const queryClient = useQueryClient()
  return useUpdateAdminMutation({
    async onSuccess({ updateAdmin }) {
      await queryClient.invalidateQueries([
        'AdminUser',
        { id: updateAdmin?.id }
      ])
      await queryClient.invalidateQueries(['Admins'])
    }
  })
}
