import { Checkbox, Table, Tabs } from '@forge/common'
import { useApiUsers } from '@forge/features/apiusers'
import { getApp, hasApp } from '@forge/features/listingservices'
import { useParams } from 'react-router-dom'
import { useActivateListingServiceApiUser } from './useActivateListingServiceApiUser'
import { useDeactivateListingServiceApiUser } from './useDeactivateListingServiceApiUser'
import { useListingService } from './useListingService'

export function ListingServiceApiUsers() {
  const { id } = useParams()
  const { data: { listingService } = {} } = useListingService({ id })

  const { data: { apiUsers } = {} } = useApiUsers()
  const { mutate: createActivation } = useActivateListingServiceApiUser()
  const { mutate: deleteActivation } = useDeactivateListingServiceApiUser()

  return (
    <div>
      <Tabs>
        <Tabs.List>
          <Tabs.Tab>API Users</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panels>
          <Tabs.Panel>
            <div className="rounded-lg bg-white shadow">
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header>Application</Table.Header>
                    <Table.Header align="center">Activated</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {apiUsers
                    ?.sort((a, b) => Number(a.id) - Number(b.id))
                    .map((user) => (
                      <Table.Row key={user.id}>
                        <Table.Data>{user.name}</Table.Data>
                        <Table.Data align="center">
                          <Checkbox
                            size="lg"
                            onChange={() => {
                              const activation = getApp(
                                listingService?.activations,
                                user.name
                              )
                              const activationId = activation?.id
                              const listingServiceId = listingService?.id
                              if (activationId) {
                                deleteActivation({ id: activationId })
                              } else {
                                if (listingServiceId) {
                                  createActivation({
                                    apiUserId: Number(user.id),
                                    listingServiceId: Number(listingServiceId)
                                  })
                                }
                              }
                            }}
                            checked={hasApp(
                              listingService?.activations,
                              user.name
                            )}
                          />
                        </Table.Data>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </div>
  )
}
