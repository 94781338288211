import {
  DashboardResourceGroupsQueryVariables,
  useDashboardResourceGroupsQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query DashboardResourceGroups($status: ResourceGroupStatus) {
    resourceGroups: resource_groups(status: $status, limit: 1000) {
      id
      role
      status
      loadingProgress: loading_progress
      adapter {
        id
        key
      }
    }
  }
`

export function useDashboardResourceGroups(
  vars: DashboardResourceGroupsQueryVariables
) {
  return useDashboardResourceGroupsQuery(vars)
}
