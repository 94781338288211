import { ArrowSmRightIcon } from '@heroicons/react/solid'
import { isEqual } from 'lodash'

type ValidValue = string | number | boolean | object | null | undefined

interface DiffProps {
  value: ValidValue
  newValue: ValidValue
}

export function Diff({ value, newValue }: DiffProps) {
  if (!newValue || isEqual(value, newValue)) return <>{value}</>

  return (
    <div className="flex">
      <div className="p-1 bg-red-200 rounded">
        <>{value}</>
      </div>
      <ArrowSmRightIcon className="w-5 h-5 mx-2 my-auto shrink-0" />
      <div className="p-1 bg-green-200 rounded">
        <>{newValue}</>
      </div>
    </div>
  )
}
