import { Menu } from '@forge/common'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CreateTransform } from './CreateTransform'

interface TransformsMenuProps {
  field: FieldPartsFragment
}

export function TransformsMenu({ field }: TransformsMenuProps) {
  const [showCreate, setShowCreate] = useState(false)

  return (
    <div>
      <Menu
        button={
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100">
            <DotsHorizontalIcon
              className="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </span>
        }>
        <Menu.Item onClick={() => setShowCreate(true)}>Add Transform</Menu.Item>
      </Menu>
      <CreateTransform
        fieldId={field.id}
        isOpen={showCreate}
        onClose={() => setShowCreate(false)}
      />
    </div>
  )
}
