import { Spinner } from '@forge/common'
import { Suspense, useEffect, useRef } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useCurrentUser } from '../auth'

export function SearchLayout() {
  const { data: currentUser } = useCurrentUser()
  const location = useLocation()
  const mainRef = useRef<HTMLElement>(null)

  useEffect(() => {
    mainRef.current?.scrollTo(0, 0)
  }, [location.pathname])

  if (!currentUser) {
    const params = new URLSearchParams(location.search)
    params.delete('token')

    return (
      <Navigate
        to="/login"
        state={{ from: { ...location, search: `?${params.toString()}` } }}
      />
    )
  }
  return (
    <main ref={mainRef}>
      <Suspense
        fallback={
          <div className="flex w-full p-5">
            <div className="mx-auto h-5 w-5 text-orange-400 ">
              <Spinner />
            </div>
          </div>
        }>
        <Outlet />
      </Suspense>
    </main>
  )
}
