import {
  AdaptersQueryVariables,
  useAdaptersQuery
} from '@forge/graphql/generated'
import { gql } from 'graphql-request'

gql`
  query Adapters($limit: Int, $page: Int, $status: AdapterStatus) {
    adapters(limit: $limit, page: $page, status: $status) {
      id
      key
      status
      adapterType: adapter_type
      currentJob: current_job
    }
  }
`

export function useAdapters(vars?: AdaptersQueryVariables) {
  return useAdaptersQuery(vars, { keepPreviousData: true })
}
