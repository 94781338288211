import { useDeleteResourceMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation deleteResource($id: ID!) {
    deleteResource(id: $id)
  }
`

export function useDeleteResource() {
  const queryClient = useQueryClient()
  return useDeleteResourceMutation({
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries(['Adapter']),
        queryClient.invalidateQueries(['AdapterResourceGroups']),
        queryClient.invalidateQueries(['ResourceGroup'])
      ])
    }
  })
}
