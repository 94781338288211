import { useParams } from 'react-router-dom'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { useResource } from './useResource'

export function ResourceRecentAudits() {
  const { id: resourceId } = useParams()
  const { data: { resource } = {} } = useResource({ id: resourceId || '' })

  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-xl">{resource?.name || ''} Recent Audits</h2>
      <div className="rounded-lg bg-white pt-6 pb-4 pr-4 shadow">
        <ResponsiveContainer height={300}>
          <ComposedChart
            data={resource?.recentAudits || []}
            // fontSize="11px"
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <Legend />
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <Tooltip />
            <XAxis dataKey="createdAt" />
            <YAxis type="number" padding={{ top: 30 }} />
            <Area
              name="Accuracy"
              dot={false}
              type="monotone"
              dataKey="accuracyRate"
              stroke="#c026d3"
              fill="#e879f9"
              strokeWidth="2"
              isAnimationActive={true}
            />
            <Line
              name="Resolved"
              dot={false}
              type="monotone"
              dataKey="resolvedRate"
              stroke="#9333ea"
              strokeWidth="2"
              isAnimationActive={true}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
