import { useDeleteListingServiceMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation deleteListingService($id: ID!) {
    deleteListingService(id: $id)
  }
`

export function useDeleteListingService() {
  const queryClient = useQueryClient()

  return useDeleteListingServiceMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['ListingServices'])
    }
  })
}
