import { Link, Outlet } from 'react-router-dom'

export function AuthLayout() {
  return (
    <div className="flex min-h-screen flex-grow flex-col items-center justify-center">
      <div className="flex w-full flex-col items-center justify-between space-y-4 p-6 sm:flex-row sm:space-y-0">
        <Link
          // href={process.env.VITE_APP_CAS_URL}
          to="/"
          target="_blank"
          rel="noreferrer"
          className="text-suite-900 flex items-center space-x-2 text-xl font-medium">
          {/* <ChevronLeftIcon className="flex-shrink-0 w-6 mr-2" /> */}
          {/* <CloudIcon className="flex-shrink-0 w-8" /> */}
          {/* <span>Cloud Agent Suite</span> */}
        </Link>
        <Link
          // href={`${process.env.VITE_APP_CAS_URL}/tools/cma`}
          to="/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1 text-sm font-medium text-green-500">
          {/* <span>Learn more about Cloud CMA</span> */}
          {/* <ArrowRightLongIcon className="w-5 h-5" /> */}
        </Link>
      </div>
      <div className="flex flex-grow p-6 xs:w-fit xs:max-w-sm md:max-w-md lg:w-full">
        <div className="m-auto space-y-8">
          <div className="flex">
            {/* <CloudCmaVerticalIcon className="w-24 mx-auto text-green-500"></CloudCmaVerticalIcon> */}
          </div>
          <Outlet />
          {/* <nav className="w-full p-6">
            <ul className="flex justify-center space-x-6 text-xs text-gray-600">
              <li>
                <Link
                  // href={`${process.env.VITE_APP_CAS_URL}/tos`}
                  to="/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  // href={`${process.env.VITE_APP_CAS_URL}/privacy`}
                  to="/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline">
                  Privacy
                </Link>
              </li>
              <li>
                <Link
                  // href={`${process.env.VITE_APP_CAS_URL}/dmca`}
                  to="/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline">
                  DMCA
                </Link>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </div>
  )
}
