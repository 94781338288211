import { Breadcrumbs, Spinner, Tooltip, Wrapper } from '@forge/common'
import { ApiUserEditForm, useApiUser } from '@forge/features/apiusers'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useNavigate } from 'react-router-dom'

export function ApiUserEdit() {
  const { apiUserId = '123' } = useParams()
  const navigate = useNavigate()

  const { data: { apiUser } = {} } = useApiUser(apiUserId)

  return (
    <>
      <Helmet>
        <title>{apiUser?.name} - Edit</title>
      </Helmet>

      <Wrapper>
        <Breadcrumbs>
          <Breadcrumbs.Crumb to="/api_users">API Users</Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/api_user/${apiUserId}/details`}>
            {apiUser?.name}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb to={`/api_user/${apiUserId}/edit`}>
            Edit
          </Breadcrumbs.Crumb>
        </Breadcrumbs>

        <div className="flex w-full">
          <h1 className="flex text-4xl font-medium">
            Edit {apiUser?.name} API User
            <div className="px-2 py-2">
              <Tooltip delay={700} placement="right" content="Edit API User">
                <InformationCircleIcon className="h-5" />
              </Tooltip>
            </div>
          </h1>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center p-4">
              <div className="h-5 w-5 text-orange-400">
                <Spinner />
              </div>
            </div>
          }>
          <div className="mx-auto max-w-lg justify-center">
            <ApiUserEditForm
              apiUser={apiUser}
              onSubmit={() => navigate(`/api_user/${apiUserId}/details`)}
            />
          </div>
        </Suspense>
      </Wrapper>
    </>
  )
}
