import {
  ListingServiceQuery,
  useActivateListingServiceApiUserMutation
} from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useApiUsers } from '../apiusers'

gql`
  mutation ActivateListingServiceApiUser(
    $listingServiceId: Int
    $apiUserId: Int
  ) {
    createActivation(
      listing_service_id: $listingServiceId
      api_user_id: $apiUserId
    ) {
      id
      createdAt: created_at
      apiUser: api_user {
        id
        name
      }
    }
  }
`

export function useActivateListingServiceApiUser() {
  const queryClient = useQueryClient()
  const { data: { apiUsers } = {} } = useApiUsers()

  return useActivateListingServiceApiUserMutation({
    onMutate: async ({ listingServiceId, apiUserId }) => {
      await queryClient.cancelQueries([
        'ListingService',
        { id: String(listingServiceId) }
      ])

      const previousListingService: ListingServiceQuery | undefined =
        queryClient.getQueryData<ListingServiceQuery>([
          'ListingService',
          { id: String(listingServiceId) }
        ])

      if (previousListingService?.listingService) {
        const user = apiUsers?.find((user) => user.id === String(apiUserId))

        queryClient.setQueryData<ListingServiceQuery>(
          ['ListingService', { id: String(listingServiceId) }],
          {
            ...previousListingService,
            listingService: {
              ...previousListingService.listingService,
              activations: [
                ...(previousListingService.listingService.activations || []),
                {
                  id: Math.random().toString(16),
                  apiUser: {
                    id: user?.id || 'NO_ID',
                    name: user?.name || 'NO_NAME'
                  }
                }
              ]
            }
          }
        )
      }
      return { previousListingService }
    },
    onError: (_, { listingServiceId }, context) => {
      queryClient.setQueryData(
        ['ListingService', { id: String(listingServiceId) }],
        context?.previousListingService
      )
    },
    onSettled() {
      queryClient.invalidateQueries(['ListingService'])
    }
  })
}
