import {
  Attribute,
  classNames,
  formatCurrency,
  formatCurrencyShort
} from '@forge/common'
import { useResourceGroupSearchFields } from '@forge/features/resourcegroups'
import { FieldPartsFragment } from '@forge/graphql/generated'
import { ListingBadge } from './ListingBadge'

interface ListingSampleProps {
  adapterId: string
  isOpen?: boolean
  onClick?: () => void
  resourceGroupId: string
  variant?: 'primary' | 'preview'
  id?: string | null
  address?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  photos?: string[] | null
  price?: number | null
  status?: string | null
  mappedStatus?: string | null
}

export function ListingSample({
  adapterId,
  isOpen,
  onClick,
  resourceGroupId,
  address,
  city,
  state,
  zip,
  photos,
  price,
  status,
  mappedStatus,
  id
}: ListingSampleProps) {
  const { data: resourceGroupSearchFields = {} } = useResourceGroupSearchFields(
    {
      resourceGroupId
    }
  )

  const searchFields = resourceGroupSearchFields?.resourceGroup?.fields?.sort(
    (a, b) => (a?.displayName || '').localeCompare(b?.displayName || '')
  )

  const fields =
    searchFields?.reduce<Record<string, FieldPartsFragment>>(
      (fields, field) => {
        const key = field.aliases?.[0] || field.colName
        if (key) return { ...fields, [key]: field }
        return fields
      },
      {}
    ) || {}
  const [firstPhoto] = photos || []
  const cityStateZip = [city, [state, zip].filter(Boolean).join(' ')]
    .filter(Boolean)
    .join(', ')

  return (
    <div>
      <div
        className={classNames('rounded-md bg-white', {
          'shadow-raised ring-2 ring-orange-500': isOpen,
          'shadow-sticky': !isOpen
        })}>
        <div
          role="button"
          tabIndex={0}
          className="flex items-center p-1 space-x-2"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onClick?.()
            }
          }}
          onClick={onClick}>
          <div className="h-[3.375rem] w-[4.5rem] overflow-hidden rounded-md bg-gray-100">
            {firstPhoto ? (
              <img
                src={firstPhoto}
                alt={`${address} thumbnail`}
                className="object-fill w-full h-full"
              />
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['photos']?.id}
                  label="photos"
                  resourceGroupId={resourceGroupId}
                  value={''}
                />
              </div>
            )}
          </div>
          <div className="grow space-y-0.5">
            {!id && (
              <div className="hover:cursor-pointer">
                Configure{' '}
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['id']?.id}
                  label="id"
                  resourceGroupId={resourceGroupId}
                  value={id}
                />{' '}
                to load a sample record.
              </div>
            )}
            {address !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={address || ''}>
                {address}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['address']?.id}
                  label="address"
                  resourceGroupId={resourceGroupId}
                  value={address}
                />
              </div>
            )}

            <div
              className="text-xs text-gray-600 truncate"
              title={cityStateZip}>
              {cityStateZip}
            </div>
          </div>
          <div className="space-y-0.5 pr-2 text-right">
            {price !== null ? (
              <div
                className="text-sm font-semibold text-gray-900 truncate"
                title={formatCurrency(price || 0)}>
                {formatCurrencyShort(price || 0)}
              </div>
            ) : (
              <div className="hover:cursor-pointer">
                <Attribute
                  adapterId={adapterId}
                  fieldId={fields?.['price']?.id}
                  label="price"
                  resourceGroupId={resourceGroupId}
                  value={price}
                />
              </div>
            )}
            <div className="flex">
              <ListingBadge mappedStatus={mappedStatus} status={status} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
