import { Input, Select, Table, Spinner } from '@forge/common'
import { DefaultCriteriaFieldsFragment } from '@forge/graphql/generated'
import { PlusIcon, TrashIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Suspense, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { DeleteCriteria } from './DeleteCriteria'
import { useCreateCriteria } from './useCreateCriteria'

const schema = yup.object({
  fieldName: yup.string(),
  value: yup.string(),
  scope: yup.string(),
  casCriterium: yup.boolean(),
  parentId: yup.number().required(),
  parentType: yup.string().required()
})

interface DefaultCriteriaProps {
  parentId: number
  parentType: string
  defaultCriteria?: DefaultCriteriaFieldsFragment[] | null
  casInvites?: boolean
  showScope?: boolean
}

export function DefaultCriteria({
  parentId,
  parentType,
  casInvites,
  defaultCriteria,
  showScope = true
}: DefaultCriteriaProps) {
  const { register, handleSubmit, reset } = useForm<
    yup.InferType<typeof schema>
  >({
    defaultValues: {
      fieldName: '',
      value: '',
      scope: '',
      casCriterium: casInvites || false,
      parentId: parentId,
      parentType: parentType
    },
    resolver: yupResolver(schema)
  })

  const { mutate: createCriteria, isLoading: isCreating } = useCreateCriteria()

  return (
    <div className="bg-white">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Field Name</Table.Header>
            <Table.Header align="left">Value</Table.Header>
            {showScope && <Table.Header>Scope</Table.Header>}
            <Table.Header align="center">Action</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {defaultCriteria?.map((crit) => (
            <DefaultCriteriaRow
              key={crit.id}
              criteria={crit}
              showScope={showScope}
            />
          ))}
          <Table.Row>
            <Table.Data>
              <Input {...register('fieldName')} placeholder="New Key" />
            </Table.Data>
            <Table.Data>
              <Input {...register('value')} placeholder="New Value" />
            </Table.Data>
            {showScope && (
              <Table.Data>
                <Select {...register('scope')}>
                  <option value="">Select a scope</option>
                  <option value="agent">Agent</option>
                  <option value="listing">Listing</option>
                  <option value="office">Office</option>
                </Select>
              </Table.Data>
            )}
            <Table.Data>
              <span className="flex justify-center">
                <button
                  className="mx-auto flex cursor-pointer items-center justify-center hover:text-orange-400"
                  onClick={handleSubmit((data) => {
                    if (defaultCriteria) {
                      createCriteria(
                        { ...data },
                        {
                          onSuccess() {
                            reset()
                          }
                        }
                      )
                    }
                  })}>
                  {!isCreating ? (
                    <PlusIcon className="h-5 w-5 hover:cursor-pointer" />
                  ) : (
                    <span className="block h-5 w-5 text-gray-500" aria-hidden>
                      <Spinner />
                    </span>
                  )}
                </button>
              </span>
            </Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

interface DefaultCriteriaRowProps {
  showScope?: boolean
  criteria: DefaultCriteriaFieldsFragment
}

function DefaultCriteriaRow({ criteria, showScope }: DefaultCriteriaRowProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Table.Row>
      <Table.Data>{criteria.fieldName}</Table.Data>
      <Table.Data>{criteria.value}</Table.Data>
      {showScope && <Table.Data>{criteria.scope}</Table.Data>}
      <Table.Data align="center" width="45">
        <button
          className="mx-auto flex cursor-pointer items-center justify-center hover:text-red-500"
          onClick={() => setIsOpen(true)}>
          <TrashIcon className="mx-auto h-5 w-5" />
        </button>
        <DeleteCriteria
          criteria={criteria}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      </Table.Data>
    </Table.Row>
  )
}

export function DefaultCriteriaTable({
  parentId,
  parentType,
  casInvites,
  defaultCriteria,
  showScope = true
}: DefaultCriteriaProps) {
  return (
    <Suspense
      fallback={
        <div className="bg-white">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header align="left">Field Name</Table.Header>
                <Table.Header align="left">Value</Table.Header>
                {showScope && <Table.Header>Scope</Table.Header>}
                <Table.Header align="center">Action</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Data>
                  <div className="flex items-center gap-2">
                    <div className="h-5 w-5 text-orange-500">
                      <Spinner />
                    </div>
                    <div>Loading Criteria</div>
                  </div>
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      }>
      <DefaultCriteria
        showScope={showScope}
        parentId={parentId}
        parentType={parentType}
        casInvites={casInvites}
        defaultCriteria={defaultCriteria}
      />
    </Suspense>
  )
}
