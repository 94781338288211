import { Button, Checkbox, FormField, Modal, Select } from '@forge/common'
import { useImportMetadata } from '@forge/features/metadata'
import { ResourceGroupRole } from '@forge/graphql/generated'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useAdapter, useAdapterMetadata } from '../adapters'
import { ResourceTabs } from './ResourceTabs'
import { SystemInfo } from './SystemInfo'

const schema = yup.object({
  adapterId: yup.string().required(),
  resourceName: yup.string().required(),
  classNames: yup.array(yup.string().required()).required(),
  role: yup
    .mixed<ResourceGroupRole>()
    .oneOf(Object.values(ResourceGroupRole))
    .required()
})

interface MetadataProps {
  adapterId: string
}

export function Metadata({ adapterId }: MetadataProps) {
  const navigate = useNavigate()
  const [showImporter, setShowImporter] = useState(false)

  const { data: { adapter } = {} } = useAdapter({ id: adapterId })

  const { data: { adapter: adapterMetaData } = {} } = useAdapterMetadata({
    id: adapter?.id
  })

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: { adapterId: adapter?.id, classNames: [] }
  })

  const {
    mutate: importMetadata,
    isLoading: isImporting,
    isSuccess,
    error
  } = useImportMetadata()

  const metadata = adapterMetaData?.metadata

  if (!metadata) return null

  if (!metadata.systemInfo?.connected && metadata.logs)
    return (
      <p
        className="max-w-fit text-red-500"
        dangerouslySetInnerHTML={{ __html: metadata.logs }}
      />
    )

  const selectedResource =
    metadata.resources?.find(
      (resource) => watch('resourceName') === resource.name
    ) || null

  return (
    <div className="flex flex-col">
      <SystemInfo systemInfo={metadata.systemInfo} />
      <div className="flex gap-4 py-6">
        <div className="w-1/2">
          <FormField label="Resource">
            <Select {...register('resourceName')}>
              <option value="">Choose Resource</option>
              {metadata.resources?.map((resource, index) => (
                <Fragment key={index}>
                  {resource.name && (
                    <option key={index} value={resource.name}>
                      {resource.name}
                    </option>
                  )}
                </Fragment>
              ))}
            </Select>
          </FormField>
        </div>
        {selectedResource && (
          <div className="w-1/2 self-end">
            <Button onClick={() => setShowImporter(true)}>
              Import Resources
            </Button>
            <Modal
              isOpen={showImporter}
              onClose={() => {
                reset()
                setShowImporter(false)
              }}>
              <Modal.Title>Import Metadata</Modal.Title>
              <form
                className="mt-4 space-y-3"
                onSubmit={handleSubmit((data) => {
                  importMetadata(data, {
                    onSuccess({ importMetadata }) {
                      navigate(
                        `/adapters/${importMetadata?.adapterId}/resource_groups/${importMetadata?.id}/settings`
                      )
                    }
                  })
                })}>
                <FormField label="Role" error={errors.role?.message}>
                  <Select placeholder="Select a role" {...register('role')}>
                    <option value="">Select a role</option>
                    {Object.values(ResourceGroupRole).map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </Select>
                </FormField>
                {selectedResource.classes?.length && (
                  <>
                    <div className="text-sm font-medium text-gray-700">
                      Choose which classes should be included
                    </div>
                    <div className="flex flex-wrap gap-4">
                      {selectedResource.classes.map(({ name }) => (
                        <Fragment key={name}>
                          {name && (
                            <Checkbox
                              size="md"
                              onChange={(evt) => {
                                const classNames = getValues('classNames')
                                setValue(
                                  'classNames',
                                  evt.target.checked
                                    ? [...classNames, name]
                                    : classNames.filter((c) => c !== name)
                                )
                              }}>
                              {name}
                            </Checkbox>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </>
                )}
                <div className="pt-1">
                  <Button
                    size="sm"
                    loading={isImporting}
                    variant={!error ? 'primary' : 'danger'}
                    leftIcon={
                      error ? (
                        <ExclamationCircleIcon />
                      ) : isSuccess ? (
                        <BeakerIcon />
                      ) : undefined
                    }>
                    {!!error && <>An Error Occured. Try again?</>}
                    {!error && (
                      <>
                        {isImporting
                          ? 'Importing'
                          : isSuccess
                          ? 'Success'
                          : 'Import'}
                      </>
                    )}
                  </Button>
                  {error && (
                    <p
                      role="alert"
                      aria-live="polite"
                      className="text-sm text-red-600">
                      {error.message}
                    </p>
                  )}
                </div>
              </form>
            </Modal>
          </div>
        )}
      </div>
      <ResourceTabs mdResource={selectedResource} />
    </div>
  )
}
