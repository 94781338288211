import { Button, Checkbox, Spinner, Table } from '@forge/common'
import { hasApp, ListingServicesTable } from '@forge/features/listingservices'
import { Suspense } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAdapter } from './useAdapter'

export function AdapterConnections() {
  const { adapterId } = useParams()
  const { data: { adapter } = {} } = useAdapter({ id: adapterId })

  if (!adapter?.listingServices?.length) {
    return (
      <Suspense
        fallback={
          <div className="mt-5 flex items-center gap-2">
            <div className="h-5 w-5 text-orange-400">
              <Spinner />
            </div>
            <div className="text-sm font-medium text-gray-500">
              Loading Listing Services
            </div>
          </div>
        }>
        <div className="mt-5 flex flex-col space-y-4">
          <p className="self-center pb-1 text-center text-sm font-semibold text-gray-700 xs:max-w-xs sm:max-w-lg">
            Add a new listing service or add from an existing Listing Service to
            link.
          </p>
          <span className="self-end">
            <Button as={Link} to="/listing_services/new">
              New Listing Service
            </Button>
          </span>
          <ListingServicesTable />
        </div>
      </Suspense>
    )
  }

  return (
    <div className="rounded-lg bg-white p-1 shadow">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header align="left">Key</Table.Header>
            <Table.Header align="left">Name</Table.Header>
            <Table.Header align="center">CMA</Table.Header>
            <Table.Header align="center">MLX</Table.Header>
            <Table.Header align="center">Streams</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {adapter?.listingServices?.map(({ id, key, name, activations }) => (
            <Table.Row key={id}>
              <Table.Data>
                <Link
                  className="hover:text-orange-400"
                  to={`/listing_services/${id}/details`}>
                  {key?.toUpperCase()}
                </Link>
              </Table.Data>

              <Table.Data>
                <Link
                  className="hover:text-orange-400"
                  to={`/listing_services/${id}/details`}>
                  {name}
                </Link>
              </Table.Data>

              <Table.Data align="center">
                <Link
                  className="hover:text-orange-400"
                  to={`/listing_services/${id}/details`}>
                  <Checkbox
                    size="lg"
                    readOnly
                    checked={hasApp(activations, 'CMA Production')}></Checkbox>
                </Link>
              </Table.Data>
              <Table.Data align="center">
                <Link
                  className="hover:text-orange-400"
                  to={`/listing_services/${id}/details`}>
                  <Checkbox
                    size="lg"
                    readOnly
                    checked={hasApp(activations, 'MLX Production')}></Checkbox>
                </Link>
              </Table.Data>
              <Table.Data align="center">
                <Link
                  className="hover:text-orange-400"
                  to={`/listing_services/${id}/details`}>
                  <Checkbox
                    size="lg"
                    readOnly
                    checked={hasApp(
                      activations,
                      'Streams Production'
                    )}></Checkbox>
                </Link>
              </Table.Data>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
